import React from 'react';
import { confirmable, createConfirmation, ReactConfirmProps } from "react-confirm";
import ThemedDialog from "../Components/ThemedDialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";

type ChoiceResult = "primary" | "secondary";

const ChoiceDialog = (props: ReactConfirmProps<ChoiceResult | undefined> & Options) => {
	return (
		<ThemedDialog open={props.show} maxWidth={props.maxWidth || "sm"} fullWidth onClose={() => props.proceed(undefined)}>
			<DialogTitle>{props.title || "Bestätigen"}</DialogTitle>
			<DialogContent>
				<DialogContentText>{props.message}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					color="secondary"
					onClick={() => props.proceed("secondary")}
				>
					{props.secondary || "Abbrechen"}
				</Button>
				<Button
					color="primary"
					onClick={() => props.proceed("primary")}
				>
					{props.primary || "OK"}
				</Button>
			</DialogActions>
		</ThemedDialog>
	);
};

interface Options {
	title?: string
	message?: string | JSX.Element
	primary?: string
	secondary?: string
	maxWidth?: false | "lg" | "md" | "sm" | "xl" | "xs" | undefined
}

export default (options: Options) => {
	const dialog = confirmable(props => <ChoiceDialog {...props} {...options} />);
	return createConfirmation<ChoiceResult | undefined>(dialog)();
};
