import React from "react";
import FormYesNo from "../../../../Components/FormYesNo";
import FormTiresAndRims from "../../../../Components/FormTiresAndRims";
import FormUpload from "../../../../Components/FormUpload";
import { Form } from "../../../../system/useForm";

export default ({ form } : { form : Form }) => {
	return (
		<>
			<FormYesNo
				name="additionalTiresAndRims"
				label="Wurden weitere Reifen und Felgen abgegeben?"
				form={form}
				options={{
					required: true
				}}
			/>
			{form.getValue("additionalTiresAndRims") && (
				<>
					<FormTiresAndRims
						canHaveNoRims
						name="suppliedTiresAndRims"
						form={form}
					/>
					<FormUpload
						name="suppliedTiresAndRims.picture"
						label="Foto"
						form={form}
						options={{
							required: true
						}}
					/>
				</>
			)}
		</>
	);
};
