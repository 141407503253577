import { ThemeColor } from "../system/Domain";
import React, { ReactElement, ReactNode } from "react";
import { alpha, Box, BoxProps, CircularProgress, colors, lighten, useTheme } from "@mui/material";

interface Props extends BoxProps {
	label: ReactNode;
	icon?: ReactElement | null;
	loading?: boolean;
	color?: ThemeColor;
	active?: boolean;
}

export default ({ label, icon = null, loading = false, active = false, color = "info", ...rest }: Props) => {
	const theme = useTheme();
	const primary = {
		borderColor: theme.palette.primary.main,
		color: theme.palette.primary.light,
		"&:hover": {
			backgroundColor: alpha(theme.palette.primary.main, 0.1),
		},
	};

	const success = {
		borderColor: theme.palette.success.main,
		color: theme.palette.success.light,
		"&:hover": {
			backgroundColor: alpha(theme.palette.success.main, 0.1),
		},
	};

	const info = {
		borderColor: colors.grey[500],
		color: lighten(colors.grey[500], 0.3),
		"&:hover": {
			backgroundColor: alpha(colors.grey[500], 0.1),
		},
	};

	const warning = {
		borderColor: theme.palette.warning.main,
		color: theme.palette.warning.light,
		"&:hover": {
			backgroundColor: alpha(theme.palette.warning.main, 0.1),
		},
	};

	const error = {
		borderColor: theme.palette.error.main,
		color: theme.palette.error.light,
		"&:hover": {
			backgroundColor: alpha(theme.palette.error.main, 0.1),
		},
	};

	const getColorStyles = (color: string): any => {
		switch (color) {
			case "success":
				return success;
			case "warning":
				return warning;
			case "error":
				return error;
			case "info":
				return info;
			default:
				return primary;
		}
	};

	const getActiveStyles = (color: string): any => {
		switch (color) {
			case "success":
				return {
					backgroundColor: alpha(theme.palette.success.main, 0.2),
				};
			case "warning":
				return {
					backgroundColor: alpha(theme.palette.warning.main, 0.2),
					color: theme.palette.warning.main,
				};
			case "error":
				return {
					backgroundColor: alpha(theme.palette.error.main, 0.2),
				};
			case "info":
				return {
					backgroundColor: alpha(colors.grey[500], 0.2),
					color: lighten(colors.grey[500], 0.5),
				};
			default:
				return {
					backgroundColor: alpha(theme.palette.primary.main, 0.2),
				};
		}
	};
	if (!!icon) {
		icon = React.cloneElement(icon, {
			sx: {
				fontSize: "1rem",
				opacity: 0.7,
			},
		});
	}

	return (
		<Box
			sx={[
				{
					cursor: "default",
					display: "flex",
					flexDirection: "row",
					paddingTop: theme.spacing(0.5),
					paddingBottom: theme.spacing(0.5),
					paddingLeft: theme.spacing(0.75),
					paddingRight: theme.spacing(0.75),
					borderWidth: 1,
					borderStyle: "solid",
					borderRadius: theme.spacing(2),
					fontSize: "0.7rem !important",
					transitionProperty: "background-color,color,border-color",
					transitionDuration: "300ms",
					whiteSpace: "nowrap",
					"&:hover": {
						backgroundColor: "unset",
					},
				},
				!!rest.onClick && { cursor: "pointer" },
				loading && {
					borderColor: alpha(colors.grey[500], 0.2),
					color: alpha(colors.grey[500], 0.5),
				},
				active && {
					fontWeight: 600,
					borderWidth: 1.5,
					...getActiveStyles(color),
				},
				getColorStyles(color),
			]}
			{...rest}
		>
			{loading && <CircularProgress size="0.9rem" color="inherit" sx={{ color: alpha(colors.grey[500], 0.5) }} />}
			{!loading && icon}
			<Box px={0.5}>{label}</Box>
		</Box>
	);
};
