import React from "react";
import { useParams } from "react-router";
import useVehicle from "../../../system/useVehicle";
import { useAsync } from "react-use";
import axios from "axios";
import { DekraAssessment, DekraAssessmentAttachment } from "../../../system/Domain";
import Layout from "../../../Components/Layout/Layout";
import LotScanContextMenu from "../LotScanContextMenu";
import VehicleHeaderMenu from "../VehicleHeaderMenu";
import VehicleSubMenu from "../VehicleSubMenu";
import { Box, CircularProgress, FormControl, Grid, NativeSelect } from "@mui/material";
import VehicleHeader from "../VehicleHeader";
import Tile from "../../../Components/Tiles/Tile";
import TileContent from "../../../Components/Tiles/TileContent";
import moment from "moment";
import Properties from "../../../Components/Properties";
import Property from "../../../Components/Property";
import DekraAssessmentAttachmentList from "./DekraAssessmentAttachmentList";
import parseContentDispositionFilename from "../../../system/parseContentDispositionFilename";
import fileDownload from "js-file-download";

const DekraAssessmentsPage = () => {
	const { id } = useParams<{ id : string }>();
	const [vehicle] = useVehicle(id);

	const { value: assessments } = useAsync(async () => {
		if (!vehicle) return [];

		const result = await axios.get<DekraAssessment[]>(`/api/dekra-assessments?vin=${vehicle.vin}`);
		return result.data;

	}, [vehicle]);

	const currentAssessment = assessments && assessments.length > 0 ? assessments[assessments.length - 1] : undefined;

	const handleDownload = async (attachment : DekraAssessmentAttachment) => {
		const response = await axios.get(`/api/dekra-assessments/${currentAssessment!.id}/attachments/${attachment.beschreibung}`, {
			responseType: "blob"
		});

		const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, filename);
	};

	return (
		<Layout
			contextMenu={[
				<LotScanContextMenu />,
				<VehicleHeaderMenu vehicle={vehicle} />
			]}
			subMenu={vehicle && <VehicleSubMenu vehicle={vehicle} />}
		>
			{!vehicle && (
				<CircularProgress size={48} />
			)}
			{vehicle && (
				<>
					<Grid container spacing={2} alignItems="flex-end" justifyContent="space-between">
						<Grid item>
							<VehicleHeader vehicle={vehicle} />
						</Grid>
						{currentAssessment && assessments && assessments.length > 1 && (
							<Grid item>
								<FormControl variant="outlined">
									<NativeSelect value={currentAssessment.dateCreated}>
										{assessments.map(assessment => {
											const date = moment(assessment.dateCreated);

											return (
												<option
													key={assessment.dateCreated}
													value={assessment.dateCreated}
												>
													Gutachten vom {date.format("DD.MM.YYYY HH:mm")}
												</option>
											)
										})}
									</NativeSelect>
								</FormControl>
							</Grid>
						)}
					</Grid>
					{currentAssessment && (
						<Box mt={4}>
							<Grid container spacing={6}>
								<Grid item xs={12} md={6}>
									<Tile
										title="Gutachten"
										subtitle={`Vom ${moment(currentAssessment.dateCreated).format("LLL")}`}
									>
										<TileContent>
											<Properties>
												<Property
													name="Auftragsnummer"
													value={currentAssessment.auftragsnummer}
												/>
												<Property
													name="Vorgangsnummer"
													value={currentAssessment.vorgangsnummer}
												/>
												<Property
													name="Kennzeichen"
													value={currentAssessment.fahrzeugdaten.akz}
												/>
											</Properties>
										</TileContent>
									</Tile>
								</Grid>
								<Grid item xs={12} md={6}>
									<Tile title="Anlagen">
										<TileContent>
											<DekraAssessmentAttachmentList
												onSelect={handleDownload}
												attachments={currentAssessment.attachments}
											/>
										</TileContent>
									</Tile>
								</Grid>
							</Grid>
						</Box>
					)}
				</>
			)}
		</Layout>
	);
};

export default DekraAssessmentsPage;
