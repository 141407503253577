import React from "react";
import { Box, Chip, PropTypes, TableBody, TableHead, TableRow } from "@mui/material";
import PaperTable from "../../Components/PaperTable";
import TextBlock from "../../Components/Layout/TextBlock";
import formatCurrency from "../../system/formatCurrency";
import formatNumber from "../../system/formatNumber";
import { BillingServices, BillingServicesBase } from "../../system/Domain";
import { Colgroup, TableCell } from "../../Components/BreakpointStyledComponents";

const row = (name: string, services: BillingServicesBase, color: Exclude<PropTypes.Color, 'inherit'> = "default") => {
	const title = (
		<Chip
			color={color}
			label={name}
		/>
	);

	const summary = (
		<TextBlock
			primary={formatCurrency(services.netAmount)}
			secondary={<span>{formatNumber(services.total)} Tätigkeiten</span>}
		/>
	);

	return (
		<TableRow
			key={name}
			hover
		>
			<TableCell>
				{title}
				<Box sx={{ display: { xs: "contents", sm: "none" } }}>
					<Box mt={2}>
						{summary}
					</Box>
				</Box>
			</TableCell>
			<TableCell sm md lg xl>
				{summary}
			</TableCell>
		</TableRow>
	);
};

export interface BillingServiceTableProps {
	services: BillingServices
}

export default ({ services }: BillingServiceTableProps) => {
	return (
		<PaperTable>
			<Colgroup sm md lg xl>
				<col width="50%" />
				<col width="50%" />
			</Colgroup>
			<TableHead>
				<TableRow>
					<TableCell>Tätigkeit</TableCell>
					<TableCell sm md lg xl>Zusammenfassung</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{services.types.length > 1 && row("Alle", services, "primary")}
				{services.types.map(t => row(t.text, t))}
			</TableBody>
		</PaperTable>
	);
}
