import React, { useState } from 'react';
import ThemedDialog from "../../../Components/ThemedDialog";
import { confirmable, createConfirmation, ReactConfirmProps } from "react-confirm";
import { Box, Button, DialogTitle } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Remarketing, ReturnOrder, TransportOrder } from "../../../system/Domain";
import AssessmentStatusActionTile from "./AssessmentStatusActionTile";

interface Props extends AlterReturnOrderAssessmentInformationOptions, ReactConfirmProps<OutputData> {
	show: boolean
}

interface OutputData {
	doAssessment: boolean
	unlinkAssessment: boolean
	relinkAssessment: boolean
	syncReturnOrder: boolean
}

const AlterReturnOrderAssessmentInformationDialog = (props: Props) => {
	const handleClose = () => {
		props.cancel();
	};

	const [doAssessment, setDoAssessment] = useState(false);
	const [unlinkAssessment, setUnlinkAssessment] = useState(false);
	const [relinkAssessment, setRelinkAssessment] = useState(false);
	const [syncReturnOrder, setSyncReturnOrder] = useState(false);

	return (
		<ThemedDialog open={props.show} maxWidth="sm" fullWidth onClose={handleClose}>
			<DialogTitle>
				Gutachtenbeauftragung - {props.transportOrder.vehicle.plateNumber}
			</DialogTitle>
			<DialogContent>
				<Box mb={2}>
					<AssessmentStatusActionTile
						transportOrder={props.transportOrder}
						returnOrder={props.returnOrder}
						remarketing={props.remarketing}
						setDoAssessment={setDoAssessment}
						setUnlinkAssessment={setUnlinkAssessment}
						setRelinkAssessment={setRelinkAssessment}
						setSyncReturnOrder={setSyncReturnOrder}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					color="secondary"
					href=""
					onClick={handleClose}
				>
					Abbrechen
				</Button>
				<Button
					variant="outlined"
					color="primary"
					href=""
					onClick={() => props.proceed({
						doAssessment: doAssessment,
						unlinkAssessment: unlinkAssessment,
						relinkAssessment: relinkAssessment,
						syncReturnOrder: syncReturnOrder
					})}
				>
					Ok
				</Button>
			</DialogActions>
		</ThemedDialog>
	);
};

interface AlterReturnOrderAssessmentInformationOptions {
	transportOrder: TransportOrder
	returnOrder?: ReturnOrder
	remarketing?: Remarketing
}

export default (options: AlterReturnOrderAssessmentInformationOptions) => {
	const dialog = confirmable(props => <AlterReturnOrderAssessmentInformationDialog {...props} {...options} />);
	return createConfirmation<OutputData>(dialog)();
}
