import React from "react";
import _ from "lodash";
import { Box, Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { TransportPricing } from "../../../system/Domain";
import TileContent from "Components/Tiles/TileContent";
import TextBlock from "Components/Layout/TextBlock";
import formatCurrency from "system/formatCurrency";
import { PricingViewRowCells, PricingViewHeaderCells } from "./PricingViewCells"

interface Props {
	pricing: TransportPricing
}

const PricingView = ({ pricing }: Props) => {
	const maxVehicles = _(pricing.areasPricing)
		.flatMap((ap) => _(ap.prices).flatMap((p) => p.numberOfVehicle).value())
		.max();
	const vehicleNumbers = Array.from({ length: maxVehicles }, (_, index) => index + 1);

	return (
		<>
			<TileContent>
				<Box>
					<Grid container>
						<Grid item xs={8} sm={6} md={4} xl={2}>
							<TextBlock
								primary="Fehlfahrt"
								secondary={formatCurrency(pricing.missedTransportPrice)}
							/>
						</Grid>
					</Grid>
				</Box>
			</TileContent>
			<TileContent>
				<Box>
					<TextBlock primary="Transport" />
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6} md={4} xl={1}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell sx={{ whiteSpace: 'nowrap' }}>PLZ</TableCell>
										<PricingViewHeaderCells vehicleNumbers={vehicleNumbers} />
									</TableRow>
								</TableHead>
								<TableBody>
									{pricing.areasPricing.map((ap, apInd) => (
										<TableRow key={apInd}>
											<TableCell>{ap.areaCode}</TableCell>
											<PricingViewRowCells vehicleNumbers={vehicleNumbers} areaPrices={ap} />
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Grid>
					</Grid>
				</Box>
			</TileContent>
		</>
	);
};

export default PricingView;
