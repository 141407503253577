import React from "react";
import Layout from "../../../Components/Layout/Layout";
import { useParams } from "react-router";
import { useAsync } from "react-use";
import { Vehicle } from "../../../system/Domain";
import axios from "axios";
import { Box, LinearProgress } from "@mui/material";
import VehiclePictureList from "Components/VehiclePictureList";

export default () => {
	const { vin } = useParams<{ vin : string }>();

	const { value: vehicle, loading } = useAsync(async () => {
		const { data: vehicles } = await axios.get<Vehicle[]>(`/api/vehicles?vin=${vin}`);
		return vehicles.length === 1 ? vehicles[0] : null;
	}, [vin]);

	let pictureSet;
	if (vehicle && vehicle.pictureSets.length > 0) {
		pictureSet = vehicle.pictureSets[vehicle.pictureSets.length - 1];
	}

	return (
		<Layout>
			{loading && (
				<LinearProgress/>
			)}
			{pictureSet && (
				<Box>
					<VehiclePictureList vin={vin} pictureSet={pictureSet}/>
				</Box>
			)}
		</Layout>
	)
}
