import React, {useState} from "react";
import ListItem from "../../../Components/ListItem";
import ListItemActions from "../../../Components/ListItemActions";
import ListItemAction from "../../../Components/ListItemAction";
import {Check, Clear, Edit, Reply} from "@mui/icons-material";
import ListItemContent from "../../../Components/ListItemContent";


interface LinkingIndicatorProps {
	link : () => void
	changeLink : boolean

	setChangeLink: React.Dispatch<React.SetStateAction<boolean>>

	captionChangeLink : string

	captionIsLinked : string
}


export const LinkingIndicator = (props : LinkingIndicatorProps) => {

	const [editMode, setEditMode] = useState(false);
	const [changeLinkPreEdit, setChangeLinkPreEdit] = useState(props.changeLink);
	const [changeLink, setChangeLink] = useState(props.changeLink);

	return (
		<ListItem color={changeLink ? "primary" : "error"}>
			<ListItemActions>
				{!editMode && (
					<>
						<ListItemAction
							icon={<Edit />}
							onClick={() =>{
								setChangeLinkPreEdit(changeLink);
								setEditMode(true)
							}}
						/>
						<ListItemAction
							icon={<Reply />}
							onClick={props.link}
						/>
					</>
				)}
				{editMode && (
					<>
						<ListItemAction
							icon={<Check />}
							onClick={() => {
								props.setChangeLink(changeLink);
								setEditMode(false)
							}}
						/>
						<ListItemAction
							icon={<Clear />}
							onClick={() => {
								setChangeLink(changeLinkPreEdit);
								setEditMode(false)
							}}
						/>
					</>
				)}

			</ListItemActions>
			<ListItemContent onClick={editMode ? () => setChangeLink(g => !g) : undefined}>
				{changeLink && (
					<>{props.captionChangeLink}</>
				)}

				{!changeLink && (
					<>{props.captionIsLinked}</>
				)}

			</ListItemContent>
		</ListItem>
	);
};



interface DoOperationIndicatorProps{
	doOperation : boolean
	setDoOperation : React.Dispatch<React.SetStateAction<boolean>>
	captionDoOperation : string
	captionNotDoOperation : string
}

export const DoOperationIndicator = (props : DoOperationIndicatorProps) => {
	const [doOperation, setDoOperation] = useState(props.doOperation);
	const [editMode, setEditMode] = useState(false);
	const [doOperationPreEdit, setDoOperationPreEdit] = useState(props.doOperation);

	return (
		<ListItem color={doOperation ? "primary" : "grey"}>
			<ListItemActions>
				{!editMode && (
					<ListItemAction
						icon={<Edit />}
						onClick={() => {
							setDoOperationPreEdit(doOperation);
							setEditMode(true)
						}}
					/>
				)}
				{editMode && (
					<>
						<ListItemAction
							icon={<Check />}
							onClick={() => {
								props.setDoOperation(doOperation);
								setEditMode(false)
							}}
						/>
						<ListItemAction
							icon={<Clear />}

							onClick={() => {
								setDoOperationPreEdit(doOperationPreEdit);
								setEditMode(false)
							}}
						/>
					</>
				)}
			</ListItemActions>
			<ListItemContent
				onClick={editMode ? () => setDoOperation(g => !g) : undefined}
			>
				{doOperation && (
					<>{props.captionDoOperation}</>
				)}
				{!doOperation && (
					<>{props.captionNotDoOperation}</>
				)}
			</ListItemContent>
		</ListItem>
	);
};


export const openLink = (url : string) => {
	const tempLink = document.createElement('a');
	tempLink.style.display = 'none';
	tempLink.href = url;
	tempLink.setAttribute('target', '_blank');
	document.body.appendChild(tempLink);
	tempLink.click();
};
