import React from "react";
import { confirmable, createConfirmation, ReactConfirmProps } from "react-confirm";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import ThemedDialog from "../Components/ThemedDialog";

const MessageDialog = ({ show, proceed, title, message, maxWidth = "sm" }: ReactConfirmProps<void> & Props) => {
	return (
		<ThemedDialog
			open={show}
			maxWidth={maxWidth}
			fullWidth
			onClose={() => proceed()}
		>
			<DialogTitle>{title || "Bestätigen"}</DialogTitle>
			<DialogContent>
				<Typography>{message}</Typography>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => proceed()}
				>
					OK
				</Button>
			</DialogActions>
		</ThemedDialog>
	);
};

interface Props {
	message: string | JSX.Element
	title?: string
	maxWidth?: false | "lg" | "md" | "sm" | "xl" | "xs" | undefined
}

export default (inputProps: Props) => {
	const dialog = confirmable(props => <MessageDialog {...props} {...inputProps} />);
	return createConfirmation(dialog)();
};
