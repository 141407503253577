import React from "react";
import { CustomerOrder } from "./system/Domain";

export const capitalize = function (str : string) {
	return str.charAt(0).toUpperCase() + str.substr(1);
};

export function getEnumName<T extends { [index : string] : string }>(enumType : T, enumValue : string) : keyof T | null {
	let keys = Object.keys(enumType).filter(x => x === enumValue);
	return keys.length > 0 ? enumType[keys[0]] : null;
};

export const copyClick = (e : React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>, content : string) => {
	e.preventDefault();

	const textField = document.createElement('textarea');
	textField.innerText = content;
	document.body.appendChild(textField);
	if (window.navigator.platform === 'iPhone') {
		textField.setSelectionRange(0, 99999)
	} else {
		textField.select()
	}
	document.execCommand('copy')
	textField.remove()
};

export const generateCustomerOrderLink = (customerOrder:CustomerOrder):string => {
	return `${window.location.protocol}//${window.location.host}/orders/customers/form/${customerOrder?.id}?access_token=${customerOrder?.token}`;
};
