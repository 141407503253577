import Tile from "./Tiles/Tile";
import { useState } from 'react';
import VehiclePicture from "./VehiclePicture";
import TileContent from "./Tiles/TileContent";
import { FileReference } from "../system/Domain";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { Box, Checkbox, FormControlLabel, IconButton, MenuItem, Select, useTheme } from "@mui/material";

export interface PictureReference {
	title: string
	picture: FileReference
}

interface Props {
	pictures: PictureReference[]
}

const PicturesTile = (props: Props) => {
	const theme = useTheme();
	const [useThumbnails, setUseThumbnails] = useState(false);

	const [selectedPicture, setSelectedPicture] = useState<PictureReference>(props.pictures[0]);

	if (!props.pictures || props.pictures.length === 0) {
		return (<></>);
	}

	const previousPicture = () => {
		const index = props.pictures.indexOf(selectedPicture);
		if (index === 0) {
			return;
		}

		setSelectedPicture(props.pictures[index - 1]);
	};

	const nextPicture = () => {
		const index = props.pictures.indexOf(selectedPicture);
		if (index === props.pictures.length - 1) {
			return;
		}

		setSelectedPicture(props.pictures[index + 1]);
	};

	return (
		<Tile title="Fotos">
			<TileContent dense>
				<Box
					display="flex"
					justifyContent="space-between"
					m={0.5}
				>
					<IconButton
						disabled={props.pictures[0] === selectedPicture}
						onClick={() => previousPicture()}
					>
						<ArrowLeft />
					</IconButton>
					<Select
						variant="standard"
						value={selectedPicture?.title}
						onChange={e => {
							const picture = props.pictures.find(p => p.title === e.target.value);
							if (!!picture) {
								setSelectedPicture(picture);
							}
						}}
						disableUnderline
					>
						{props.pictures.map(p => (
							<MenuItem key={p.title} value={p.title}>{p.title}</MenuItem>
						))}

					</Select>
					<IconButton
						disabled={props.pictures[props.pictures.length - 1] === selectedPicture}
						onClick={() => nextPicture()}
					>
						<ArrowRight />
					</IconButton>
				</Box>
				<Box
					sx={{
						gap: theme.spacing(2)
					}}
					display="flex"
					flexWrap="wrap"
				>
					{selectedPicture && (
						<VehiclePicture
							key={selectedPicture.picture.hash}
							picture={selectedPicture.picture}
							thumbnail={useThumbnails}
						/>
					)}
				</Box>
			</TileContent>
			<Box display="flex" justifyContent="flex-end" mt={1}>
				<FormControlLabel
					control={(
						<Checkbox
							checked={useThumbnails}
							onChange={e => setUseThumbnails(e.target.checked)}
						/>
					)}
					label="Geringere Auflösung benutzen"
				/>
			</Box>
		</Tile>
	);
};

export default PicturesTile;
