import React, { useState } from 'react';
import { useHistory } from "react-router";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import SidebarGroup from "../../../Components/Sidebar/SidebarGroup";
import SidebarButton from "../../../Components/Sidebar/SidebarButton";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { vehicleRegistrationDocumentStates, VehicleRegistrationDocumentStatus } from "../../../system/Domain";
import SidebarForm from "../../../Components/Sidebar/SidebarForm";
import axios from "axios";
import parseContentDispositionFilename from "../../../system/parseContentDispositionFilename";
import fileDownload from "js-file-download";
import { translateVehicleRegistrationDocumentStatus } from "./VehicleRegistrationDocumentsStatusPill";

const VehicleDocumentManagementSidebar = () => {
	const history = useHistory();
	const [states, setStates] = useState<VehicleRegistrationDocumentStatus[]>(["Requested", "NotArrived", "PartiallyArrived"]);

	const triggerStatusExport = async () => {
		const response = await axios.post("/api/vehicles/remarketing/registration-documents/export/status", {
			states: states
		}, {
			responseType: "blob",
		});

		const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, filename);
	};

	return (
		<Sidebar>
			<SidebarGroup>
				<SidebarButton
					label="Manueller Dokumenteneingang"
					color="primary"
					onClick={() => history.push(`/vehicles/registration-documents/manual/arrivals`)}
				/>
				<SidebarButton
					label="Dokumenteneingang"
					color="primary"
					onClick={() => history.push(`/vehicles/registration-documents/arrivals`)}
				/>
			</SidebarGroup>
			<SidebarGroup>
				<SidebarForm>
					<FormControl variant="standard" fullWidth>
						<InputLabel>Status</InputLabel>
						<Select
							multiple
							multiline
							value={states || []}
							onChange={(e) => {
								const value = e.target.value as VehicleRegistrationDocumentStatus[];
								if (!value) {
									return;
								}

								setStates(value);
							}}
						>
							{vehicleRegistrationDocumentStates.filter(f => f !== "Unknown" && f !== "Storage").map(s => (
								<MenuItem value={s}>
									{translateVehicleRegistrationDocumentStatus(s)}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</SidebarForm>
				<SidebarButton
					label="Export"
					color="primary"
					onClick={triggerStatusExport}
				/>
			</SidebarGroup>
			<SidebarGroup>
				<SidebarButton
					label="Importe"
					color="primary"
					onClick={() => history.push(`/vehicles/registration-documents/imports/unregistrations`)}
				/>
			</SidebarGroup>
		</Sidebar>
	);
};

export default VehicleDocumentManagementSidebar;
