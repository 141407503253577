import React from "react";
import Tile from "../../../Components/Tiles/Tile";
import TileContent from "../../../Components/Tiles/TileContent";
import { ArmsAttachment } from "./ArmsVehicle";
import { Box, Link } from "@mui/material";

interface ArmsAttachmentsTileProps {
	attachments : ArmsAttachment[]
	onClick? : (value : ArmsAttachment) => void
}

const ArmsAttachmentsTile = ({ attachments, onClick } : ArmsAttachmentsTileProps) => {
	const handleClick = (value : ArmsAttachment) => {
		onClick && onClick(value);
	};

	return (
		<Tile title="Dateianhänge">
			<TileContent>
				{attachments.map(a => (
					<Box>
						<Link onClick={() => handleClick(a)}>{a.file.filename}</Link>
					</Box>
				))}
				{attachments.length === 0 && (
					<Box>Keine Daten gefunden</Box>
				)}
			</TileContent>
		</Tile>
	)
};

export default ArmsAttachmentsTile;
