import React, { PropsWithChildren, ReactElement } from "react";

export interface InlineListProps {
	delimiter?: string | ReactElement | null | boolean;
}

export default ({ children, delimiter = null }: PropsWithChildren<InlineListProps>) => {
	if (!delimiter) {
		delimiter = <span> · </span>;
	}

	let first = true;

	const items = React.Children.map(children, (child: any) => {
		if (!child) {
			return;
		}

		const item = (
			<>
				{!first && delimiter}
				{child}
			</>
		);

		first = false;

		return item;
	});

	return <>{items}</>;
};
