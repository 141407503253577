import { Box } from "@mui/material";
import TextBlock from "./Layout/TextBlock";
import React, { ReactNodeArray } from "react";

interface Props {
	plateNumber: string;
	entries: ReactNodeArray;
	additionalEntries?: ReactNodeArray;
}

const AldVehicleHeader = (props: Props) => {
	const additionalEntries = props.additionalEntries && props.additionalEntries.filter((f) => !!f);

	return (
		<TextBlock
			// primary={<Plate plateNumber={props.plateNumber} />}
			secondary={
				<Box alignItems="baseline">
					{props.entries.map((e, i) => (
						<React.Fragment key={i}>
							<span>{e}</span>
							{i < props.entries.length - 1 && <span> · </span>}
						</React.Fragment>
					))}
					{additionalEntries &&
						additionalEntries.length > 0 &&
						additionalEntries.map((a, i) => (
							<React.Fragment key={i}>
								<span> · </span>
								{a}
							</React.Fragment>
						))}
				</Box>
			}
		/>
	);
};

export default AldVehicleHeader;
