import React from "react";
import { Box, Divider, List, ListItem, ListProps } from "@mui/material";
import Text from "Pages/VehicleSales/Text";
import { ReturnForm } from "system/Domain";

import { damageTypes, damageComponents } from "system/damages";

interface Props extends ListProps {
	returnForm: ReturnForm
}

const RemarketingReturnFormDamages = ({ returnForm }: Props) => {
	const specialDamageTags = [damageComponents.exterior['Windshield']];

	const IndexedListItem = ({ index, itemText }: { index: number, itemText: string }) => {
		const isSpecialDamage = itemText && specialDamageTags.some((dt => itemText.toLowerCase().includes(dt.toLowerCase())));

		return (
			<ListItem key={index}>
				<Text
					value={`${index}. ${itemText}`}
					color={isSpecialDamage ? 'warning' : null}
				/>
			</ListItem>
		);
	};

	return (
		<Box sx={{ fontSize: "0.7rem" }}>
			{returnForm?.damagesExterior && returnForm?.damagesExterior.length > 0 && (
				<List>
					{returnForm.damagesExterior.map((de, index) => {
						return (
							<IndexedListItem index={index + 1} itemText={`${damageComponents.exterior[de.component]}, ${damageTypes.exterior[de.type]}`} />
						);
					})}
				</List>
			)}
			{returnForm?.damagesInterior && returnForm?.damagesInterior.length > 0 && (
				<>
					<Box>
						<Divider />
					</Box>
					<List>
						{returnForm.damagesInterior.map((di, index) => {
							return (
								<IndexedListItem index={index + 1} itemText={`${damageComponents.interior[di.component]}, ${damageTypes.interior[di.type]}`} />
							);
						})}
					</List>
				</>
			)}
		</Box>
	)
};

export default RemarketingReturnFormDamages;
