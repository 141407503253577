import React from "react";
import { confirmable, createConfirmation, ReactConfirmProps } from "react-confirm";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import ThemedDialog from "../Components/ThemedDialog";
import DialogContentText from "@mui/material/DialogContentText";

interface ConfirmResults {
	ok? : boolean
}

interface ConfirmDialogProps extends ReactConfirmProps<ConfirmResults> {
	message : string
	title?: string
}

const ConfirmDialog = ({ show, proceed, title, message } : ConfirmDialogProps ) => {
	return (
		<ThemedDialog open={show} maxWidth="sm" fullWidth onClose={() => proceed({})}>
			<DialogTitle>{title || "Bestätigen"}</DialogTitle>
			<DialogContent>
				<DialogContentText>{message}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					color="secondary"
					onClick={() => proceed({})}
				>
					Abbrechen
				</Button>
				<Button
					color="primary"
					onClick={() => proceed({ ok: true })}
				>
					OK
				</Button>
			</DialogActions>
		</ThemedDialog>
	);
};

export default (message: string, title?: string) => {
	const dialog = confirmable(props => <ConfirmDialog {...props} message={message} title={title} />);
	return createConfirmation<ConfirmResults>(dialog)();
};
