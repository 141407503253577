import React, { Dispatch, SetStateAction } from "react";
import { Grid } from "@mui/material";
import useForm from "../../system/useForm";
import FormText from "../../Components/FormText";
import { FleetnetVehicleQuery } from "../../system/Domain";
import Tile from "../../Components/Tiles/Tile";
import FormNumber from "../../Components/FormNumber";

interface Props {
	query : FleetnetVehicleQuery
	setQuery : Dispatch<SetStateAction<FleetnetVehicleQuery>>
}

export default ({ query, setQuery } : Props) => {
	const form = useForm({
		values: query,
		setValues: setQuery
	});

	return (
		<Tile title="Suche">
			<Grid container spacing={3}>
				<FormText
					form={form}
					name="plateNumber"
					label="Kennzeichen"
					fullWidth
				/>
				<FormText
					form={form}
					name="vin"
					label="FIN"
					fullWidth
				/>
				<FormNumber
					form={form}
					name="clientNr"
					label="Fuhrpark Nummer"
					fullWidth
				/>
				<FormNumber
					form={form}
					name="nr"
					label="Kfz Nummer"
					fullWidth
				/>
			</Grid>
		</Tile>
	);
}
