import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setUserMessage } from "./reducers/userMessage/actions";

export default (value : any) => {
	const dispatch = useDispatch();

	const copyWithAltKey = useCallback((e : React.MouseEvent<HTMLElement, MouseEvent>) => {
		if (!e.altKey) {
			return;
		}

		if (!value || typeof value === "object") {
			return;
		}

		const text = value.toString();
		if (!text) {
			return;
		}

		if (e.altKey) {
			e.stopPropagation();
			navigator.clipboard.writeText(text);
			dispatch(setUserMessage(`Text kopiert: ${text}`, "success"));
		}
	}, [value]);

	return copyWithAltKey;
}
