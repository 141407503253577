import React from 'react';
import { confirmable, createConfirmation, ReactConfirmProps } from "react-confirm";
import ThemedDialog from "../../Components/ThemedDialog";
import { DialogContent, DialogTitle } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";

interface YesNoResult {
	yes?: boolean
}

interface Props {
	title: string
	message: string
}

const CustomerConfirmationDialog = (props: ReactConfirmProps<YesNoResult> & Props) => {
	return (
		<ThemedDialog open={props.show} maxWidth="sm" fullWidth onClose={() => props.proceed({})}>
			<DialogTitle>{props.title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{props.message}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					color="secondary"
					onClick={() => props.proceed({})}
				>
					Nein
				</Button>
				<Button
					color="primary"
					onClick={() => props.proceed({ yes: true })}
				>
					Ja
				</Button>
			</DialogActions>
		</ThemedDialog>
	);
};

export default (inputProps: Props) => {
	const dialog = confirmable(props => <CustomerConfirmationDialog {...props} {...inputProps} />);
	return createConfirmation<YesNoResult>(dialog)();
};
