import React, { useState } from "react";
import { Box, CircularProgress, List, ListItem, ListProps, useTheme } from "@mui/material";
import axios from "axios";
import { Vehicle, VehicleService } from "system/Domain";
import useAsyncEffect from "system/useAsyncEffect";
import VehicleServiceTypeIcon from "Pages/VehicleServices/VehicleServiceTypeIcon";
import TextBlock from "../../Components/Layout/TextBlock";
import { vehicleServiceStatusInformation } from "../VehicleServices/VehicleServiceStatusInformation";

interface Props extends ListProps {
	vehicle: Vehicle
	onLoaded?: (damagesCount: number) => void
}

const RemarketingVehicleServices = ({ vehicle, onLoaded }: Props) => {
	const theme = useTheme();
	const [services, setServices] = useState<VehicleService[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	useAsyncEffect(async (cancelToken) => {
		setIsLoading(true);

		try {
			const { data: services } = await axios.get<VehicleService[]>(`/api/vehicles/services`, {
				params: {
					vehicleId: vehicle.id,
					sort: 'dateCreated:desc'
				},
				cancelToken: cancelToken.getAxiosToken()
			});

			setServices(services);
			if (onLoaded) {
				onLoaded(services.length);
			}
		} finally {
			setIsLoading(false);
		}
	}, [vehicle]);

	return (
		<List sx={{ fontSize: "0.7rem", paddingBottom: 0 }}>
			{services.map(s => {
				const statusInfo = vehicleServiceStatusInformation(s);

				return (
					<ListItem key={s.text} sx={{ paddingLeft: 0 }}>
						<Box sx={{ display: "flex", direction: "row", gap: theme.spacing(1.5) }}>
							<VehicleServiceTypeIcon vehicleServiceType={s.serviceType} />
							<TextBlock
								primary={s.serviceType.text}
								secondary={statusInfo.text}
								tertiary={statusInfo.date}
							/>
						</Box>
					</ListItem>
				);
			})}
			{!isLoading && services.length === 0 && (
				<ListItem>Keine Daten gefunden oder nicht geladen</ListItem>
			)}
			{isLoading && (
				<CircularProgress size={24} />
			)}
		</List>
	);
};

export default RemarketingVehicleServices;
