import Tile from "../../Components/Tiles/Tile";
import { Box } from "@mui/material";
import TileContent from "../../Components/Tiles/TileContent";
import React, { ReactElement } from "react";

interface CustomerChoiceProps {
	title: string
	icon: ReactElement
}

const CustomerChoiceDisplay = (props: CustomerChoiceProps) => {
	return (
		<Tile>
			<Box display="flex">
				<div
					style={{
						width: "3px", backgroundColor: "white", float: "left"
					}}
				>
				</div>
				<TileContent>
					<Box
						p={2}
						fontSize="18px"
						fontWeight="300"
						display="flex" flexDirection="row" justifyContent="space-between">
						<Box display="flex" flexDirection="column" justifyContent="space-around">
							{props.icon}
						</Box>
						<Box ml={2} textAlign="left">
							{props.title}
						</Box>
					</Box>
				</TileContent>
			</Box>
		</Tile>
	);
};

export default CustomerChoiceDisplay;
