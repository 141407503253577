import React from "react";
import { Table, TableBody } from "@mui/material";

const Properties = ({children}: {children: React.ReactNode}) => (
	<Table padding="none">
		<TableBody>
			{children}
		</TableBody>
	</Table>
);

export default Properties;
