import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Sale, SaleOrder } from "../../system/Domain";
import formatCurrency from "../../system/formatCurrency";
import { useHistory } from "react-router";
import TextBlock from "../../Components/Layout/TextBlock";
import ListItemChips from "../../Components/ListItemChips";
import { paintSaleOrderStatus, saleOrderStatusLabels } from "./Orders/SalesOrderPage";
import ListItemChip from "../../Components/ListItemChip";
import ListItem from "Components/ListItem";
import ListItemContent from "Components/ListItemContent";
import ListItemActions from "Components/ListItemActions";
import { Launch } from "@mui/icons-material";
import ListItemAction from "../../Components/ListItemAction";
import _ from "lodash";
import useVat from "../../system/useVat";

interface Props {
	sale : Sale
	order : SaleOrder
}

export default ({ sale, order } : Props) => {
	const history = useHistory();
	const vat = useVat(sale.dateSold);
	const [salesAmount, setSalesAmount] = useState<number | null>(0);

	let buyerName = order.buyer.name;
	if (order.buyer.name2) {
		buyerName = `${buyerName} ${order.buyer.name2}`;
	}

	const openOrder = () => history.push(`/sales/${sale.id}/orders/${order.id}`);

	useEffect(() => {
		if (!vat.fullVatRate) {
			setSalesAmount(null);
			return;
		}

		let saleAmount = _.sum(order.lines.map(l => l.grossAmount));
		const hasVat = _.sum(order.lines.map(l => l.vat));

		if (hasVat === 0) {
			saleAmount = vat.calculateGrossAmount(saleAmount);
		}

		setSalesAmount(saleAmount);
	}, [vat, sale, order]);

	return (
		<ListItem>
			<ListItemActions>
				<ListItemAction
					icon={<Launch />}
					onClick={openOrder}
				/>
			</ListItemActions>
			<ListItemContent onClick={openOrder}>
				<TextBlock
					flat
					primary={buyerName}
					secondary={
						<Box>
							<Box>{order.buyer.email}</Box>
							<Box>{order.buyer.street}</Box>
							<Box>{order.buyer.zipCode} {order.buyer.city}</Box>
						</Box>
					}
				/>

				<ListItemChips>
					<ListItemChip
						label={saleOrderStatusLabels[order.status]}
						color={paintSaleOrderStatus(order.status)}
						onClick={openOrder}
					/>

					{order.lines?.length > 0 && (
						<ListItemChip
							label={formatCurrency(salesAmount)}
							onClick={openOrder}
						/>
					)}
				</ListItemChips>
			</ListItemContent>
		</ListItem>
	);
}
