import { useState } from "react";

export default <T>(key : string, executor : () => Promise<T>) => {
	let global_promise : Promise<T> = (window as any)[key];
	if (!global_promise) {
		global_promise = (window as any)[key] = executor();
	}

	const [state, setState] = useState<T>();
	if (!state) {
		global_promise.then(setState);
	}

	return [
		state
	];
};
