import React from "react";
import Property from "../../../Components/Property";
import { ArmsSimpleValue } from "./ArmsVehicle";
import armsValueConverter from "./armsValueConverter";

const ArmsProperty = ({ armsValue } : { armsValue : ArmsSimpleValue }) => {
	const value = armsValueConverter(armsValue);

	return (
		<Property name={armsValue.displayName} value={value} tooltip={armsValue.name}/>
	);
};

export default ArmsProperty;
