import React, { useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { Inventory } from "../../system/Domain";
import useAsyncEffect from "../../system/useAsyncEffect";
import axios from "axios";
import InventoryTable from "./InventoryTable";
import Layout from "../../Components/Layout/Layout";
import { useAsyncFn } from "react-use";
import InventoriesSidebar from "./InventoriesSidebar";
import VehiclesNavigation from "Navigation/VehiclesNavigation";

export default () => {
	const [inventories, setInventories] = useState<Inventory[]>([]);

	const [loadInventoriesState, loadInventories] = useAsyncFn(async () => {
		const { data: inventories } = await axios.get<Inventory[]>(`/api/inventories`);
		setInventories(inventories);
	}, []);

	useAsyncEffect(() => loadInventories(), []);

	return (
		<Layout
			title="Inventuren"
			navigation={<VehiclesNavigation />}
			sidebar={(
				<InventoriesSidebar
					inventories={inventories}
					setInventories={setInventories}
				/>
			)}
		>
			{loadInventoriesState.loading && (
				<CircularProgress size={48} />
			)}
			{!loadInventoriesState.loading && (
				<Box>
					{inventories.length === 0 && (
						<Box>Keine Inventuren vorhanden</Box>
					)}
					{inventories.length > 0 && (
						<InventoryTable
							inventories={inventories}
						/>
					)}
				</Box>
			)}
		</Layout>
	);
};
