import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { Clear, Delete, Edit, ExpandMore, InfoOutlined } from "@mui/icons-material";
import { Box, CircularProgress, Grid, Menu, MenuItem, TextField, useTheme } from "@mui/material";
import ActionButton from "../../Components/ActionButton";
import Layout from "../../Components/Layout/Layout";
import TextBlock from "../../Components/Layout/TextBlock";
import Tile from "../../Components/Tiles/Tile";
import VehicleBookmark from "../../Components/VehicleBookmark";
import VehicleNavigation from "../../Navigation/VehicleNavigation";
import dateFormats from "../../system/dateFormats";
import {
	GeoPoint,
	CompoundPlace,
	LotProcessStep,
	RecursivePartial,
	Vehicle,
	VehiclePicture,
} from "../../system/Domain";
import useAsyncEffect from "../../system/useAsyncEffect";
import useCompoundPlaces from "../../system/useCompoundPlaces";
import useVehicle from "../../system/useVehicle";
import LotMap from "./LotMap";
import LotScanContextMenu from "./LotScanContextMenu";
import VehicleHeader from "./VehicleHeader";
import VehicleHeaderMenu from "./VehicleHeaderMenu";
import VehicleSubMenu from "./VehicleSubMenu";
import ask from "Dialogs/ask";
import translateError from "system/translateError";

export default () => {
	const { id } = useParams<{ id: string }>();
	const [vehicle, setVehicle] = useVehicle(id);

	const [saving, setSaving] = useState<boolean>(false);
	const [processSteps, setProcessSteps] = useState<LotProcessStep[]>([]);
	const [vehiclePicture, setVehiclePicture] = useState<VehiclePicture | null>();

	const [showInlineNotes, setShowInlineNotes] = useState<boolean>(false);
	const [inputNote, setInputNote] = useState<boolean>(false);
	const [noteValue, setNoteValue] = useState<string>("");

	const theme = useTheme();
	const history = useHistory();

	useAsyncEffect(async () => {
		setProcessSteps([]);

		if (!vehicle) {
			return;
		}

		const { data: processSteps } = await axios.get<LotProcessStep[]>(
			`/api/vehicles/${vehicle.id}/process/available-steps`,
		);
		setProcessSteps(processSteps.filter((s) => s.status !== "Eingang"));
	}, [vehicle?.remarketing.status]);

	useAsyncEffect(async () => {
		if (!vehicle) {
			setVehiclePicture(null);
			return;
		}

		if (vehicle.pictureSets.length > 0) {
			// id of the set is the hash of the first and identifying picture
			setVehiclePicture({
				hash: vehicle.pictureSets[vehicle.pictureSets.length - 1].frontLeft.hash,
				filename: `${vehicle.vin}.jpg`,
				dmsId: "",
			});
			return;
		}

		try {
			const { data: vehiclePicture } = await axios.get<VehiclePicture | null>(
				`/api/vehicles/${vehicle.id}/picture`,
			);
			setVehiclePicture(vehiclePicture);
		} catch (e) {
			setVehiclePicture(null);
		}
	}, [vehicle]);

	useEffect(() => {
		setNoteValue(vehicle?.note?.text ?? "");
		setInputNote(!!vehicle?.note?.text);
	}, [vehicle]);

	const [places] = useCompoundPlaces();
	const [currentPlace, setCurrentPlace] = useState<CompoundPlace>();
	const [selectedPlace, setSelectedPlace] = useState<CompoundPlace>();
	const [location, setLocation] = useState<GeoPoint>();

	useEffect(() => {
		if (!vehicle?.placeId || !places) {
			setCurrentPlace(undefined);
			return;
		}

		const place = places.find((p) => p.id === vehicle.placeId);
		setCurrentPlace(place);
	}, [vehicle?.placeId, places]);

	const [additionalStepsAnchor, setAdditionalStepsAnchor] = useState<HTMLButtonElement | null>(null);

	const onLocationChanged = (location: GeoPoint) => setLocation(location);
	const onPlaceClick = (place: CompoundPlace) => {
		if (place === currentPlace || place === selectedPlace) {
			setSelectedPlace(undefined);
		} else {
			setSelectedPlace(place);
		}
	};

	interface IsUpdateAllowedResponse {
		isAllowed: boolean;
		errorMessage: string;
	}

	const canUpdateVehicle = async (update: RecursivePartial<Vehicle>) => {
		try {
			const { data } = await axios.post<IsUpdateAllowedResponse>(
				`/api/vehicles/${vehicle.id}/is-update-allowed`,
				update,
			);

			if (!data.isAllowed) {
				const { yes } = await ask(
					translateError(data.errorMessage, true) + ". Wollen Sie zur Fahrzeugsauslieferung gehen?",
				);

				if (yes) {
					history.push(`/vehicles/ship?saleId=${vehicle.remarketing.saleId}`);
				}
				return false;
			} else {
				return true;
			}
		} catch {
			return false;
		}
	};

	const updateVehicle = async (update: RecursivePartial<Vehicle>) => {
		if (!vehicle || saving) {
			return;
		}

		if (!(await canUpdateVehicle(update))) {
			return;
		}

		setSaving(true);
		try {
			const { data: updatedVehicle } = await axios.put<Vehicle>(`/api/vehicles/${vehicle.id}`, update);
			setSelectedPlace(undefined);
			setAdditionalStepsAnchor(null);
			setVehicle(updatedVehicle);
			setShowInlineNotes(!!updatedVehicle.note?.text);
		} finally {
			setSaving(false);
		}
	};

	const assignPlace = (place: CompoundPlace) =>
		updateVehicle({
			orderId: vehicle?.orderId,
			remarketing: { status: vehicle?.remarketing.status },
			placeId: place.id,
			location,
		});

	const transitionProcessStep = (step: LotProcessStep) =>
		updateVehicle({
			orderId: vehicle?.orderId,
			remarketing: { status: step.status },
			placeId: step.status === "Ausgang" ? undefined : vehicle?.placeId,
			location,
		});

	const putNote = (text: string) =>
		updateVehicle({
			note: {
				text: text,
			},
		});

	const onUpdate = (vehicle: Vehicle) => {
		setVehicle(vehicle);
	};

	return (
		<Layout
			title="Platzlogistik"
			plateNumber={vehicle?.plateNumber}
			navigation={<VehicleNavigation vehicle={vehicle} />}
			contextMenu={[<LotScanContextMenu />]}
			subMenu={vehicle && <VehicleSubMenu vehicle={vehicle} />}
		>
			{!vehicle && <CircularProgress size={48} />}
			{vehicle && (
				<>
					<Box
						sx={{
							display: "flex",
							alignItems: "flex-end",
							justifyContent: "space-between",
							flexWrap: "wrap",
						}}
					>
						<VehicleHeader vehicle={vehicle} picture={vehiclePicture} />
						<Box
							sx={{
								flexWrap: "nowrap",
								alignItems: "center",
								justifyContent: "space-between",
								display: "flex",
								gap: theme.spacing(2),
							}}
						>
							<VehicleBookmark vehicle={vehicle} updateCallback={onUpdate} setSaving={setSaving} />
							<Box>
								<InfoOutlined
									sx={{
										mt: 0.5,
										cursor: "pointer",
										opacity: 0.8,
										"&:hover": {
											opacity: 1,
										},
										color: theme.palette.primary.light,
										...(!vehicle.note?.text && { color: theme.palette.text.secondary }),
									}}
									type="button"
									onClick={() => setShowInlineNotes(!showInlineNotes)}
								/>
							</Box>
							<VehicleHeaderMenu vehicle={vehicle} />
							{processSteps.length > 0 && (
								<>
									<ActionButton
										variant="outlined"
										disabled={saving}
										endIcon={<ExpandMore />}
										onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
											setAdditionalStepsAnchor(e.currentTarget)
										}
									>
										Nächster Schritt
									</ActionButton>
									<Menu
										variant="menu"
										anchorEl={additionalStepsAnchor}
										anchorReference="anchorEl"
										anchorOrigin={{
											horizontal: "right",
											vertical: "bottom",
										}}
										transformOrigin={{
											vertical: "top",
											horizontal: "right",
										}}
										open={!!additionalStepsAnchor}
										onClose={() => setAdditionalStepsAnchor(null)}
									>
										{processSteps.map((s) => (
											<MenuItem key={s.status} onClick={() => transitionProcessStep(s)}>
												{s.displayName}
											</MenuItem>
										))}
									</Menu>
								</>
							)}
							{!!selectedPlace && vehicle.placeId !== selectedPlace.id && (
								<ActionButton
									variant="outlined"
									disabled={saving}
									onClick={() => assignPlace(selectedPlace)}
								>
									Platz zuweisen: {selectedPlace.name}
								</ActionButton>
							)}
						</Box>
					</Box>

					{showInlineNotes && (
						<Box mt={2}>
							<Tile>
								<Box p={1}>
									<Grid container>
										<Grid item xs={12} sm={9}>
											<Grid container alignItems="center" spacing={1}>
												<Grid item>
													<ActionButton
														icon
														variant="text"
														title={inputNote ? "Ändern" : "Zurücksetzen"}
														onClick={() => {
															!inputNote && setNoteValue(vehicle.note?.text ?? "");
															setInputNote(!inputNote);
														}}
													>
														{inputNote ? <Edit /> : <Clear />}
													</ActionButton>
												</Grid>
												<Grid item xs>
													<TextField
														fullWidth
														disabled={inputNote}
														sx={{
															"& .MuiInputBase-root.Mui-disabled": {
																color: theme.palette.text.primary,
															},
														}}
														InputProps={{ disableUnderline: inputNote }}
														onChange={(event) => setNoteValue(event.target.value)}
														placeholder="Notiz einfügen"
														value={noteValue}
														variant="standard"
													/>
												</Grid>
												{!inputNote && (
													<Grid item>
														<ActionButton variant="text" onClick={() => putNote(noteValue)}>
															Speichern
														</ActionButton>
													</Grid>
												)}
											</Grid>
										</Grid>
										<Grid
											item
											container
											xs={12}
											sm={3}
											alignItems="center"
											justifyContent="space-between"
										>
											{vehicle.note?.user != null && (
												<Box ml={2} display="flex" alignItems="center">
													<TextBlock
														primary={vehicle.note.user.name}
														secondary={moment(vehicle.note.dateCreated).format(
															dateFormats.dateTime,
														)}
													/>
												</Box>
											)}
											{vehicle.note?.user != null && (
												<Box mr={1}>
													<ActionButton
														icon
														variant="text"
														title="Notiz löschen"
														onClick={() => putNote("")}
													>
														<Delete />
													</ActionButton>
												</Box>
											)}
										</Grid>
									</Grid>
								</Box>
							</Tile>
						</Box>
					)}

					<Box mt={2} flexGrow={1} display="flex">
						<LotMap
							currentPlace={currentPlace}
							selectedPlace={selectedPlace}
							onPlaceClick={onPlaceClick}
							onLocationChanged={onLocationChanged}
						/>
					</Box>
				</>
			)}
		</Layout>
	);
};
