import React, { useEffect, useState } from 'react';
import { Form } from "../../../system/useForm";
import { Box, Grid, useTheme } from "@mui/material";
import FormYesNo from "../../../Components/FormYesNo";
import FormText from "../../../Components/FormText";
import FormDateTimePicker from "../../../Components/FormDateTimePicker";
import FormChoices from "../../../Components/FormChoices";
import { EngineType } from "../../../system/Domain";
import QuickFAQ from "../CustomerQuickFAQ";
import FormElementLabel from "../../../Components/FormElementLabel";

interface Props {
	form: Form
	isCustomerDelivery: boolean
}

const IsElectricCarReason = "Elektroautos gelten generell als nicht fahrbereit.";
const HasNoValidMainInspectionReason = "Fahrzeuge ohne gültige Hauptuntersuchung/Abgasuntersuchung gelten nicht als fahrbereit.";

const StepCarState = (props: Props) => {
	const theme = useTheme();
	const isElectricCar = !!props.form.getValue('isElectricCar') || props.form.getValue('engineType') === "Electric";

	const [disableDrivable, setDisableDrivable] = useState<boolean>(isElectricCar);
	const [displayReasonNotDrivable, setDisplayReasonNotDrivable] = useState<string>(isElectricCar && disableDrivable ? IsElectricCarReason : "");

	useEffect(() => {
		const isElectricCar = !!props.form.getValue('isElectricCar') || props.form.getValue('engineType') === "Electric";
		const hasNoValidMainInspection = props.form.getValue('hasValidMainInspection') === false;

		const cannotBeDrivable = isElectricCar || hasNoValidMainInspection;

		if (disableDrivable !== cannotBeDrivable) {
			if (cannotBeDrivable) {
				props.form.setValue('isDrivable', false);
			}

			setDisplayReasonNotDrivable(isElectricCar ? IsElectricCarReason : (hasNoValidMainInspection ? HasNoValidMainInspectionReason : ""));
			setDisableDrivable(cannotBeDrivable);
		}
	}, [props.form]);

	const engineType: EngineType = props.form.getValue('engineType');

	const engineTypeName = (() => {
		switch (engineType) {
			case "Combustion":
				return "Verbrennungsmotor";
			case "Hybrid":
				return "Hybrid";
			case "Electric":
				return "Elektro";
			case "Unknown":
				return "Unbekannt";
		}
	})();

	const isRegistered = props.form.getValue("isRegistered") ?? true;
	const isDrivable = props.form.getValue("isDrivable") ?? true;

	return (
		<Box mb={2} mt={2}>
			<Grid container spacing={4}>
				{engineType === "Unknown" && (
					<FormYesNo
						name="isElectricCar"
						label="Ist das Fahrzeug ein Elektroauto? (Hybrid gilt nicht als Elektrofahrzeug)"
						form={props.form}
						options={{ required: true }}
					/>
				)}
				{engineType !== "Unknown" && (
					<Grid item xs={12}>
						Motortyp: {engineTypeName}
					</Grid>
				)}
				<FormYesNo
					name="isRegistered"
					label={
						!props.isCustomerDelivery ?
							<Box>Ist das Fahrzeug zugelassen?
								<QuickFAQ faqKey={"unregistered-vehicles"} open={!isRegistered} />
							</Box>
							: "Ist das Fahrzeug zugelassen?"
					}
					form={props.form}
					options={{ required: true }}
				/>
				<FormYesNo
					name="hasValidMainInspection"
					label="Ist die Hauptuntersuchung/Abgasuntersuchung gültig?"
					form={props.form}
					options={{ required: true }}
				/>
				{props.form.getValue("hasValidMainInspection") === true && (
					<Grid item xs={12}>
						<FormDateTimePicker
							name="mainInspectionValidUntil"
							form={props.form}
							label="HU/AU gültig bis"
							openTo="year"
							views={["year", "month"]}
							variant="date"
							format="MM.YYYY"
							disablePast
							required
						/>
					</Grid>
				)}
				<FormYesNo
					name="isDrivable"
					label={
						<Box mb={0.25}>
							<FormElementLabel text="Ist das Fahrzeug fahrbereit?" form={props.form} name="isDrivable" />
							{!props.isCustomerDelivery && (
								<>
									<QuickFAQ faqKey={"motor-type-electro"} open={isElectricCar && !isDrivable} />
								</>
							)}
							<Box color={theme.palette.secondary.light}>{displayReasonNotDrivable}</Box>
						</Box>
					}
					form={props.form}
					disabled={disableDrivable}
					options={{ required: true }}
				/>
				{props.form.getValue("isDrivable") === false && (
					<FormText
						name="drivableComment"
						form={props.form}
						label={"Bemerkung zur Fahrbereitschaft"}
						type="text"
					/>
				)}
				<FormChoices
					name="tireType"
					label={
						<Box mb={0.25}>
							<FormElementLabel
								text="Welche Reifenart ist aufgezogen?"
								form={props.form}
								name="tireType"
							/>
							<QuickFAQ faqKey={"tires"} />
						</Box>
					}
					form={props.form}
					choices={{
						"wr": "Winterreifen",
						"sr": "Sommerreifen",
						"gjr": "Ganzjahresreifen",
						"unk": "Unbekannt"
					}}
					options={{ required: true }}
				/>
				<FormChoices
					name="carType"
					label="Ist das Auto ein Kastenwagen oder SUV?"
					form={props.form}
					choices={{
						"SUV": "SUV",
						"Van": "Kastenwagen",
						"Unknown": "Unbekannt",
						"None": "Nein"
					}}
					options={{ required: true }}
				/>
			</Grid>
		</Box>
	)
};

export default StepCarState;
