import React, { useState } from "react";
import Layout from "../../Components/Layout/Layout";
import { Box, CircularProgress } from "@mui/material";
import { useParams } from "react-router";
import useBilling from "./useBilling";
import BillingSubMenu from "./BillingSubMenu";
import { VehicleService } from "../../system/Domain";
import useAsyncEffect from "../../system/useAsyncEffect";
import axios from "axios";
import BillingHeader from "./BillingHeader";
import VehicleServiceTable from "../VehicleServices/VehicleServiceTable";
import useQuery from "../../system/useQuery";
import { VehicleServiceQuery } from "../VehicleServices/useVehicleServices";
import FetchNextButton from "../../Components/FetchNextButton";
import BillingServicesSidebar from "./BillingServicesSidebar";

export default () => {
	const { id } = useParams<{ id : string }>();
	const [billing] = useBilling(id);

	const [query, setQuery, fetchNext, resetQuery] = useQuery<VehicleServiceQuery>('billingServicesQuery', {
		skip: 0,
		take: 20,
		sort: "dateCompleted:desc"
	});

	const [services, setServices] = useState<VehicleService[]>([]);
	const [isFetching, setIsFetching] = useState<boolean>(false);

	useAsyncEffect(async (cancelToken) => {
		if (!billing) {
			setServices([]);
			return;
		}

		setIsFetching(true);

		try {
			const { data: services } = await axios.get<VehicleService[]>(`/api/vehicles/services`, {
				params: {
					...query,
					billingId: billing.id
				},
				cancelToken: cancelToken.getAxiosToken()
			});

			if (query.skip > 0) {
				setServices(s => [
					...s,
					...services
				]);
			} else {
				setServices(services);
			}
		} finally {
			setIsFetching(false);
		}
	}, [billing, query]);

	return (
		<Layout
			title="Tätigkeiten zur Abrechnung"
			subMenu={billing && <BillingSubMenu billing={billing} />}
			sidebar={billing && <BillingServicesSidebar billing={billing} query={query} setQuery={setQuery} resetQuery={resetQuery} />}
		>
			{!billing && (
				<CircularProgress size={48} />
			)}
			{billing && (
				<>
					<BillingHeader billing={billing} />
					{services.length > 0 && (
						<Box mt={4}>
							<VehicleServiceTable vehicleServices={services} setVehicleServices={setServices} showVehiclePlate />
							<FetchNextButton
								hidden={services.length < query.skip + query.take}
								my={3}
								onNext={fetchNext}
								disabled={isFetching}
							/>
						</Box>
					)}
				</>
			)}
		</Layout>
	);
}
