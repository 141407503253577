import React, { useEffect } from "react";
import { Form } from "../../../system/useForm";
import { FormControl, FormControlLabel, Radio, RadioGroup, useTheme } from "@mui/material";
import { Address, CustomerOrderAddressInformation } from "../../../system/Domain";

interface Props {
	name: string;
	form: Form;
	aldAddressData: CustomerOrderAddressInformation;
	required?: boolean;
	isTraderOrder: boolean;
}

const CollectionRadioChoice = (props: Props) => {
	const theme = useTheme();

	useEffect(() => {
		props.form.register(props.name, { required: props.required ?? false });

		return () => {
			props.form.unregister(props.name);
		};
	}, [props.name]);

	const handleEntryTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const selectedValue = (event.target as HTMLInputElement).value;

		props.form.setValue(props.name, selectedValue);
		props.form.validate(props.name, selectedValue);

		const setter = (address: Address | undefined) => {
			if (!address) return;

			props.form.setValue("collection.address.name", address.name);
			props.form.setValue("collection.address.name1", address.name1);
			props.form.setValue("collection.address.city", address.city);
			props.form.setValue("collection.address.street", address.street);
			props.form.setValue("collection.address.zipCode", address.zipCode);
			props.form.setValue("collection.address.country", address.country);
			props.form.setValue("collection.address.additionalInformation", address.additionalInformation);
		};

		if (selectedValue === "CustomersAddress") {
			setter(props.aldAddressData.customerAddress);
		} else if (selectedValue === "TraderAddress") {
			setter(props.aldAddressData.traderAddress);
		}
	};

	return (
		<FormControl component="fieldset" sx={!!props.form.errors[props.name] && { color: theme.palette.error.main }}>
			<RadioGroup
				row
				name="position"
				defaultValue="top"
				value={props.form.getValue(props.name) ?? ""}
				onChange={handleEntryTypeChange}
			>
				{!props.isTraderOrder && (
					<FormControlLabel
						value="CustomersAddress"
						control={
							<Radio
								sx={!!props.form.errors[props.name] && { color: theme.palette.error.main }}
								color="primary"
							/>
						}
						label="Kundenadresse"
						labelPlacement="end"
					/>
				)}
				<FormControlLabel
					value="TraderAddress"
					control={
						<Radio
							sx={!!props.form.errors[props.name] && { color: theme.palette.error.main }}
							color="primary"
						/>
					}
					label="Händleradresse"
					labelPlacement="end"
				/>
				<FormControlLabel
					value="CustomEntry"
					control={
						<Radio
							sx={!!props.form.errors[props.name] && { color: theme.palette.error.main }}
							color="primary"
						/>
					}
					label="Freie Eingabe"
					labelPlacement="end"
				/>
			</RadioGroup>
		</FormControl>
	);
};

export default CollectionRadioChoice;
