import React from "react";
import { InventoryProps } from "./Inventory";
import Chip from "../../Components/Chip";

export default ({ inventory } : InventoryProps) => (
	<Chip
		label={!inventory.inventoryDate ? "In Bearbeitung" : "Abgeschlossen"}
		color={!inventory.inventoryDate ? "secondary" : "primary"}
	/>
);
