import Layout from "../../../Components/Layout/Layout";
import React, { useState } from "react";

import axios, { AxiosRequestConfig } from "axios";
import FluentGrid from "../../../Components/FluentGrid";

import { AssessmentsKpis, AssessmentsKpisQuery, CapacityKpisGroup, RepairOdersKpisQuery, RepairOrderStatus, RepairOrdersKpis, WorkItemType, UserRole } from "../../../system/Domain";
import { CircularProgress } from "@mui/material";
import useAxiosEffect from "../../../system/useAxiosEffect";

import { axiosDownload } from "../../../system/axiosDownload";
import { setWorkSession } from "../../../system/reducers/workSessions/actions";
import moment from "moment";
import { useDispatch } from "react-redux";
import KpisGroupTile from './CapacityKpisGroupTile';
import OrdersNavigation from 'Navigation/OrdersNavigation';
import { translateRepairOrderStatus } from 'system/translate';
import { NoCrash, PrecisionManufacturing } from "@mui/icons-material";
import useUser from "../../../system/useUser";

export default () => {
	const dispatch = useDispatch();
	const [, , hasRole] = useUser();

	const canStartSession = hasRole(UserRole.AldUser);

	const translateRepairOrdersNames = (data: RepairOrdersKpis) => {
		data.all.name = "Gesamt";
		data.all.sections[0].groups.forEach(g => g.name = translateRepairOrderStatus(g.name as RepairOrderStatus) ?? g.name);
		data.ordered.name = "Gesamt";
		data.costEstimated.name = "Gesamt";
	};

	const [repairKpis, setRepairKpis] = useState<RepairOrdersKpis>();
	const loadRepairOrdersKpis = async (axiosConfig: AxiosRequestConfig = {}) => {
		const { data } = await axios.get<RepairOrdersKpis>(`/api/orders/repairs/kpis`, axiosConfig);
		translateRepairOrdersNames(data);
		setRepairKpis(data);
	};

	const startRepairOrdersKpisKpisSession = async (group: CapacityKpisGroup<RepairOdersKpisQuery>) => {
		if (!canStartSession) return;

		let workItemType: WorkItemType = "VehicleRepairOrder";
		const { data: workSession } = await axios.post(`/api/orders/repairs/kpis/work-session`, {
			title: group.name,
			workItemType,
			query: group.query
		});

		if (workSession) {
			dispatch(setWorkSession(workSession));
		}
	};

	const exportRepairOrdersKpis = async (group: CapacityKpisGroup<RepairOdersKpisQuery>) => {
		const response = await axios.post(`/api/orders/repairs/kpis/export`, group.query, {
			responseType: "blob"
		});

		axiosDownload(response, `${moment().format('YYYY-MM-DD')} ${group.name}.xlsx`);
	};

	const [assessmentKpis, setAssessmentKpis] = useState<AssessmentsKpis>();
	const loadAssessmentKpis = async (axiosConfig: AxiosRequestConfig = {}) => {
		const { data } = await axios.get<AssessmentsKpis>(`/api/orders/assessments/kpis`, axiosConfig);
		data.all.name = "Offene Gesamt";
		setAssessmentKpis(data);
	};

	const startAssessmentsKpisKpisSession = async (group: CapacityKpisGroup<AssessmentsKpisQuery>) => {
		if (!canStartSession) return;

		let workItemType: WorkItemType = "AssessmentOrder";
		const { data: workSession } = await axios.post(`/api/orders/assessments/kpis/work-session`, {
			title: group.name,
			workItemType,
			query: group.query
		});

		if (workSession) {
			dispatch(setWorkSession(workSession));
		}
	};

	const exportAssesmentsKpis = async (group: CapacityKpisGroup<AssessmentsKpisQuery>) => {
		const response = await axios.post(`/api/orders/assessments/kpis/export`, group.query, {
			responseType: "blob"
		});

		axiosDownload(response, `${moment().format('YYYY-MM-DD')} ${group.name}.xlsx`);
	};

	useAxiosEffect(loadRepairOrdersKpis, []);
	useAxiosEffect(loadAssessmentKpis, [])

	return (
		<Layout
			title="Dashboard"
			navigation={<OrdersNavigation />}
		>
			{!repairKpis && !assessmentKpis && (
				<CircularProgress size={48} />
			)}

			{repairKpis && (
				<FluentGrid itemWidth={350} itemGap={6}>
					<KpisGroupTile
						tileName="Werkstattaufträge"
						tileIcon={<PrecisionManufacturing />}
						group={repairKpis.all}
						showNullAge
						startSession={startRepairOrdersKpisKpisSession}
						exportKpis={exportRepairOrdersKpis}
					/>
					<KpisGroupTile
						tileName={translateRepairOrderStatus("Ordered")}
						tileIcon={<PrecisionManufacturing />}
						group={repairKpis.ordered}
						startSession={startRepairOrdersKpisKpisSession}
						exportKpis={exportRepairOrdersKpis}
					/>
					<KpisGroupTile
						tileName={translateRepairOrderStatus("CostEstimated")}
						tileIcon={<PrecisionManufacturing />}
						group={repairKpis.costEstimated}
						startSession={startRepairOrdersKpisKpisSession}
						exportKpis={exportRepairOrdersKpis}
					/>
				</FluentGrid>
			)}
			{assessmentKpis && (
				<FluentGrid itemWidth={350} itemGap={6}>
					<KpisGroupTile
						tileName="Gutachtenaufträge"
						tileIcon={<NoCrash />}
						group={assessmentKpis.all}
						showNullAge
						startSession={startAssessmentsKpisKpisSession}
						exportKpis={exportAssesmentsKpis}
					/>
				</FluentGrid>
			)}
		</Layout>
	);
};
