import React from "react";
import { ThemeProvider, DialogProps, Dialog, StyledEngineProvider, useTheme } from "@mui/material";

import store from "../system/store";
import { RecoilRoot } from "recoil";
import { Provider } from "react-redux";

export interface ThemedDialogProps extends DialogProps {
	children: React.ReactNode
}

export default ({ children, onClose, ...props }: ThemedDialogProps) => {
	const handleClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
		if (reason === "backdropClick") {
			return false;
		}

		if (typeof onClose === "function") {
			onClose(event, reason);
		}
	};

	const theme = useTheme();

	return (
		<ThemeProvider theme={theme}>
			<Provider store={store}>
				<RecoilRoot>
					<StyledEngineProvider injectFirst>
						<Dialog {...props} onClose={handleClose}>
							{children}
						</Dialog>
					</StyledEngineProvider>
				</RecoilRoot>
			</Provider>
		</ThemeProvider>
	);
};
