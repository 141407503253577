import { Box, Button, FormControl, Grid, Typography } from "@mui/material";
import axios from "axios";
import FormDateTimePicker from "Components/FormDateTimePicker";
import FormText from "Components/FormText";
import FormYesNo from "Components/FormYesNo";
import Layout from "Components/Layout/Layout";
import moment from "moment";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { Address, ContactPerson, PoolFleetVehicle } from "system/Domain";
import useForm from "system/useForm";

interface NewContractForm {
	customerId: number | null
	duration: number
	mileage: number
	rate: number
	includeDelivery: boolean
	desiredDeliveryDate: string
	deliveryAddress: Address | null
	deliveryContact: ContactPerson | null
}

function AddNewPoolFleetContractPage() {
	const { id } = useParams<{ id: string }>();
	const history = useHistory();

	const [newContract, setNewContract] = useState<NewContractForm>({
		customerId: 8329333,
		duration: 12,
		mileage: 20000,
		rate: 0.0,
		includeDelivery: true,
		desiredDeliveryDate: moment().add(1, "day").format("YYYY-MM-DD"),
		deliveryAddress: {
			name: "",
			street: "",
			zipCode: "",
			city: "",
			country: "Deutschland"
		},
		deliveryContact: {
			name: "",
			email: "",
			telephone: ""
		}
	});

	const [busy, setBusy] = useState(false);

	const form = useForm({
		values: newContract,
		setValues: setNewContract,
		deltaValues: null
	});

	async function syncVehicleWithLeasman() {
		await axios.post<PoolFleetVehicle>(`/api/pool-fleet/vehicles/${id}/sync-with-leasman`);
	}

	async function handleSubmit(event: React.FormEvent) {
		event.preventDefault();

		setBusy(true);
		try {
			await axios.post(`/api/pool-fleet/vehicles/${id}/contracts`, {
				...newContract,
				customer: {
					id: newContract.customerId
				},
				customerId: undefined
			});
			history.push(`/pool-fleet/vehicles/${id}`);
		} catch (e) {
			// in case something goes wrong, Leasman might have been changed
			// Resync with Leasman to ensure we see the latest changes
			await syncVehicleWithLeasman();
			throw e;
		} finally {
			setBusy(false);
		}
	}

	return (
		<Layout
			title="Neuer Pool Fleet Vertrag"
			loading={busy}
		>
			<form onSubmit={handleSubmit}>
				<Grid container spacing={6}>
					<Grid item xs={12} md={6}>
						<Typography gutterBottom>Vertrag</Typography>
						<Box mt={1} sx={{
							"& div": {
								marginTop: 1
							}
						}}>
							<FormText
								fullWidth
								type="number"
								label="Leasman Kundennummer"
								form={form}
								name="customerId"
								options={{
									required: true
								}}
							/>
							<FormText
								fullWidth
								type="number"
								label="Vertragslaufzeit"
								form={form}
								name="duration"
								options={{
									required: true
								}}
							/>
							<FormText
								fullWidth
								type="number"
								label="Vertragslaufleistung"
								form={form}
								name="mileage"
								options={{
									required: true
								}}
							/>
							<FormText
								fullWidth
								type="currency"
								label="Rate"
								form={form}
								name="rate"
								options={{
									required: true
								}}
							/>
						</Box>
					</Grid>
					<Grid item xs={12} md={6}>
						<Typography gutterBottom>Versand</Typography>
						<Box mt={1} sx={{
							"& div": {
								marginTop: 1
							}
						}}>
							<FormYesNo
								label="Mit Transport"
								form={form}
								name="includeDelivery"
							/>
							<FormDateTimePicker
								variant="date"
								margin="normal"
								label="Abholdatum"
								form={form}
								name="desiredDeliveryDate"
							/>
						</Box>
					</Grid>
					{form.getValue("includeDelivery") === true && (
						<Grid item xs={12} md={6} sx={{
							"& div": {
								marginTop: 1
							}
						}}>
							<Typography gutterBottom>Versandaddresse</Typography>
							<FormText
								fullWidth
								label="Name"
								form={form}
								name="deliveryAddress.name"
							/>
							<FormText
								fullWidth
								label="Strasse"
								form={form}
								name="deliveryAddress.street"
							/>
							<Box display="flex" style={{ columnGap: 8 }}>
								<FormControl>
									<FormText
										label="Postleitzahl"
										form={form}
										name="deliveryAddress.zipCode"
									/>
								</FormControl>
								<FormControl fullWidth>
									<FormText
										fullWidth
										label="Ort"
										form={form}
										name="deliveryAddress.city"
									/>

								</FormControl>
							</Box>
						</Grid>
					)}
					{form.getValue("includeDelivery") === true && (
						<Grid item xs={12} md={6} sx={{
							"& div": {
								marginTop: 1
							}
						}}>
							<Typography gutterBottom>Versandkontakt</Typography>
							<FormText
								fullWidth
								label="Name"
								form={form}
								name="deliveryContact.name"
							/>
							<FormText
								fullWidth
								label="Email"
								type="email"
								form={form}
								name="deliveryContact.email"
							/>
							<FormText
								fullWidth
								label="Telefon"
								type="number"
								form={form}
								name="deliveryContact.telephone"
							/>
						</Grid>
					)}
					<Grid item xs={12}>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disabled={busy}
						>
							Vertrag anlegen
						</Button>
					</Grid>
				</Grid>
			</form>
		</Layout>
	)
}

export default AddNewPoolFleetContractPage;
