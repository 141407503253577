import FormElement from "./FormElement";
import FormDeltaValue from "./FormDeltaValue";
import { Form, FormOptions } from "../system/useForm";
import { MenuItem, Select } from "@mui/material";
import React, { ReactNode, useCallback, useEffect } from "react";

export type FormSelectProps = {
	name: string
	label?: ReactNode
	choices?: any,
	entries?: { choice: string, label: string }[]
	sort?: boolean
	form: Form
	options?: FormOptions
	fullWidth?: boolean
};

export default React.forwardRef(({ name, label, choices, entries, form, options, sort = false, fullWidth = true }: FormSelectProps, ref) => {
	const value = form.getValue(name);
	const deltaValue = form.getDeltaValue(name);
	const onChange = useCallback((event) => form.setValue(name, event.target.value, true), [name]);

	useEffect(() => {
		if (!name) return;

		form.register(name, options);

		return () => {
			form.unregister(name);
		};
	}, [name]);

	if (form.deltaMode && value === deltaValue) {
		return null;
	}

	if (!choices && !entries) {
		return null;
	}

	const selectEntries = entries ?? Object.keys(choices).map(key => ({
		choice: key,
		label: choices[key]
	}));

	if (sort) {
		selectEntries.sort((a, b) => a.label > b.label ? 1 : -1);
	}

	const entry = selectEntries.find(e => e.choice === value);
	const deltaEntry = selectEntries.find(e => e.choice === deltaValue);

	return (
		<FormElement
			label={label}
			gridProps={{
				sx: {
					"&.MuiInputBase-root": {
						width: fullWidth ? "100%" : 250
					}
				}
			}}
		>
			<Select
				ref={ref}
				sx={{
					root: {
						fontSize: "0.75rem",
						fontWeight: 400,
						lineHeight: 1.66,
						letterSpacing: "0.03333em",
						paddingTop: 0,
						paddingBottom: 0,
					},
					select: {
						paddingTop: 12,
						paddingBottom: 10
					}
				}}
				value={value ?? ''}
				onChange={onChange}
				fullWidth
				error={!!form.errors[name]}
				readOnly={form.readOnly}
				disabled={form.readOnly}
				inputProps={{
					name: name,
					id: name,
				}}
				variant="outlined"
			>
				{selectEntries.map(e => (
					<MenuItem
						key={e.choice}
						value={e.choice}
					>
						{e.label}
					</MenuItem>
				))}
			</Select>
			{
				form.deltaMode && (
					<FormDeltaValue
						deltaValue={deltaEntry ? deltaEntry.label : "Keine Angabe"}
						value={entry ? entry.label : "Keine Angabe"}
					/>
				)
			}
		</FormElement >
	)
});
