import React, { useCallback, useState } from "react";
import axios from "axios";
import useAsyncEffect from "../../system/useAsyncEffect";
import useAutoResetState from "../../system/useAutoResetState";
import { confirmable, createConfirmation, ReactConfirmProps } from "react-confirm";
import { DialogTitle, DialogContent, DialogActions, Button, Box } from "@mui/material"
import Search from "../../Components/Search";
import { CompanyReference, TransportOrder, User } from "../../system/Domain";
import TransportOrderTable from "Pages/Orders/Transports/TransportOrderTable";
import ThemedStatefulDialog from "Components/ThemedStatefulDialog";

export interface TransportOrderReference {
	id: string
	company: CompanyReference
}

interface SelectOrderDialogProps extends ReactConfirmProps<TransportOrderReference | null> {
	plateNumber: string
	forUser: User
}

const SelectOrderDialog = ({ show, proceed, plateNumber, forUser }: SelectOrderDialogProps) => {
	const [orders, setOrders] = useState<Array<TransportOrder>>([]);
	const [isFetching, setIsFetching] = useState(false);
	const [fetchingJustStarted, setFetchingJustStarted] = useAutoResetState(false, 100);

	const [query, setQuery] = useState(
		{
			perspective: "All",
			sort: "dateAssigned:desc",
			skip: 0,
			take: 20,
			orderPlateNumber: plateNumber
		}
	);

	useAsyncEffect(async (cancelToken) => {
		setIsFetching(true);
		setFetchingJustStarted(true);
		try {
			const { data: orders } = await axios.get<TransportOrder[]>(`/api/orders/transports`, {
				params: query,
				cancelToken: cancelToken.getAxiosToken()
			});
			setOrders(orders);

		} finally {
			setFetchingJustStarted(false);
			setIsFetching(false);
		}
	}, [query]);

	const onOrder = useCallback((o: TransportOrder) => {
		if (o) {
			proceed({
				id: o.id,
				company: o.assignedTo
			})
		}
	}, [proceed]);

	return (
		<ThemedStatefulDialog
			open={show}
			fullWidth
			maxWidth="lg"
		>
			<DialogTitle>{`Transportaufträge für ${plateNumber} suchen`}</DialogTitle>
			<DialogContent>
				<Box mb={2}>
					<Search
						value={query.orderPlateNumber}
						realtime
						onSearch={(value) => value !== query.orderPlateNumber && setQuery(q => ({
							...q,
							orderPlateNumber: value
						}))}
					/>
					<Box mt={2}>
						<TransportOrderTable
							orders={!isFetching || fetchingJustStarted ? orders : []}
							isLoading={isFetching && !fetchingJustStarted}
							onOrderClick={onOrder}
						/>
					</Box>
				</Box>
			</DialogContent>
			<DialogActions style={{ justifyContent: "space-between", flexDirection: "row-reverse" }}>
				<DialogActions>
					<Button
						variant="outlined"
						color="secondary"
						onClick={() => proceed(null)}
					>
						Abbrechen
					</Button>
				</DialogActions>
			</DialogActions>
		</ThemedStatefulDialog>
	);
};

export default (plateNumber: string, user: User) => {
	const dialog = confirmable(props => <SelectOrderDialog {...props} plateNumber={plateNumber} forUser={user} />);
	return createConfirmation<TransportOrderReference | null>(dialog)();
};
