import Tile from "../../../Components/Tiles/Tile";
import { Box, Divider, Typography } from "@mui/material";
import TileContent from "../../../Components/Tiles/TileContent";
import TextBlock from "../../../Components/Layout/TextBlock";
import AppointmentInformation from "../../../Components/AppointmentInformation";
import TileActions from "../../../Components/Tiles/TileActions";
import ActionButton from "../../../Components/ActionButton";
import React from "react";
import { ReturnOrder } from "../../../system/Domain";

interface Props {
	returnOrder: ReturnOrder
	primary?: ButtonProps
	secondary?: ButtonProps
}

interface ButtonProps {
	title: string
	action: () => void
}

const CollectionOverview = ({ returnOrder, ...props }: Props) => {
	return (
		<Tile>
			<Box p={2}>
				<Typography variant="h6">Abholung</Typography>
			</Box>
			<Divider />
			<TileContent>
				<Box>
					<TextBlock
						primary={returnOrder.collection.address.name}
						secondary={`${returnOrder.collection.address.street} - ${returnOrder.collection.address.zipCode}`}
						tertiary={`${returnOrder.collection.address.city} - ${returnOrder.collection.address.country}`}
					/>
					<TextBlock
						primary={""}
						secondary={returnOrder.collection.address.additionalInformation}
					/>
				</Box>
				<Box mt={2}>
					<TextBlock
						primary="Kontaktperson"
						secondary={`${returnOrder.collection.contact?.firstName} ${returnOrder.collection.contact?.lastName}`}
						tertiary={`${returnOrder.collection.contact?.email} - ${returnOrder.collection.contact?.telephone}`}
					/>
				</Box>
				{returnOrder.collection.comment && (
					<Box mt={2}>
						<TextBlock
							primary={""}
							secondary={returnOrder.collection.comment}
						/>
					</Box>
				)}
				{returnOrder.collection.appointment && (
					<AppointmentInformation appointment={returnOrder.collection.appointment} />
				)}
			</TileContent>
			<TileActions>
				{props.secondary && (
					<ActionButton
						variant="text"
						hideOnDisabled
						color="secondary"
						title={props.secondary.title}
						onClick={props.secondary.action}
					>
						{props.secondary.title}
					</ActionButton>
				)}
				{props.primary && (
					<ActionButton
						variant="text"
						hideOnDisabled
						title={props.primary.title}
						onClick={props.primary.action}
					>
						{props.primary.title}
					</ActionButton>
				)}
			</TileActions>
		</Tile>
	);
};

export default CollectionOverview;
