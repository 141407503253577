import React, { useState } from "react";
import { confirmable, createConfirmation, ReactConfirmProps } from "react-confirm";
import ThemedDialog from "../../Components/ThemedDialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";

interface DialogResult {
	force : boolean
}

interface CreateNewAversiSessionProps extends ReactConfirmProps<DialogResult | null> {

}

const CreateNewAversiSessionDialog = ({ show, proceed } : CreateNewAversiSessionProps) => {
	const [force, setForce] = useState(false);

	const handleClose = () => {
		proceed(null);
	};

	const handleSave = (event : React.FormEvent) => {
		event.preventDefault();
		proceed({ force });
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setForce(event.target.checked);
	};

	return (
		<ThemedDialog
			open={show}
			fullWidth
			maxWidth="sm"
			onClose={handleClose}
		>
			<DialogTitle>Neue Aversi Monatsmeldung erstellen</DialogTitle>
			<form onSubmit={handleSave}>
				<DialogContent>
					<FormControlLabel
						control={<Checkbox value={force} onChange={handleChange}/>}
						label="Update der Leasman Daten erzwingen"
					/>
					<Typography variant={"body2"}>
						Die Leasman Daten werden nur einmalig, bei der Erstanlage, synchonisiert. Durch setzen dieses Häckens kann auch
						nachträglich eine Synchronisation erzwungen werden.
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" color="secondary" onClick={handleClose} href="">
						Abbrechen
					</Button>
					<Button variant="outlined" color="primary" type="submit">
						OK
					</Button>
				</DialogActions>
			</form>
		</ThemedDialog>
	);
};

export default () => {
	const dialog = confirmable(props => <CreateNewAversiSessionDialog {...props}/>);
	return createConfirmation<DialogResult | null>(dialog)();
}
