import moment from "moment";

import { Clear, CloudDownload, Delete, Email, Mail } from "@mui/icons-material";

import TextBlock from "../../Components/Layout/TextBlock";
import ListItem from "../../Components/ListItem";
import ListItemAction from "../../Components/ListItemAction";
import ListItemActions from "../../Components/ListItemActions";
import ListItemChip from "../../Components/ListItemChip";
import ListItemChips from "../../Components/ListItemChips";
import ListItemContent from "../../Components/ListItemContent";
import { BatchFile, BatchFileStatus, BatchFileType, DateFormat, ThemeColor, UserRole } from "../../system/Domain";
import formatDateTime from "../../system/formatDateTime";
import formatNumber from "../../system/formatNumber";
import formatRelativeDate from "../../system/formatRelativeDate";
import useAction from "../../system/useAction";
import useUser from "../../system/useUser";
import { downloadFile } from "./SaleOfferListItem";
import axios from "axios";
import { useEffect, useState } from "react";
import showMessage from "Dialogs/showMessage";
import ask from "../../Dialogs/ask";
import { Box, Button, useTheme } from "@mui/material";

export const formatBatchFile = (batchFile: BatchFile) => {
	switch (batchFile.type) {
		case "Unknown":
			return "Unbekannt";
		case "PricingTags":
			return "Preisschilder";
		case "WebMobilSales":
			return "WebMobil";
		case "RepairOrders":
			return `${batchFile.repairOrders?.company.name} Werkstattaufträge`;
		case "BcaSales":
			return batchFile.file?.filename || "BCA Verkäufe";
		case "DadArrivalTransmission":
			return "Packliste";
		default:
			return batchFile.type;
	}
};

export const formatBatchFileStatus = (status: BatchFileStatus) => {
	switch (status) {
		case "Planned":
			return "Geplant";
		case "Created":
			return "Erstellt";
		case "Failed":
			return "Fehler";
		default:
			return status;
	}
};

export const paintBatchFileStatus = (status: BatchFileStatus): ThemeColor => {
	switch (status) {
		case "Planned":
			return "primary";
		case "Created":
			return "success";
		case "Failed":
			return "error";
		default:
			return "info";
	}
};

interface Props {
	batchFile: BatchFile;
	onDelete?: (batchFile: BatchFile) => Promise<void>;
}

type Mode = "view" | "mail";

export default ({ batchFile, onDelete }: Props) => {
	const [, , , , hasAnyRole] = useUser();
	const downloadBatchFile = () => {
		if (!batchFile.file?.hash) {
			return;
		}

		downloadFile(batchFile.file.hash);
	};

	const [deleteBatchFile, isDeleting] = useAction(async () => {
		if (onDelete) {
			await onDelete(batchFile);
		}
	});

	const [mailBatchFile, mailBatchFileRunning] = useAction(async () => {
		if (batchFile.type === "RepairOrders") {
			await axios.post(`/api/orders/repairs/batch/${batchFile.id}/send`);
		} else if (batchFile.type === "BcaSales") {
			await axios.post(`/api/sales/bca/batches/${batchFile.id}/mails`);
		}

		setMode("view");
	});

	const theme = useTheme();
	const showMail = batchFile.type === "RepairOrders" || batchFile.type === "BcaSales";
	const [mode, setMode] = useState<Mode>("view");

	let color: ThemeColor = "info";
	if (mode === "mail") {
		color = "primary";
	}

	return (
		<ListItem color={color}>
			<ListItemActions>
				{mode === "view" && (
					<>
						{!!batchFile.file?.hash && (
							<>
								<ListItemAction
									icon={<CloudDownload />}
									tooltip="Datei herunterladen"
									onClick={downloadBatchFile}
									color="inherit"
								/>
								{showMail && (
									<ListItemAction
										icon={<Mail />}
										onClick={() => setMode("mail")}
										tooltip="Mail versenden"
										disabled={mailBatchFileRunning}
										color="inherit"
									/>
								)}
							</>
						)}
						{hasAnyRole([UserRole.SalesAdmin, UserRole.SalesBackoffice]) && onDelete && (
							<ListItemAction
								icon={<Delete />}
								disabled={isDeleting}
								onClick={deleteBatchFile}
								color="inherit"
							/>
						)}
					</>
				)}
				{mode !== "view" && (
					<ListItemAction icon={<Clear />} tooltip="Abbrechen" onClick={() => setMode("view")} />
				)}
			</ListItemActions>
			<ListItemContent>
				<TextBlock
					flat
					primary={formatBatchFile(batchFile)}
					secondary={`Erstellt ${formatRelativeDate(batchFile.dateCreated)}`}
				/>

				{mode === "mail" && (
					<Box mt={3} mb={1} color={theme.palette.primary.light}>
						<Button
							color="inherit"
							variant="text"
							onClick={mailBatchFile}
							startIcon={<Mail />}
							disabled={mailBatchFileRunning}
						>
							Mail versenden
						</Button>
					</Box>
				)}

				<ListItemChips>
					<ListItemChip
						label={formatBatchFileStatus(batchFile.status)}
						color={paintBatchFileStatus(batchFile.status)}
					/>

					{batchFile.mailReferences?.length > 0 && (
						<ListItemChip
							label={`Versendet am ${formatDateTime(batchFile.mailReferences[batchFile.mailReferences.length - 1].referenceCreated)}`}
						/>
					)}

					{batchFile.entries > 0 && <ListItemChip label={`${formatNumber(batchFile.entries)} Einträge`} />}

					{batchFile.pricingTags && <ListItemChip label={batchFile.pricingTags.location} />}

					{batchFile.pricingTags?.firstTimeBatching != null && (
						<ListItemChip
							label={batchFile.pricingTags?.firstTimeBatching ? "Neu" : "Angepasst"}
							color="primary"
						/>
					)}

					{batchFile.repairOrders && (
						<>
							<ListItemChip label={batchFile.repairOrders.company.name} />
							{batchFile.repairOrders.compound && (
								<ListItemChip label={batchFile.repairOrders.compound.name} />
							)}
						</>
					)}

					{batchFile.arrivalTransmission && (
						<>
							{batchFile.arrivalTransmission.compound && (
								<ListItemChip label={batchFile.arrivalTransmission.compound.name} />
							)}
							{batchFile.arrivalTransmission.businessLines.map((s, i) => (
								<ListItemChip key={i} label={s} />
							))}
						</>
					)}
				</ListItemChips>
			</ListItemContent>
		</ListItem>
	);
};
