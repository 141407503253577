import axios from "axios";
import Actions from "Components/Actions";
import Layout from "Components/Layout/Layout";
import TextBlock from "Components/Layout/TextBlock";
import { Plate } from "Components/Plate";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { useAsync } from "react-use";
import { LeasmanInvoice, Sale, SaleBid, SaleOrder, Vehicle } from "system/Domain";
import formatCurrency from "system/formatCurrency";
import translateError from "system/translateError";
import { CheckBoxOutlineBlank, CheckBoxOutlined } from "@mui/icons-material";
import { Box, Button, CircularProgress, Link, Typography } from "@mui/material";
import InlineList from "../../../Components/InlineList";
import List from "../../../Components/List";
import ListItem from "../../../Components/ListItem";
import ListItemAction from "../../../Components/ListItemAction";
import ListItemActions from "../../../Components/ListItemActions";
import ListItemContent from "../../../Components/ListItemContent";
import SaleAddressInfo from "./SalesAddressInfo";
import SalesInformation from "./SalesInformation";

const DirectSalesPage = () => {
	const { id } = useParams<{ id: string }>();
	const [order, setOrder] = useState<SaleOrder | null>(null);
	const [sales, setSales] = useState<Sale | null>(null);
	const [, setBid] = useState<SaleBid | null>(null);
	const [invoices, setInvoices] = useState<LeasmanInvoice[]>([]);
	const [selectedInvoices, setSelectedInvoices] = useState<LeasmanInvoice[]>([]);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [saving, setSaving] = useState(false);
	const [vehicle, setVehicle] = useState<Vehicle>();
	const dispatch = useDispatch();
	const history = useHistory();

	const aldLogo = "/static/ald-logo.png";
	const leaseplanLogo = "/static/LeasePlan_Logo.png";
	const { loading } = useAsync(async () => {
		if (!id) {
			setOrder(null);
			return;
		}

		dispatch({ type: "SUSPEND_ERROR_HANDLING" });
		try {
			const { data: order } = await axios.post<SaleOrder>(`/api/sales/${id}/prepare-order`);
			if (!order.invoiceId) {
				setErrorMessage(
					"Das Fahrzeug ist noch nicht verkauft. Bitte zunächst das Fahrzeug in Leasman verkaufen"
				);
				return;
			}
			setOrder(order);
		} catch (e) {
			if (e?.response?.data) {
				setErrorMessage(translateError(e.response.data));
			}
		} finally {
			dispatch({ type: "RESUME_ERROR_HANDLING" });
		}
	}, [id]);

	useAsync(async () => {
		if (!id) {
			setSales(null);
			return;
		}

		const { data: sales } = await axios.get<Sale>(`/api/sales/${id}`);
		const { data: vehicle } = await axios.get<Vehicle>(`/api/vehicles/${sales.vehicle.id}`);
		setSales(sales);
		setVehicle(vehicle);
	}, [id]);

	useEffect(() => {
		if (!sales) {
			setBid(null);
			return;
		}

		let bid: SaleBid | null = null;
		const offer = sales.offers.find((o) => o.status === "Reserved");
		if (offer) {
			bid = offer.reservedBid;
		}
		setBid(bid);
	}, [sales]);

	const { loading: loadingInvoices } = useAsync(async () => {
		if (!order?.invoiceId) {
			return;
		}

		const { data: invoices } = await axios.post<LeasmanInvoice[]>(
			`/api/leasman/${order.tenant}/outgoing/invoices/search`,
			{
				addressId: order.buyer.id,
				contractId: order.contractId,
				plateNumber: order.contractId ? undefined : order?.plateNumber
			}
		);

		// In case, we sell to the customer, we don't want to see
		// the regular leasing invoices
		const relevantInvoices = invoices.filter((i) => i.invoiceType === "AVR" || i.invoiceType === "MER");

		setInvoices(relevantInvoices);

		const vehicleSaleInvoice = relevantInvoices.find((i) => i.id === order.invoiceId);
		if (vehicleSaleInvoice) {
			setSelectedInvoices([vehicleSaleInvoice]);
		} else {
			setSelectedInvoices([]);
		}
	}, [order]);

	const handleSell = async () => {
		if (
			!selectedInvoices?.flatMap((si) => si.lines).some((il) => il.productGroup === "VERK") &&
			invoices?.length > 0
		) {
			setErrorMessage("Mindestens eine Verkaufspreisposition muss ausgewählt sein");
			return;
		} else {
			setErrorMessage("");
		}
		setSaving(true);

		try {
			const { data: newSales } = await axios.post<Sale>(`/api/sales/${id}/direct-sales`, {
				invoiceIds: selectedInvoices.map((i) => i.id),
				offerId: order?.offerId
			});

			const newOrder = newSales.orders.find((o) => o.status === "Invoiced");
			if (newOrder) {
				history.push(`/sales/${id}/orders/${newOrder.id}`);
			}
		} catch (e) {
			setSaving(false);
		}
	};

	const handleRefresh = () => {
		window.location.reload();
	};

	const updateButton = !loading && !order ? (
		<Box>
			<Typography color="error">{errorMessage}</Typography>
			<Box mt={1}>
				<Button variant="contained" color="primary" onClick={handleRefresh}>
					Aktualisieren
				</Button>
			</Box>
		</Box>
	) : null;

	return (
		<Layout
			title="Fahrzeug verkaufen"
			subtitle={order ? `Verkauf in ${order.channel.name}` : undefined}
			loading={loading}
			plateNumber={sales?.plateNumber}
		>
			{sales && (
				<Box mb={2}>
					<Box mb={2} sx={{ display: "flex", justifyContent: "space-between" }}>
						<Box>
							<TextBlock flat
									   primary={<>{`${vehicle?.remarketing?.publicHeadline} ${vehicle?.vin} `}<b>{sales?.vehicle.businessLine}</b></>}
							/>

						</Box>
						<Box
							sx={{ maxWidth: "130px", marginLeft: 2 }}>
							<img width="100%" src={sales.vehicle.businessLine !== "Leaseplan" ? aldLogo : leaseplanLogo} />
						</Box>
					</Box>

					{!order && <SalesInformation sale={sales} updateButton={updateButton} vehicle={vehicle} />}
				</Box>
			)}

			{!saving && order && (
				<Box>
					<Typography color="error">{errorMessage}</Typography>
				</Box>
			)}
			{order && (
				<>
					<Box marginTop={4}>
						<Typography variant="h5" gutterBottom>
							Verkauf an
						</Typography>
						<SaleAddressInfo maxWidth={600} address={order.buyer} />
					</Box>
					{loadingInvoices && (
						<Box textAlign="center">
							<CircularProgress size="small" />
						</Box>
					)}
					{!loadingInvoices && invoices.length === 0 && (
						<Box marginTop={4}>
							<Typography gutterBottom>
								Es sind zum Verkausprozess noch keine Rechnungen erfasst. Das Fahrzeug kann erst dann
								verkauft werden, wenn in Leasman die Verkaufsrechnung erfasst ist.
							</Typography>
							<Typography>
								Sie können stattdessen für das Fahrzeug eine{" "}
								<Link href={`/sales/${id}/orders/new?offerId=${order.offerId}`}>
									verbindliche Fahrzeugbestellung erfassen
								</Link>
							</Typography>
						</Box>
					)}

					{!loadingInvoices && invoices.length > 0 && (
						<>
							<Box mt={4}>
								<Box>
									<Typography variant="h5">Rechnungen</Typography>
									<Typography>
										Rechungen aus Leasman an den Käufer, die auf dasselbe Fahrzeug gebucht wurden
									</Typography>
								</Box>
								<List mt={2} maxWidth={600}>
									{invoices.map((invoice) => {
										const handleSelect = () => {
											if (invoice.id === order?.invoiceId) {
												return;
											}
											const index = selectedInvoices.indexOf(invoice);
											if (index >= 0) {
												setSelectedInvoices([
													...selectedInvoices.slice(0, index),
													...selectedInvoices.slice(index + 1)
												]);
											} else {
												setSelectedInvoices([...selectedInvoices, invoice]);
											}
										};

										const isSelected = selectedInvoices.includes(invoice);
										const isVehicleInvoice = invoice.id === order?.invoiceId;

										return (
											<ListItem key={invoice.id} color={isVehicleInvoice ? "success" : undefined}>
												<ListItemActions>
													{!isSelected && (
														<ListItemAction
															icon={<CheckBoxOutlineBlank />}
															onClick={handleSelect}
														/>
													)}
													{isSelected && (
														<ListItemAction
															icon={<CheckBoxOutlined />}
															onClick={handleSelect}
														/>
													)}
												</ListItemActions>
												<ListItemContent onClick={handleSelect}>
													<TextBlock
														primary={
															<Box
																display="flex"
																flexDirection="row"
																justifyContent="space-between"
															>
																<Box>{invoice.id}</Box>
																<Box>{formatCurrency(invoice.grossAmount)}</Box>
															</Box>
														}
														secondary={
															<InlineList>{invoice.lines.map((l) => l.text)}</InlineList>
														}
													/>
												</ListItemContent>
											</ListItem>
										);
									})}
									<Box mt={2}>
										<Actions>
											<Button
												color="primary"
												variant="contained"
												disabled={saving}
												onClick={handleSell}
											>
												Fahrzeug verkaufen
											</Button>
										</Actions>
									</Box>
								</List>
							</Box>
						</>
					)}
				</>
			)}
		</Layout>
	);
};

export default DirectSalesPage;
