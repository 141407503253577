import List from "../../../Components/List";
import ListItem from "../../../Components/ListItem";
import ListItemContent from "../../../Components/ListItemContent";
import Tile from "../../../Components/Tiles/Tile";
import { AssessmentOrder, AssessmentOrderDefectItem, Assessor, UserRole } from "../../../system/Domain";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import ListItemActions from "../../../Components/ListItemActions";
import ListItemAction from "../../../Components/ListItemAction";
import { Add } from "@mui/icons-material";
import useUser from "system/useUser";
import { RepairOrderViewModeBase, ViewMode } from "../../../Pages/Vehicles/DekraRepairOrderListItem";
import InquiryDefectListItem from "./InquiryDefectListItem";
import TextBlock from "../../../Components/Layout/TextBlock";
import axios, { AxiosResponse } from "axios";
import LoadingIndicator from "../../../Components/LoadingIndicator";

export interface AssessmentOrderPropsBase {
	isLoading: boolean
}

export interface SubjectInquiryFormType {
	id?: string
	mileage: number
	hasCarsChequebook: boolean
	dateLastMaintenance?: string
	isReadyToDrive: boolean
	assessor: Assessor
	defectItems: AssessmentOrderDefectItem[]
}

interface Props extends AssessmentOrderPropsBase {
	title: string
	assesment: AssessmentOrder
	setAssessmentOrder: (assessmentOrder: AssessmentOrder) => void
	setIsSubmitting: (isSubmitting: React.SetStateAction<boolean>) => void
	hideSubjectInquiryForm: () => void;
}

const InquiryAssesmentTile = (props: Props) => {
	const [assesmentInterruptionFormType, setAssesmentInterruptionFormType] = useState<Partial<SubjectInquiryFormType>>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const localIdPrefix = 'local';
	const [, , hasRole] = useUser();

	const isAldEntitledUser = !hasRole(UserRole.DamageAssessor) && (hasRole(UserRole.LotManager) || hasRole(UserRole.AldManager));
	const isDamageAssessor = hasRole(UserRole.DamageAssessor);

	const newInquiry = () => {
		setAssesmentInterruptionFormType({});
	};

	const cancel = () => {
		setAssesmentInterruptionFormType(undefined);
	};

	const canSave = (assesmentInterruptionFormType: SubjectInquiryFormType) =>
		assesmentInterruptionFormType.mileage !== undefined &&
		+assesmentInterruptionFormType.mileage > 0 &&
		assesmentInterruptionFormType.assessor &&
		assesmentInterruptionFormType.hasCarsChequebook !== undefined &&
		assesmentInterruptionFormType.isReadyToDrive !== undefined &&
		assesmentInterruptionFormType.defectItems &&
		assesmentInterruptionFormType.defectItems.length > 0;

	const onSavingInquiry = async (formData: SubjectInquiryFormType, viewMode: ViewMode): Promise<void> => {
		formData.defectItems.forEach(element => {
			if (element?.id?.startsWith(localIdPrefix)) {
				element.id = null
			}
		});

		let response: AxiosResponse<AssessmentOrder>;
		if (!formData) {
			return;
		}

		props.setIsSubmitting(true);
		setIsLoading(true);

		try {
			if (!formData.id) {
				response = await axios.post<AssessmentOrder>(`/api/orders/assessments/${props.assesment.id}/subject-inquiry`, formData);
			} else {
				response = await axios.put<AssessmentOrder>(`/api/orders/assessments/${props.assesment.id}/subject-inquiry`, formData);
			}
			props.setAssessmentOrder(response.data);
		} finally {
			props.setIsSubmitting(false);
			setIsLoading(false);
			setAssesmentInterruptionFormType(undefined);
		}
	};

	const alreadyHasDefectReport = props.assesment?.defect;

	if (isLoading) {
		return (<LoadingIndicator isLoading={props.isLoading} />);
	}

	return (
		<Tile title={props.title}>
			<Box boxShadow={0}>
				<List boxShadow={0}>

					{isDamageAssessor && !props.isLoading && !assesmentInterruptionFormType && !alreadyHasDefectReport && (
						<ListItem transparent boxShadow={0}>
							<ListItemActions boxShadow={0}>
								<ListItemAction
									icon={<Add />}
									onClick={newInquiry}
								/>
							</ListItemActions>
							<ListItemContent onClick={newInquiry} boxShadow={0}>
								<Typography variant="subtitle2">Sachverhaltsanfrage hinzufügen</Typography>
							</ListItemContent>
						</ListItem>
					)}

					{!props.isLoading && assesmentInterruptionFormType && (
						<InquiryDefectListItem
							assessmentOrder={props.assesment}
							cancel={cancel}
							new
							key="New"
							onSaving={onSavingInquiry}
							viewMode={RepairOrderViewModeBase.NewItem}
							canSave={canSave}
							isLoading={props.isLoading}
							setAssessmentOrder={props.setAssessmentOrder}
							setIsSubmitting={props.setIsSubmitting}
						/>
					)}

					{props.assesment?.subjectInquiries?.map(subjectInquiry => (
						<InquiryDefectListItem
							key={subjectInquiry.id}
							assessmentOrder={props.assesment}
							cancel={cancel}
							subjectInquiryDefect={subjectInquiry}
							defectItem={subjectInquiry}
							onSaving={onSavingInquiry}
							viewMode={RepairOrderViewModeBase.View}
							canSave={canSave}
							isLoading={props.isLoading}
							setAssessmentOrder={props.setAssessmentOrder}
							setIsSubmitting={props.setIsSubmitting} />
					))}
				</List>
			</Box>
			{props.assesment?.subjectInquiries?.length === 0 && (
				<>
					{isAldEntitledUser && <TextBlock secondary="Es liegen keine Sachverhaltsanfrage für dieses Fahrzeug vor." />}
					{isAldEntitledUser && alreadyHasDefectReport && (<br />)}
					{alreadyHasDefectReport && (<TextBlock secondary="Sachverhaltsanfragen können nur gestellt werden, wenn es noch keine Mängelmeldung gibt." />)}
				</>
			)}
		</Tile>
	);
};

export default InquiryAssesmentTile;
