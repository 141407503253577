import React, { useEffect, useState } from 'react';
import { CarType, CollectionType, CustomerOrder, EngineType } from "../../../system/Domain";
import CustomerStepper, { Step, StepProps } from "../CustomerStepper";
import StepCollectionFinalRemarks from "../Steps/StepCollectionFinalRemarks";
import useForm from "../../../system/useForm";
import axios from "axios";
import { useRecoilState } from "recoil";
import { customerTitle } from "../../../system/atoms/customerTitle";
import StepCollectionInstructions from "../Steps/StepCollectionInstructions";
import StepCarState from "../Steps/StepCarState";
import StepCollection from "../Steps/StepCollection";

interface Props {
	customerOrder: CustomerOrder
	toStatusOverview: () => void
	backAction: () => void
	formData?: CustomerReturnFormType
}

const steps: Step[] = [
	{
		id: 1,
		title: "Anleitung",
		ui: (props: StepProps) => <StepCollectionInstructions {...props} />
	}, {
		id: 2,
		title: "Allgemeine Fahrzeuginformationen",
		ui: (props: StepProps) => <StepCarState {...props} />
	}, {
		id: 3,
		title: "Abholung",
		ui: (props: StepProps) => <StepCollection {...props} />
	}, {
		id: 4,
		title: "Fahrzeugrückgabe",
		ui: (props: StepProps) => <StepCollectionFinalRemarks {...props} />
	}
];

export interface CustomerReturnFormType {
	plateNumber: string
	contractNumber: number
	isElectricCar?: boolean
	isDrivable?: boolean
	drivableComment?: string
	isRegistered?: boolean
	hasValidMainInspection?: boolean
	mainInspectionValidUntil?: string
	tireType?: string
	carType?: CarType
	collection?: {
		collectionType?: CollectionType
		comment?: string
		address?: {
			name?: string
			street?: string
			additionalInformation?: string
			zipCode?: string
			city?: string
			country?: string
		}
		contact?: {
			firstName?: string
			lastName?: string
			email?: string
			telephone?: string
		}
		appointment?: {
			notBefore?: string
			from?: string
			to?: string
		}
	}
	engineType?: EngineType
}

const CollectionChoice = (props: Props) => {
	const [, setCustomerTitle] = useRecoilState(customerTitle);
	const [customerForm, setCustomerForm] = useState<CustomerReturnFormType>(props.formData ?? {
		plateNumber: props.customerOrder.vehicle.plateNumber,
		contractNumber: props.customerOrder.leasmanContract?.id ?? 0,
		engineType: props.customerOrder.engineType,
		isDrivable: props.customerOrder.engineType === "Electric" ? false : undefined
	});

	const editMode = !!props.customerOrder.returnOrderId;

	useEffect(() => setCustomerTitle(editMode ? "Abholung bearbeiten" : "Abholung vereinbaren"), []);

	const form = useForm({
		values: customerForm,
		setValues: setCustomerForm
	});

	const updateCall = async () => {
		await axios.put(`/api/orders/customers/${props.customerOrder.id}/return`, customerForm);
	};

	const createCall = async () => {
		await axios.post(`/api/orders/customers/${props.customerOrder.id}/return`, customerForm);
	};

	return (
		<CustomerStepper
			customerOrder={props.customerOrder}
			steps={steps}
			backAction={props.backAction}
			editMode={editMode}
			toStatusOverview={props.toStatusOverview}
			update={updateCall}
			create={createCall}
			form={form}
		/>
	);
}

export default CollectionChoice;
