import React from "react";
import { Redirect } from "react-router-dom";
import Layout from "../Components/Layout/Layout";
import Box from "@mui/material/Box";
import DekraNewOrdersTile from "./Companies/DekraNewOrdersTile";
import useUser from "../system/useUser";

export default () => {
	const [user] = useUser();

	if (user?.company?.name === "Dekra") {
		return (
			<Layout>
				<Box m={2}>
					<DekraNewOrdersTile />
				</Box>
			</Layout>
		);
	}

	return (
		<Redirect to="/orders/transports" />
	);
};
