import Sidebar from "../../../Components/Sidebar/Sidebar";
import SidebarGroup from "../../../Components/Sidebar/SidebarGroup";
import React, { Dispatch, SetStateAction, useState } from "react";
import { JobQuery } from "./Jobs";
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select } from "@mui/material";
import SidebarForm from "../../../Components/Sidebar/SidebarForm";
import useAxiosEffect from "../../../system/useAxiosEffect";
import axios from "axios";
import TextBlock from "../../../Components/Layout/TextBlock";
import formatDateTime from "../../../system/formatDateTime";
import { formatJobStatus } from "./JobListItem";
import { JobStatus, jobStatusList } from "system/Domain";

interface Props {
	query: JobQuery;
	setQuery: Dispatch<SetStateAction<JobQuery>>;
}

interface JobType {
	type: string;
	count: number;
	last: string;
}

export const formatJobType = (type: string) => {
	const tokens = type.split(", ");
	const typeTokens = tokens[0].split(".");
	return typeTokens[typeTokens.length - 1];
};

export default ({ query, setQuery }: Props) => {
	const [jobTypes, setJobTypes] = useState<JobType[]>();

	useAxiosEffect(async (config) => {
		const { data } = await axios.get<JobType[]>(`/api/admin/jobs/types`, config);
		setJobTypes(data);
	}, []);

	return (
		<Sidebar>
			<SidebarGroup>
				<SidebarForm>
					<FormControl fullWidth variant="standard">
						<InputLabel>Typen</InputLabel>
						<Select
							multiple
							multiline
							value={query.types || []}
							renderValue={(v) => (v as string[])?.map(formatJobType).join(", ")}
							onChange={(e) => {
								const value = e.target.value as string[];
								if (!value) {
									return;
								}

								setQuery((q) => ({
									...q,
									types: value,
								}));
							}}
						>
							{jobTypes &&
								jobTypes.map((t) => (
									<MenuItem key={t.type} value={t.type}>
										<TextBlock
											reduced
											primary={formatJobType(t.type)}
											secondary={formatDateTime(t.last)}
										/>
									</MenuItem>
								))}
						</Select>
					</FormControl>
					<FormControl variant="standard" fullWidth>
						<InputLabel>Status</InputLabel>
						<Select
							multiple
							multiline
							value={query.states || []}
							renderValue={(v) => (v as JobStatus[])?.map(formatJobStatus).join(", ")}
							onChange={(e) => {
								const value = e.target.value as JobStatus[];
								if (!value) {
									return;
								}

								setQuery((q) => ({
									...q,
									states: value,
								}));
							}}
						>
							{jobStatusList.map((s) => (
								<MenuItem value={s} key={s}>
									{formatJobStatus(s)}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl fullWidth>
						<FormControlLabel
							control={
								<Checkbox
									value={query.onlyRecurring}
									onChange={(e) =>
										setQuery((q) => ({
											...q,
											onlyRecurring: e.target.checked,
										}))
									}
								/>
							}
							label="Nur Cronjobs"
						/>
					</FormControl>
				</SidebarForm>
			</SidebarGroup>
		</Sidebar>
	);
};
