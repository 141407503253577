import React from "react";
import moment from "moment";
import dateFormats from "../../system/dateFormats";
import { InventoryProps } from "./Inventory";

export default ({ inventory } : InventoryProps) => <>
	{!inventory.inventoryDate
		? 'Neue Inventur'
		: `Inventur vom ${moment(inventory.inventoryDate).format(dateFormats.date)}`
	}
</>
