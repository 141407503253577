import { Box, useTheme } from "@mui/material";
import Thumbnail from "../../Components/Thumbnail";
import VehicleDescription from "./VehicleDescription";
import TextBlock from "../../Components/Layout/TextBlock";
import { Vehicle, VehiclePicture } from "../../system/Domain";

export interface VehicleHeaderProps {
	vehicle: Vehicle;
	picture?: VehiclePicture | null;
	showDescription?: boolean;
}

export default ({ vehicle, picture, showDescription = true }: VehicleHeaderProps) => {
	const theme = useTheme();

	if (!picture && vehicle.pictureSets.length > 0) {
		picture = {
			hash: vehicle.pictureSets[vehicle.pictureSets.length - 1].frontLeft.hash,
			filename: `${vehicle.vin}.jpg`,
			dmsId: "",
		};
	}

	return (
		<Box
			mb={2}
			sx={{
				display: "flex",
				flexDirection: "row",
				gap: theme.spacing(2),
				alignItems: "center",
			}}
		>
			{picture && (
				<Box
					sx={{
						display: { xs: "none", sm: "contents" },
						"& img": {
							borderRadius: theme.spacing(0.5),
						},
					}}
				>
					{picture.hash !== "0383a250e987fe737edbf2518f208bc7" && (
						<Thumbnail
							width={80}
							height={60}
							asyncUrl={`/api/storage/${picture.hash}/thumbnail`}
							downloadUrl={`/api/storage/${picture.hash}`}
						/>
					)}
				</Box>
			)}
			<TextBlock
				flat
				primary={vehicle.type?.description}
				secondary={showDescription && <VehicleDescription vehicle={vehicle} />}
			/>
		</Box>
	);
};
