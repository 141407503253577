import Menu from "./Menu";
import useUser from "system/useUser";
import { useRecoilState } from "recoil";
import { Plate } from "Components/Plate";
import { UserRole } from "system/Domain";
import useHotkeys from "system/useHotkeys";
import ErrorSnackbar from "./ErrorSnackbar";
import { menuAtom } from "system/atoms/menu";
import { useDropzone } from "react-dropzone";
import LayoutNavigation from "./LayoutNavigation";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import NotificationSnackbar from "./NotificationSnackbar";
import { AppState } from "../../system/store";
import NotificationArea from "./Notifications/NotificationArea";
import NetworkStateNotification from "./NetworkStateNotification";
import VehiclesSearchOverlay from "Components/VehiclesSearchOverlay";
import RootNavigationSection from "Navigation/RootNavigationSection";
import { Drawer, IconButton, Typography } from "@mui/material";
import { alpha, Box, LinearProgress, useTheme } from "@mui/material";
import { clearUserMessage } from "../../system/reducers/userMessage/actions";
import NotificationNotification from "./Notifications/NotificationNotification";
import { CallMerge, Search, VerticalSplit, Videocam } from "@mui/icons-material";
import React, {
	PropsWithChildren,
	ReactElement,
	ReactNode,
	useCallback,
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from "react";
import { navigationAtom } from "system/atoms/navigation";

interface LayoutProps {
	title?: string | ReactElement;
	plateNumber?: string;
	subtitle?: string;
	footer?: ReactNode;
	onScroll?: React.EventHandler<any>;
	subMenu?: ReactNode;
	contextMenu?: ReactElement[];
	navigation?: ReactNode;
	sidebar?: ReactNode;
	disablePadding?: boolean;
	loading?: boolean;
	hideNavigation?: boolean;
	onDropFiles?: (files: File[]) => void;
	notification?: ReactNode;
	disableOverlay?: boolean;
}

const host = window.location.href;
let environment: "localhost" | "dev" | "stage" | "prod" = host.includes("dev.ald")
	? "dev"
	: host.includes("localhost:")
		? "localhost"
		: host.includes("stage.ald")
			? "stage"
			: "prod";

export default ({
	title,
	plateNumber,
	children,
	footer,
	onScroll,
	navigation,
	sidebar,
	onDropFiles,
	notification,
	disablePadding = false,
	loading = false,
	hideNavigation = false,
	disableOverlay = false,
}: PropsWithChildren<LayoutProps>) => {
	const theme = useTheme();

	const paintEnvironmentBackground = () => {
		switch (environment) {
			case "stage":
			case "dev":
				return theme.palette.secondary.light;
			case "localhost":
			default:
				return theme.palette.primary.light;
		}
	};

	const paintEnvironmentForeground = () => {
		switch (environment) {
			case "stage":
			case "dev":
				return theme.palette.common.black;
			case "localhost":
			default:
				return theme.palette.common.white;
		}
	};

	const dispatch = useDispatch();

	const onDrop = useCallback((files) => {
		onDropFiles && onDropFiles(files);
	}, []);

	const { getRootProps } = useDropzone({
		onDrop,
		multiple: true,
	});

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>();
	const notifications = useSelector((state: any) => state.notifications);
	const error = useSelector((state: any) => state.errors.error);
	const shouldShowError = useSelector((state: any) => state.errors.handleError);
	const userMessage = useSelector((state: AppState) => state.userMessage.userMessage);

	const [menu, setMenu] = useRecoilState(menuAtom);
	useHotkeys("ctrl+m", () =>
		setMenu((m) => ({
			...m,
			open: !m.open,
		})),
	);
	const [, setNavigation] = useRecoilState(navigationAtom);
	useHotkeys("ctrl+o", () =>
		setNavigation((n) => ({ ...n, orientation: n.orientation !== "vertical" ? "vertical" : "horizontal" })),
	);

	const [overlay, setOverlay] = useState<"none" | "navigation" | "search">("none");
	useHotkeys("ctrl+enter", () => setOverlay((o) => (o !== "navigation" ? "navigation" : "none")));
	useHotkeys("ctrl+s", () => (!disableOverlay ? setOverlay((o) => (o !== "search" ? "search" : "none")) : null));
	useHotkeys("escape", () => setOverlay("none"));

	const location = useLocation();
	useEffect(() => {
		setOverlay("none");
		setNavigation((n) => ({ ...n, filter: "" }));
	}, [location]);

	const [sidebarOpen, setSidebarOpen] = useState(false);
	const contentRef = useRef<HTMLDivElement>(null);

	const handleClearError = () => {
		dispatch({ type: "CLEAR_ERROR" });
	};

	const unreadNotifications = notifications.filter((n: any) => !n.dateRead);

	const handleScroll = useCallback(() => {
		if (!onScroll || !contentRef.current) {
			return;
		}

		onScroll(contentRef.current);
	}, [onScroll]);

	useLayoutEffect(() => {
		if (contentRef && contentRef.current) {
			window.addEventListener("scroll", handleScroll);
			return () => window.removeEventListener("scroll", handleScroll);
		}
	}, [contentRef.current, handleScroll]);

	const [user, , hasRole] = useUser();
	const history = useHistory();

	const header = (
		<Box
			sx={[
				{
					display: "flex",
					justifyContent: "space-between",
					paddingLeft: theme.spacing(3),
					paddingRight: theme.spacing(3),
					alignItems: "center",
					backgroundColor: theme.palette.background.default,
					color: theme.palette.text.primary,
					borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
					borderBottomColor: alpha(theme.palette.text.primary, 0.05),
					boxShadow: "none",
					minHeight: { xs: "unset", sm: 64 },
					height: "auto",
					gap: theme.spacing(2),
				},
				overlay !== "none" && { background: "none" },
				loading && { borderBottom: "none" },
			]}
		>
			{user && !hasRole(UserRole.Customer) && (
				<IconButton
					onClick={() => setOverlay((o) => (o !== "navigation" ? "navigation" : "none"))}
					color="inherit"
				>
					<CallMerge />
				</IconButton>
			)}
			<Box
				sx={{
					display: "flex",
					gap: theme.spacing(2),
					flexGrow: 1,
					alignItems: "center",
					[theme.breakpoints.down("xs")]: {
						flexDirection: "column-reverse",
						paddingTop: theme.spacing(1),
						paddingBottom: theme.spacing(1),
						gap: theme.spacing(0.5),
					},
				}}
			>
				{plateNumber && (
					<>
						<Box sx={{ display: { xs: "contents", sm: "none" } }}>
							<Plate plateNumber={plateNumber} style={{ zoom: 0.3, display: "block" }} />
						</Box>
						<Box sx={{ display: { xs: "none", sm: "contents" } }}>
							<Plate plateNumber={plateNumber} style={{ zoom: 0.5, display: "block" }} />
						</Box>
					</>
				)}
				<Typography
					variant="h4"
					sx={{
						fontSize: { xs: "1.3rem", sm: "1.5rem" },
						fontWeight: 300,
						color: theme.palette.primary.light,
						display: "inline-block",
					}}
				>
					{title}
				</Typography>
			</Box>

			{environment !== "prod" && (
				<Box
					sx={{
						backgroundColor: alpha(paintEnvironmentBackground(), 0.8),
						borderRadius: 20,
						textAlign: "center",
						px: 1.5,
						py: 0.5,
					}}
				>
					<Typography
						sx={{
							lineHeight: "1.8rem",
							color: paintEnvironmentForeground(),
							fontFamily: "Chillax-Regular",
						}}
					>
						{environment}
					</Typography>
				</Box>
			)}

			{hasRole(UserRole.LotManager) && user?.company?.name === "Dekra" && (
				<IconButton onClick={() => history.push(`/vehicles/scan`)} color="inherit">
					<Videocam />
				</IconButton>
			)}
			{user && !hasRole(UserRole.Customer) && !disableOverlay && (
				<IconButton onClick={() => setOverlay((o) => (o !== "search" ? "search" : "none"))} color="inherit">
					<Search />
				</IconButton>
			)}
			{sidebar && overlay === "none" && (
				<IconButton
					onClick={() => setSidebarOpen((open) => !open)}
					sx={{ display: { xs: "contents", md: "none" }, color: "inherit" }}
				>
					<VerticalSplit />
				</IconButton>
			)}
		</Box>
	);

	return (
		<Box
			sx={{
				display: "flex",
				height: "100%",
				minWidth: 350,
				backgroundColor: theme.palette.background.default,
				"& .MuiGrid-container": {
					flexShrink: 0,
				},
			}}
		>
			<>
				{shouldShowError && <ErrorSnackbar error={error} onClose={handleClearError} />}
				<NotificationArea open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
					<NotificationNotification items={unreadNotifications} />
				</NotificationArea>
				<NotificationSnackbar
					message={userMessage.message}
					onClose={() => dispatch(clearUserMessage())}
					color={userMessage.color}
				/>
			</>

			<Box
				sx={{
					flexGrow: 1,
					display: "flex",
					flexDirection: "column",
					overflow: "hidden",
				}}
			>
				<Box
					sx={{
						outline: "none",
						display: "flex",
						overflow: "hidden",
						flexGrow: 1,
					}}
					{...getRootProps()}
				>
					{menu.open && (
						<Box sx={{ display: { xs: "none", lg: "contents" } }}>
							<Box display="fixed">
								<Menu hideNavigation={hideNavigation} />
							</Box>
						</Box>
					)}

					<Box display="flex" overflow="hidden" flexGrow={1} position="relative">
						<Box
							sx={{
								display: "flex",
								overflow: "hidden",
								position: "relative",
								flexGrow: 1,
								flexDirection: "column",
							}}
						>
							{header}

							{loading && <LinearProgress />}

							<NetworkStateNotification />
							{notification}

							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									flexGrow: 1,
									overflow: "auto",
									padding: !disablePadding ? theme.spacing(4) : "unset",
								}}
								ref={contentRef}
							>
								{children}
							</Box>
						</Box>

						{sidebar && (
							<Box sx={{ display: { xs: "none", md: "contents" } }}>
								<Box
									sx={{
										width: "1px",
										backgroundColor: alpha(theme.palette.text.primary, 0.05),
									}}
								/>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										padding: theme.spacing(4),
										overflow: "auto",
										flexBasis: 300,
										flexGrow: 0,
										flexShrink: 0,
									}}
								>
									{sidebar}
								</Box>
							</Box>
						)}
						{overlay !== "none" && (
							<Box
								sx={{
									display: "flex",
									height: "100%",
									width: "100%",
									flexGrow: 1,
									backgroundColor:
										theme.palette.mode === "dark"
											? "rgba(42, 46, 60, 0.9)"
											: theme.palette.background.default,
									backdropFilter: "blur(6px)",
									top: 0,
									left: 0,
									position: "absolute",
									flexDirection: "column",
								}}
							>
								{header}
								<Box overflow="auto">
									{overlay === "navigation" &&
										(navigation || (
											<LayoutNavigation>
												<RootNavigationSection />
											</LayoutNavigation>
										))}
									{overlay === "search" && <VehiclesSearchOverlay />}
								</Box>
							</Box>
						)}
					</Box>
				</Box>
				{footer && <Box>{footer}</Box>}
			</Box>

			<Box sx={{ display: { xs: "contents", md: "none" } }}>
				<Drawer
					variant="temporary"
					anchor={theme.direction === "rtl" ? "left" : "right"}
					open={sidebarOpen}
					onClose={() => setSidebarOpen(false)}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
				>
					<Box
						sx={{
							width: 300,
							flexDirection: "row",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								padding: theme.spacing(4),
								overflow: "auto",
								flexBasis: 300,
								flexGrow: 0,
								flexShrink: 0,
							}}
						>
							{sidebar}
						</Box>
					</Box>
				</Drawer>
			</Box>
		</Box>
	);
};
