import React, { ReactElement } from "react";
import { Button, ButtonProps, useTheme } from "@mui/material";
import _ from "lodash";

interface Props extends ButtonProps {
	icon?: ReactElement
}

export default ({ icon, ...rest }: Props) => {
	const theme = useTheme();

	if (!!icon) {
		icon = React.cloneElement(icon, {
			sx: {
				fontSize: "1.1rem"
			}
		});
	}

	return (
		<Button
			sx={{
				flexGrow: 1,
				minWidth: "auto",
				padding: theme.spacing(1)
			}}
			{...rest}
		>
			{icon}
		</Button>
	);
}
