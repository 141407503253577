import React from 'react';
import { Import, VehicleRegistrationDocumentsProjection } from "../../../system/Domain";
import { useHistory } from "react-router";
import { useAsync } from "react-use";
import axios from "axios";
import { Box, TableBody, TableCell, TableRow } from "@mui/material";
import PaperTable from "../../../Components/PaperTable";
import Plate from "../../../Components/Plate/Plate";
import TextBlock from "../../../Components/Layout/TextBlock";
import moment from "moment";
import dateFormats from "../../../system/dateFormats";

const VehicleRegistrationDocumentRequestsImportView = ({ imp } : { imp : Import }) => {
	const history = useHistory();

	const { value: vehicles } = useAsync(async () => {
		const { data } = await axios.get<VehicleRegistrationDocumentsProjection[]>(`/api/vehicles/remarketing/registration-documents`, {
			params: {
				importId: imp.id,
				specialSort: "PlateNumberNumbers"
			}
		});
		return data;
	}, [imp]);

	return (
		<Box mt={2}>
			<PaperTable>
				<colgroup>
					<col width="auto" />
					<col width="auto" />
				</colgroup>
				<TableBody>
					{vehicles && vehicles.map((v, i) => (
						<TableRow
							key={i}
							hover
							onClick={() => history.push(`/vehicles/${v.id}/orders`)}
						>
							<TableCell>
								<Plate plateNumber={v.plateNumber} style={{ zoom: 0.6 }} />
								<TextBlock
									primary={v.makeModel}
									secondary={v.vin}
									compact
								/>
							</TableCell>
							<TableCell>
								{v.registrationDocuments.handover && (
									<TextBlock
										primary={v.registrationDocuments!.handover!.handoverType!.toString()}
										secondary={moment(v.registrationDocuments.handover.dateVaultRemoval).format(dateFormats.dateTime)}
										tertiary={v.registrationDocuments!.handover!.trackingReference!}
									/>
								)}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</PaperTable>
		</Box>
	);
};

export default VehicleRegistrationDocumentRequestsImportView;
