import { Appointment, AssessmentOrderStatusInformation, CarType, DeliveryType, EngineType, ReturnOrder, ReturnOrderTireType } from "../../../system/Domain";
import TextBlock from "../../../Components/Layout/TextBlock";
import moment from "moment";
import dateFormats from "../../../system/dateFormats";
import { getEnumName } from "../../../utils";

interface Props {
	returnOrder : ReturnOrder
	assessmentOrderStatusInformation? : AssessmentOrderStatusInformation
}

const getTimeInformation = (appointment : Appointment) : string => {
	if (appointment.from) {
		if (appointment.to) {
			return `${moment(appointment.from).format(dateFormats.time)} - ${moment(appointment.to).format(dateFormats.time)}`
		} else {
			return `ab ${moment(appointment.from).format(dateFormats.time)}`;
		}
	} else {
		if (appointment.to) {
			return `bis ${moment(appointment.to).format(dateFormats.time)}`
		} else {
			return '';
		}
	}
};

const getAssessmentInformation = (props:Props) => {
	if (!props.assessmentOrderStatusInformation || !props.assessmentOrderStatusInformation.assessmentDate) {
		return props.returnOrder.assessment?.appointment ?
			<TextBlock
				primary={`Gutachtenerstellung ab dem ${moment(props.returnOrder.assessment.appointment.notBefore).format(dateFormats.date)}`}
				secondary={getTimeInformation(props.returnOrder.assessment.appointment)}
			/>
			: undefined;
	}

	return (
		<TextBlock
			primary={`Gutachten erstellt am: ${moment(props.assessmentOrderStatusInformation.assessmentDate).format(dateFormats.date)}`}
		/>
	)
};

const AppointmentInformation = (props : Props) => {
	const isCustomerDelivery = props.returnOrder.delivery.deliveryType === DeliveryType.CustomerDelivery;

	let collection = !isCustomerDelivery && props.returnOrder.collection.appointment ?
		<TextBlock
			primary={`Abholung ab dem ${moment(props.returnOrder.collection.appointment.notBefore).format(dateFormats.date)}`}
			secondary={getTimeInformation(props.returnOrder.collection.appointment)}
		/>
		: undefined;
	let delivery = isCustomerDelivery ?
		<TextBlock
			primary={`Eigenanlieferung geplant am ${moment(props.returnOrder.delivery.appointment.notBefore).format(dateFormats.date)}`}
		/>
		: undefined;

	let assessment = getAssessmentInformation(props);

	const collectionFirst = moment(props.returnOrder.collection.appointment?.notBefore) <= moment(props.returnOrder.assessment?.appointment?.notBefore);

	if (collectionFirst) {
		return (
			<>
				{collection ?? delivery}
				{assessment}
			</>
		);
	} else {
		return (
			<>
				{assessment}
				{collection ?? delivery}
			</>
		)
	}
};

const VehicleInformation = (props : Props) => {
	const getEngineTypeName = (engineType : EngineType) => {
		switch (engineType) {
			case "Combustion":
				return "Verbrennungsmotor";
			case "Hybrid":
				return "Hybrid";
			case "Electric":
				return "Elektro";
			case "Unknown":
				return "Unbekannt";
		}
	};

	return (
		<>
			{props.returnOrder && (
				<>
					{props.returnOrder.status === "Processed" && (
						<AppointmentInformation returnOrder={props.returnOrder} assessmentOrderStatusInformation={props.assessmentOrderStatusInformation} />
					)}
					{props.returnOrder.isDrivable ? (
						<TextBlock
							primary={`Fahrzeug ist fahrbereit`}
							secondary={props.returnOrder.drivableComment}
						/>
					) : (
						<>Fahrzeug ist nicht fahrbereit</>
					)}
					<br />
					{props.returnOrder.hasValidMainInspection ? (
						<TextBlock
							primary={"Hu/Au gültig"}
							secondary={props.returnOrder.mainInspectionValidUntil ? `bis ${moment(props.returnOrder.mainInspectionValidUntil).format(dateFormats.monthYear)}` : undefined}
						/>
					) : (
						<>HuAu nicht gültig</>
					)}
					<br />
					{`Fahrzeug ${!props.returnOrder.isRegistered ? "nicht " : ""}angemeldet`}
					<br />
					{props.returnOrder.tireType.toString() === "unk" && (
						<>Reifentyp: </>
					)}
					{getEnumName(ReturnOrderTireType, props.returnOrder.tireType)}
					{props.returnOrder.engineType === "Unknown" && props.returnOrder.isElectricCar && (
						<>
							<br />
							Elektroauto
						</>
					)}
					{props.returnOrder.engineType && (
						<>
							<br />
							Motortyp: {getEngineTypeName(props.returnOrder.engineType)}
						</>
					)}
					{props.returnOrder.isPoolfleet && (
						<>
							<br />
							ALD Flex
						</>
					)}
					{props.returnOrder.carType && (
						<>
							<br />
							{props.returnOrder.carType.toString() === "unk" && (
								<>Fahrzeugtyp: </>
							)}
							{getEnumName(CarType, props.returnOrder.carType)}
						</>
					)}
					{props.returnOrder.customerServiceAgent && (
						<>
							<br />
							<br />
							<TextBlock
								primary={props.returnOrder.customerServiceAgent.name}
								secondary={props.returnOrder.customerServiceAgent.email}
								tertiary={props.returnOrder.customerServiceAgent.telephone}
							/>
						</>
					)}
				</>
			)}
		</>
	);
};

export default VehicleInformation;
