import React from 'react';
import { CustomerOrder } from "../../system/Domain";
import PricingRequestStatusOverview from "./PricingRequest/PricingRequestStatusOverview";
import CustomerDeliveryStatusOverview from "./CustomerDelivery/CustomerDeliveryStatusOverview";
import CollectionStatusOverview from "./Collection/CollectionStatusOverview";

export interface CustomerOrderWithReturnOrderId extends CustomerOrder {
	returnOrderId: string
}

interface Props {
	customerOrder: CustomerOrderWithReturnOrderId
	toStatusOverview: () => void
	customerCancellation: (customerOrder: CustomerOrder) => void
	updateCustomerOrder: (customerOrder: CustomerOrder) => void
	customerDecision: (customerOrder: CustomerOrder) => void
}

const CustomerStatusOverview = (props: Props) => {
	return (
		<>
			{props.customerOrder.type === "Return" && (
				<CollectionStatusOverview
					customerOrder={props.customerOrder as CustomerOrderWithReturnOrderId}
					toStatusOverview={props.toStatusOverview}
					customerCancellation={props.customerCancellation}
				/>
			)}
			{props.customerOrder.type === "CustomerDelivery" && (
				<CustomerDeliveryStatusOverview
					customerOrder={props.customerOrder as CustomerOrderWithReturnOrderId}
					toStatusOverview={props.toStatusOverview}
					customerCancellation={props.customerCancellation}
				/>
			)}
			{props.customerOrder.type === "PricingRequest" && (
				<PricingRequestStatusOverview
					customerOrder={props.customerOrder as CustomerOrderWithReturnOrderId}
					toStatusOverview={props.toStatusOverview}
					customerCancellation={props.customerCancellation}
					updateCustomerOrder={props.updateCustomerOrder}
					customerDecision={props.customerDecision}
				/>
			)}
		</>
	);
}

export default CustomerStatusOverview;
