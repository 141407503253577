import React, { useState } from "react";
import Layout from "../../Components/Layout/Layout";
import { LogEvent } from "../../system/Domain";
import Log from "./Log";
import useEvent from "../../system/useEvent";
import useChannel from "../../system/useChannel";

export default () => {
	const [logEvents, setLogEvents] = useState<LogEvent[]>([]);

	useChannel("realtime-log");

	useEvent("job:log", data => {
		const logEvent = data as LogEvent;
		if (!logEvent) {
			return;
		}

		setLogEvents(state => {
			return [...state, logEvent];
		});
	});

	return (
		<Layout disablePadding>
			<Log items={logEvents} top={64} />
		</Layout>
	);
};
