import { Box, Grid, Typography } from "@mui/material";
import axios from "axios";
import ActionButton from "Components/ActionButton";
import TextBlock from "Components/Layout/TextBlock";
import Tile from "Components/Tiles/Tile";
import TileActions from "Components/Tiles/TileActions";
import TileDivider from "Components/Tiles/TileDivider";
import { Dispatch, SetStateAction } from "react";
import useRunAction from "system/useRunAction";
import FormChoices from "../../Components/FormChoices";
import FormDateTimePicker from "../../Components/FormDateTimePicker";
import FormNumber from "../../Components/FormNumber";
import TileContent from "../../Components/Tiles/TileContent";
import Image, { ImageViewMode } from "../../Pages/Vehicles/PictureSets/Image";
import dateFormats from "../../system/dateFormats";
import { PricingRequest } from "../../system/Domain";
import formatCurrency from "../../system/formatCurrency";
import { formatVehiclePricingRequestPurchaser } from "../../system/formatVehiclePricingRequestPurchaser";
import useForm from "../../system/useForm";
import Thumbnail from "../../Components/Thumbnail";

interface Props {
	request?: PricingRequest
	setRequest: Dispatch<SetStateAction<PricingRequest | undefined>>
}

export default ({ request, setRequest }: Props) => {
	const form = useForm({
		values: request,
		setValues: setRequest,
		deltaValues: null
	});

	const readOnly = !!request && request.status !== "Created";
	const isComplete = !!request && !!request.datePurchase && !!request.purchaser && !!request.mileage;

	const [actionRunning, runAction] = useRunAction();

	const requestPricing = () => runAction(async () => {
		if (!request || !isComplete) {
			return;
		}

		await axios.put<PricingRequest>(`/api/pricing-requests/${request.id}`, request);
		const { data } = await axios.post<PricingRequest>(`/api/pricing-requests/${request.id}/request`);
		setRequest(data);
	});

	const withdrawPricing = () => runAction(async () => {
		if (!request) {
			return;
		}

		const { data } = await axios.post<PricingRequest>(`/api/pricing-requests/${request.id}/withdraw`);
		setRequest(data);
	});

	return (
		<Tile title="Kaufvertrag">
			<TileContent>
				<Box pb={2}>
					{request.price && (request.status === "Priced" || request.status === "Completed") && (
						<Box>
							<Box display="flex" flexDirection="row" justifyContent="space-between">
								<Box>
									<Box mb={0.25}>
										<Typography variant="caption" color="textSecondary">Netto-Preis</Typography>
									</Box>
									<Box fontSize="1.4rem" fontWeight="bold" display="inline">{formatCurrency(request.price.netAmount)}</Box>
								</Box>
								<Box>
									<Box mb={0.25}>
										<Typography variant="caption" color="textSecondary">Brutto-Preis</Typography>
									</Box>
									<Box fontSize="1.4rem" fontWeight="bold" display="inline">{formatCurrency(request.price.grossAmount)}</Box>
								</Box>
							</Box>
							<TileDivider sx={{ mt: 2, mb: 3 }} />
						</Box>
					)}

					<Grid container spacing={5}>
						<FormChoices
							form={form}
							label="Übernahme durch"
							name="purchaser"
							choices={{
								"Driver": formatVehiclePricingRequestPurchaser("Driver"),
								"Lessee": formatVehiclePricingRequestPurchaser("Lessee"),
								"Dealer": formatVehiclePricingRequestPurchaser("Dealer"),
								"BusinessClient": formatVehiclePricingRequestPurchaser("BusinessClient"),
							}}
							disabled={readOnly}
						/>

						<Grid item xs={12}>
							<Box width={250}>
								<FormDateTimePicker
									form={form}
									label="Datum Kaufvertrag"
									name="datePurchase"
									variant="date"
									format={dateFormats.date}
									disabled={readOnly}
									fullWidth
								/>
							</Box>
						</Grid>

						<FormNumber
							form={form}
							label="KM Stand Kaufvertrag"
							name="mileage"
							readOnly={readOnly}
							inline
						/>
						{!!request.mileagePictureProof && request.customerOrderId && (
							<Grid item>
								<Box mt={2}>
									<Thumbnail
										asyncUrl={`/api/storage/${request.mileagePictureProof.hash}/thumbnail`}
										downloadUrl={`/api/storage/${request.mileagePictureProof.hash}`}
									/>
									<TextBlock
										secondary="Bildnachweis des Kunden"
									/>
								</Box>
							</Grid>
						)}
					</Grid>
				</Box>
			</TileContent>
			<TileActions>
				{request.status === "Created" && (
					<ActionButton
						color="primary"
						variant="text"
						disabled={actionRunning || !isComplete}
						onClick={requestPricing}
					>
						Anfrage stellen
					</ActionButton>
				)}
				{(request.status === "Created" || request.status === "Pricing") && (
					<ActionButton
						color="secondary"
						variant="text"
						disabled={actionRunning}
						onClick={withdrawPricing}
					>
						Anfrage zurückziehen
					</ActionButton>
				)}
			</TileActions>
		</Tile>
	);
}
