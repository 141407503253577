import React, { useState } from 'react';
import { confirmable, createConfirmation, ReactConfirmProps } from "react-confirm";
import { Box, Button, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import ThemedDialog from "../../Components/ThemedDialog";

interface Props extends ChangeTextOptions, ReactConfirmProps<string> {
	show : boolean
}

const ChangeTextDialog = (props : Props) => {
	const [text, setText] = useState(props.text);

	const handleClose = () => {
		props.cancel();
	};

	return (
		<ThemedDialog open={props.show} maxWidth="sm" fullWidth onClose={handleClose}>
			<DialogTitle>
				{props.headline}
			</DialogTitle>
			<DialogContent>
				<Box mb={2}>
					<TextField
						fullWidth
						variant="standard"
						value={text}
						label={props.label}
						onChange={(e) => setText(e.target.value)}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					color="secondary"
					href=""
					onClick={handleClose}
				>
					Abbrechen
				</Button>
				<Button
					variant="outlined"
					color="primary"
					href=""
					onClick={() => {
						if (!props.required) {
							props.proceed(text);
						} else if (text) {
							props.proceed(text);
						}
					}}
				>
					Ok
				</Button>
			</DialogActions>
		</ThemedDialog>
	);
};

interface ChangeTextOptions {
	text : string
	headline : string
	label : string
	required? : boolean
}

export default (options : ChangeTextOptions) => {
	const dialog = confirmable(props => <ChangeTextDialog {...props} {...options} />);
	return createConfirmation<string>(dialog)();
}
