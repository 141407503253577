import useReturnOrder from "../../../system/useReturnOrder";
import React, { useEffect, useState } from "react";
import CustomerConfirmationDialog from "../CustomerConfirmationDialog";
import axios from "axios";
import { CustomerOrder, ReturnOrder, ReturnOrderStatus, TransportOrderStatus } from "../../../system/Domain";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import { Box, Divider, Grid, Typography } from "@mui/material";
import Tile from "../../../Components/Tiles/Tile";
import TileContent from "../../../Components/Tiles/TileContent";
import { ReturnList } from "../Steps/StepCollectionFinalRemarks";
import CustomerCarState from "../CustomerCarState";
import CustomerDeliveryOverview from "./CustomerDeliveryOverview";
import CustomerDeliveryChoice, { CustomerDeliveryFormType } from "./CustomerDeliveryChoice";
import { CustomerOrderWithReturnOrderId } from "../CustomerStatusOverview";
import CustomerChoiceDisplay from "../CustomerChoiceDisplay";
import { useRecoilState } from "recoil";
import { customerTitle } from "../../../system/atoms/customerTitle";
import moment from "moment/moment";
import dateFormats from "../../../system/dateFormats";
import useAsyncEffect from "../../../system/useAsyncEffect";
import { EmojiTransportation } from "@mui/icons-material";

interface Props {
	customerOrder: CustomerOrderWithReturnOrderId;
	toStatusOverview: () => void;
	customerCancellation: (customerOrder: CustomerOrder) => void;
}

interface Status {
	transportOrderStatus: TransportOrderStatus;
	returnOrderStatus: ReturnOrderStatus;
}

const calculateFormData = (returnOrder: ReturnOrder): CustomerDeliveryFormType => {
	return {
		plateNumber: returnOrder.plateNumber,
		contractNumber: returnOrder.leasmanContract?.id || 0,
		isElectricCar: returnOrder.isElectricCar,
		isDrivable: returnOrder.isDrivable,
		drivableComment: returnOrder.drivableComment,
		isRegistered: returnOrder.isRegistered,
		hasValidMainInspection: returnOrder.hasValidMainInspection,
		mainInspectionValidUntil: returnOrder.mainInspectionValidUntil,
		tireType: returnOrder.tireType,
		carType: returnOrder.carType,
		delivery: {
			deliveryType: returnOrder.delivery.deliveryType,
			compound: returnOrder.delivery.compound,
			comment: returnOrder.delivery.comment,
			appointment: {
				notBefore: returnOrder.delivery.appointment.notBefore,
				from: returnOrder.delivery.appointment.from,
				to: returnOrder.delivery.appointment.to,
			},
		},
		engineType: returnOrder.engineType,
	};
};

const CustomerDeliveryStatusOverview = (props: Props) => {
	const [, setCustomerTitle] = useRecoilState(customerTitle);
	const [returnOrder, loadingCompleted] = useReturnOrder(props.customerOrder.returnOrderId);
	const [wantsToEdit, setWantsToEdit] = useState<boolean | undefined>(undefined);
	const [status, setStatus] = useState<Status>();

	useEffect(() => setCustomerTitle("Status Anlieferung"), []);

	useAsyncEffect(async () => {
		const response = await axios.get<Status>(`/api/orders/customers/${props.customerOrder.id}/status`);

		setStatus(response.data);
	}, [props.customerOrder]);

	const edit = () => {
		setWantsToEdit(true);
	};

	const back = () => {
		setWantsToEdit(undefined);
		setCustomerTitle("Status Anlieferung");
	};

	const confirmCancellation = async () => {
		const answer = await CustomerConfirmationDialog({
			title: "Anlieferung stornieren",
			message: "Sind Sie sich sicher, dass sie die Anlieferung stornieren wollen?",
		});

		if (answer.yes) {
			const { data: customerOrder } = await axios.post<CustomerOrder>(
				`/api/orders/customers/${props.customerOrder.id}/revoke`,
			);

			props.customerCancellation(customerOrder);
		}
	};

	const transportIsCompleted = status?.transportOrderStatus === "Completed";

	const displayTitle = transportIsCompleted
		? "Das Fahrzeug wurde bereits angeliefert"
		: !returnOrder
			? "Anlieferung geplant"
			: `Anlieferung geplant am ${moment(returnOrder.delivery.appointment.notBefore).format(dateFormats.date)}`;

	const couldEdit = returnOrder?.status !== "Cancelled" && !transportIsCompleted;

	return (
		<LoadingIndicator isLoading={!loadingCompleted || !status}>
			{returnOrder && status && !wantsToEdit && (
				<Grid container spacing={2}>
					<Grid item xs={12} lg={10}>
						<Box mt={2}>
							<CustomerChoiceDisplay title={displayTitle} icon={<EmojiTransportation />} />
						</Box>
					</Grid>
					<Grid item xs={12} lg={10} xl={6}>
						<Tile>
							<Box p={2}>
								<Typography variant="h6">Rückgabehinweise</Typography>
							</Box>
							<Divider />
							<TileContent>
								<ReturnList />
							</TileContent>
						</Tile>
					</Grid>
					<Grid item xs={12} lg={2} xl={6}></Grid>
					<Grid item xs={12} sm={6} lg={5} xl={3}>
						<CustomerCarState returnOrder={returnOrder} />
					</Grid>
					<Grid item xs={12} sm={6} lg={5} xl={3}>
						<CustomerDeliveryOverview
							returnOrder={returnOrder}
							primary={couldEdit ? { title: "Bearbeiten", action: edit } : undefined}
							secondary={couldEdit ? { title: "Stornieren", action: confirmCancellation } : undefined}
						/>
					</Grid>
				</Grid>
			)}
			{returnOrder && couldEdit && wantsToEdit && (
				<CustomerDeliveryChoice
					customerOrder={props.customerOrder}
					toStatusOverview={props.toStatusOverview}
					backAction={back}
					formData={calculateFormData(returnOrder)}
				/>
			)}
		</LoadingIndicator>
	);
};

export default CustomerDeliveryStatusOverview;
