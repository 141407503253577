import React, { useState } from "react";
import Layout from "Components/Layout/Layout";
import { useHistory, useParams } from "react-router";
import axios from "axios";
import { Box, Button, Grid, Typography } from "@mui/material";
import useForm from "system/useForm";
import Actions from "Components/Actions";
import FormTextField from "Components/FormTextField";
import SalesOrderTable from "./SalesOrderTable";
import SalesAddressInfo from "./SalesAddressInfo";
import { Sale, SaleOrder } from "../../../system/Domain";
import { useAsync } from "react-use";
import TextBlock from "../../../Components/Layout/TextBlock";
import { Plate } from "../../../Components/Plate";
import SalesOrderPrivateGridItem from "./SalesOrderPrivateGridItem";
import SalesOrderCommercialGridItem from "./SalesOrderCommercialGridItem";
import { useDispatch } from "react-redux";
import translateError from "../../../system/translateError";

const CreateSalesOrderPage = () => {
	const { id } = useParams<{ id: string }>();
	const [order, setOrder] = useState<SaleOrder | null>(null);
	const [error, setError] = useState<string | null>(null);
	const history = useHistory();
	const dispatch = useDispatch();

	const form = useForm({
		values: order,
		setValues: setOrder,
		deltaValues: null
	});

	const { loading } = useAsync(async () => {
		if (!id) {
			setOrder(null);
			return;
		}

		if (parseInt(id, 10) !== order?.id) {
			setOrder(null);
		}

		setError(null);

		dispatch({ type: "SUSPEND_ERROR_HANDLING" });
		try {
			const { data } = await axios.post<SaleOrder>(`/api/sales/${id}/prepare-order`);
			setOrder(data);
		} catch (error) {
			if (error.isAxiosError) {
				setError(error.response.data);
			} else {
				throw error;
			}
		} finally {
			dispatch({ type: "RESUME_ERROR_HANDLING" });
		}
	}, [id]);

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();

		const { data: newSale } = await axios.post<Sale>(`/api/sales/${id}/orders`, order);

		const newOrder = newSale.orders.find(o => o.status === "Active");
		if (newOrder) {
			history.push(`/sales/${id}/orders/${newOrder.id}`);
		} else {
			history.push(`/sales/${id}`);
		}
	};

	const handleCancel = () => {
		history.push(`/sales/${id}`);
	};

	const handleRefresh = () => {
		window.location.reload();
	};

	return (
		<Layout
			title="Fahrzeug bestellen"
			subtitle={order ? `Verkauf in ${order.channel.name}` : undefined}
			loading={loading}
		>
			{!order && !loading && (
				<Box>
					{error && (
						<Typography>
							{translateError(error)}
						</Typography>
					)}
					{!error && (
						<Typography>
							Sie müssen zunächst einen Verkaufvorgang in Leasman anlegen
						</Typography>
					)}
					<Box mt={2}>
						<Button
							color="primary"
							variant="contained"
							onClick={handleRefresh}
						>
							Ansicht aktualisieren
						</Button>
					</Box>
				</Box>
			)}
			{order && (
				<>
					<Box>
						<TextBlock
							primary={<Plate plateNumber={order.plateNumber} />}
							secondary={
								<>
									<Box display="inline-block" mr={1}>
										{order.vin}
									</Box>
								</>
							}
						/>
					</Box>
					<Box my={4}>
						<form onSubmit={handleSubmit}>
							<Grid container spacing={4}>
								<Grid item xs={12}>
									<Grid container spacing={4} alignItems="stretch">
										<Grid item xs={12}>
											<Typography gutterBottom>Verkauf an</Typography>
											<SalesAddressInfo address={order.buyer} />
										</Grid>
										<Grid item xs>
											<FormTextField
												name="numberOfOwners"
												fullWidth
												form={form}
												variant="filled"
												label="Anzahl der Vorbesitzer"
											/>
										</Grid>
										<Grid item xs>
											<FormTextField
												name="numberOfRealOwners"
												fullWidth
												form={form}
												variant="filled"
												label="Anzahl der tatsächlichen Vorbesitzer"
											/>
										</Grid>
										<Grid item xs>
											<FormTextField
												name="mileageOwner"
												fullWidth
												form={form}
												variant="filled"
												label="Kilometerstand (Vorbesitzer)"
											/>
										</Grid>
										<Grid item xs>
											<FormTextField
												name="mileage"
												fullWidth
												form={form}
												variant="filled"
												label="Kilometerstand"
											/>
										</Grid>
										<Grid item xs={12}>
											<FormTextField
												name="accessories"
												fullWidth
												form={form}
												label="Sonderausstattungen"
												variant="filled"
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Box py={4}>
										<SalesOrderTable
											order={order}
										/>
									</Box>
								</Grid>

								{order.buyer.naturalPerson === "J" ? (
									<SalesOrderPrivateGridItem
										item
										xs={12}
										form={form}
										saleOrder={order}
									/>
								) : (
									<SalesOrderCommercialGridItem
										item
										xs={12}
										form={form}
									/>
								)}

								<Grid item xs={12}>
									<Actions>
										<Button
											color="primary"
											variant="outlined"
											type="submit"
										>
											Speichern
										</Button>
										<Button
											color="secondary"
											variant="outlined"
											onClick={handleCancel}
										>
											Abbrechen
										</Button>
									</Actions>
								</Grid>
							</Grid>
						</form>
					</Box>
				</>
			)}
		</Layout>
	);
};

export default CreateSalesOrderPage;
