import React from 'react';
import { setUserMessage } from "../system/reducers/userMessage/actions";
import { useDispatch } from "react-redux";

const VinDisplay = ({ vin } : { vin? : string }) => {
	const dispatch = useDispatch();

	if (!vin) return <></>;

	if (vin.length < 6) {
		return <span>{vin}</span>;
	}

	const copy = (e: React.MouseEvent<HTMLElement, MouseEvent>, text:string) => {
		if (e.altKey) {
			e.stopPropagation();
			navigator.clipboard.writeText(text);
			dispatch(setUserMessage(`Text kopiert: ${text}`, "success"));
		}
	};

	return (
		<span>
			<span onClick={(e) => copy(e, vin)}>
				{vin.substring(0, vin.length - 6)}
			</span>
			<span style={{fontWeight:"bold"}} onClick={(e) => copy(e, vin.substring(vin.length - 6))}>
				{vin.substring(vin.length - 6)}
			</span>
		</span>
	);
};

export default VinDisplay;
