import { Box } from "@mui/material";
import TextBlock from "../../../Components/Layout/TextBlock";
import moment from "moment/moment";
import dateFormats from "../../../system/dateFormats";
import { formatVehiclePricingRequestPurchaser } from "../../../system/formatVehiclePricingRequestPurchaser";
import Image, { ImageViewMode } from "../../Vehicles/PictureSets/Image";
import { Email } from "@mui/icons-material";
import React from "react";
import { CustomerOrder, PricingRequest } from "../../../system/Domain";

interface Props {
	customerOrder: CustomerOrder
	pricingRequest: PricingRequest
}

const PricingRequestInformationOverview = (props: Props) => {
	return (
		<Box>
			<TextBlock
				primary={`Kaufdatum ${moment(props.pricingRequest.datePurchase).format(dateFormats.date)}`}
				secondary={`Fahrzeugübernahme als ${formatVehiclePricingRequestPurchaser(props.pricingRequest.purchaser)}`}
			/>

			<Box mt={2}>
				<TextBlock
					primary={props.pricingRequest.mileage.toString()}
					secondary="Aktueller Kilometerstand"
				/>
			</Box>

			<Box mt={2}>
				<Image
					hash={props.pricingRequest.mileagePictureProof.hash}
					filename={`${props.pricingRequest.mileagePictureProof.filename}.jpg`}
				/>
			</Box>

			<Box mt={4}>
				<TextBlock
					primary={
						<Box display="flex" flexDirection="row">
							<Box mr={1} display="flex"><Email /></Box>
							{props.customerOrder.customerEmail && (
								<Box display="flex" alignItems="center">Emailbenachrichtigung aktiv</Box>
							)}
							{!props.customerOrder.customerEmail && (
								<Box display="flex" alignItems="center">Emailbenachrichtigung inaktiv</Box>
							)}
						</Box>
					}
					secondary={props.customerOrder.customerEmail}
					tertiary={props.customerOrder.customerEmail ? "Wir werden Sie über den Status der Kaufpreisanfrage informieren" : undefined}
				/>
			</Box>
		</Box>
	);
};

export default PricingRequestInformationOverview;
