import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import useVehicle from "../../../system/useVehicle";
import { useAsync } from "react-use";
import axios from "axios";
import { StorageFile, VehicleAssessment, VehicleAssessmentAttachment } from "../../../system/Domain";
import Layout from "../../../Components/Layout/Layout";
import LotScanContextMenu from "../LotScanContextMenu";
import VehicleHeaderMenu from "../VehicleHeaderMenu";
import VehicleSubMenu from "../VehicleSubMenu";
import { Box, Button, CircularProgress, Grid, MenuItem, Typography } from "@mui/material";
import VehicleHeader from "../VehicleHeader";
import Tile from "../../../Components/Tiles/Tile";
import TileContent from "../../../Components/Tiles/TileContent";
import AssessmentAttachmentList from "./AssessmentAttachmentList";
import parseContentDispositionFilename from "../../../system/parseContentDispositionFilename";
import fileDownload from "js-file-download";
import AssessmentDamageList from "./AssessmentDamageList";
import AssessmentOverviewTile from "./AssessmentOverviewTile";
import rejectVehicleAssessment from "./rejectVehicleAssessment";
import _ from "lodash";
import HeaderMenu from "Components/Layout/HeaderMenu";
import VehicleNavigation from "Navigation/VehicleNavigation";

export interface AssessmentDamage {
	text: string;
	repairCosts: number;
	attachments: [
		{
			text: string;
			file: StorageFile;
			mimeType: string;
		},
	];
}

const AssessmentPage = () => {
	const { id } = useParams<{ id: string }>();
	const { assessmentId } = useParams<{ assessmentId: string }>();
	const [vehicle] = useVehicle(id);
	const [assessment, setAssessment] = useState<VehicleAssessment | null>(null);

	const history = useHistory();

	const { value: assessments } = useAsync(async () => {
		if (!vehicle) return [];

		const { data: results } = await axios.get<VehicleAssessment[]>(`/api/assessments?vin=${vehicle.vin}`);
		return results;
	}, [vehicle]);

	useEffect(() => {
		if (!assessments || assessments.length === 0) {
			setAssessment(null);
			return;
		}

		if (assessmentId === "latest") {
			setAssessment(_.last(assessments.filter((a) => !a.dateRejected)) || null);
		} else {
			setAssessment(assessments.find((a) => a.id === assessmentId) || null);
		}
	}, [assessments, vehicle, assessmentId]);

	const handleDownload = async (attachment: VehicleAssessmentAttachment) => {
		const response = await axios.get(`/api/assessments/${assessment!.id}/attachments/${attachment.storageFile.hash}`, {
			responseType: "blob",
		});

		const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, filename);
	};

	const handleSyncDekraAssessment = () => {
		if (!assessment) {
			return;
		}

		if (assessment.source === "Dfo" || assessment.source === "Lea") {
			return async () => {
				const result = await axios.post<VehicleAssessment>(`/api/assessments/${assessment.id}/re-sync`);
				setAssessment(result.data);
			};
		}
	};

	const handleRejectAssessment = () => {
		if (!vehicle || !assessment) {
			return;
		}

		if (assessment.source === "Dfo" || assessment.source === "Lea") {
			return async () => {
				const result = await rejectVehicleAssessment({});

				const { data: newVehicleAssessment } = await axios.post<VehicleAssessment>(`/api/assessments/${assessment.id}/reject`, {
					reason: result.reason,
					topic: result.topic,
				});

				setAssessment(newVehicleAssessment);
			};
		}
	};

	const handleWithdrawAssessmentRejection = async () => {
		if (!vehicle || !assessment) {
			return;
		}

		const { data: newVehicleAssessment } = await axios.post<VehicleAssessment>(`/api/assessments/${assessment.id}/withdraw-rejection`);

		setAssessment(newVehicleAssessment);
	};

	const handleAddToRemarketing = () => {
		if (!vehicle || !assessment) {
			return;
		}

		if (vehicle.remarketing.saleId) {
			// we don't allow to add the assessment to the sale in case
			// there is a sale already being created
			return;
		}

		if (vehicle.remarketing.assessment?.assessmentId) {
			// we don't allow to "reattach" an assessment to the remarketing
			return;
		}

		if (assessment.source !== "Lea") {
			return;
		}

		return async () => {
			await axios.post(`/api/vehicles/${vehicle.id}/remarketing/assessment`, {
				assessmentId: assessment.id,
			});

			history.push(`/vehicles/${vehicle.id}/remarketing`);
		};
	};

	const handleShowAll = () => {
		history.push(`/vehicles/${vehicle!.id}/assessments`);
	};

	return (
		<Layout
			title="Gutachten"
			plateNumber={vehicle?.plateNumber}
			navigation={<VehicleNavigation vehicle={vehicle} />}
			contextMenu={[<LotScanContextMenu />, <VehicleHeaderMenu vehicle={vehicle} />]}
			subMenu={vehicle && <VehicleSubMenu vehicle={vehicle} />}
		>
			{!vehicle && <CircularProgress size={48} />}
			{vehicle && (
				<>
					<Grid container spacing={2} alignItems="flex-end" justifyContent="space-between">
						<Grid item>
							<VehicleHeader vehicle={vehicle} />
						</Grid>
						<Grid item>
							<Grid container direction="row" alignItems="center">
								<Grid item>
									<Button color="primary" variant="outlined" size="small" onClick={handleShowAll}>
										Übersicht
									</Button>
								</Grid>
								<Grid item>
									<HeaderMenu>
										<MenuItem onClick={handleSyncDekraAssessment()}>Dekra Gutachten erneut synchonisieren</MenuItem>
										<MenuItem onClick={handleAddToRemarketing()}>Gutachten in der Verkaufsvorbereitung verwenden</MenuItem>
										<MenuItem onClick={handleRejectAssessment()} disabled={!assessment || !!assessment.dateRejected}>
											Gutachten bei Provider ablehnen
										</MenuItem>
										{assessment && assessment.dateRejected && <MenuItem onClick={handleWithdrawAssessmentRejection}>Ablehnung des Gutachtens widerrufen</MenuItem>}
									</HeaderMenu>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					{!assessment && (
						<Box mt={4}>
							<Typography>Dem Fahrzeug ist kein Gutachten zugeordnet</Typography>
						</Box>
					)}
					{assessment && (
						<Box mt={4}>
							<Grid container spacing={6}>
								<Grid item xs={12} md={6} lg={4}>
									<AssessmentOverviewTile assessment={assessment} />
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<Tile title="Reparaturen">
										<TileContent dense>
											<AssessmentDamageList assessment={assessment} />
										</TileContent>
									</Tile>
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<Tile title="Anlagen">
										<TileContent>
											<AssessmentAttachmentList onSelect={handleDownload} attachments={assessment.attachments} />
										</TileContent>
									</Tile>
								</Grid>
							</Grid>
						</Box>
					)}
				</>
			)}
		</Layout>
	);
};

export default AssessmentPage;
