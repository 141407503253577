import React, { useState } from "react";
import { useParams } from "react-router";
import useVehicle from "system/useVehicle";
import { useAsync } from "react-use";
import axios from "axios";
import Layout from "Components/Layout/Layout";
import LotScanContextMenu from "../LotScanContextMenu";
import VehicleHeaderMenu from "../VehicleHeaderMenu";
import VehicleSubMenu from "../VehicleSubMenu";
import { Box, CircularProgress, FormControl, Grid, NativeSelect } from "@mui/material";
import VehicleHeader from "../VehicleHeader";
import _ from "lodash";
import ArmsAttributeGroup from "./ArmsAttributeGroup";
import ArmsAttributeTableGroup from "./ArmsAttributeTableGroup";
import { FileReference } from "../../../system/Domain";
import ArmsAttachmentsTile from "./ArmsAttachmentsTile";
import parseContentDispositionFilename from "../../../system/parseContentDispositionFilename";
import fileDownload from "js-file-download";

export interface ArmsSimpleValue {
	groupName : string
	name : string
	displayName : string
	dataType : string
	value : any
}

interface ArmsTableRow {
	rowNumber : number
	columns : ArmsSimpleValue[]
}

export interface ArmsTableValue {
	groupName : string
	columns : string[]
	rows : ArmsTableRow[]
}

export type ArmsValue = ArmsSimpleValue & ArmsTableValue

export interface ArmsAttachment {
	id : number
	plateNumber : string
	file : FileReference
}

const armsPages = [
	"Allocation page",
	"Carmarket_DE",
	"Contract",
	"Damage Assessment",
	"General",
	"Invoicing History",
	"Logistics Request Screen",
	"Maintenance",
	"Price Tag",
	"Resale",
	"Return information",
	"Sales Contract Information",
	"TRADE - IN / SOULTE",
	"Transport"
];

const ArmsVehicle = () => {
	const { id } = useParams<{ id : string }>();
	const [vehicle] = useVehicle(id);
	const [currentPage, setCurrentPage] = useState("Allocation page");

	const { value: vehicleAttributes } = useAsync(async () => {
		if (!vehicle) return [];

		const result = await axios.post<ArmsValue[]>(`/api/armsVehiclePage`, {
			plateNumber: vehicle.plateNumber,
			page: currentPage,
			vin: vehicle.vin
		});

		return result.data;

	}, [vehicle, currentPage]);

	const { value: attachments } = useAsync(async () => {
		if (!vehicle) return null;
		if (currentPage !== "General") return null;

		const result = await axios.get<ArmsAttachment[]>(`/api/armsAttachments?plateNumber=${vehicle.plateNumber}`);
		return result.data;
	}, [vehicle, currentPage]);

	const handlePageChange = (event : React.ChangeEvent<{ name : string; value : string }>) => {
		setCurrentPage(event.target.value);
	};

	const handleDownloadAttachment = async (attachment: ArmsAttachment) => {
		const response = await axios.get(`/api/armsAttachments/${attachment.id}`, {
			responseType: "blob"
		});

		const filename = parseContentDispositionFilename(response.headers["content-disposition"]);

		fileDownload(response.data, filename);
	};

	const attributes = vehicleAttributes || [];
	const groups = _.uniq(attributes.map(va => va.groupName));

	return (
		<Layout
			contextMenu={[
				<LotScanContextMenu/>,
				<VehicleHeaderMenu vehicle={vehicle} />
			]}
			subMenu={vehicle && <VehicleSubMenu vehicle={vehicle}/>}
		>
			{!vehicle && (
				<CircularProgress size={48}/>
			)}
			{vehicle && (
				<>
					<Grid container spacing={6} alignItems="flex-end" justifyContent="space-between">
						<Grid item>
							<VehicleHeader vehicle={vehicle}/>
						</Grid>
						<Grid item>
							<FormControl variant="outlined">
								<NativeSelect
									value={currentPage}
									onChange={handlePageChange}
								>
									{armsPages.map(p => {
										return (
											<option
												key={p}
												value={p}
											>
												{p}
											</option>
										);
									})}
								</NativeSelect>
							</FormControl>
						</Grid>
					</Grid>
					{vehicleAttributes && (
						<Box mt={2}>
							<Grid container spacing={6}>
								{groups.map(g => {
									const values = attributes.filter(va => va.groupName === g);

									if (values.length === 1) {
										const value = values[0];
										if (value.rows) {
											// There is a table...
											return (
												<Grid key={g} item xs={12}>
													<ArmsAttributeTableGroup name={g} value={value}/>
												</Grid>
											)
										}
									}

									return (
										<Grid key={g} item xs={6} md={6} xl={3}>
											<ArmsAttributeGroup key={g} name={g} values={values}/>
										</Grid>
									)
								})}

								{attachments && (
									<Grid item xs={6}>
										<ArmsAttachmentsTile
											attachments={attachments}
											onClick={handleDownloadAttachment}
										/>
									</Grid>
								)}
							</Grid>
						</Box>
					)}
				</>
			)}
		</Layout>
	);
};

export default ArmsVehicle
