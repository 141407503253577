import React, { SetStateAction, useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { TransportPricing } from "../../../system/Domain";
import axios from "axios";
import TileContent from "Components/Tiles/TileContent";
import TextBlock from "Components/Layout/TextBlock";
import useForm from "../../../system/useForm";
import { PricingFormData, createForm, applyForm } from "./transportPricingUtils";
import FormText from "Components/FormText";
import Actions from "Components/Actions";
import ActionButton from "Components/ActionButton";
import { RotateLeft } from "@mui/icons-material";
import makeAreaClusters, { AreaCluster } from "./makeAreaClusters";
import useAsyncEffect from "../../../system/useAsyncEffect";
import showMessage from "Dialogs/showMessage";

export interface FormProps {
	pricing: TransportPricing
	maxVehicleNo?: number
	onSave: ((cp: TransportPricing) => Promise<void>)
}

const usePricingForm = ({ pricing, maxVehicleNo: maxVehiclesNo = 8, onSave }: FormProps) => {
	const vehicleNumbers = Array.from({ length: maxVehiclesNo }, (_, index) => index + 1);

	const [formData, setFormData] = useState<PricingFormData>();
	const [vehicleNo, setVehicleNo] = useState(1);
	const [selectedAreaCluster, setSelectedAreaCluster] = useState<AreaCluster>();
	const [areaCodes, setAreaCodes] = useState<string[]>(null);
	const [reset, setReset] = useState(false);
	const [isDirty, setIsDirty] = useState(false);
	const [areaClusters, setAreaClusters] = useState<AreaCluster[]>([]);

	const setFormValues = (x: SetStateAction<PricingFormData>) => {
		setIsDirty(true);
		setFormData(x);
	}

	const form = useForm({
		values: formData,
		setValues: setFormValues
	});

	useAsyncEffect(async () => {
		const { data } = await axios.get<string[]>('/api/transport-pricing/area-codes');
		setAreaCodes(data);
	}, []);

	useEffect(() => {
		if (areaCodes) {
			const { clusters } = makeAreaClusters({ areaCodes, groupCountInCluster: 2 });
			setAreaClusters(clusters);
			if (clusters.length > 0) {
				setSelectedAreaCluster(clusters[0])
			}
		}
		if (pricing && areaCodes) {
			const pricingForm = createForm(areaCodes, vehicleNumbers, pricing)
			setFormData(pricingForm);
		}
		setReset(false);
		setIsDirty(false);
	}, [pricing, areaCodes, reset]);

	const save = async () => {
		let pr = { ...pricing };
		applyForm(formData, pr);

		// validate
		const anyPrice = _(pr.areasPricing).flatMap((ap) => _(ap.prices).flatMap((p) => p.pricePerVehicle).value())
			.find((pv) => pv !== null);
		if (!anyPrice) {
			await showMessage({ title: "Fehler", message: "Mindestens ein Gebiet muss einen Preis haben." });
			return;
		}

		await onSave(pr);
		setIsDirty(false);
	};

	const refresh = async () => {
		setReset(true);
	};

	const formComponent = (
		<>
			<TileContent>
				<Box>
					<Grid container spacing={2}>
						<Grid item xs={8} sm={6} md={4} xl={2}>
							<TextBlock primary="Fehlfahrt" />
							<FormText
								label=""
								name="missedTransportPrice"
								form={form}
								type="currency"
								inline
								options={{
									required: false
								}}
								fullWidth
							/>
						</Grid>
					</Grid>
				</Box>
			</TileContent>
			{areaCodes && // there is no point to render the below, if areaCodes is null, so...
				<TileContent>
					<Box>
						<TextBlock primary="Transport" gutterBottom />
						<Grid container spacing={2}>
							<Grid item xs={6} sm={5} md={4} xl={2}>
								<FormControl fullWidth variant="standard">
									<InputLabel>Fahrzeuganzahl</InputLabel>
									<Select
										variant="standard"
										value={vehicleNo}
										onChange={(e) => setVehicleNo(e.target.value as number)}
									>
										{vehicleNumbers?.map(t => (
											<MenuItem key={t} value={t}>
												{t}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={6} sm={5} md={4} xl={2}>
								<FormControl fullWidth variant="standard">
									<InputLabel>Gebiete</InputLabel>
									<Select
										variant="standard"
										value={selectedAreaCluster ?? ''}
										onChange={(e) => setSelectedAreaCluster(e.target.value as AreaCluster)}
									>
										{areaClusters?.map(ac => (
											//@ts-ignore - necessary to load object (not string) into value
											<MenuItem key={ac.firstAreaCode} value={ac}>
												{`${ac.firstAreaCode} - ${ac.lastAreaCode}`}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>

						<Grid container spacing={2}>
							<Grid item xs={8} sm={10} md={8} xl={3}>
								{selectedAreaCluster && (
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>PLZ</TableCell>
												<TableCell>Preis</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{formData.areasPricing.map((ap, apInd) => (
												(apInd >= selectedAreaCluster.firstAreaCodeIndex && apInd <= selectedAreaCluster.lastAreaCodeIndex)
												&&
												<TableRow key={apInd}>
													<TableCell>
														<TextBlock align='center' primary={ap.areaCode} />
													</TableCell>
													<TableCell>
														<FormText
															label=""
															name={`areasPricing.${apInd}.prices[${vehicleNo - 1}]`}
															form={form}
															type="currency"
															inline
															options={{ required: false }}
															fullWidth
														/>
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								)}
							</Grid>
						</Grid>
						<Grid mt={1} container spacing={4}>
							<Grid item xs={3}>
								<Actions>
									<ActionButton
										onClick={save}
									>
										Speichern
									</ActionButton>
									<ActionButton
										color="secondary"
										onClick={refresh}
									>
										<RotateLeft />
										Zurücksetzen
									</ActionButton>
								</Actions>
							</Grid>
						</Grid>
					</Box>
				</TileContent>
			}
		</>
	)

	const getIsDirty = useCallback(() => isDirty, [isDirty]);
	const cancelChanges = useCallback(() => setReset(true), []);

	return {
		pricingForm: formComponent,
		getIsDirty: getIsDirty,
		cancelChanges: cancelChanges
	}
};

export default usePricingForm;
