import Layout from "Components/Layout/Layout";
import Imports from "Pages/Imports/Imports";
import React from "react";

export default function LeaseplanImport() {
	return (
		<Layout title="Leaseplan Fahrzeuge importieren">
			<Imports importType="LeaseplanVehicleImport" />
		</Layout>
	);
}
