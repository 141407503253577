import axios from "axios";
import moment from "moment";
import { useAsync } from "react-use";
import Layout from "../../Components/Layout/Layout";
import React, { ChangeEvent, useState } from "react";
import formatCurrency from "../../system/formatCurrency";
import AversiLineTable from "./Components/AversiLineTable";
import { AversiLeasmanLine, AversiLine } from "./AversiSessionsPage";
import AversiLeasmanLineTable from "./Components/AversiLeasmanLineTable";
import { Box, Button, Grid, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Typography, useTheme } from "@mui/material";

interface PaymentPlanItem {
	month: number
	year: number
	aversiAmount: number
	leasmanAmount: number
	balance: number
}

interface PaymentPlan {
	items: PaymentPlanItem[]
	balance: number
	tenantName: string
	contractId: number
}

const TabPanel = ({ children, value, index }: { children: React.ReactNode, value: number, index: number }) => {
	const theme = useTheme();

	return (
		<Box hidden={value !== index} sx={{
			width: "100%",
			margin: theme.spacing(4),
			[theme.breakpoints.up("md")]: {
				width: "50%"
			}
		}}>
			{children}
		</Box>
	)
};


const Property = ({ label, value }: { label: string, value: number | string | null }) => {
	if (!value) return null

	return (
		<Box my={1}>
			<Typography variant="caption">{label}</Typography>
			<Typography variant="body2">{value}</Typography>
		</Box>
	)
};

const AversiPaymentPlanPage = () => {
	const [id, setId] = useState<string | null>(null);
	const [contractId, setContractId] = useState<string>("");
	const [tabValue, setTabValue] = useState(0);

	const { value: plan } = useAsync(async () => {
		if (!id) {
			return null;
		}

		const { data: plan } = await axios.get<PaymentPlan>(`/api/admin/aversi/payment-plans/${id}`);
		return plan;
	}, [id]);

	const { value: lines } = useAsync(async () => {
		if (!id) {
			return null;
		}

		const { data: lines } = await axios.get<AversiLine[]>(`/api/admin/aversi/lines?contractId=${id}`);
		return lines;
	}, [id]);

	const { value: leasmanLines } = useAsync(async () => {
		if (!id) {
			return null;
		}

		const { data: lines } = await axios.get<AversiLeasmanLine[]>(`/api/admin/aversi/leasman-lines?contractId=${id}`);
		return lines;
	}, [id]);

	const handleContractIdChange = (event: ChangeEvent<HTMLInputElement>) => {
		setContractId(event.target.value);
	};

	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		setId(contractId);
	};

	const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setTabValue(newValue)
	};

	return (
		<Layout title="Aversi Zahlungsplan">
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<form onSubmit={handleSubmit}>
						<Box display="flex" alignItems="baseline" mt={2}>
							<TextField
								value={contractId}
								onChange={handleContractIdChange}
								label="Vertragsnummer"
								variant="outlined"
								autoFocus
								margin="normal"
							/>
							<Box ml={2}>
								<Button variant="outlined" color="primary" type="submit">Suchen</Button>
							</Box>
						</Box>
					</form>
				</Grid>
				{plan && (
					<>
						<Grid item xs={12}>
							<Property label="Vertragsnummer" value={plan.contractId} />
							<Property label="Mandant" value={plan.tenantName} />
						</Grid>
						<Grid item xs={12}>
							<Tabs value={tabValue} onChange={handleTabChange}>
								<Tab label="Zahlungsplan" />
								<Tab label="Aversi-Lines" />
								<Tab label="Leasman Buchungen" />
							</Tabs>
							<TabPanel value={tabValue} index={0}>
								<Table size="small" sx={{
									"& td": {
										borderBottom: "none",
										padding: "0px 0px 0px 6px",
									}
								}} padding="none">
									<TableHead>
										<TableRow>
											<TableCell>Monat</TableCell>
											<TableCell align="right">Aversi</TableCell>
											<TableCell align="right">Leasman</TableCell>
											<TableCell align="right">Differenz</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow>
											<TableCell colSpan={4} align="right" sx={{
												fontWeight: 700
											}}>
												{formatCurrency(plan.balance)}
											</TableCell>
										</TableRow>
										{plan.items.map(item => {
											const month = moment(`${item.year}-${item.month}-01`).format("MM/YYYY");

											return (
												<TableRow key={`${item.month}/${item.year}`}>
													<TableCell>
														{month}
													</TableCell>
													<TableCell align="right">
														{formatCurrency(item.aversiAmount)}
													</TableCell>
													<TableCell align="right">
														{formatCurrency(item.leasmanAmount)}
													</TableCell>
													<TableCell align="right">
														{formatCurrency(item.balance)}
													</TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TabPanel>
							<TabPanel value={tabValue} index={1}>
								<AversiLineTable lines={lines || []} size="small" sx={{
									"& td": {
										borderBottom: "none",
										padding: "0px 0px 0px 6px",
									}
								}} padding="none" />
							</TabPanel>
							<TabPanel value={tabValue} index={2}>
								<AversiLeasmanLineTable lines={leasmanLines || []} size="small" sx={{
									"& td": {
										borderBottom: "none",
										padding: "0px 0px 0px 6px",
									}
								}} padding="none" />
							</TabPanel>
						</Grid>
					</>
				)}
			</Grid>
		</Layout>
	)
};

export default AversiPaymentPlanPage;
