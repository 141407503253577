import React from 'react';
import { Grid } from "@mui/material";
import faqList from "./FaqList";
import TextBlock from "../../Components/Layout/TextBlock";
import Layout from "../../Components/Layout/Layout";

const CustomerFAQ = () => {
	const faqEntries = faqList;

	return (
		<Layout
			hideNavigation
			title="FAQ Kundenaufträge"
		>
			<Grid container spacing={3}>
				{
					faqEntries.filter(f => f.includeInList).map(entry => (
						<Grid key={entry.key} item xs={12}>
							<TextBlock primary={entry.title} secondary={entry.answer} />
						</Grid>
					))
				}
			</Grid>
		</Layout>
	);
};

export default CustomerFAQ;
