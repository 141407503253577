import React from "react";
import FormYesNo from "../../../../Components/FormYesNo";
import FormUpload from "../../../../Components/FormUpload";
import FormText from "../../../../Components/FormText";
import { Form } from "../../../../system/useForm";

export default ({ form } : { form : Form }) => {
	return (
		<>
			<FormYesNo
				name="impedingConditions.lighting"
				label="Lichtverhältnisse schlecht"
				form={form}
				options={{
					required: true
				}}
			/>
			<FormYesNo
				name="impedingConditions.rain"
				label="Regen / Nässe"
				form={form}
				options={{
					required: true
				}}
			/>
			<FormYesNo
				name="impedingConditions.dirt"
				label="Verschmutzung"
				form={form}
				options={{
					required: true
				}}
			/>
			<FormYesNo
				name="impedingConditions.snowOrIce"
				label="Schnee / Eis"
				form={form}
				options={{
					required: true
				}}
			/>
			<FormText
				name="impedingConditions.other"
				label="Sonstiges"
				form={form}
				options={{
					required: false
				}}
			/>
			<FormUpload
				name="impedingConditions.pictureOfEnvironment"
				label="Umgebung"
				form={form}
			/>
			<FormUpload
				name="impedingConditions.pictureOfVehicle"
				label="Fahrzeug"
				form={form}
				options={{
					required: true
				}}
			/>
		</>
	);
};
