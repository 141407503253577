import TextGroup from "../VehicleSales/TextGroup";
import TextLine from "../VehicleSales/TextLine";
import formatDateTime from "../../system/formatDateTime";
import { formatRepairOrderStatus } from "./CarglassRepairOrderListItem";
import { Box } from "@mui/material";
import React from "react";
import { RepairOrder } from "../../system/Domain";

interface Props {
	repairOrder: RepairOrder
}

const RepairOrderStatusHistory = (props: Props) => {
	return (
		<Box mt={3} mb={1}>
			<TextGroup title="Statushistorie">
				{[...props.repairOrder.statusHistoryTemp].reverse().map(h => (
					<>
						<TextLine
							label={formatDateTime(h.date)}
							value={formatRepairOrderStatus(h.value.status)}
							color={"info"}
						/>
						{h.value.workshopName &&
							(<TextLine label="" value={h.value.workshopName} />)
						}
						{h.createdBy &&
							(<TextLine label="" value={h.createdBy.name} />)
						}
					</>
				))}
			</TextGroup>
		</Box>
	);
};

export default RepairOrderStatusHistory;
