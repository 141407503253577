import axios from 'axios';
import moment from 'moment';
import queryString from 'query-string';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useAsync } from 'react-use';

import { AddAPhotoSharp, Check, Edit } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, LinearProgress, MenuItem, MenuList, Typography } from '@mui/material';

import Actions from '../../../Components/Actions';
import FormDateTimePicker from '../../../Components/FormDateTimePicker';
import InlineList from '../../../Components/InlineList';
import Layout from '../../../Components/Layout/Layout';
import TextBlock from '../../../Components/Layout/TextBlock';
import Plate from '../../../Components/Plate/Plate';
import Thumbnail from '../../../Components/Thumbnail';
import Tile from '../../../Components/Tiles/Tile';
import TileContent from '../../../Components/Tiles/TileContent';
import { Sale, SaleDocument, UserRole, Vehicle } from '../../../system/Domain';
import translateError from '../../../system/translateError';
import useForm from '../../../system/useForm';
import SalesAddressInfo from '../../VehicleSales/Orders/SalesAddressInfo';
import SalesDocumentTitle from './SalesDocumentTitle';
import useUser from "../../../system/useUser";
import VehicleNavigation from "../../../Navigation/VehicleNavigation";

interface ShippingForm {
	shippingDate: string
}

type ShippingDocument = "ShippingAttorney" | "ShippingPickup" | "Other" | "ShippingForm" | "IdentityCard" | "BillOfLading"

const shippingDocuments: { [key in ShippingDocument]: string } = {
	"ShippingAttorney": "Abholvollmacht",
	"ShippingPickup": "Pickup",
	"ShippingForm": "Übergabeprotokoll",
	"IdentityCard": "Ausweisdokument",
	"BillOfLading": "Frachtpapiere",
	"Other": "Andere"
}

interface Props {
	sale: Sale,
	vehicle: Vehicle
}

const ShipVehiclePage = (props: Props) => {
	const location = useLocation();
	const [, , hasRoles] = useUser();
	const [error, setError] = useState<string | null>(null);
	const [sales, setSales] = useState<Sale | null>();
	const [vehicle, setVehicle] = useState<Vehicle | null>(null);
	const [editing, setEditing] = useState<boolean>(false);
	const [shippingForm, setShippingForm] = useState<ShippingForm>({
		shippingDate: moment().toISOString()
	});
	const [currentDocument, setCurrentDocument] = useState<ShippingDocument | null>(null);
	const [uploadProgress, setUploadProgress] = useState<number | null>(null);
	const history = useHistory();

	const dispatch = useDispatch();

	const isLotManager = hasRoles(UserRole.LotManager);

	const form = useForm({
		values: shippingForm,
		setValues: setShippingForm,
		deltaValues: null
	});

	useAsync(async () => {
		setSales(props.sale);
		setVehicle(props.vehicle);

		const query = queryString.parse(location.search);

		if (!query.pickupToken && !query.saleId) {
			return;
		}

		dispatch({ type: "SUSPEND_ERROR_HANDLING" });
		try {
			const { data: sales } = await axios.post<Sale>(`/api/sales/prepare-shipping`, {
				pickupToken: query.pickupToken,
				saleId: query.saleId
			});

			setSales(sales);
		} catch (error) {
			if (error.isAxiosError && error.response.status === 400) {
				setError(translateError(error.response.data));
			} else {
				throw error;
			}
		} finally {
			dispatch({ type: "RESUME_ERROR_HANDLING" });
		}
	}, [location.search]);

	useAsync(async () => {
		if (!sales) {
			setVehicle(null);
			return;
		}

		const { data: newVehicle } = await axios.get<Vehicle>(`/api/vehicles/${sales.vehicle.id}`);
		setVehicle(newVehicle);
	}, [sales]);

	const handleSetDocument = (document: ShippingDocument) => () => {
		setCurrentDocument(document);
	};

	const onDrop = useCallback(async acceptedFiles => {
		if (!sales) return;
		if (!currentDocument) return;

		if (acceptedFiles.length !== 1) {
			return;
		}

		const file = acceptedFiles[0];

		const formData = new FormData();
		formData.append("file", file);
		formData.append("documentType", currentDocument);

		setUploadProgress(0);
		try {
			const { data: newSales } = await axios.post<Sale>(`/api/sales/${sales.id}/shipping-documents`,
				formData,
				{
					headers: {
						'Content-Type':
							'multipart/form-data'
					},
					onUploadProgress: evt => {
						setUploadProgress(Math.ceil((evt.loaded / evt.total) * 100));
					}
				}
			);
			setUploadProgress(100);
			setSales(newSales);
			setCurrentDocument(null);
		} finally {
			setUploadProgress(null);
		}
	}, [sales, currentDocument]);

	const handleConfirmShipping = async () => {
		const { data: newSales } = await axios.post<Sale>(`/api/sales/${sales!.id}/confirm-shipping`, shippingForm);
		history.push(`/vehicles/${newSales.vehicle.id}`);
	};

	const handleDeleteDocument = (document: SaleDocument) => async () => {
		const { data: newSales } = await axios.delete(`/api/sales/${sales!.id}/shipping-documents/${document.file.hash}`);
		setSales(newSales);
	};

	const toggleEditingMode = () => {
		setEditing(mode => !mode);
	};

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		multiple: false
	});

	const documents = sales?.shipping?.documents || [];
	const order = sales?.orders.find(o => o.status === "Paid");

	return (
		<Layout
			navigation={<VehicleNavigation vehicle={vehicle} />}
			title={vehicle?.remarketing.status === "Ausgang" ? "Dokumente zur Auslieferung bearbeiten" : "Fahrzeug ausliefern"}
		>
			{error && (
				<Typography color="error">{error}</Typography>
			)}
			{sales && !currentDocument && (
				<Box mb={2}>
					<Grid
						container
						spacing={2}
						justifyContent="space-between"
						alignItems="center"
					>
						<Grid item>
							<TextBlock
								primary={<Plate plateNumber={sales.plateNumber} />}
								secondary={
									<InlineList>
										{sales.vin}
										{sales.vehicle.businessLine}
									</InlineList>
								}
							/>
						</Grid>
						{vehicle && vehicle.remarketing.status !== "Ausgang" && isLotManager &&
							<Grid item>
								<Button
									variant="contained"
									color="primary"
									onClick={handleConfirmShipping}
								>
									Auslieferung bestätigen
								</Button>
							</Grid>
						}
					</Grid>
				</Box>
			)}
			<Box>
				{currentDocument && uploadProgress && (
					<Box mt="10rem" mx="20%">
						<Typography align="center" gutterBottom>Bild wird hochgeladen</Typography>
						<LinearProgress variant="determinate" value={uploadProgress} />
					</Box>
				)}
				{currentDocument && !uploadProgress && (
					<div {...getRootProps()}>
						<input {...getInputProps()} capture />
						<Box display="flex" flexDirection="column" alignItems="center" mt="10rem">
							<Typography variant="h4" gutterBottom>{shippingDocuments[currentDocument]}</Typography>
							<AddAPhotoSharp style={{ fontSize: "4rem", textAlign: "center" }} />
						</Box>
					</div>
				)}
				{sales && !currentDocument && (
					<>
						<Box mb={2}>
							<Grid container spacing={1}>
								{vehicle && (
									<Grid item md={12} lg={6} style={{ flexGrow: 1 }}>
										<Tile title="Auslieferung">
											<TileContent>
												<Grid container spacing={1} justifyContent="space-between">
													<Grid item>
														<TextBlock
															primary={vehicle.type?.description}
															secondary={`Status: ${vehicle.remarketing.status}`}
														/>
														{!editing && (
															<Box mt={2}>
																<Typography>Auslieferungsdatum</Typography>
																<Typography>{moment(sales.dateShipped || shippingForm.shippingDate).format("L")}</Typography>
															</Box>
														)}
														{editing && (
															<FormDateTimePicker
																name="shippingDate"
																form={form}
																label="Auslieferungsdatum"
																margin="normal"
																variant="date"
																format="DD.MM.YYYY"
															/>
														)}
													</Grid>
													{isLotManager && sales.status === "ReadyForShipping" && (
														<Grid item>
															<Actions>
																{!editing && (
																	<IconButton
																		size="small"
																		onClick={toggleEditingMode}
																	>
																		<Edit />
																	</IconButton>
																)}
																{editing && (
																	<>
																		<IconButton onClick={toggleEditingMode}>
																			<Check />
																		</IconButton>
																	</>
																)}
															</Actions>
														</Grid>
													)}
												</Grid>
											</TileContent>
										</Tile>
									</Grid>
								)}
								{order && (
									<Grid item md={12} lg={6} style={{ flexGrow: 1 }}>
										<Tile title="Abholer">
											<TileContent>
												<SalesAddressInfo address={order.buyer} />
											</TileContent>
										</Tile>
									</Grid>
								)}
							</Grid>
						</Box>
						<Tile title="Dokumente">
							<Grid container spacing={2}>
								{documents.map(d => (
									<Grid key={d.file.hash} item sx={
										!d.isCurrent && {
											filter: `grayscale(100%)`,
											opacity: 0.4,
											"&:hover": {
												filter: `grayscale(50%)`,
												opacity: 1
											}
										}
									}>
										<SalesDocumentTitle
											title={shippingDocuments[d.documentType]}
											onClose={isLotManager && d.isCurrent ? handleDeleteDocument(d) : null}
										/>
										<Thumbnail
											width={300}
											height={400}
											asyncUrl={`/api/storage/${d.file.hash}/thumbnail`}
											downloadUrl={`/api/storage/${d.file.hash}`}
											standalone
										/>
									</Grid>
								))}
								{isLotManager && (
									<Grid item>
										<SalesDocumentTitle title="Neues Dokument hinzufügen" />
										<Box minWidth={300} height={400} border={1} p={2}>
											<Box textAlign="center" mb={2}>
												<AddAPhotoSharp fontSize="large" />
											</Box>
											<MenuList>
												{Object.keys(shippingDocuments).map(k => {
													const shippingDocument = k as ShippingDocument;
													const value = shippingDocuments[shippingDocument];

													return (
														<MenuItem
															key={shippingDocument}
															onClick={handleSetDocument(shippingDocument)}
														>
															{value}
														</MenuItem>
													);
												})}
											</MenuList>
										</Box>
									</Grid>
								)}
							</Grid>
						</Tile>
					</>
				)}
			</Box>
		</Layout>
	);
};

export default ShipVehiclePage;
