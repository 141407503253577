import React from "react";
import { Box } from "@mui/material";
import { ContactPerson } from "../../../system/Domain";
import TextBlock from "../../../Components/Layout/TextBlock";

interface Props {
	contact: ContactPerson
}

export default (props: Props) => {
	const renderName = props.contact.name?.trim() ?? "";
	const renderEmail = props.contact.email?.trim() ?? "";
	const renderTelephone = props.contact.telephone?.trim() ?? "";

	const useBreak = !renderName && renderName.length + renderTelephone.length > 35;

	return (
		<>
			{useBreak && (
				<TextBlock primary={renderName} secondary={renderTelephone} tertiary={<Box fontStyle="italic">{renderEmail}</Box>} />
			)}
			{!useBreak && (
				<TextBlock primary={<Box>{renderName} {renderTelephone}</Box>} secondary={<Box fontStyle="italic">{renderEmail}</Box>} />
			)}
		</>
	);
};
