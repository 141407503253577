import React, { useState } from "react";
import { CircularProgress, TableBody, TableHead, TableRow } from "@mui/material";
import { Sale, SaleQuery } from "../../system/Domain";
import useAxiosEffect from "../../system/useAxiosEffect";
import axios from "axios";
import TextBlock from "../../Components/Layout/TextBlock";
import PaperTable from "../../Components/PaperTable";
import { Colgroup, TableCell } from "../../Components/BreakpointStyledComponents";
import { Plate } from "Components/Plate";
import formatRelativeDate from "system/formatRelativeDate";
import formatCurrency from "system/formatCurrency";
import _ from "lodash";
import ListItemChips from "Components/ListItemChips";
import ListItemChip from "Components/ListItemChip";
import { formatSaleStatus, paintSaleStatus } from "./SaleListItem";
import { useHistory } from "react-router-dom";
import { paintSaleOfferStatus } from "./SaleOfferStatus";

interface Props {
	query?: SaleQuery,
}

export default ({ query = {} }: Props) => {
	const [sales, setSales] = useState<Sale[]>();
	const history = useHistory();

	useAxiosEffect(async config => {
		console.log('cfg ', config)
		if (!query) {
			query = {};
		}

		const { data } = await axios.post<Sale[]>(`/api/sales/search`, query, config);
		setSales(data);
	}, [query]);

	return (

		<PaperTable>
			<Colgroup xl>
				<col style={{ width: "40%" }} />
				<col style={{ width: "15%" }} />
				<col style={{ width: "15%" }} />
				<col style={{ width: "20%" }} />
				<col style={{ width: "10%" }} />
			</Colgroup>
			<TableHead>
				<TableRow>
					<TableCell>Fahrzeug</TableCell>
					<TableCell xs sm md>Gebot Zielpreis</TableCell>
					<TableCell lg xl>Höchstgebot</TableCell>
					<TableCell lg xl>Höchster Zielpreis</TableCell>
					<TableCell>Aktion</TableCell>
					<TableCell lg xl>Mandant</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{sales?.map(s => {

					const highestBid = _.max(s.offers.filter(o => o.status !== "Archived").map(o => o.highestBid?.price));
					const highestTargetPrice = _.max(s.offers.filter(o => o.status !== "Archived").map(o => o.pricing?.targetPrice));

					return (
						<TableRow
							hover
							key={s.id}
							onClick={() => history.push(`/sales/${s.id}`)}
						>
							<TableCell>
								<div>
									<Plate plateNumber={s.plateNumber} style={{ zoom: 0.6 }} />
								</div>
								<TextBlock
									primary={s.vehicle.type?.description}
									secondary={s.vin}
								/>
								<ListItemChips>
									<ListItemChip
										label={formatSaleStatus(s)}
										color={paintSaleStatus(s)}
									/>
								</ListItemChips>
								<ListItemChips>
									{s.offers.map(o =>
										<ListItemChip
											key={o.id}
											label={o.channel.name}
											color={paintSaleOfferStatus(o.status)}
										/>
									)}
								</ListItemChips>
							</TableCell>
							<TableCell lg xl><TextBlock primary={formatCurrency(highestBid)} /></TableCell>
							<TableCell lg xl><TextBlock primary={formatCurrency(highestTargetPrice)} /></TableCell>
							<TableCell xs sm md>
								<TextBlock primary={formatCurrency(highestBid)} />
								<TextBlock primary={formatCurrency(highestTargetPrice)} />
							</TableCell>
							<TableCell lg xl>
								<TextBlock
									primary="Verkauf angelegt"
									secondary={formatRelativeDate(s.dateCreated)}
								/>
								<TextBlock
									primary="Letzte Anpassung"
									secondary={formatRelativeDate(s.dateUpdated)}
								/>
							</TableCell>
							<TableCell lg xl><TextBlock secondary={s.contract?.tenant} /></TableCell>
							<TableCell xs sm md>
								<TextBlock
									primary="Verkauf angelegt"
									secondary={formatRelativeDate(s.dateCreated)}
								/>
								<TextBlock
									primary="Letzte Anpassung"
									secondary={formatRelativeDate(s.dateUpdated)}
								/>
								<TextBlock secondary={s.contract?.tenant} />
							</TableCell>
						</TableRow>
					)
				})}
				{!sales && (
					<TableRow>
						<TableCell
							colSpan={9}
							sx={{ textAlign: "center" }}
						>
							<CircularProgress />
						</TableCell>
					</TableRow>
				)}
			</TableBody>
		</PaperTable>

	);
}
