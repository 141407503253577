import React, { useMemo, useState } from "react";
import { LogEvent } from "../../../system/Domain";
import useChannel from "../../../system/useChannel";
import useEvent from "../../../system/useEvent";
import useAsyncEffect from "../../../system/useAsyncEffect";
import axios from "axios";
import { JobProps } from "./JobLayout";
import moment, { Moment } from "moment";
import Log from "../Log";

class DebounceContext<T> {
	private items: T[] = [];
	private lastInvocation?: Moment;

	private readonly ms: number;
	private timeout?: any;
	private readonly onInvoke: (items: T[]) => void;

	constructor(ms: number, onInvoke: (items: T[]) => void) {
		this.ms = ms;
		this.onInvoke = onInvoke;
	}

	push(item: T) {
		this.items.push(item);

		const msSinceLastInvocation = moment().diff(this.lastInvocation, "ms");

		if (!this.lastInvocation || msSinceLastInvocation > this.ms) {
			this.invoke();
		} else {
			if (!this.timeout) {
				this.timeout = setTimeout(() => {
					this.invoke();
				}, this.ms - msSinceLastInvocation);
			}
		}
	}

	private invoke() {
		if (this.items.length === 0) {
			return;
		}

		if (this.timeout) {
			clearTimeout(this.timeout);
			delete this.timeout;
		}

		const items = this.items;
		this.items = [];
		this.lastInvocation = moment();

		this.onInvoke(items);
	}
}

export default ({ job, importId }: JobProps) => {
	const [logEvents, setLogEvents] = useState<LogEvent[]>([]);
	useChannel(job.id);

	const debounceContext = useMemo<DebounceContext<LogEvent>>(
		() =>
			new DebounceContext<LogEvent>(1000, (logEvents) =>
				setLogEvents((state) => [...state, ...logEvents].sort((a, b) => a.id.localeCompare(b.id))),
			),
		[],
	);

	useEvent(
		"job:log",
		(logEvent: LogEvent) => {
			if (!logEvent) {
				return;
			}

			debounceContext.push(logEvent);
		},
		[debounceContext],
	);

	const [loading, setLoading] = useState(false);

	useAsyncEffect(async () => {
		if (!job || !job.id) {
			setLogEvents([]);
			return;
		}

		setLoading(true);

		const path = !importId ? `/api/admin/jobs/${job.id}/logs` : `/api/imports/${importId}/job/logs`;

		try {
			const { data: logEvents } = await axios.get<LogEvent[]>(path);

			setLogEvents((state) =>
				[...logEvents, ...state.filter((s) => !logEvents.some((e) => e.id === s.id))].sort((a, b) =>
					a.id.localeCompare(b.id),
				),
			);
		} finally {
			setLoading(false);
		}
	}, [job?.id]);

	return <Log loading={loading} items={logEvents} scrollIntoView />;
};
