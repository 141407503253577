import React from 'react';
import { CustomerOrder } from "../../../system/Domain";
import PaperTable from "../../../Components/PaperTable";
import { Box, Chip, CircularProgress, TableBody, TableHead, TableRow } from "@mui/material";
import { useHistory } from "react-router-dom";
import CustomerOrderStatusPill from "./CustomerOrderStatusPill";
import { Plate } from "../../../Components/Plate";
import TextBlock from "../../../Components/Layout/TextBlock";
import ActionButton from "../../../Components/ActionButton";
import { copyClick, generateCustomerOrderLink } from "../../../utils";
import { translateCustomerOrderType } from "../../../system/translate";
import PricingRequestDecisionStatusPill from "./PricingRequestDecisionStatusPill";
import { TableCell } from "../../../Components/BreakpointStyledComponents";
import { blue, grey } from "@mui/material/colors";

interface Props {
	customerOrders: CustomerOrder[]
	isLoading?: boolean
	noRowsMessage?: string
	align?: boolean
}

const CustomerOrdersOverviewTable = (props: Props) => {
	const history = useHistory();

	return (
		<PaperTable>
			{props.align ?
				<TableHead>
					<TableRow>
						<TableCell xs sm md lg xl sx={{ width: { md: "30%", lg: "20%", xl: "20%" } }}>Fahrzeug</TableCell>
						<TableCell xl sx={{ width: "10%" }}>Status</TableCell>
						<TableCell md lg xl>Kunde</TableCell>
					</TableRow>
				</TableHead>
				:
				<>
					<colgroup>
						<col width="auto" />
						<col width="auto" />
						<col width="auto" />
						<col width="1px" />
					</colgroup>
					<TableHead>
						<TableRow>
							<TableCell>Fahrzeug</TableCell>
							<TableCell xl>Status</TableCell>
							<TableCell md lg xl>Kunde</TableCell>
							<TableCell md lg xl>Aktionen</TableCell>
						</TableRow>
					</TableHead>
				</>}
			<TableBody>
				{props.customerOrders && props.customerOrders.map(customerOrder => (
					<TableRow
						key={customerOrder.id}
						hover
						onClick={() => history.push(`/orders/customers/${customerOrder.id}`)}
					>
						<TableCell>
							<Plate plateNumber={customerOrder.plateNumber} style={{ zoom: 0.6 }} />
							<TextBlock
								primary={!props.align && customerOrder.vehicle.type?.description}
								secondary={customerOrder.vin}
								compact
							/>
							<Box sx={{ display: { xs: "contents", xl: "none" } }}>
								{customerOrder.isTraderOrder && (
									<Box mb={2}>
										<Chip
											label="Händlerauftrag"
											size="small"
											style={{ backgroundColor: blue[500] }}
										/>
									</Box>
								)}
								{!customerOrder.isTraderOrder && (
									<Box mb={2}>
										<Chip
											label="Kundenauftrag"
											size="small"
											style={{ backgroundColor: grey[500] }}
										/>
									</Box>
								)}
								<CustomerOrderStatusPill customerOrder={customerOrder} />
								{customerOrder.type && (
									<Box mt={2}>
										<Chip
											size="small"
											label={translateCustomerOrderType(customerOrder.type)}
										/>
									</Box>
								)}
							</Box>
							<Box sx={{ display: { xs: "contents", md: "none" } }}>
								<TextBlock
									primary={`Kunde: ${customerOrder.leasmanContract?.customer.name1}`}
								/>
								{!props.align && <Box>
									<ActionButton variant="text" color="primary" onClick={(e) => {
										e.stopPropagation();
										copyClick(e, generateCustomerOrderLink(customerOrder))
									}} style={{ whiteSpace: "nowrap" }}>
										Link kopieren
									</ActionButton>
								</Box>}
							</Box>

						</TableCell>
						<TableCell xl>
							{customerOrder.isTraderOrder && (
								<Box mb={2}>
									<Chip
										label="Händlerauftrag"
										size="small"
										style={{ backgroundColor: blue[500] }}
									/>
								</Box>
							)}
							{!customerOrder.isTraderOrder && (
								<Box mb={2}>
									<Chip
										label="Kundenauftrag"
										size="small"
										style={{ backgroundColor: grey[500] }}
									/>
								</Box>
							)}
							<CustomerOrderStatusPill customerOrder={customerOrder} />
							{customerOrder.type && (
								<Box mt={2}>
									<Chip
										size="small"
										label={translateCustomerOrderType(customerOrder.type)}
									/>
								</Box>
							)}
							{customerOrder.type === "PricingRequest" && (
								<Box mt={2}>
									<PricingRequestDecisionStatusPill customerOrder={customerOrder} disableLink />
								</Box>
							)}
						</TableCell>
						<TableCell md lg xl>
							<TextBlock
								primary={customerOrder.leasmanContract?.customer.name1}
							/>
						</TableCell>
						{!props.align &&
							<TableCell md lg xl>
								<ActionButton variant="text" color="primary" onClick={(e) => {
									e.stopPropagation();
									copyClick(e, generateCustomerOrderLink(customerOrder))
								}} style={{ whiteSpace: "nowrap" }}>
									Link kopieren
								</ActionButton>
							</TableCell>
						}
					</TableRow>
				))}
				{props.isLoading === true && (
					<TableRow>
						<TableCell
							colSpan={4}
							style={{ textAlign: "center" }}
						>
							<CircularProgress />
						</TableCell>
					</TableRow>
				)}
				{props.customerOrders.length === 0 && !props.isLoading && (
					<TableRow>
						<TableCell
							colSpan={4}
							style={{ textAlign: "center" }}
						>
							{props.noRowsMessage}
						</TableCell>
					</TableRow>
				)}
			</TableBody>
		</PaperTable>
	);
};

export default CustomerOrdersOverviewTable;
