import SidebarGroup from './SidebarGroup';
import React, { PropsWithChildren } from "react";
import { useReduxState } from "../../system/store";
import { alpha, Box, Divider, Grid, useTheme } from "@mui/material";

const sidebarGroupType = (<SidebarGroup />).type;

export interface SidebarProps { }

export default ({ children }: PropsWithChildren<SidebarProps>) => {
	const theme = useTheme();
	const layout = useReduxState(s => s.layout);

	let first = true;
	const items = React.Children.map(children, (child: any) => {
		if (!child) return;
		

		if (child.type === sidebarGroupType) {
			const groupItems = React.Children.toArray(child.props.children).filter(c => !!c);
			if (groupItems.length === 0) return;

			const item = (
				<>
					{!first && (
						<Grid item xs={12}>
							<Box my={3} mx={-4} sx={{
								"& > hr": {
									borderColor: alpha(theme.palette.text.primary, 0.05),
								}
							}}>
								<Divider />
							</Box>
						</Grid>
					)}
					{groupItems.map((i, index) => (
						<Grid key={index} item xs={12}>
							{i}
						</Grid>
					))}
				</>
			);

			first = false;

			return item;
		} else {
			return child;
		}
	});

	return (
		<Box mt={layout.fullscreen ? 6 : 0}>
			<Grid
				container
				spacing={1}
			>
				{items}
			</Grid>
		</Box>
	);
}
