import React, { useState } from 'react';
import { confirmable, ReactConfirmProps, createConfirmation } from "react-confirm";
import ThemedDialog from "../../Components/ThemedDialog";
import { Box, Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import CurrencyInput from "../../Components/CurrencyInput";

interface Props extends ChangePriceOptions, ReactConfirmProps<number> {
	show: boolean
}

const ChangePriceDialog = ({ show, proceed, price, cancel }: Props) => {
	const [inputPrice, setInputPrice] = useState(price);

	const handleClose = () => {
		cancel();
	};

	return (
		<ThemedDialog open={show} maxWidth="sm" fullWidth onClose={handleClose}>
			<DialogTitle>
				{price ? "Preis anpassen" : "Preis zuweisen"}
			</DialogTitle>
			<DialogContent>
				<Box mb={2}>
					<CurrencyInput
						value={inputPrice}
						setValue={setInputPrice}
						variant="standard"
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					color="secondary"
					href=""
					onClick={handleClose}
				>
					Abbrechen
				</Button>
				<Button
					variant="outlined"
					color="primary"
					href=""
					onClick={inputPrice ? () => proceed(inputPrice) : undefined}
				>
					Ok
				</Button>
			</DialogActions>
		</ThemedDialog>
	);
};

interface ChangePriceOptions {
	price?: number
}

export default (options: ChangePriceOptions) => {
	const dialog = confirmable(props => <ChangePriceDialog {...props} {...options} />);
	return createConfirmation<number>(dialog)();
}
