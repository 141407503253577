import { VehicleAssessment } from "../../../system/Domain";
import moment from "moment";
import TileContent from "../../../Components/Tiles/TileContent";
import formatCurrency from "../../../system/formatCurrency";
import formatDateTime from "../../../system/formatDateTime";
import Tile from "../../../Components/Tiles/Tile";
import TileActions from "../../../Components/Tiles/TileActions";
import ActionButton from "../../../Components/ActionButton";
import { useHistory } from "react-router";
import dateFormats from "../../../system/dateFormats";
import formatVehicleAssessmentRejectionTopic from "../formatVehicleAssessmentRejectionTopic";
import TextGroup from "../../VehicleSales/TextGroup";
import TextLine from 'Pages/VehicleSales/TextLine';

interface CommonProps {
	assessment: VehicleAssessment
}

const AssessmentOverviewTile = (props: CommonProps) => {

	const isRejected = !!props.assessment.dateRejected;
	const assessmentDate = moment(props.assessment.assessmentDate).format("L");
	let subtitle: string;
	if (props.assessment.dateRejected) {
		subtitle = `vom ${assessmentDate}, abgelehnt am ${moment(props.assessment.dateRejected).format("L")}`;
	} else {
		subtitle = `vom ${assessmentDate}`;
	}

	return (
		<Tile
			title="Gutachten"
			subtitle={subtitle}
		>
			<TileContent>
				{isRejected && (
					<TextGroup title="Ablehnung">
						<TextLine
							color="error"
							label="Grund"
							value={formatVehicleAssessmentRejectionTopic(props.assessment.rejectionTopic!)}
						/>
						<TextLine
							label="Kommentar"
							value={props.assessment.rejectionReason}
						/>
						<TextLine
							label="Abgelehnt von"
							value={props.assessment.rejectedBy?.name}
						/>
						<TextLine
							label="Abgelehnt am"
							value={moment(props.assessment.dateRejected).format(dateFormats.dateTime)}
						/>
					</TextGroup>
				)}
				<TextGroup title="Allgemein">
					<TextLine
						label="Vorgangsnummer"
						value={props.assessment.externalId}
					/>
					<TextLine
						label="Vin"
						value={props.assessment.vin}
					/>
					<TextLine
						label="Kennzeichen"
						value={props.assessment.plateNumber}
					/>
					<TextLine
						label="ESN"
						value={props?.assessment?.kbaEmissionNumber}
					/>
					<TextLine
						label="HSN"
						value={props?.assessment.kbaMakerNumber}
					/>
					<TextLine
						label="TSN"
						value={props?.assessment.kbaModelNumber}
					/>
					<TextLine
						label="Reparaturkosten"
						value={formatCurrency(props.assessment.repairCosts)}
					/>
					<TextLine
						label="Minderwerte"
						value={formatCurrency(props.assessment.depreciationAmount)}
					/>
					<TextLine
						label="Schäden"
						value={formatCurrency(props.assessment.totalDamageAmount)}
					/>
					<TextLine
						label="Market1Price"
						value={formatCurrency(props.assessment.market1Price)}
					/>
					<TextLine
						label="Market2Price"
						value={formatCurrency(props.assessment.market2Price)}
					/>
					<TextLine
						label="Datum übertrag an DMS"
						value={formatDateTime(props.assessment.dateDms)}
					/>
					<TextLine
						label="Datum übertrag an WebMobile (Inklusive Ausstattungen)"
						value={formatDateTime(props.assessment.dateWebMobile)}
					/>
				</TextGroup>
			</TileContent>
		</Tile>
	);
};

export default AssessmentOverviewTile;
