import React, { useState } from "react";
import { KpisGroup } from "../system/Domain";
import Tile from "./Tiles/Tile";
import TileContent from "./Tiles/TileContent";
import { Box } from "@mui/material";
import TileContentDivider from "./Tiles/TileContentDivider";
import { KpisGroupTileEntry } from "../Pages/VehicleSales/SalesKpis";

interface TileProps<TQuery> {
	group: KpisGroup<TQuery>;
	startSession: (group: KpisGroup<TQuery>) => Promise<void>;
	exportKpis: (group: KpisGroup<TQuery>) => Promise<void>;
}

const KpisGroupTile = <TQuery,>({ group, startSession, exportKpis }: TileProps<TQuery>) => {
	const [activeEntry, setActiveEntry] = useState<string | undefined>();

	const handleClick = (_e: React.MouseEvent<HTMLElement, MouseEvent>, group: KpisGroup<TQuery>) => {
		setActiveEntry((value) => (value === group.name ? undefined : group.name));
	};

	return (
		<Tile title={group.name}>
			<TileContent dense>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 1.5,
						paddingTop: 1.5,
						paddingBottom: 1.5,
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<KpisGroupTileEntry
							key={group.name}
							group={{
								...group,
								name: "Gesamt",
							}}
							active={activeEntry === group.name}
							startSession={startSession}
							exportKpis={exportKpis}
							onClick={(e) => handleClick(e, group)}
						/>
					</Box>

					{group.sections
						.filter((s) => s.groups.length > 0)
						.map((s, i) => (
							<React.Fragment key={i}>
								<TileContentDivider dense />
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
									}}
								>
									{s.groups.map((g) => (
										<KpisGroupTileEntry
											key={g.name}
											group={g}
											active={activeEntry === g.name}
											startSession={startSession}
											exportKpis={exportKpis}
											onClick={(e) => handleClick(e, g)}
										/>
									))}
								</Box>
							</React.Fragment>
						))}
				</Box>
			</TileContent>
		</Tile>
	);
};

export default KpisGroupTile;
