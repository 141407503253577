import React from 'react';
import { Form } from "../../../system/useForm";
import { Box, Grid, Typography } from "@mui/material";
import FormChoices from "../../../Components/FormChoices";
import FormTextField from "../../../Components/FormTextField";
import FormDateTimePicker from "../../../Components/FormDateTimePicker";
import { VehicleAddresses } from "../../../system/Domain";
import AssessmentRadioChoice from "./AssessmentRadioChoice";

interface Props {
	form: Form
	addresses: VehicleAddresses
}

const StepAssessment = (props: Props) => {
	const locationFormField = "assessmentLocationOrigin";
	const locationType = props.form.getValue(locationFormField) ?? "";

	const canEdit = locationType === "CustomEntry";
	const needsContactInformation = locationType !== "CarParkAddress";

	return (
		<Box mb={2}>
			<Box mb={2}>
				<FormChoices
					name="assessmentType"
					label="Welcher Gutachtentyp?"
					form={props.form}
					choices={{
						"Restwertgutachten": "R1 - Restwertgutachten",
						"Minderwertsgutachten": "R2 - Minderwertsgutachten"
					}}
					options={{ required: true }}
				/>
			</Box>
			<Box mb={2}>
				<AssessmentRadioChoice
					name={locationFormField}
					form={props.form}
					isRkv={props.addresses.isRkv}
					aldAddressData={props.addresses}
					required
				/>
			</Box>
			<Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>
				Adresse
			</Typography>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<FormTextField
						form={props.form}
						disabled={!canEdit}
						fullWidth
						name="address.name"
						label="Name"
						required
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormTextField
						form={props.form}
						disabled={!canEdit}
						fullWidth
						name="address.street"
						label="Straße"
						required
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormTextField
						form={props.form}
						disabled={!canEdit}
						fullWidth
						name="address.additionalInformation"
						label="Adresszusatz"
						maxLength={30}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={4}>
					<FormTextField
						form={props.form}
						disabled={!canEdit}
						required
						name="address.zipCode"
						label="PLZ"
						fullWidth
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={4}>
					<FormTextField
						form={props.form}
						disabled={!canEdit}
						required
						name="address.city"
						label="Stadt"
						fullWidth
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={4}>
					<FormTextField
						form={props.form}
						disabled={!canEdit}
						required
						name="address.country"
						label="Land"
						fullWidth
						defaultValue="Deutschland"
					/>
				</Grid>
			</Grid>
			{needsContactInformation && (
				<>
					<Typography variant="h6" gutterBottom sx={{ marginTop: 8 }}>
						Ansprechpartner
					</Typography>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6}>
							<FormTextField
								form={props.form}
								required
								name="contact.firstName"
								label="Vorname"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormTextField
								form={props.form}
								required
								name="contact.lastName"
								label="Nachname"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormTextField
								form={props.form}
								required
								name="contact.email"
								label="E-Mail"
								fullWidth
								email
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormTextField
								required
								form={props.form}
								name="contact.telephone"
								label="Telefon"
								fullWidth
							/>
						</Grid>
					</Grid>
				</>
			)}
			<Typography variant="h6" gutterBottom sx={{ marginTop: 8 }}>
				Wunschtermin
			</Typography>
			<Grid container spacing={3}>
				<Grid item xs={12} md={6}>
					<FormDateTimePicker
						name="appointment.notBefore"
						form={props.form}
						label="ab dem"
						margin="normal"
						variant="date"
						format="DD.MM.YYYY"
						disablePast
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<FormDateTimePicker
						name="appointment.from"
						form={props.form}
						label="Zeit von"
						variant="time"
						ampm={false}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<FormDateTimePicker
						name="appointment.to"
						form={props.form}
						label="bis"
						variant="time"
						ampm={false}
					/>
				</Grid>
			</Grid>
			<Typography variant="h6" gutterBottom sx={{ marginTop: 8 }}>
				Zusatzinformationen
			</Typography>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<FormTextField
						form={props.form}
						name="comment"
						label="Bemerkung"
						fullWidth
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

export default StepAssessment;
