import React, { useState } from 'react';
import Layout from "../../../Components/Layout/Layout";
import OrdersSubMenu from "../OrdersSubMenu";
import { Box, CircularProgress } from "@mui/material";
import { ReturnOrderSession } from "../../../system/Domain";
import ReturnOrderSessionsOverviewTable from "./ReturnOrderSessionsOverviewTable";
import FilelessReturnOrderSessionsOverviewTable from "./Sessions/ReturnOrderSessionsOverviewTable";
import FetchNextButton from "../../../Components/FetchNextButton";
import useQuery, { IQuery } from "../../../system/useQuery";
import useAsyncEffect from "../../../system/useAsyncEffect";
import axios from "axios";
import ReturnOrderSessionsOverviewSidebar from "./ReturnOrderSessionsOverviewSidebar";
import Search from "../../../Components/Search";
import OrdersNavigation from 'Navigation/OrdersNavigation';

export interface ReturnOrderSessionsOverviewQuery extends IQuery {
	sort: string
	perspective?: string
	search?: string
	type?: string
}

const ReturnOrderSessionsOverview = () => {
	const [returnOrderSessions, setReturnOrderSessions] = useState<ReturnOrderSession[]>([]);
	const [isFetching, setIsFetching] = useState(false);
	const [query, setQuery, fetchNext, resetQuery] = useQuery<ReturnOrderSessionsOverviewQuery>('returnOrderSessionOverviewQuery', {
		perspective: "All",
		type: "Fileless",
		skip: 0,
		take: 20,
		sort: "dateCreated:desc"
	});

	useAsyncEffect(async (cancelToken) => {
		const axiosCancelTokenSource = axios.CancelToken.source();
		cancelToken.promise.then(() => axiosCancelTokenSource.cancel());

		setIsFetching(true);

		try {
			const { data: ros } = await axios.get<ReturnOrderSession[]>(`/api/orders/returns/sessions`, {
				params: query,
				cancelToken: axiosCancelTokenSource.token
			});

			if (query.skip > 0) {
				setReturnOrderSessions(o => [
					...(o ?? []),
					...ros
				]);
			} else {
				setReturnOrderSessions(ros)
			}
		} finally {
			setIsFetching(false);
		}
	}, [query]);

	return (
		<Layout
			title="Beauftragungen"
			navigation={<OrdersNavigation />}
			subMenu={<OrdersSubMenu />}
			sidebar={<ReturnOrderSessionsOverviewSidebar query={query} setQuery={setQuery} resetQuery={resetQuery} />}
		>
			<Box mb={2}>
				{!returnOrderSessions && (
					<CircularProgress size={48} />
				)}
				{returnOrderSessions && (
					<>
						<Box mb={2}>
							<Search
								value={query.search}
								realtime={false}
								onSearch={(value) => value !== query.search && setQuery(q => ({
									...q,
									skip: 0,
									search: value
								}))}
							/>
						</Box>
						{query.type === "Fileless" && (
							<FilelessReturnOrderSessionsOverviewTable
								returnOrderSessions={returnOrderSessions}
								setReturnOrderSessions={setReturnOrderSessions}
							/>
						)}
						{query.type === "ImportBased" && (
							<ReturnOrderSessionsOverviewTable returnOrderSessions={returnOrderSessions} setReturnOrderSessions={setReturnOrderSessions} />
						)}
						<FetchNextButton
							hidden={returnOrderSessions.length < query.take + query.skip}
							mb={3}
							onNext={fetchNext}
							disabled={isFetching}
						/>
					</>
				)}
			</Box>
		</Layout>
	);
};

export default ReturnOrderSessionsOverview;
