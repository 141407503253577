import { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { Form } from "../../../system/useForm";
import FormTextField from "../../../Components/FormTextField";
import FormDateTimePicker from "../../../Components/FormDateTimePicker";
import CollectionRadioChoice from "./CollectionRadioChoice";
import { Address, Compound, ContactInformation, VehicleAddresses } from "../../../system/Domain";
import FormYesNo from "../../../Components/FormYesNo";
import { EditMode, ReturnOrderFormType } from "./ReturnOrdersForm";
import { addressGetter, addressSetter, contactGetter, contactSetter, setContactFromCompound } from "./ReturnOrderUtils";
import FormSelect from "Components/FormSelect";

interface Props {
	form: Form;
	returnOrder: ReturnOrderFormType;
	addresses: VehicleAddresses;
	editMode: EditMode;
	compounds: Compound[];
}

const StepCollection = ({ form, addresses, returnOrder, editMode, compounds }: Props) => {
	const [lastCollectionType, setLastCollectionType] = useState<string>();
	const [customEntry, setCustomEntry] = useState<{address: Address, contact: ContactInformation}>();
	const [customerContactInfo, setCustomerContactInfo] = useState<ContactInformation>();
	const [traderContactInfo, setTraderContactInfo] = useState<ContactInformation>();

	const entryType = form.getValue("collection.collectionType") ?? "";

	const canEdit = entryType === "CustomEntry";

	useEffect(() => {
		const compound =
			!!returnOrder.collection?.compound &&
			!!compounds &&
			compounds.find((c) => c.name === returnOrder.collection.compound.name);

			switch (lastCollectionType) {
				case "CustomersAddress":
					setCustomerContactInfo(contactGetter("collection.contact", form));
					break;

				case "TraderAddress":
					setTraderContactInfo(contactGetter("collection.contact", form));
					break;
				
				case "CustomEntry":
					setCustomEntry({ address: addressGetter("collection.address", form), contact: contactGetter("collection.contact", form) })
					break;
			
				default:
					break;
			}

			if (!!lastCollectionType) {
				form.setValue("collection.contact", null);
			}

			switch (entryType) {
				case "CustomersAddress":
					addressSetter("collection.address", form, addresses.customerAddress);
					contactSetter("collection.contact", form, customerContactInfo);
					form.setValue("collection.compound", null);
					break;
				
				case "UsedCars":
					if (!compound) {
						form.setValue("collection.address", null);
						form.setValue("collection.contact", null);
						if (!returnOrder.collection.compound && !!compounds) {
							const defaultCompound = compounds?.find((c) => c.isDefault);
							if (!!defaultCompound) {
								form.setValue("collection.compound.name", defaultCompound.name);
							}
						}
					} else {
						addressSetter("collection.address", form, compound.address);
						setContactFromCompound("collection.contact", form, compound);
					}
					break;
	
				case "DriversAddress":
					addressSetter("collection.address", form, addresses.driverAddress);
					form.setValue("collection.compound", null);
					break;
	
				case "TraderAddress":
					addressSetter("collection.address", form, addresses.traderAddress);
					contactSetter("collection.contact", form, traderContactInfo);
					form.setValue("collection.compound", null);
					break;
		
				case "CustomEntry":
					addressSetter("collection.address", form, customEntry?.address);
					contactSetter("collection.contact", form, customEntry?.contact);
					form.setValue("collection.compound", null);
					break;
	
				default:
					form.setValue("collection.address", null);
					form.setValue("collection.contact", null);
					form.setValue("collection.compound", null);
					break;
			}
	
			setLastCollectionType(entryType);

	}, [compounds, returnOrder.collection?.compound?.name, entryType]);


	return (
		<>
			<Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>
				Abholung von
			</Typography>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<CollectionRadioChoice
						name="collection.collectionType"
						form={form}
						aldAddressData={addresses}
						required
					/>
				</Grid>
			</Grid>
			{returnOrder?.collection?.collectionType === "UsedCars" && compounds && (
				<Grid container spacing={3}>
					<Grid item xs={12} md={7} lg={5}>
						<Grid container spacing={3}>
							<FormSelect
								fullWidth={false}
								form={form}
								name="collection.compound.name"
								label="Standort"
								choices={compounds
									.map((c) => c.name)
									.reduce((acc, curr) => ((acc[curr] = curr), acc), {})}
							/>
						</Grid>
					</Grid>
				</Grid>
			)}
			{entryType && (
				<>
					<Typography variant="h6" gutterBottom sx={{ mt: 8, mb: 2 }}>
						Addresse
					</Typography>
					<Grid container spacing={3}>
						<Grid item xs={12} md={7} lg={5}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<FormTextField
										form={form}
										fullWidth
										name="collection.address.name"
										label="Name"
										disabled={!canEdit}
										required
									/>
								</Grid>
								<Grid item xs={12}>
									<FormTextField
										form={form}
										fullWidth
										name="collection.address.name1"
										label="Name"
										disabled={!canEdit}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<FormTextField
										form={form}
										fullWidth
										name="collection.address.street"
										label="Straße und Hausnummer"
										disabled={!canEdit}
										required
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<FormTextField
										form={form}
										fullWidth
										name="collection.address.additionalInformation"
										label="Adresszusatz"
										disabled={!canEdit}
										maxLength={30}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<FormTextField
										form={form}
										disabled={!canEdit}
										name="collection.address.zipCode"
										label="PLZ"
										required
										maxLength={5}
										fullWidth
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<FormTextField
										form={form}
										required
										name="collection.address.city"
										label="Stadt"
										fullWidth
										disabled={!canEdit}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<FormTextField
										form={form}
										required
										name="collection.address.country"
										defaultValue="Deutschland"
										label="Land"
										fullWidth
										disabled={!canEdit}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					{entryType !== "UsedCars" && (
						<>
							<Typography variant="h6" gutterBottom sx={{ marginTop: 8 }}>
								Ansprechpartner
							</Typography>
							<Grid container spacing={3}>
								<Grid item xs={12} md={7} lg={5}>
									<Grid container spacing={3}>
										<Grid item xs={12} sm={6}>
											<FormTextField
												form={form}
												required
												name="collection.contact.firstName"
												label="Vorname"
												fullWidth
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<FormTextField
												form={form}
												required
												name="collection.contact.lastName"
												label="Nachname"
												fullWidth
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<FormTextField
												form={form}
												required
												name="collection.contact.email"
												label="E-Mail"
												fullWidth
												email
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<FormTextField
												required
												form={form}
												name="collection.contact.telephone"
												label="Telefon"
												fullWidth
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</>
					)}
					{!editMode && (
						<>
							<Grid>
								<Typography variant="h6" gutterBottom sx={{ marginTop: 8 }}>
									Benachrichtigungen
								</Typography>
								<Grid item xs={12} sm={6}>
									<FormYesNo
										required
										form={form}
										name="collection.sendEmail"
										label="E-Mail zu eventueller Verzögerung versenden"
										fullWidth
									/>
								</Grid>
							</Grid>
						</>
					)}

					<Typography variant="h6" gutterBottom sx={{ marginTop: 8 }}>
						Wunschtermin
					</Typography>
					<Grid container spacing={3}>
						<Grid item xs={12} md={7} lg={5}>
							<Grid container spacing={3}>
								<Grid item xs={12} md={6}>
									<FormDateTimePicker
										name="collection.appointment.notBefore"
										form={form}
										label="ab dem"
										margin="normal"
										variant="date"
										format="DD.MM.YYYY"
										disablePast
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={3}>
									<FormDateTimePicker
										name="collection.appointment.from"
										form={form}
										label="Zeit von"
										variant="time"
										ampm={false}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={3}>
									<FormDateTimePicker
										name="collection.appointment.to"
										form={form}
										label="bis"
										variant="time"
										ampm={false}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Typography variant="h6" gutterBottom sx={{ marginTop: 8 }}>
						Zusatzinformationen
					</Typography>
					<Grid container spacing={3}>
						<Grid item xs={12} md={7} lg={5}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<FormTextField
										form={form}
										name="collection.comment"
										label="Bemerkung"
										fullWidth
									/>
								</Grid>
								<Grid item xs={12}>
									<FormTextField
										form={form}
										name="collection.internalComment"
										label="interne Bemerkung"
										fullWidth
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</>
			)}
		</>
	);
};

export default StepCollection;
