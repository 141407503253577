import React, { useRef, useState } from "react";
import { Button, TableCell, TableRow, TextField, Typography } from "@mui/material";
import useVat from "../../../system/useVat";
import CurrencyInput from "../../../Components/CurrencyInput";
import formatCurrency from "../../../system/formatCurrency";
import { SaleOrderLine } from "../../../system/Domain";

interface RowProps {
	line? : SaleOrderLine | null
	onChange? : (line : SaleOrderLine) => void
}

const SalesOrderRow = ({ line, onChange } : RowProps) => {
	const blankLine = {
		text: "",
		vatRate: 0,
		netAmount: 0,
		vat: 0,
		grossAmount: 0
	};

	const [currentLine, setCurrentLine] = useState(line ? line : blankLine);
	const inputRef = useRef<HTMLInputElement | null>(null);
	const [error, setError] = useState<string | null>(null);
	const vat = useVat();

	const handleTextChange = (event : React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;

		setError(null);

		setCurrentLine(line => {

			if (!line) return line;

			return {
				...line,
				text: value
			};
		})
	};

	const setCurrencyValue = (field : string) => (value: number) => {
		setError(null);

		let netAmount: number, vatAmount: number, grossAmount: number;

		if (field === "netAmount") {
			netAmount = value;
			vatAmount = vat.calculateVatAmount(value);
			grossAmount = netAmount + vatAmount;
		} else {
			grossAmount = value;
			netAmount = vat.calculateNetAmount(value);
			vatAmount = grossAmount - netAmount;
		}

		setCurrentLine(line => ({
			...line!,
			netAmount,
			grossAmount,
			vat: vatAmount
		}));
	};

	const handleSubmit = () => {
		try {
			onChange && onChange(currentLine);
			setCurrentLine(blankLine);
			inputRef.current?.focus();
		} catch (error) {
			setError(error.message);
		}
	};

	return (
		<>
			<TableRow key="currentLine">
				<TableCell>
					<TextField
						inputRef={inputRef}
						value={currentLine.text}
						onChange={handleTextChange}
						fullWidth
						autoFocus
						variant="outlined"
					/>
				</TableCell>
				<TableCell>
					<CurrencyInput
						variant="outlined"
						fullWidth
						value={currentLine.netAmount}
						setValue={setCurrencyValue("netAmount")}
					/>
				</TableCell>
				<TableCell align="right">
					{formatCurrency(currentLine.vat)}
				</TableCell>
				<TableCell>
					<CurrencyInput
						variant="outlined"
						fullWidth
						value={currentLine.grossAmount}
						setValue={setCurrencyValue("grossAmount")}
					/>
				</TableCell>
				<TableCell>
					<Button onClick={handleSubmit}>
						{line ? "Speichern" : "Hinzufügen"}
					</Button>
				</TableCell>
			</TableRow>
			<TableRow style={{ padding: "unset" }}>
				<TableCell colSpan={5}>
					<Typography color="error">{error}</Typography>
				</TableCell>
			</TableRow>
		</>
	);
};

export default SalesOrderRow;
