import React from "react";
import { Table, TableCell, TableProps, TableRow } from "@mui/material";
import { AversiLeasmanLine } from "../AversiSessionsPage";
import formatCurrency from "../../../system/formatCurrency";

interface Props extends TableProps {
	lines : AversiLeasmanLine[]
}

const AversiLeasmanLineTable = ({ lines, ...rest } : Props) => (
	<Table {...rest}>
		{lines.map(line => {
			return (
				<TableRow key={line.id}>
					<TableCell>{`${line.month}/${line.year}`}</TableCell>
					<TableCell>{line.invoiceId}</TableCell>
					<TableCell>{line.serviceType}</TableCell>
					<TableCell align="right">{formatCurrency(line.amount)}</TableCell>
				</TableRow>
			)
		})}
	</Table>
);

export default AversiLeasmanLineTable
