import React from "react";
import { TooltipProps } from "@mui/material/Tooltip";
import { Box, Tooltip, Typography } from "@mui/material";

export default ({title, children, ...rest} : TooltipProps) => {

	return (
		<Tooltip
			title={(
				<Box p={1}>
					<Typography>{title}</Typography>
				</Box>
			)}
			{...rest}
		>
			{children}
		</Tooltip>
	)
}
