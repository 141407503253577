import React from "react";
import FormDamages from "../../../../Components/FormDamages";
import { damageComponents, damageTypes } from "../../../../system/damages";
import { Form } from "../../../../system/useForm";

export default ({ form } : { form : Form }) => {
	return (
		<>
			<FormDamages
				name="damagesInterior"
				form={form}
				damageTypes={damageTypes.interior}
				damageComponents={damageComponents.interior}
			/>
		</>
	);
};
