import React from "react";
import { Box, TableBody, TableHead, TableRow } from "@mui/material";
import { UserRole, Vehicle } from "../../system/Domain";
import CircularProgress from "@mui/material/CircularProgress";
import { useHistory } from "react-router-dom";
import { Plate } from "../../Components/Plate";
import TextBlock from "../../Components/Layout/TextBlock";
import useCompoundPlaces from "../../system/useCompoundPlaces";
import { InventoryProps } from "./Inventory";
import VehicleStatus from "../Vehicles/VehicleStatus";
import VehiclePlace from "../Vehicles/VehiclePlace";
import PaperTable from "../../Components/PaperTable";
import useUser from "../../system/useUser";
import ActionButton from "../../Components/ActionButton";
import { Colgroup, TableCell } from "../../Components/BreakpointStyledComponents";

export interface InventoryVehicleListProps extends InventoryProps {
	vehicles?: Vehicle[];
	onClick?: (vehicle: Vehicle) => void;
}

export default ({ inventory, vehicles, onClick }: InventoryVehicleListProps) => {
	const [, , hasRole] = useUser();

	const history = useHistory();
	const [places] = useCompoundPlaces();

	const canSeeManualInventoryOption = hasRole(UserRole.AldManager);

	const widths = canSeeManualInventoryOption ? ["35%", "20%", "25%", "20%"] : ["40%", "30%", "30%"];

	const addManually = (e: React.MouseEvent<HTMLElement, MouseEvent>, vehicle: Vehicle) => {
		e.preventDefault();
		e.stopPropagation();

		history.push(`/inventories/${inventory.id}/scan`, vehicle.vin);
	};

	return (
		<PaperTable>
			<Colgroup sm md lg xl>
				<col style={{ width: widths[0] }} />
				<col style={{ width: widths[1] }} />
				<col style={{ width: widths[2] }} />
				{canSeeManualInventoryOption && <col style={{ width: widths[3] }} />}
			</Colgroup>
			<TableHead>
				<TableRow>
					<TableCell>Fahrzeug</TableCell>
					<TableCell sm md lg xl>
						Status
					</TableCell>
					<TableCell sm md lg xl>
						Platz
					</TableCell>
					<TableCell sm md lg xl>
						Zum Bestand hinzufügen
					</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{vehicles &&
					places &&
					vehicles.map((v: Vehicle) => {
						const inventoryEntry = v.history.entries.find((e) => e.inventoryId === inventory.id);

						const index = inventoryEntry ? v.history.entries.indexOf(inventoryEntry) : -1;

						const previousEntry = index <= 0 ? null : v.history.entries[index - 1];

						const status = (
							<>
								{previousEntry && inventoryEntry!.statusChanged && (
									<Box mb={2}>
										<VehicleStatus
											vehicle={v}
											historyEntry={previousEntry}
											showDate
											strikethrough
										/>
									</Box>
								)}
								{inventoryEntry && (
									<VehicleStatus
										vehicle={v}
										historyEntry={inventoryEntry}
										showDate
										defaultColor={inventoryEntry.statusChanged ? "primary" : "default"}
									/>
								)}
								{!inventoryEntry && <VehicleStatus vehicle={v} showStatusChangedDate />}
							</>
						);

						const place = (
							<>
								{previousEntry && inventoryEntry!.placeChanged && (
									<Box mb={2}>
										<VehiclePlace vehicle={v} historyEntry={previousEntry} showDate strikethrough />
									</Box>
								)}
								{inventoryEntry && (
									<VehiclePlace
										vehicle={v}
										historyEntry={inventoryEntry}
										showDate
										defaultColor={inventoryEntry.placeChanged ? "primary" : "default"}
									/>
								)}
								{!inventoryEntry && <VehiclePlace vehicle={v} showPlaceChangedDate />}
							</>
						);
						return (
							<TableRow
								hover
								key={v.id}
								onClick={() => {
									history.push({
										pathname: `/vehicles/${v.id}`,
									});
								}}
							>
								<TableCell>
									<div>
										{v.plateNumber && <Plate plateNumber={v.plateNumber} style={{ zoom: 0.6 }} />}
									</div>
									<TextBlock primary={v.type?.description} secondary={v.vin} />
									<Box sx={{ display: { xs: "contents", sm: "none" } }}>
										<Box mt={2}>
											<Box mb={0.5}>
												<TextBlock primary="Status" />
											</Box>
											{status}
										</Box>
										<Box mt={2}>
											<Box mb={0.5}>
												<TextBlock primary="Platz" />
											</Box>
											{place}
										</Box>
									</Box>
								</TableCell>
								<TableCell sm md lg xl>
									{status}
								</TableCell>
								<TableCell sm md lg xl>
									{place}
								</TableCell>
								<TableCell sm md lg xl>
									<ActionButton variant="text" color="primary" onClick={(e) => addManually(e, v)}>
										Hinzufügen
									</ActionButton>
								</TableCell>
							</TableRow>
						);
					})}
				{(!vehicles || !places) && (
					<TableRow>
						<TableCell colSpan={4} style={{ textAlign: "center" }}>
							<CircularProgress />
						</TableCell>
					</TableRow>
				)}
			</TableBody>
		</PaperTable>
	);
};
