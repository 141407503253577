import React, { useEffect, useState } from "react";
import Layout from "../../../Components/Layout/Layout";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { Box, CircularProgress, Grid, List, ListItem, ListItemText } from "@mui/material";
import moment from "moment";
import { useAsync } from "react-use";
import qs from "query-string";
import Kpi from "./Kpi";
import { useHistory } from "react-router";

interface OrderStatisticItem {
	orderId : string
	dateAssigned : string
	expectedReturnDate : string
	plateNumber : string
	returnDate : string
}

interface StatisticItem {
	orders : OrderStatisticItem[]
	assigns : OrderStatisticItem[]
	returnForms : OrderStatisticItem[]
	plannedReturns : OrderStatisticItem[]
	expectedReturns : OrderStatisticItem[]
	returns : OrderStatisticItem[]
}

type EventType = keyof StatisticItem;

const parseSearch = (search : string) => {
	if (search && search.startsWith("?")) {
		return qs.parse(search.substr(1));
	}

	return {};
};

export default () => {
	const { date } = useParams<{date: string}>();
	const location = useLocation();
	const [eventType, setEventType] = useState<EventType>("orders");
	const [company, setCompany] = useState<string | null>(null);
	const [details, setDetails] = useState<OrderStatisticItem[]>([])

	const history = useHistory();

	const statisticQuery = useAsync(async () => {
		if (!company) {
			return undefined;
		}
		const response = await axios.get<StatisticItem>(`/api/statistics/${date}?company=${company}`);
		return response.data;
	}, [date, company])

	useEffect(() => {
		let { company: locationCompany, eventType: locationEventType } = parseSearch(location.search);

		if (locationCompany && locationCompany !== company) {
			setCompany(locationCompany.toString())
		}

		if (locationEventType && locationEventType !== eventType) {
			// @ts-ignore
			setEventType(locationEventType.toString());
		}
	}, [location]);

	const statistic = statisticQuery.value;

	useEffect(() => {
		if (!statistic || !eventType) {
			setDetails([]);
			return;
		}
		setDetails(statistic[eventType]);
	}, [eventType, statistic]);

	const handleKpiClick = (name : string) => () => {
		const search = qs.stringify({ eventType: name, company });
		history.push(`${location.pathname}?${search}`);
	};

	return (
		<Layout>
			{statisticQuery.loading && (
				<Box mt={3} style={{ textAlign: "center" }}>
					<CircularProgress/>
				</Box>

			)}
			{!statisticQuery.loading && (
				<Box m={2} mt={3}>
					{statistic && (
						<Box>
							<Grid container spacing={2}>
								<Grid item>
									<Kpi
										title="Offene Aufträge"
										value={statistic.orders.length}
										isSelected={eventType === "orders"}
										onClick={handleKpiClick("orders")}
									/>
								</Grid>
								<Grid item>
									<Kpi
										title="Neue Aufträge"
										value={statistic.assigns.length}
										isSelected={eventType === "assigns"}
										onClick={handleKpiClick("assigns")}
									/>
								</Grid>
								<Grid item>
									<Kpi
										title="Rückgaben"
										value={statistic.returns.length}
										isSelected={eventType === "returns"}
										onClick={handleKpiClick("returns")}
									/>
								</Grid>
								<Grid item>
									<Kpi
										title="Ausstehende Rückgaben"
										value={statistic.expectedReturns.length}
										isSelected={eventType === "expectedReturns"}
										onClick={handleKpiClick("expectedReturns")}
									/>
								</Grid>
								<Grid item>
									<Kpi
										title="Rückgabeprotokolle"
										value={statistic.returnForms.length}
										isSelected={eventType === "returnForms"}
										onClick={handleKpiClick("returnForms")}
									/>
								</Grid>
							</Grid>
							<Grid item xs={12} md={6}>
								<List>
									{details.map(d => {

										const handleClick = () => {
											history.push(`/orders/transports/${d.orderId}`);
										};

										return (
											<ListItem
												key={d.orderId}
												button
												onClick={handleClick}
											>
												<ListItemText
													primary={`Logistikauftrag für ${d.plateNumber}`}
													secondary={(
														`Zugewiesen am ${moment(d.dateAssigned).format("L")}`
													)}
												/>
											</ListItem>
										)
									})}
								</List>
							</Grid>
						</Box>
					)}
				</Box>
			)}
		</Layout>
	);
};
