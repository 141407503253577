import axios from "axios";
import fileDownload from "js-file-download";
import moment from "moment";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { GetApp, RotateLeft } from "@mui/icons-material";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Sidebar from "../../Components/Sidebar/Sidebar";
import SidebarButton from "../../Components/Sidebar/SidebarButton";
import SidebarForm from "../../Components/Sidebar/SidebarForm";
import SidebarGroup from "../../Components/Sidebar/SidebarGroup";
import useCompoundPlaces from "../../system/useCompoundPlaces";
import useCompoundProcessSteps from "../../system/useCompoundProcessSteps";
import useUser from "../../system/useUser";
import showMessage from "Dialogs/showMessage";
import parseContentDispositionFilename from "system/parseContentDispositionFilename";

export interface VehiclesSidebarProps {
}

export default () => {
	const [processSteps] = useCompoundProcessSteps();
	const [compoundPlaces] = useCompoundPlaces();

	const [locationsFrom, setLocationsFrom] = useState([]);
	const [locationsTo, setLocationsTo] = useState([]);
	const [locationFrom, setLocationFrom] = useState('');
	const [locationTo, setLocationTo] = useState('');
	const [dateTimeFrom, setDateTimeFrom] = useState();
	const [dateTimeTo, setDateTimeTo] = useState();

	const [, , hasRole] = useUser();

	useEffect(() => {
		if (!compoundPlaces) return;

		// get unique compound names
		const locations = compoundPlaces
			.map((item) => {
				return {
					id: item.id,
					name: item.compound.name
				}
			})
			.filter((item, index, array) => array.findIndex(i => i.name === item.name) === index);

		locations.sort((l1, l2) => l1.name == l2.name ? 0 : (l1.name < l2.name ? -1 : 1))
		setLocationsFrom(locations);
		setLocationsTo(locations);
	}, [compoundPlaces]);


	const [isExporting, setIsExporting] = useState(false);
	const exportVehicleMovements = async () => {
		if (!locationFrom) {
			await showMessage({ message: "Aus darf nicht leer sein" });
			return;
		}
		if (!locationTo) {
			await showMessage({ message: "Nach darf nicht leer sein" });
			return;
		}
		if (locationFrom === locationTo) {
			await showMessage({ message: "Aus und Nach darf nicht dasselbe sein" });
			return;
		}
		if (!dateTimeFrom) {
			await showMessage({ message: "Seit darf nicht leer sein" });
			return;
		}
		if (!dateTimeTo) {
			await showMessage({ message: "Bis darf nicht leer sein" });
			return;
		}
		if (dateTimeFrom > dateTimeTo) {
			await showMessage({ message: "Seit darf nicht spaeter als Bis sein" });
			return;
		}
		setIsExporting(true);
		try {
			const response = await axios.get(`/api/vehicles/compound-place-movement/export`, {
				responseType: "blob",
				params: {
					fromPlace: locationFrom,
					toPlace: locationTo,
					fromDateTime: moment(dateTimeFrom).toISOString(true),
					toDateTime: moment(dateTimeTo).toISOString(true)
				}
			});
			const fileName = parseContentDispositionFilename(response.headers["content-disposition"]);
			fileDownload(response.data, fileName);

		} finally {
			setIsExporting(false);
		}
	}

	const resetFilters = () => {
		setLocationFrom('');
		setLocationTo('');
		setDateTimeFrom(null);
		setDateTimeTo(null);
	}


	return (
		<Sidebar>

			<SidebarGroup>
				<SidebarForm>
					<FormControl variant="standard" fullWidth>
						<InputLabel>Aus</InputLabel>
						<Select
							value={locationFrom}
							onChange={(e) => setLocationFrom(e.target.value as string)}
						>
							{locationsFrom.map(l => (<MenuItem key={l.id} value={l.name}>{l.name}</MenuItem>))}
						</Select>
					</FormControl>
					<FormControl variant="standard" fullWidth>
						<InputLabel>Nach</InputLabel>
						<Select
							value={locationTo}
							onChange={(e) => setLocationTo(e.target.value as string)}
						>
							{locationsFrom.map(l => (<MenuItem key={l.id} value={l.name}>{l.name}</MenuItem>))}
						</Select>
					</FormControl>
					<FormControl fullWidth variant="standard">
						<DatePicker
							format="DD.MM.YYYY HH:mm"
							sx={{ border: 0 }}
							label="Von"
							slotProps={{ textField: { variant: "standard" } }}
							value={dateTimeFrom === undefined ? null : moment(dateTimeFrom)}
							onChange={(dt: any) => { setDateTimeFrom(dt) }}
						/>
					</FormControl>
					<FormControl fullWidth variant="standard">
						<DatePicker
							format="DD.MM.YYYY HH:mm"
							sx={{ border: 0 }}
							label="Bis"
							slotProps={{ textField: { variant: "standard" } }}
							value={dateTimeTo === undefined ? null : moment(dateTimeTo)}
							onChange={(dt: any) => { setDateTimeTo(dt) }}
						/>
					</FormControl>
				</SidebarForm>
			</SidebarGroup>
			<SidebarGroup>
				<SidebarButton
					startIcon={<GetApp />}
					color="inherit"
					label="Standortwechsel Export"
					onClick={exportVehicleMovements}
					disabled={isExporting}
				/>
				<SidebarButton
					startIcon={<RotateLeft />}
					label="Filter zurücksetzen"
					color="inherit"
					onClick={resetFilters}
				/>
			</SidebarGroup>
		</Sidebar>
	);
};
