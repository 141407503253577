import React from 'react';
import { CustomerOrder } from "../../../system/Domain";
import { green, orange, red } from "@mui/material/colors";
import moment from "moment";
import dateFormats from "../../../system/dateFormats";
import TextBlock from "../../../Components/Layout/TextBlock";
import { Chip } from "@mui/material";

interface Props {
	customerOrder : CustomerOrder
}

export const customerOrderStatusSettings = {
	"Created": {
		label: "Erstellt",
		backgroundColor: orange[500],
		date: (customerOrder : CustomerOrder) : string => moment(customerOrder.dateCreated).format(dateFormats.dateTime)
	},
	"Cancelled": {
		label: "Storniert",
		backgroundColor: red[500],
		date: (customerOrder : CustomerOrder) : string => moment(customerOrder.dateCancelled).format(dateFormats.dateTime)
	},
	"Completed": {
		label: "Abgeschlossen",
		backgroundColor: green[500],
		date: (customerOrder : CustomerOrder) : string => moment(customerOrder.dateCompleted).format(dateFormats.dateTime)
	}
};

const CustomerOrderStatusPill = (props : Props) => {
	const setting = customerOrderStatusSettings[props.customerOrder.status];

	return (
		<TextBlock
			primary={
				<Chip
					label={setting.label}
					size="small"
					style={{ backgroundColor: setting.backgroundColor }}
				/>
			}
			secondary={setting.date(props.customerOrder)}
		/>
	);
};

export default CustomerOrderStatusPill;
