import React, { useState } from "react";
import Layout from "../../../Components/Layout/Layout";
import { Box, Button, FormControl, Typography } from "@mui/material";
import useForm from "../../../system/useForm";
import FormDateTimePicker from "../../../Components/FormDateTimePicker";
import moment from "moment";
import FormSelect from "../../../Components/FormSelect";
import axios from "axios";
import { useHistory, useParams } from "react-router";
import { Sale } from "../../../system/Domain";
import { useDispatch } from "react-redux";
import translateError from "../../../system/translateError";

const reasonsForManualPaymentConfirmation = {
	"TransferToDifferentAccount": "Zahlungseingang auf anderem Konto",
	"PaymentDeferred": "Eingang der Zahlung verzögert",
	"PartialPayment": "Teilzahlung erfolgt",
	"Bookmarked": "Vormerkposten SAP liegt vor",
	"ALDFlex":"Interner Verkauf (ALD Flex)",
	"ALDCompanyCar":"Interner Verkauf (Dienstwagen)",
	"ALDVarious":"Interner Verkauf (Diverse)"
};

interface PaymentConfirmation {
	paymentDate : string
	reason : keyof typeof reasonsForManualPaymentConfirmation | null
}

const ConfirmPaymentPage = () => {
	const { id } = useParams<{ id : string }>();
	const [paymentConfirmation, setPaymentConfirmation] = useState<PaymentConfirmation>({
		paymentDate: moment().format("MM.DD.YYYY"),
		reason: null
	});
	const [error, setError] = useState<string | null>(null);
	const history = useHistory();
	const dispatch = useDispatch();

	const setValues = (func : (values : any) => void) => (values: any) => {
		setError(null);
		func(values);
	};

	const form = useForm({
		values: paymentConfirmation,
		setValues: setValues(setPaymentConfirmation),
		deltaValues: null
	});

	const handleSubmit = async (event : React.FormEvent) => {
		event.preventDefault();

		const { reason, paymentDate } = paymentConfirmation;
		if (!reason) {
			setError("Sie müssen einen Grund für die manuelle Bestätigung des Zahlungseinganges angeben");
			return;
		}

		dispatch({ type: "SUSPEND_ERROR_HANDLING" });
		try {
			const { data: sales } = await axios.post<Sale>(`/api/sales/${id}/confirm-payment`, {
				remark: reasonsForManualPaymentConfirmation[reason],
				paymentDate
			});

			const paidOrder = sales.orders.find(o => o.status === "Paid");
			if (paidOrder) {
				history.push(`/sales/${id}/orders/${paidOrder.id}`);
			}
		} catch (error) {
			if (error.isAxiosError && error.response.status === 400) {
				setError(translateError(error.response.data));
			} else {
				throw error;
			}
		} finally {
			dispatch({ type: "RESUME_ERROR_HANDLING" });
		}
	};

	return (
		<Layout
			title="Zahlungseingang bestätigen"
		>
			{error && (
				<Typography color="error">
					{error}
				</Typography>
			)}
			<form onSubmit={handleSubmit}>
				<Box display="flex" flexDirection="column" maxWidth={600}>
					<FormControl margin="normal">
						<FormSelect
							label="Grund für manuellen Zahlungseingang"
							name="reason"
							choices={reasonsForManualPaymentConfirmation}
							form={form}
						/>
					</FormControl>
					<FormControl margin="normal">
						<FormDateTimePicker
							name="paymentDate"
							form={form}
							label="Datum der Zahlung"
							required
							variant="date"
							format="DD.MM.YYYY"
						/>
					</FormControl>
					<Box mt={2} display="flex" flexDirection="row-reverse">
						<Button
							variant="contained"
							color="primary"
							type="submit"
						>
							Speichern
						</Button>
					</Box>
				</Box>
			</form>
		</Layout>
	);
};

export default ConfirmPaymentPage;
