import React, { ReactNode, useState } from 'react';
import { Form } from "../../system/useForm";
import { CustomerOrder, CustomerOrderAddressInformation, UserRole } from "../../system/Domain";
import {
	Box,
	Button,
	CircularProgress,
	MobileStepper,
	StepContent,
	StepLabel,
	Stepper,
	Step,
	Typography, Paper
} from "@mui/material";
import { joinDisplay } from "../Orders/Returns/ReturnOrderUtils";
import TextBlock from "../../Components/Layout/TextBlock";
import useUser from "../../system/useUser";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

export interface StepProps {
	form: Form
	aldAddressData: CustomerOrderAddressInformation
	isCustomerDelivery: boolean
	customerOrder: CustomerOrder
}

export interface Step {
	id: number
	title: string
	ui: (props: any) => ReactNode
}

interface Props {
	customerOrder: CustomerOrder
	steps: Step[]
	backAction: () => void
	editMode: boolean
	toStatusOverview: () => void
	update: () => void
	create: () => void
	form: Form
	isCustomerDelivery?: boolean
}

const CustomerStepper = (props: Props) => {
	const [, , hasRole] = useUser();
	const [activeStep, setActiveStep] = useState(0);
	const [completed, setCompleted] = useState<boolean>(false);

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const canSave = hasRole(UserRole.Customer) || hasRole(UserRole.AldAdmin);

	const maxSteps = props.steps.length;

	const isLastStep = activeStep === props.steps.length - 1;
	const isFirstStep = activeStep === 0;

	const handleBack = () => {
		if (activeStep === 0) {
			props.backAction();
		}

		setActiveStep((prev) => prev - 1);
	};

	const completeCreate = async () => {
		if (!canSave) return;

		setIsSubmitting(true);

		try {
			await props.create();
		} finally {
			setIsSubmitting(false);
		}
	};

	const completeUpdate = async () => {
		if (!canSave) return;

		setIsSubmitting(true);

		try {
			await props.update();
		} finally {
			setIsSubmitting(false);
		}
	};

	const handleNext = async () => {
		const validated = props.form.validateAll();

		if (validated) {
			if (!isLastStep) {
				setActiveStep((prev) => prev + 1);
			} else {
				if (props.editMode) {
					await completeUpdate();
				} else {
					await completeCreate();
				}
				setCompleted(true);
			}
		}
	};

	const customerServiceAgentInformation = props.customerOrder?.leasmanContract?.serviceAgent ? joinDisplay([
		props.customerOrder.leasmanContract.serviceAgent.name,
		props.customerOrder.leasmanContract.serviceAgent.email,
		props.customerOrder.leasmanContract.serviceAgent.telephone
	]) : undefined;

	return (
		<>
			{!completed && (
				<>
					<Box>
						<TextBlock
							secondary={customerServiceAgentInformation}
						/>
					</Box>
					<Box mt={1}>
						<Paper>
							<Box p={2}>
								<Stepper activeStep={activeStep} orientation="vertical">
									{props.steps.map((step, index) => (
										<Step key={index}>
											<StepLabel>
												{step.title}
											</StepLabel>
											<StepContent>
												{step.ui({
													form: props.form,
													aldAddressData: props.customerOrder.addresses,
													isCustomerDelivery: !!props.isCustomerDelivery,
													customerOrder: props.customerOrder
												})}
											</StepContent>
										</Step>
									))}
								</Stepper>
							</Box>
						</Paper>
					</Box>
					<MobileStepper
						backButton={
							<Button
								size="small"
								onClick={handleBack}
								disabled={activeStep < 0}
								color="info"
							>
								{<KeyboardArrowLeft />}
								{!isFirstStep ? "Zurück" : "Abbrechen"}
							</Button>
						}
						nextButton={
							isSubmitting ? (
								<CircularProgress size={24} />
							) : (
								<Button
									size="small"
									onClick={handleNext}
									disabled={activeStep === maxSteps || (activeStep + 1 === maxSteps && !canSave)}
									color="info"
								>
									{!isLastStep ? "Weiter" : "Formular absenden"}
									{<KeyboardArrowRight />}
								</Button>
							)
						}
						style={{ position: "absolute", bottom: 0, left: 0, width: "100%" }}
						steps={maxSteps}
						position="static"
						variant="progress"
						activeStep={activeStep}
					/>
				</>
			)}
			{completed && (
				<Box mt={3}>
					<Typography variant="body1">
						Vielen Dank für Ihre Mitarbeit. Über Ihren Link können Sie ab sofort den Status ihres Auftrags einsehen.
						<br />
						<br />
						<Button
							variant="outlined"
							color="primary"
							onClick={props.toStatusOverview}
						>
							Status anzeigen
						</Button>
					</Typography>
				</Box>
			)}
		</>
	);
}

export default CustomerStepper;
