import React from "react";
import { useAsync } from "react-use";
import axios from "axios";
import PaperTable from "../../Components/PaperTable";
import { Import, VehicleService } from "../../system/Domain";
import { Box, Chip, CircularProgress, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import TextBlock from "../../Components/Layout/TextBlock";
import moment from "moment";

export default ({ imp }: { imp: Import }) => {
	const { value: services, loading } = useAsync(async () => {
		const { data } = await axios.get<VehicleService[]>(`/api/imports/${imp.id}/vehicle-services`);
		return data;
	}, [imp])

	if (!services || services.length === 0) {
		return null;
	}

	return (
		<PaperTable>
			<colgroup>
				<col width="auto"/>
				<col width="50%"/>
				<col width="50%%"/>
				<col width="auto"/>
				<col width="auto"/>
			</colgroup>

			<TableHead>
				<TableRow>
					<TableCell>Status</TableCell>
					<TableCell>Fahrzeug</TableCell>
					<TableCell>Text</TableCell>
					<TableCell>Zugewiesen</TableCell>
					<TableCell>Abgeschlossen</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{loading && (
					<TableRow>
						<TableCell colSpan={5}>
							<Box textAlign="center">
								<CircularProgress/>
							</Box>
						</TableCell>
					</TableRow>
				)}

				{services && services.map(service => {
					const assigned = service.dateAssigned ? moment(service.dateAssigned).format("L") : "-";
					const completed = service.dateCompleted ? moment(service.dateCompleted).format("L") : "-";

					return (
						<TableRow key={service.id}>
							<TableCell>
								<Chip
									label={service.vehicleId ? "Vorhanden" : "Fehlt"}
									color={service.vehicleId ? "primary" : "secondary"}
								/>
							</TableCell>
							<TableCell>
								<TextBlock
									primary={service.plateNumber}
									secondary={<span>{service.vin} - {service.businessLine}</span>}
								/>
							</TableCell>
							<TableCell>
								{service.text}
							</TableCell>
							<TableCell>
								{assigned}
							</TableCell>
							<TableCell>
								{completed}
							</TableCell>
						</TableRow>
					)
				})}
			</TableBody>
		</PaperTable>
	)
}
