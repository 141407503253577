import { Dispatch, SetStateAction, useState } from "react";
import { VehicleService } from "../../system/Domain";
import useAsyncEffect from "../../system/useAsyncEffect";
import axios from "axios";
import { IQuery } from "../../system/useQuery";

export interface VehicleServiceQuery extends IQuery {
	perspective? : string
	sort : string
	company? : string
	serviceTypeId? : string
	dateFrom? : string
	dateTo? : string
	billingId? : string
	billingClient? : string
}

export default (query : VehicleServiceQuery, isReady: ((query : VehicleServiceQuery) => boolean) | null = null) : [VehicleService[], Dispatch<SetStateAction<VehicleService[]>>, boolean] => {
	const [isFetching, setIsFetching] = useState(false);
	const [vehicleServices, setVehicleServices] = useState<VehicleService[]>([]);

	useAsyncEffect(async (cancelToken) => {
		if (!!isReady && !isReady(query)) {
			setVehicleServices([]);
			return;
		}

		setIsFetching(true);

		try {
			const { data: services } = await axios.get<VehicleService[]>(`/api/vehicles/services`, {
				params: query,
				cancelToken: cancelToken.getAxiosToken()
			});

			if (query.skip > 0) {
				setVehicleServices(s => [
					...s,
					...services
				]);
			} else {
				setVehicleServices(services);
			}
		} finally {
			setIsFetching(false);
		}
	}, [query]);

	return [vehicleServices, setVehicleServices, isFetching];
}
