import React, { useState } from "react";
import { Button, ButtonProps, Menu } from "@mui/material";

interface Props extends ButtonProps {
	label: string
}

const ButtonMenu = ({ label, children, ...rest }: Props) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const handleClose = () => {
		setAnchorEl(null);
	};

	const closeOnClick = (func: any) => () => {
		setAnchorEl(null);
		func();
	};

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const clickableChildren = React.Children.map(children, (child: any) => {
		if (!child?.props.onClick) return null;

		return React.cloneElement(child, {
			onClick: closeOnClick(child.props.onClick)
		});
	});

	if (!clickableChildren) {
		return <Button {...rest} disabled>{label}</Button>;
	}

	if (clickableChildren.length === 1) {
		// if there is only on option use it as a default
		const onClick = clickableChildren && clickableChildren[0].props.onClick;
		return <Button {...rest} onClick={onClick}>{label}</Button>;
	}

	return (
		<>
			<Button {...rest} onClick={handleClick}>
				{label}
			</Button>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				{clickableChildren}
			</Menu>
		</>
	);
};

export default ButtonMenu;
