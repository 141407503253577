import React, { useState } from "react";
import { DialogTitle, DialogContent, DialogActions, Button } from "@mui/material"
import { confirmable, createConfirmation, ReactConfirmProps } from "react-confirm";
import ThemedDialog from "./ThemedDialog";
import moment from "moment";
import { FormControl } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { disableWeekends } from "./FormDateTimePicker";

interface GetDateDialogProps extends ReactConfirmProps<string | null> {
	title: string
	text?: string
}

const GetDateDialog = ({ show, proceed, title, text }: GetDateDialogProps) => {
	const [date, setDate] = useState(moment());
	const [error, setError] = useState<string | null>(null);

	const handleChange = (date: any) => {
		setDate(date || moment());
		setError(null);
	};

	const handleClose = () => proceed(null);

	const handleSave = async () => {
		const dateValue = moment(date);

		if (!dateValue.isValid()) {
			setError("Ungültiges Datum");
		} else {
			proceed(dateValue.toISOString(true));
		}
	};

	return (
		<ThemedDialog
			open={show}
			fullWidth
			maxWidth="xs"
			onClose={handleClose}
		>
			<DialogTitle>{title}</DialogTitle>
			{text && <DialogContent>{text}</DialogContent>}
			<DialogContent>
				<FormControl fullWidth>
					<LocalizationProvider utils={AdapterMoment} dateAdapter={AdapterMoment}>
						<DateTimePicker
							format="DD.MM.YYYY HH:ss"
							openTo="day"
							views={["day"]}
							value={date}
							onChange={handleChange}
							disableFuture
							slotProps={{ textField: { variant: 'standard', error: !!error } }}
							shouldDisableDate={disableWeekends}
						/>
					</LocalizationProvider>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<DialogActions>
					<Button variant="outlined" color="secondary" onClick={handleClose} href="">
						Abbrechen
					</Button>
					<Button variant="outlined" color="primary" onClick={handleSave} href="">
						Speichern
					</Button>
				</DialogActions>
			</DialogActions>
		</ThemedDialog>
	);
};

export default (title: string, text?: string) => {
	const dialog = confirmable(props => <GetDateDialog {...props} title={title} text={text} />);
	return createConfirmation<string | null>(dialog)();
};
