import axios from "axios";
import { useAsync } from "react-use";
import Layout from "../../Components/Layout/Layout";
import React, { useCallback, useState } from "react";
import { Box, FormControl, InputLabel, List, ListItem, ListItemText, MenuItem, Select } from "@mui/material";

interface FtpStorageItem {
	name : string
}

const FtpStorage = () => {
	const [directory, setDirectory] = useState("dekra-prod");
	const [files, setFiles] = useState<FtpStorageItem[]>([]);
	const [busy, setBusy] = useState(false);

	useAsync(async () => {
		const { data } = await axios.get<FtpStorageItem[]>(`/api/ftp-storage/${directory}`);
		setFiles(data)
	}, [directory]);

	const handleDrop = useCallback(async (files : File[]) => {
		setBusy(true);

		let formData = new FormData();

		files.forEach(f => formData.append("files", f));

		await axios.post<string>(`/api/ftp-storage/${directory}`,
			formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		);

		setFiles(state => [
			...files.map(ff => ({
				name: ff.name
			})),
			...state
		]);

		setBusy(false);
	}, [directory]);

	// React.ChangeEvent<{ value : unknown }> bad type after upgrade to mui 5
	const handleChange = (event : any): any => {
		setDirectory(event.target.value as string);
	};

	return (
		<Layout
			title="FTP Server"
			onDropFiles={handleDrop}
			loading={busy}
		>
			<Box mb={2}>
				<FormControl variant="standard">
					<InputLabel>Verzeichnis</InputLabel>
				
					<Select value={directory} onChange={handleChange}>
						<MenuItem value="dekra-test">Testverzeichnis</MenuItem>
						<MenuItem value="dekra-prod">Produktionsverzeichnis</MenuItem>
					</Select>
				</FormControl>
			</Box>
			<List dense>
				{files.length === 0 && (
					<ListItem>
						<ListItemText primary="keine Dateien gefunden"/>
					</ListItem>
				)}
				{files.map(f => {
					return (
						<ListItem disableGutters>
							<ListItemText
								primary={f.name}
							/>
						</ListItem>
					)
				})}
			</List>
		</Layout>
	)
};

export default FtpStorage;
