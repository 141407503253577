import moment from "moment";
import { DateTimeRange } from "../system/Domain";
import React, { useEffect, useRef, useState } from "react";
import { TextField, Select, MenuItem, BaseTextFieldProps, Box } from "@mui/material";

interface DateTimeRangeProps extends BaseTextFieldProps {
	value: DateTimeRange,
	onChange: any
	margin: "none" | "normal"
}

export default ({ value, onChange, margin = "none", ...rest }: DateTimeRangeProps) => {
	const [dateValue, setDateValue] = useState<string>(moment(value.date).format("YYYY-MM-DD"));
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		setDateValue(value.date);
	}, [value]);

	const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setDateValue(event.currentTarget.value);
		const date = moment(event.currentTarget.value);

		if (date.isValid()) {
			onChange({
				target: {
					value: {
						...value,
						date: date.format("YYYY-MM-DD")
					}
				}
			});
		}
	};

	const handleRangeChange = (event: React.ChangeEvent<any>) => {
		onChange({
			...event,
			target: {
				value: {
					...value,
					range: event.target.value
				}
			}
		});
	};

	return (
		<Box sx={{
			display: "flex",
			flexDirection: "row",
			...(margin !== 'none' && {
				marginTop: 16,
				marginBottom: 8
			})
		}}>
			<TextField
				{...rest}
				inputRef={inputRef}
				variant="outlined"
				value={dateValue}
				type="date"
				onChange={handleDateChange}
			/>
			<Select
				sx={{
					paddingRight: 32,
					marginLeft: 8
				}}
				variant="outlined"
				value={value.range}
				onChange={handleRangeChange}
			>
				<MenuItem value={1}>Vormittags</MenuItem>
				<MenuItem value={2}>Nachmittags</MenuItem>
			</Select>
		</Box>
	);
};
