import React, { useEffect, useState } from "react";
import TextBlock from "../../Components/Layout/TextBlock";
import { ThemeColor, UserRole, WorkSession, WorkSessionUserNavigation } from "../../system/Domain";
import axios from "axios";
import ListItem from "../../Components/ListItem";
import ListItemContent from "../../Components/ListItemContent";
import ListItemActions from "../../Components/ListItemActions";
import ListItemAction from "../../Components/ListItemAction";
import formatRelativeDate from "../../system/formatRelativeDate";
import ListItemChips from "../../Components/ListItemChips";
import ListItemChip from "../../Components/ListItemChip";
import { useDispatch } from "react-redux";
import useUser from "../../system/useUser";
import { setWorkSession } from "../../system/reducers/workSessions/actions";
import formatWorkSessionPerspective from "../WorkSessions/formatWorkSessionPerspective";
import InlineList from "../../Components/InlineList";
import { Launch, Stop } from "@mui/icons-material";
import useEvent from "../../system/useEvent";

interface Props {
	session : WorkSession
}

type Mode = "view"
	| "sell";

export default ({ session } : Props) => {
	const [mode] = useState<Mode>("view");

	const [current, setCurrent] = useState(session.current);
	useEffect(() => {
		setCurrent(session.current);
	}, [session.current]);

	let color : ThemeColor = "info";
	switch (mode) {
		case "view":
		default:
			color = "info";
			break;
	}

	const dispatch = useDispatch();
	const [user, , hasRole] = useUser();

	const openSession = async (id : string) => {
		const { data: session } = await axios.get<WorkSession>(`/api/work-sessions/${id}`);
		dispatch(setWorkSession(session));
	};

	const closeSession = async (id : string) => {
		await axios.post<WorkSession>(`/api/work-sessions/${id}/close`);
	};

	const clickSession = (e : React.MouseEvent<HTMLElement, MouseEvent>) => {
		if (e.altKey && hasRole(UserRole.AldAdmin)) {
			closeSession(session.id);
			return;
		}
		openSession(session.id)
	}

	useEvent("work-session:user-navigation", data => {
		const update = data as WorkSessionUserNavigation;

		if (!update || !session || update.sessionId !== session.id) {
			return;
		}

		const fromCreator = update.user.id === session.createdBy.id;
		if (fromCreator) {
			setCurrent(update.current);
		}
	}, [session]);

	return (
		<ListItem
			color={color}
		>
			<ListItemActions>
				{mode === "view" && (
					<>
						<ListItemAction
							icon={<Launch />}
							onClick={clickSession}
						/>
						{(session.createdBy.id === user?.id || hasRole(UserRole.AldAdmin)) && (
							<ListItemAction
								icon={<Stop />}
								onClick={() => closeSession(session.id)}
							/>
						)}
					</>
				)}
			</ListItemActions>
			<ListItemContent
				onClick={clickSession}
			>
				<TextBlock
					flat
					primary={session.title || formatWorkSessionPerspective(session.perspective)}
					secondary={(
						<InlineList>
							{session.createdBy.name}
							{formatRelativeDate(session.dateCreated)}
						</InlineList>
					)}
				/>
				{mode === "view" && (
					<></>
				)}
				<ListItemChips>
					<ListItemChip
						active
						label={`Position: ${current}/${session.items.length}`}
					/>
				</ListItemChips>
			</ListItemContent>
		</ListItem>
	);
}
