import { CarType, ReturnOrder, ReturnOrderTireType } from "../../system/Domain";
import React from "react";
import Tile from "../../Components/Tiles/Tile";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import TileContent from "../../Components/Tiles/TileContent";
import TextBlock from "../../Components/Layout/TextBlock";
import { getEnumName } from "../../utils";
import moment from "moment";
import dateFormats from "../../system/dateFormats";

interface Props {
	returnOrder: ReturnOrder
	primary?: ButtonProps
	secondary?: ButtonProps
}

interface ButtonProps {
	title: string
	action: () => void
}

const CustomerCarState = ({ returnOrder, ...props }: Props) => {
	return (
		<>
			<Tile>
				<Box p={2}>
					<Typography variant="h6">Fahrzeuginformationen</Typography>
				</Box>
				<Divider />
				<TileContent>
					<Box mb={2}>
						{returnOrder.isDrivable ? (
							<TextBlock
								primary={`Fahrzeug ist fahrbereit`}
								secondary={returnOrder.drivableComment}
							/>
						) : (
							<>Fahrzeug ist nicht fahrbereit</>
						)}
						<br />
						{returnOrder.hasValidMainInspection ? (
							<TextBlock
								primary={"Hu/Au gültig"}
								secondary={returnOrder.mainInspectionValidUntil ? `bis ${moment(returnOrder.mainInspectionValidUntil).format(dateFormats.monthYear)}` : undefined}
							/>
						) : (
							<>HuAu nicht gültig</>
						)}
						<br />
						{`Fahrzeug ${!returnOrder.isRegistered ? "nicht " : ""}angemeldet`}
						<br />
						{returnOrder.tireType.toString() === "unk" && (
							<>Reifentyp: </>
						)}
						{getEnumName(ReturnOrderTireType, returnOrder.tireType)}
						{returnOrder.engineType === "Unknown" && returnOrder.isElectricCar && (
							<>
								<br />
								Elektroauto
							</>
						)}
						{returnOrder.carType && (
							<>
								<br />
								{returnOrder.carType.toString() === "unk" && (
									<>Fahrzeugtyp: </>
								)}
								{getEnumName(CarType, returnOrder.carType)}
							</>
						)}
					</Box>
				</TileContent>
			</Tile>
		</>
	);
};

export default CustomerCarState;
