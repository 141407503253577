import React, { PropsWithChildren, useState } from 'react';
import { Badge } from "@mui/material";
import useAsyncEffect from "../../../system/useAsyncEffect";
import axios from "axios";
import useVehicleServicePermissions from "../../../Pages/VehicleServices/useVehicleServicePermissions";
import { useNetworkState } from "react-use";

const VehicleServiceUnconfirmedBadge = (props : PropsWithChildren<any>) => {
	const networkState = useNetworkState();
	const [count, setCount] = useState(0);
	const vehicleServicePermissions = useVehicleServicePermissions();

	useAsyncEffect(async () => {
		if (!vehicleServicePermissions.canSeeMainMenuBadge || !networkState.online) {
			return;
		}

		const { data } = await axios.get<number>(`/api/vehicles/services`, {
			params: {
				status: "Requested",
				countOnly: true
			}
		});

		setCount(data);
	}, []);

	return (
		<Badge color="primary" badgeContent={count}
			   anchorOrigin={{
				   vertical: 'top',
				   horizontal: 'right',
			   }}>
			{props.children}
		</Badge>
	);
};

export default VehicleServiceUnconfirmedBadge;
