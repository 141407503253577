import React from "react";
import TextBlock from "../../Components/Layout/TextBlock";
import formatDateTime from "../../system/formatDateTime";
import { PricingRequest } from "../../system/Domain";
import { colors } from "@mui/material";
import InlineList from "../../Components/InlineList";
import formatRelativeDate from "../../system/formatRelativeDate";
import { formatPricingRequestStatus } from "./PricingRequestDetailsTile";

interface Props {
	request: PricingRequest
	historyId?: string
	showDate?: boolean
	showRelativeDate?: boolean
}

export default ({ request, historyId, showDate = true, showRelativeDate = false }: Props) => {
	const historyEntry = !!historyId
		? request.statusHistory.find(h => h.id === historyId)
		: request.statusHistory[request.statusHistory.length - 1];

	if (!historyEntry) {
		return null;
	}

	const secondary = (
		<InlineList>
			{showDate && formatDateTime(historyEntry.date)}
			{showRelativeDate && formatRelativeDate(historyEntry.date)}
		</InlineList>
	);

	const label = formatPricingRequestStatus(historyEntry.value);

	switch (historyEntry.value) {
		case "Created":
			return (
				<TextBlock
					chip={{
						label: label,
						color: "default"
					}}
					secondary={secondary}
				/>
			);
		case "Requested":
			return (
				<TextBlock
					chip={{
						label: label,
						color: "secondary"
					}}
					secondary={secondary}
				/>
			);
		case "Pricing":
			return (
				<TextBlock
					chip={{
						label: label,
						color: "primary"
					}}
					secondary={secondary}
				/>
			);
		case "Priced":
			return (
				<TextBlock
					chip={{
						label: label,
						explicitColor: colors.green[500]
					}}
					secondary={secondary}
				/>
			);
		case "Completed":
			return (
				<TextBlock
					chip={{
						label: label,
						explicitColor: colors.green[500]
					}}
					secondary={secondary}
				/>
			);
		case "Withdrawn":
			return (
				<TextBlock
					chip={{
						label: label,
						explicitColor: colors.red[500]
					}}
					secondary={secondary}
				/>
			);
		default:
			return (
				<TextBlock
					chip={{
						label: label,
						color: "default"
					}}
					secondary={secondary}
				/>
			);
	}
}
