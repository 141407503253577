import React from 'react';
import { Import, UnregistrationOrder } from "../../../system/Domain";
import { useAsync } from "react-use";
import axios from "axios";
import { Box, Chip, TableBody, TableCell, TableRow } from "@mui/material";
import PaperTable from "../../../Components/PaperTable";
import { useHistory } from "react-router";
import { Plate } from "../../../Components/Plate";
import TextBlock from "../../../Components/Layout/TextBlock";
import UnregistrationOrderStatusPill from "../../Orders/Unregistrations/UnregistrationOrderStatusPill";

const CompleteUnregistrationsView = ({ imp }: { imp: Import }) => {
	const history = useHistory();

	const { value: orders } = useAsync(async () => {
		const { data } = await axios.get<UnregistrationOrder[]>(`/api/orders/unregistrations`, {
			params: {
				importId: imp.id
			}
		});
		return data;
	}, [imp]);

	return (
		<Box mt={2}>
			<PaperTable>
				<colgroup>
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />
				</colgroup>
				<TableBody>
					{orders && orders.map((o, i) => (
						<TableRow
							key={i}
							hover
							onClick={() => history.push(`/orders/unregistrations/${o.id}`)}
						>
							<TableCell>
								<Plate plateNumber={o.plateNumber} style={{ zoom: 0.6 }} />
								<TextBlock
									primary={o.vehicle.type?.description}
									secondary={o.vin}
									compact
								/>
							</TableCell>
							<TableCell>
								<Chip
									label={o.assignedTo.name}
									size="small"
								/>
							</TableCell>
							<TableCell>
								<UnregistrationOrderStatusPill unregistrationOrder={o} />
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</PaperTable>
		</Box>
	);
};

export default CompleteUnregistrationsView;
