import React from "react";
import Layout from "../../Components/Layout/Layout";
import { Box, CircularProgress } from "@mui/material";
import { useParams } from "react-router";
import useBilling from "./useBilling";
import BillingSubMenu from "./BillingSubMenu";
import BillingHeader from "./BillingHeader";
import BillingClientTable from "./BillingClientTable";
import BillingClient from "./BillingClient";
import BillingSidebar from "./BillingSidebar";
import getBillingInvoices from "./getBillingInvoices";

export default () => {
	const { id, client } = useParams<{ id : string, client? : string }>();
	const [billing, setBilling] = useBilling(id);

	const billingClient = billing && client
		? billing.clients.find(c => c.name === client)
		: undefined;

	const billingInvoices = getBillingInvoices(billing, billingClient);

	return (
		<Layout
			title="Abrechnung"
			subMenu={billing && (
				<BillingSubMenu
					billing={billing}
				/>
			)}
			sidebar={billing && (
				<BillingSidebar
					billing={billing}
					setBilling={setBilling}
					client={billingClient}
					invoices={billingInvoices}
				/>
			)}
		>
			{!billing && (
				<CircularProgress size={48} />
			)}
			{billing && (
				<>
					<BillingHeader
						billing={billing}
					/>
					<Box mt={4}>
						{!client && (
							<BillingClientTable
								billing={billing}
								setBilling={setBilling}
							/>
						)}
						{client && (
							<BillingClient
								billing={billing}
								setBilling={setBilling}
								client={billingClient}
								services={billingClient?.services ?? billing.services}
								invoices={billingInvoices}
							/>
						)}
					</Box>
				</>
			)}
		</Layout>
	);
}
