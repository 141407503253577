import React, { PropsWithChildren } from "react";
import { Grid } from "@mui/material";

export interface SidebarFormProps {
}

export default ({ children } : PropsWithChildren<SidebarFormProps>) => {
	return (
		<Grid container spacing={4}>
			{React.Children.map(children, (child : any) => {
				if (!child) {
					return;
				}

				return (
					<Grid item xs={12}>
						{child}
					</Grid>
				);
			})}
		</Grid>
	);
}
