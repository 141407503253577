import { Remarketing, ReturnOrder, TransportOrder } from "../../../system/Domain";
import { confirmable, createConfirmation, ReactConfirmProps } from "react-confirm";
import React, { useState } from "react";
import ThemedDialog from "../../../Components/ThemedDialog";
import { Box, Button, DialogTitle } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import UnregistrationStatusActionTile from "./UnregistrationStatusActionTile";

interface Props extends AlterReturnOrderUnregistrationInformationOptions, ReactConfirmProps<OutputData> {
	show: boolean
}

interface OutputData {
	doUnregistration: boolean
	unlinkUnregistration: boolean
}

interface AlterReturnOrderUnregistrationInformationOptions {
	transportOrder: TransportOrder
	returnOrder?: ReturnOrder
	remarketing?: Remarketing
}

const AlterReturnOrderUnregistrationInformationDialog = (props: Props) => {
	const handleClose = () => {
		props.cancel();
	};

	const [doUnregistration, setDoUnregistration] = useState(false);
	const [unlinkUnregistration, setUnlinkAUnregistration] = useState(false);

	return (
		<ThemedDialog open={props.show} maxWidth="sm" fullWidth onClose={handleClose}>
			<DialogTitle>
				Abmeldeauftrag - {props.transportOrder.vehicle.plateNumber}
			</DialogTitle>
			<DialogContent>
				<Box mb={2}>
					<UnregistrationStatusActionTile
						transportOrder={props.transportOrder}
						returnOrder={props.returnOrder}
						remarketing={props.remarketing}
						setDoUnregistration={setDoUnregistration}
						setUnlinkUnregistration={setUnlinkAUnregistration}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					color="secondary"
					href=""
					onClick={handleClose}
				>
					Abbrechen
				</Button>
				<Button
					variant="outlined"
					color="primary"
					href=""
					onClick={() => props.proceed({
						doUnregistration: doUnregistration,
						unlinkUnregistration: unlinkUnregistration
					})}
				>
					Ok
				</Button>
			</DialogActions>
		</ThemedDialog>
	);
};

export default (options: AlterReturnOrderUnregistrationInformationOptions) => {
	const dialog = confirmable(props => <AlterReturnOrderUnregistrationInformationDialog {...props} {...options} />);
	return createConfirmation<OutputData>(dialog)();
}
