import { Chip, PropTypes, useTheme } from "@mui/material";
import React, { ReactNode, useEffect, useState } from "react";

export interface ChipProps {
	label: ReactNode;
	size?: "small" | "medium" | undefined;
	color?: Exclude<PropTypes.Color, "inherit">;
	explicitColor?: string;
	multiline?: boolean;
	strikethrough?: boolean;
	clickable?: boolean;
	onClick?: () => void;
	style?: React.CSSProperties;
	icon?: React.ReactElement;
	variant?: "filled" | "outlined";
}

export default ({
	label,
	size = "small",
	color = "default",
	explicitColor = undefined,
	clickable = undefined,
	onClick = undefined,
	multiline = false,
	strikethrough = false,
	style,
	icon,
	variant = "filled",
}: ChipProps) => {
	const theme = useTheme();
	const [chipStyle, setChipStyle] = useState<React.CSSProperties>();

	useEffect(() => {
		if (!explicitColor && !style) {
			setChipStyle(undefined);
			return;
		}
		if (variant === "filled") {
			setChipStyle({
				backgroundColor: explicitColor,
				...style,
			});
		} else {
			setChipStyle({
				borderColor: explicitColor,
				color: explicitColor,
				...style,
			});
		}
	}, [explicitColor, style]);

	return (
		<Chip
			label={label}
			size={size}
			color={color}
			style={chipStyle}
			clickable={clickable}
			onClick={onClick}
			icon={icon}
			variant={variant}
			sx={{
				borderRadius: theme.spacing(2),
				...(multiline && {
					borderRadius: theme.spacing(1),
					height: "auto",
					marginTop: -theme.spacing(0.5),
					marginBottom: -theme.spacing(0.5),
					paddingTop: theme.spacing(0.5),
					paddingBottom: theme.spacing(0.5),
				}),
				...(strikethrough && { textDecoration: "line-through" }),
			}}
		/>
	);
};
