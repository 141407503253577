import { Cancel, Check, Clear, ExpandMore, Undo } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import ListItem from "../../Components/ListItem";
import FluentGrid from "../../Components/FluentGrid";
import TextBlock from "../../Components/Layout/TextBlock";
import ListItemAction from "../../Components/ListItemAction";
import ListItemActions from "../../Components/ListItemActions";
import ListItemContent from "../../Components/ListItemContent";
import { Plate } from "../../Components/Plate";
import React, { Dispatch, SetStateAction, useState } from "react";
import { AssessmentOrderCreationMethod, RepairOrder, ThemeColor, UserRole } from "system/Domain";
import FormSelect from "../../Components/FormSelect";
import useForm from "system/useForm";
import useWorkshops from "system/useWorkshops";
import axios from "axios";
import ListItemChips from "../../Components/ListItemChips";
import ListItemChip from "../../Components/ListItemChip";
import formatNumber from "system/formatNumber";
import LineList from "../../Components/LineList";
import formatDate from "system/formatDate";
import { formatRepairOrderStatus, paintRepairOrderStatus } from "./CarglassRepairOrderListItem";
import RepairCostEstimateActionButtons from "./RepairCostEstimateActionButtons";
import RepairCostEstimateDownloadButton from "./RepairCostEstimateDownloadButton";
import RepairCostEstimateUndoButton from "./RepairCostEstimateUndoButton";
import RepairOrderCompleteButton from "./RepairOrderCompleteButton";
import RepairOrderGenerateOrderPdfChip from "./RepairOrderGenerateOrderPdfChip";
import useUser from "system/useUser";
import RepairOrderDeleteCancelButton from "./RepairOrderDeleteCancelButton";
import { GeneralRepairOrderAction } from "./GeneralRepairOrderListItem";
import RepairOrderStatusHistory from "./RepairOrderStatusHistory";

export enum RepairOrderViewModeBase {
	View = "View",
	Cancel = "Cancel",
	CostEstimated = "CostEstimated",
	UndoCostEstimation = "UndoCostEstimation",
	Complete = "Complete",
	StatusHistory = "StatusHistory",
	NewItem = "NewItem",
	Edit = "Edit"
}

export enum ViewModeAssessment {
	Details = "Details",
	List = "List",
	Delete = "Delete",
	CancelOrder = "CancelOrder",
	CancelWorkshopOrder = "CancelWorkshopOrder",
}

export type ViewMode = RepairOrderViewModeBase | ViewModeAssessment;

export interface RepairOrderTaskAssignmentFormType {
	company?: string;
}

export interface Props {
	compact?: boolean;
	setOrder?(order: RepairOrder): void
	order: RepairOrder
	setIsFetching: Dispatch<SetStateAction<boolean>>
	viewMode?: ViewMode
	defaultViewMode: ViewMode
	orders?: RepairOrder[]
	setOrders?: React.Dispatch<React.SetStateAction<RepairOrder[]>>
}

const DekraRepairOrderListItem = ({
	setOrder,
	order,
	setIsFetching,
	viewMode,
	orders,
	setOrders,
	defaultViewMode,
	compact,
}: Props) => {
	const [workshops] = useWorkshops();
	const [mode, setMode] = useState<ViewMode>(viewMode ?? defaultViewMode);
	const [, , , , hasAnyRole] = useUser();

	const [repairOrderTaskAssignmentFormType, setRepairOrderTaskAssignmentFormType] =
		useState<RepairOrderTaskAssignmentFormType>({
			company: order.company?.name,
		});

	const form = useForm({
		values: repairOrderTaskAssignmentFormType,
		setValues: setRepairOrderTaskAssignmentFormType,
	});

	let color: ThemeColor;
	switch (mode) {
		case RepairOrderViewModeBase.Cancel:
		case ViewModeAssessment.CancelOrder:
			color = "warning";
			break;
		case ViewModeAssessment.Details:
			color = "primary";
			break;
		case RepairOrderViewModeBase.UndoCostEstimation:
		case ViewModeAssessment.CancelWorkshopOrder:
			color = "error";
			break;
		case RepairOrderViewModeBase.Complete:
			color = "primary";
			break;
		case RepairOrderViewModeBase.View:
		case ViewModeAssessment.List:
		default:
			color = "info";
			break;
	}

	const onWorkshopSave = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		e.preventDefault();
		e.stopPropagation();

		if (!repairOrderTaskAssignmentFormType) {
			return;
		}

		setIsFetching(true);

		try {
			const { data: responseData } = await axios.put<RepairOrder>(`/api/orders/repairs/${order.id}/workshop`, {
				company: repairOrderTaskAssignmentFormType.company,
			});

			if (setOrder) {
				setOrder(responseData);
			}

			setMode(defaultViewMode);
		} finally {
			setIsFetching(false);
		}
	};

	const onCancel = () => {
		setMode(defaultViewMode);
		setRepairOrderTaskAssignmentFormType({
			company: order.company?.name,
		});
	};

	const deleteGeneralRepairOrder = async (order: RepairOrder, action: GeneralRepairOrderAction) => {
		setIsFetching(true);
		try {
			if (action === GeneralRepairOrderAction.Delete) {
				throw new Error("Deletion of Assessment repair orders is not supported. Use cancelation instead");
			}

			if (action === GeneralRepairOrderAction.Cancel) {
				const { data } = await axios.post<RepairOrder>(`/api/orders/repairs/${order.id}/cancel`, {});

				setOrders(orders?.map((o) => (o.id !== order.id ? o : data)));
			}
		} finally {
			setIsFetching(false);
			setMode(defaultViewMode);
		}
	};

	const onWorkshopOrderCancellation = async () => {
		setIsFetching(true);
		try {
			const { data } = await axios.put<RepairOrder>(`/api/orders/repairs/${order.id}/workshop/cancel`);
			setOrder(data);
		} finally {
			setIsFetching(false);
			setMode(defaultViewMode);
		}
	};

	const isFormCompanyUnassigned =
		!repairOrderTaskAssignmentFormType.company || repairOrderTaskAssignmentFormType.company === "";

	const isUnassignedChanged =
		(isFormCompanyUnassigned && order.company) || (!isFormCompanyUnassigned && !order.company);

	const isWorkshopChanged = (order.company && repairOrderTaskAssignmentFormType.company !== (order.company?.name)) || isUnassignedChanged;

	const formatYesNo = (input: boolean) => (input ? "Ja" : "Nein");

	const getDescriptionOrDefaultText = (input: string, emptyPlaceholder?: string) => {
		return input || emptyPlaceholder || "Unbekannt";
	};

	return (
		<ListItem color={color} transparent={order.status === "Completed"}>
			<ListItemActions>
				{(mode === RepairOrderViewModeBase.View || mode === ViewModeAssessment.List) && (
					<ListItemAction icon={<ExpandMore />} onClick={() => setMode(ViewModeAssessment.Details)} />
				)}
				{mode === ViewModeAssessment.Details && (
					<>
						{(order.status === "Assignable" || order.status === "Assigned") && (
							<>
								<ListItemAction
									icon={<Check />}
									onClick={onWorkshopSave}
									disabled={!isWorkshopChanged}
								/>

								{hasAnyRole([UserRole.AldUser, UserRole.AldManager]) && (
									<ListItemAction
										tooltip="Reparaturauftrag stornieren"
										icon={<Cancel />}
										onClick={() => setMode(ViewModeAssessment.CancelOrder)}
									/>
								)}
							</>
						)}

						{order.status === "Ordered" && (
							<>
								<RepairCostEstimateActionButtons order={order} setOrder={setOrder} />

								<ListItemAction
									tooltip="Beauftragung rückgängig machen"
									icon={<Undo />}
									onClick={() => {
										setMode(ViewModeAssessment.CancelWorkshopOrder);
									}}
								/>
							</>
						)}

						{order.status === "CostEstimated" && (
							<ListItemAction
								tooltip="KVA Upload rückgängig machen"
								icon={<Undo />}
								onClick={() => setMode(RepairOrderViewModeBase.UndoCostEstimation)}
							/>
						)}

						<ListItemAction icon={<Clear />} onClick={onCancel} />
					</>
				)}

				{(mode === RepairOrderViewModeBase.Cancel ||
					mode === RepairOrderViewModeBase.Complete ||
					mode === RepairOrderViewModeBase.UndoCostEstimation ||
					mode === ViewModeAssessment.CancelWorkshopOrder ||
					mode === ViewModeAssessment.CancelOrder ||
					mode === RepairOrderViewModeBase.StatusHistory) && (
					<ListItemAction icon={<Clear />} onClick={() => setMode(defaultViewMode)} />
				)}
			</ListItemActions>
			<ListItemContent>
				{!compact && (
					<TextBlock
						primary={<Plate plateNumber={order.plateNumber} style={{ zoom: 0.6 }} />}
						secondary={`${order.vehicle.type?.make.name} ${order.vehicle.type?.model.version} ${order.vehicle.type?.model.variant}`}
					/>
				)}
				{compact && (
					<TextBlock
						primary="Mängelmeldung Reparatur"
						secondary={order?.company?.name ?? "Nicht zugewiesen"}
					/>
				)}

				<Box mt={2} mb={1}>
					<FluentGrid>
						{mode !== ViewModeAssessment.List && !compact && (
							<TextBlock primary={order.vin} secondary="FIN" reduced />
						)}

						{!compact && <TextBlock primary="Mängelmeldung Reparatur" secondary="Reparatur" reduced />}

						{(mode === ViewModeAssessment.Details ||
							mode === ViewModeAssessment.List ||
							mode === RepairOrderViewModeBase.Complete) && (
							<>
								<TextBlock
									primary={getDescriptionOrDefaultText(order?.vehicleAssessmentDefectInfo?.mileage?.toString())}
									secondary="Letzter KM-Stand"
									reduced
								/>

								<TextBlock
									primary={formatDate(order?.vehicleAssessmentDefectInfo?.dateLastMaintenance, "Unbekannt")}
									secondary="Letzte Wartung"
									reduced
								/>

								<TextBlock
									primary={formatYesNo(order?.vehicleAssessmentDefectInfo?.hasCarsChequebook)}
									secondary="Scheckheft gepflegt"
									reduced
								/>

								<TextBlock
									primary={formatYesNo(order?.vehicleAssessmentDefectInfo?.isReadyToDrive)}
									secondary="Fahrbereit"
									reduced
								/>

								{order?.comment && (
									<Box sx={{ gridColumn: "span 2" }}>
										<TextBlock primary={order?.comment} secondary="Kommentar" reduced />
									</Box>
								)}

								<TextBlock
									primary={<LineList lines={order.repairWorks.map((t) => t.description)} />}
									secondary="Reparatur"
									reduced
								/>
							</>
						)}
					</FluentGrid>

					{mode === ViewModeAssessment.Details &&
						(order.status === "Assignable" || order.status === "Assigned") && (
							<FormSelect
								label="Werkstatt"
								name="company"
								form={form}
								entries={[
									{
										choice: "",
										label: "-",
									},
									...workshops?.map((w) => {
										return {
											choice: w.name,
											label: w.name,
										};
									}),
								]}
								options={{ required: true }}
							/>
						)}
				</Box>

				{mode === RepairOrderViewModeBase.Complete && (
					<RepairOrderCompleteButton order={order} setMode={setMode} />
				)}

				{mode === RepairOrderViewModeBase.UndoCostEstimation && (
					<RepairCostEstimateUndoButton order={order} setOrder={setOrder} setMode={setMode} />
				)}

				{mode === ViewModeAssessment.CancelOrder && (
					<RepairOrderDeleteCancelButton
						order={order}
						onRepairOrderDelete={deleteGeneralRepairOrder}
						generalRepairOrderAction={GeneralRepairOrderAction.Cancel}
					/>
				)}

				{mode === ViewModeAssessment.CancelWorkshopOrder && (
					<Button color="error" onClick={onWorkshopOrderCancellation}>
						Beauftragung rückgängig machen
					</Button>
				)}

				{mode === RepairOrderViewModeBase.StatusHistory && <RepairOrderStatusHistory repairOrder={order} />}

				<ListItemChips>
					<ListItemChip
						label={formatRepairOrderStatus(order.status)}
						color={paintRepairOrderStatus(order.status)}
						active={mode === RepairOrderViewModeBase.StatusHistory}
						onClick={() =>
							setMode(
								mode === RepairOrderViewModeBase.StatusHistory
									? defaultViewMode
									: RepairOrderViewModeBase.StatusHistory,
							)
						}
					/>

					{!compact && !!order?.company && <ListItemChip label={order.company.name ?? "Nicht zugewiesen"} />}

					{mode === ViewModeAssessment.List && (
						<ListItemChip
							label={formatNumber(order.repairWorks.length, "-", "Einträge", "Eintrag")}
							color="info"
						/>
					)}

					{mode !== ViewModeAssessment.List && (
						<ListItemChip
							label={formatNumber(order.repairWorks.length, "-", "Einträge", "Eintrag")}
							color="primary"
							onClick={() =>
								setMode((m) => (m === defaultViewMode ? ViewModeAssessment.Details : defaultViewMode))
							}
						/>
					)}

					<ListItemChip
						label={order?.vehicleAssessmentDefectInfo?.isExternalAssessment ? "externes GA" : "internes GA"}
					/>

					{order?.creationMethod === AssessmentOrderCreationMethod.SubjectInquiryConversion &&
						<ListItemChip label="Sachverhaltsanfrage" />
					}

					{(order.status === "CostEstimated" || order.status === "Completed") && (
						<RepairCostEstimateDownloadButton order={order} />
					)}

					{mode === ViewModeAssessment.List && order.status === "Ordered" && (
						<RepairOrderGenerateOrderPdfChip repairOrder={order} />
					)}

					{order.compound && <ListItemChip label={order.compound.name} />}
				</ListItemChips>
			</ListItemContent>
		</ListItem>
	);
};

export default DekraRepairOrderListItem;
