import { amber, blue, green, red, blueGrey, indigo, orange } from "@mui/material/colors";
import { adaptV4Theme, createTheme, darken } from "@mui/material";

function darkenColorPalette(palette: Record<string, string>, amount: number): Record<string, string> {
	const darkenedPalette: Record<string, string> = {};
	Object.keys(palette).forEach((shade) => {
		const color = palette[shade];
		darkenedPalette[shade] = darken(color, amount);
	});
	return darkenedPalette;
}

export default (paletteMode: "dark" | "light") => {
	const isDarkMode = paletteMode === "dark";

	return createTheme(
		adaptV4Theme({
			palette: {
				mode: paletteMode,
				primary: isDarkMode ? blue : darkenColorPalette(blue, 0.2),
				secondary: amber,
				success: {
					main: green[500],
				},
				warning: {
					main: orange[500],
				},
				error: {
					main: red[500],
				},
				grey: {},
				info: {
					main: isDarkMode ? "#fff" : "#000",
				},
				background: {
					default: isDarkMode ? "#262a32" : "#f3f9fe",
					paper: isDarkMode ? "#232833" : "#ffffff",
				},
			},
			typography: {
				// fontFamily: "Chillax-Regular",
				h6: {
					fontWeight: 300,
				},
			},
			overrides: {
				MuiButton: {
					colorInherit: {
						borderColor: isDarkMode ? "rgba(255, 255, 255, 0.25)" : "rgba(0, 0, 0, 0.25)",
					},
				},
			},
		}),
	);
};
