import  { ReactNode } from "react";
import ActionButton from "../ActionButton";
import { ButtonProps } from "@mui/material";

interface Props extends ButtonProps {
	label : ReactNode
}

export default ({ label, ...rest } : Props) => {
	return (
		<ActionButton
			variant="text"
			size="small"
			color="inherit"
			{...rest}
		>
			{label}
		</ActionButton>
	);
};
