import React, { useState } from "react";
import { AssessmentOrder, CustomerOrder, ReturnOrder, TransportOrder, UnregistrationOrder, UserRole } from "../../system/Domain";
import TransportOrderTable from "Pages/Orders/Transports/TransportOrderTable";
import useAsyncEffect from "../../system/useAsyncEffect";
import axios from "axios";
import { Box, CircularProgress } from "@mui/material";
import VehicleHeader from "./VehicleHeader";
import { useParams } from "react-router";
import useVehicle from "../../system/useVehicle";
import LotScanContextMenu from "./LotScanContextMenu";
import VehicleHeaderMenu from "./VehicleHeaderMenu";
import VehicleSubMenu from "./VehicleSubMenu";
import Layout from "../../Components/Layout/Layout";
import AssessmentOrdersOverviewTable from "../Orders/Assessments/AssessmentOrdersOverviewTable";
import UnregistrationOrdersOverviewTable from "../Orders/Unregistrations/UnregistrationOrdersOverviewTable";
import useUser from "../../system/useUser";
import LayoutSubTitle from "../../Components/Layout/LayoutSubTitle";
import VehicleNavigation from "Navigation/VehicleNavigation";
import ReturnOrdersOverviewTable from "Pages/Orders/Returns/ReturnOrdersOverviewTable";
import CustomerOrdersOverviewTable from "Pages/Orders/Customers/CustomerOrdersOverviewTable";
import useCompounds from "system/useCompounds";

export default () => {
	const { id } = useParams<{ id : string }>();

	const [, , hasRole] = useUser();
	const [vehicle, , reloadVehicle] = useVehicle(id);
	const [compounds] = useCompounds();

	const isAldUser = hasRole(UserRole.AldUser);
	const isLotManager = hasRole(UserRole.LotManager);
	const isDamageAssessor = hasRole(UserRole.DamageAssessor);

	const [orders, setOrders] = useState<TransportOrder[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const [assessmentOrders, setAssessmentOrders] = useState<AssessmentOrder[]>([]);
	const [isLoadingAssessmentOrders, setIsLoadingAssessmentOrders] = useState(false);

	const [unregistrationOrders, setUnregistrationOrders] = useState<UnregistrationOrder[]>([]);
	const [isLoadingUnregistrationOrders, setIsLoadingUnregistrationOrders] = useState(false);
	
	const [returnOrders, setReturnOrders] = useState<ReturnOrder[]>([]);
	const [isLoadingReturnOrders, setIsLoadingReturnOrders] = useState(false);

	const [customerOrders, setCustomerOrders] = useState<CustomerOrder[]>([]);
	const [isLoadingCustomerOrders, setIsLoadingCustomerOrders] = useState(false);

	useAsyncEffect(async () => {
		setOrders([]);
		setIsLoading(false);

		if (!vehicle) {
			return;
		}

		setIsLoading(true);

		try {
			const response = await axios.get<TransportOrder[]>("/api/orders/transports", {
				params: {
					vin: vehicle.vin,
					sort: "dateAssigned:desc"
				}
			});

			setOrders(response.data);
		} finally {
			setIsLoading(false);
		}
	}, [vehicle]);

	useAsyncEffect(async () => {
		if (!vehicle) {
			return;
		}

		if (!isAldUser && !isDamageAssessor && !isLotManager) {
			return;
		}

		setIsLoadingAssessmentOrders(true);

		try {
			const response = await axios.get<AssessmentOrder[]>("/api/orders/assessments", {
				params: {
					vin: vehicle.vin
				}
			});

			setAssessmentOrders(response.data);
		} finally {
			setIsLoadingAssessmentOrders(false);
		}
	}, [vehicle]);

	useAsyncEffect(async () => {
		if (!vehicle) {
			return;
		}

		if (!isAldUser) {
			return;
		}

		setIsLoadingUnregistrationOrders(true);

		try {
			const response = await axios.get<UnregistrationOrder[]>("/api/orders/unregistrations", {
				params: {
					vin: vehicle.vin
				}
			});

			setUnregistrationOrders(response.data);
		} finally {
			setIsLoadingUnregistrationOrders(false);
		}
	}, [vehicle]);

	useAsyncEffect(async () => {
		setReturnOrders([]);
		setIsLoadingReturnOrders(false);

		if (!vehicle) {
			return;
		}

		if (!isAldUser) {
			return;
		}

		setIsLoadingReturnOrders(true);

		try {
			const response = await axios.get<ReturnOrder[]>("/api/orders/returns", {
				params: {
					vin: vehicle.vin,
					sort: "dateAssigned:desc"
				}
			});

			setReturnOrders(response.data);
		} finally {
			setIsLoadingReturnOrders(false);
		}
	}, [vehicle]);

	useAsyncEffect(async () => {
		setCustomerOrders([]);
		setIsLoadingCustomerOrders(false);

		if (!vehicle) {
			return;
		}

		if (!isAldUser) {
			return;
		}

		setIsLoadingCustomerOrders(true);

		try {
			const response = await axios.get<CustomerOrder[]>("/api/orders/customers", {
				params: {
					vin: vehicle.vin,
					sort: "dateAssigned:desc"
				}
			});

			setCustomerOrders(response.data);
		} finally {
			setIsLoadingCustomerOrders(false);
		}
	}, [vehicle]);

	return (
		<Layout
			title="Aufträge"
			plateNumber={vehicle?.plateNumber}
			navigation={<VehicleNavigation vehicle={vehicle} />}
			contextMenu={[
				<LotScanContextMenu />,
				<VehicleHeaderMenu vehicle={vehicle} />
			]}
			subMenu={vehicle && <VehicleSubMenu vehicle={vehicle} />}
		>
			{!vehicle && (
				<CircularProgress size={48} />
			)}
			{vehicle && (
				<>
					<VehicleHeader vehicle={vehicle} />
					<Box mt={4} mb={2}>
						<LayoutSubTitle label="Transportaufträge" />
					</Box>
					<TransportOrderTable
						orders={orders}
						isLoading={isLoading}
						noRowsMessage="Es liegen keine Transportaufträge für dieses Fahrzeug vor."
						vehicle={vehicle}
						reloadVehicle={reloadVehicle}
						compounds={compounds}
						align
					/>
					{(isAldUser || isDamageAssessor || isLotManager) && (
						<>
							<Box mt={6} mb={2}>
								<LayoutSubTitle label="Gutachtenaufträge" />
							</Box>
							<AssessmentOrdersOverviewTable		
								assessmentOrders={assessmentOrders}
								isLoading={isLoadingAssessmentOrders}
								noRowsMessage="Es liegen keine Gutachtenaufträge für dieses Fahrzeug vor."
								vehicle={vehicle}
								reloadVehicle={reloadVehicle}
								align
							/>
						</>
					)}
					{isAldUser && (
						<>
							<Box mt={6} mb={2}>
								<LayoutSubTitle label="Abmeldeaufträge" />
							</Box>
							<UnregistrationOrdersOverviewTable
								unregistrationOrders={unregistrationOrders}
								isLoading={isLoadingUnregistrationOrders}
								noRowsMessage="Es liegen keine Abmeldeaufträge für dieses Fahrzeug vor."
								vehicle={vehicle}
								reloadVehicle={reloadVehicle}
								align
							/>
						</>
					)}
					{isAldUser && (
						<>
							<Box mt={6} mb={2}>
								<LayoutSubTitle label="Rückholungen" />
							</Box>
							<ReturnOrdersOverviewTable
								returnOrders={returnOrders}
								isLoading={isLoadingReturnOrders}
								compounds={compounds}
								noRowsMessage="Es liegen keine Rückholungen für dieses Fahrzeug vor."
								align
							/>
						</>
					)}
					{isAldUser && (
						<>
							<Box mt={6} mb={2}>
								<LayoutSubTitle label="Kundenaufträge" />
							</Box>
							<CustomerOrdersOverviewTable
								customerOrders={customerOrders}
								isLoading={isLoadingCustomerOrders}
								noRowsMessage="Es liegen keine Kundenaufträge für dieses Fahrzeug vor."
								align
							/>
						</>
					)}
				</>
			)}
		</Layout>
	);
};
