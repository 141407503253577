import React, { useEffect, useState } from "react";
import moment from "moment";
import useAsyncEffect from "../../../system/useAsyncEffect";
import axios from "axios";
import dateFormats from "../../../system/dateFormats";
import { CustomerOrder, PricingRequest } from "../../../system/Domain";
import { CustomerOrderWithReturnOrderId } from "../CustomerStatusOverview";
import { useRecoilState } from "recoil";
import { customerTitle } from "../../../system/atoms/customerTitle";
import { Box, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import CustomerChoiceDisplay from "../CustomerChoiceDisplay";
import { LocalOffer } from "@mui/icons-material";
import Tile from "../../../Components/Tiles/Tile";
import TileContent from "../../../Components/Tiles/TileContent";
import TextBlock from "../../../Components/Layout/TextBlock";
import TileActions from "../../../Components/Tiles/TileActions";
import ActionButton from "../../../Components/ActionButton";
import CustomerConfirmationDialog from "../CustomerConfirmationDialog";
import CustomerEmailNotificationDialog from "../CustomerEmailNotificationDialog";
import PricingRequestInformationOverview from "./PricingRequestInformationOverview";
import CustomerPricingRequestConfirmationDialog from "./CustomerPricingRequestConfirmationDialog";
import PricingDisplay from "./PricingDisplay";
import PricingRequestChoice, { calculateFormData } from "./PricingRequestChoice";
import NewCustomOrderConfirmationDialog from "./NewCustomOrderConfirmationDialog";
import showMessage from "Dialogs/showMessage";

interface Props {
	customerOrder: CustomerOrderWithReturnOrderId
	toStatusOverview: () => void
	customerCancellation: (customerOrder: CustomerOrder) => void
	updateCustomerOrder: (customerOrder: CustomerOrder) => void
	customerDecision: (customerOrder: CustomerOrder) => void
}

const PricingRequestStatusOverview = (props: Props) => {
	const [, setCustomerTitle] = useRecoilState(customerTitle);
	const [wantsToEdit, setWantsToEdit] = useState(false);
	const pricingRequestDenied = props.customerOrder.type === "PricingRequest" && props.customerOrder.pricingRequestDecision?.accepted === false;
	const canCreateOrder = pricingRequestDenied && !props.customerOrder.linkedOrder;

	useEffect(() => setCustomerTitle("Status Kaufpreisanfrage"), []);

	const [pricingRequest, setPricingRequest] = useState<PricingRequest>();

	useAsyncEffect(async () => {
		const response = await axios.get<PricingRequest>(`/api/pricing-requests/${props.customerOrder.pricingRequestId}`);

		setPricingRequest(response.data);
	}, [props.customerOrder]);

	const awaitingCustomerPricingChoice = (pricingRequest?.status === "Completed" || pricingRequest?.status === "Priced") && !props.customerOrder.pricingRequestDecision;
	const showDecision = !!props.customerOrder.pricingRequestDecision;

	const decision = showDecision ? props.customerOrder.pricingRequestDecision.accepted ? "- Angenommen" : "- Abgelehnt" : "";

	const pricingRequestStatus = !pricingRequest ? "" :
		pricingRequest.status === "Pricing" ? "- In Bearbeitung" :
			pricingRequest.status === "Priced" ? "- Bepreist" :
				pricingRequest.status === "Completed" ? (
					showDecision ? decision : "- Bepreist"
				) : "";

	const canEdit = pricingRequest?.status === "Requested";
	const canCancel = pricingRequest?.status === "Requested";

	const confirmCancellation = async () => {
		const answer = await CustomerConfirmationDialog({
			title: "Kaufpreisanfrage stornieren",
			message: "Sind Sie sich sicher, dass sie die Kaufpreisanfrage stornieren wollen? "
		});

		if (answer.yes) {
			const { data: customerOrder } = await axios.post<CustomerOrder>(`/api/orders/customers/${props.customerOrder.id}/revoke`);

			props.customerCancellation(customerOrder);
		}
	};

	const handleEmailChange = async () => {
		const result = await CustomerEmailNotificationDialog({
			title: "Emailbenachrichtigung anpassen",
			message: "Bitte geben Ihre aktuelle Email-Adresse ein",
			initialValue: props.customerOrder.customerEmail
		});

		// undefined means nothing needs to change
		if (result !== undefined) {
			const { data: customerOrder } = await axios.put<CustomerOrder>(`/api/orders/customers/${props.customerOrder.id}/pricing-request/email-notification`, {
				customerEmail: result
			});

			props.updateCustomerOrder(customerOrder);
		}
	};

	const edit = () => setWantsToEdit(true);
	const stopEditing = () => setWantsToEdit(false);

	const denyOffer = async () => {
		const result = await CustomerPricingRequestConfirmationDialog({
			title: "Kaufpreis ablehnen",
			message: "Sind Sie sich sicher, dass sie den angebotenen Kaufpreis ablehnen wollen?",
			buttonLabel: "Ablehnen"
		});

		if (result) {
			const response = await axios.post<CustomerOrder>(`/api/orders/customers/${props.customerOrder.id}/pricing-request/confirmation`, {
				decision: false
			});

			props.customerDecision(response.data);
		}
	};

	const acceptOffer = async () => {
		const result = await CustomerPricingRequestConfirmationDialog({
			title: "Kaufpreis annehmen",
			message: "Sind Sie sich sicher, dass sie den angebotenen Kaufpreis annehmen wollen? Der Kundenservice wird sich daraufhin mit Ihnen in Verbindung setzen.",
			buttonLabel: "Annehmen"
		});

		if (result) {
			const response = await axios.post<CustomerOrder>(`/api/orders/customers/${props.customerOrder.id}/pricing-request/confirmation`, {
				decision: true
			});

			props.customerDecision(response.data);
		}
	};

	const createOrder = async () => {
		const result = await NewCustomOrderConfirmationDialog({
			proposedEmail: props.customerOrder.customerEmail.trim()
		});

		if (result) {
			const response = await axios.post<CustomerOrder>(`/api/orders/customers/${props.customerOrder.id}/follow-up`, {
				email: result
			});

			props.updateCustomerOrder(response.data)

			await showMessage({
				message: "Ein neuer Link wurde an Ihre angegbene E-Mail Adresse geschickt. Bitte überprüfen Sie auch den Spamordner.",
				title: "Informationen"
			});
		}
	};

	return (
		<>
			{!wantsToEdit && (
				<Grid container spacing={2}>
					<Grid item xs={12} lg={10}>
						<Box mt={2}>
							<CustomerChoiceDisplay
								title={`Kaufpreisanfrage ${pricingRequestStatus}`}
								icon={<LocalOffer />}
							/>
						</Box>
					</Grid>
					{(awaitingCustomerPricingChoice || showDecision) && pricingRequest && (
						<Grid item xs={12} lg={10} xl={8}>
							<Box p={2}>
								<Typography variant="h6">
									Ihre Kaufpreisanfrage vom {moment(props.customerOrder.dateCompleted).format(dateFormats.date)}
								</Typography>
							</Box>
							<Box mb={2}>
								<TileContent>
									<PricingDisplay pricingRequest={pricingRequest} />
								</TileContent>
							</Box>
							{showDecision && !props.customerOrder.pricingRequestDecision.accepted && (
								<Box mt={1} mb={2}>
									<TileContent>
										<Box>
											Wir bedauern, dass der angebotene Kaufpreis keine Zustimmung gefunden hat.<br />
											<br />
											Um das Fahrzeug zur Rückgabe anzumelden, verwenden Sie bitte den unten angeführten Link. <br />
											<br />
											Sofern Sie den Wagen bei sich oder Ihrem Autohaus abholen lassen möchten, so verwenden Sie bitte den Button „Abholung planen“, wenn Sie das Fahrzeug zu
											unserem
											Gebrauchtwagenplatz bringen wollen, verwenden Sie bitte „Selbst anliefern“. <br />
											<br />
											Bitte beachten Sie, dass eine Überführung im Winter nur mit entsprechender Bereifung stattfinden kann. Alternativ können wir Ihnen eine Überführung per
											Fremdachse (LKW bzw. Trailer) anbieten. Hier entstehen Zusatzkosten in Höhe von 99,-€ netto (PKW) und 129,-€ netto (Transporter). Dasselbe gilt für ein
											bereits
											abgemeldetes Fahrzeug.
										</Box>
									</TileContent>
								</Box>
							)}
							{showDecision && (
								<>
									<TileContent>
										<Box>
											<>
												{props.customerOrder.pricingRequestDecision.accepted && (
													<strong>Sie haben das Angebot angenommen. Ihr Kundenbetreuer wird sich mit Ihnen in Verbindung setzen</strong>
												)}
												{!props.customerOrder.pricingRequestDecision.accepted && (
													<>
														<strong>Sie haben das Angebot abgelehnt.</strong>
														{canCreateOrder && (
															<> <strong>Klicken Sie hier um die Rückführung Ihres Fahrzeugs zu planen.</strong> <br /><br /> Bitte beachten Sie, dass bis zur Rückmeldung
																des Fahrzeuges der Leasingvertrag in Berechnung verbleibt.</>
														)}
													</>
												)}
											</>
										</Box>
										{props.customerOrder.linkedOrder && (
											<Box>Neue Rückführung angefragt am {moment(props.customerOrder.linkedOrder.dateCreated).format(dateFormats.dateTime)}</Box>
										)}
									</TileContent>

									{pricingRequestDenied && (
										<TileActions>
											<ActionButton
												variant="text"
												color="secondary"
												title="Rückführung planen"
												onClick={createOrder}
											>
												{canCreateOrder ? "Rückführung planen" : "E-Mail erneut anfordern"}
											</ActionButton>
										</TileActions>
									)}
								</>
							)}

							{awaitingCustomerPricingChoice && (
								<>
									<TileContent>
										<Box>
											<strong>Dieser Verkaufspreis ist ein freibleibendes Angebot basierend auf den von Ihnen eingebenen Daten.</strong>
										</Box>
										<Box mt={2}>
											<TextBlock
												primary={moment(pricingRequest.datePurchase).format(dateFormats.date)}
												secondary="Ankaufstermin"
												// align="center"
												compact
											/>
											<Box mt={2}>
												<TextBlock
													primary={pricingRequest.mileage.toString()}
													secondary="Kilometerstand: km (gemäß Ihren Angaben)"
													// align="center"
													compact
												/>
											</Box>
										</Box>
										<Box mt={2}>
											Bei dem Kaufpreis ist ein ordnungsgemäßer Fahrzeugzustand, dem Alter und der Laufleistung angemessen, zugrunde
											gelegt.
										</Box>
										<Box mt={2}>
											Wenn Sie dieses Angebot annehmen möchten, akzeptieren Sie bitte den Preis auf dieser Seite. Die Annahme dieses
											Antrages auf
											Abschluss eines Kaufvertrages behalten wir uns vor. Die Annahme erfolgt vielmehr erst durch die Zusendung eines
											entsprechenden
											vorbereiteten Kaufvertrages, welcher von beiden Parteien rechtsgültig unterzeichnet werden muss.
											<br />
											<br />
											Wichtig: Es erfolgt eine Mehr-/Minderkilometerabrechnung auf Grundlage des o. g.
											Kilometerstandes. Diese tatsächliche Fahrleistung wird der sich zum Ankauftermin ergebenden „Soll-Fahrleistung"
											gemäß
											Leasingvertrag gegenübergestellt und zu den vereinbarten Cent-Sätzen abgerechnet. Bei Technik-Service mit „offener
											Abrechnung"
											erfolgt die Abrechnung des Kosten-/Erlös-Saldos innerhalb von acht Wochen nach Verkauf des Fahrzeuges.
											<br />
											<br />
											Sollte der geschlossene Technik-Service Vertragsbestandteil sein, ist ab sofort vor jeder weiteren Reparatur die
											Zustimmung
											der
											ALD einzuholen, da jede weitere Reparatur den Verkaufspreis gegebenenfalls noch verändern kann.
											<br />
											<br />
											Für Rückfragen stehen wir Ihnen gern zur Verfügung.
										</Box>
									</TileContent>
									<TileActions>
										<ActionButton
											variant="text"
											hideOnDisabled
											color="secondary"
											title="Angebot ablehnen"
											onClick={denyOffer}
										>
											Angebot ablehnen
										</ActionButton>
										<ActionButton
											variant="text"
											hideOnDisabled
											color="primary"
											title="Angebot annehmen"
											onClick={acceptOffer}
										>
											Angebot annehmen
										</ActionButton>
									</TileActions>
								</>
							)}
						</Grid>
					)}
					<Grid item xs={12}>
						<Box maxWidth="700px">
							<Tile>
								<Box p={2}>
									<Typography variant="h6">Ihre Angaben vom {moment(props.customerOrder.dateCompleted).format(dateFormats.date)}</Typography>
								</Box>
								<Divider />
								<TileContent>
									{!pricingRequest && (
										<CircularProgress size={48} />
									)}
									{pricingRequest && (
										<PricingRequestInformationOverview customerOrder={props.customerOrder} pricingRequest={pricingRequest} />
									)}
								</TileContent>
								<TileActions>
									<ActionButton
										variant="text"
										color="secondary"
										disabled={!canCancel}
										title="Stornieren"
										onClick={canCancel ? confirmCancellation : undefined}
									>
										Stornieren
									</ActionButton>
									<ActionButton
										variant="text"
										disabled={!canEdit}
										color="primary"
										title="Angaben anpassen"
										onClick={canEdit ? edit : undefined}
									>
										Angaben anpassen
									</ActionButton>
									<ActionButton
										variant="text"
										color="primary"
										title="Emailbenachrichtigung anpassen"
										onClick={handleEmailChange}
									>
										Emailbenachrichtigung anpassen
									</ActionButton>
								</TileActions>
							</Tile>
						</Box>
					</Grid>
				</Grid>
			)}
			{
				wantsToEdit && (
					<PricingRequestChoice
						toStatusOverview={stopEditing}
						backAction={stopEditing}
						updateCustomerOrder={props.updateCustomerOrder}
						customerOrder={props.customerOrder}
						formData={calculateFormData(pricingRequest, props.customerOrder)}
					/>
				)
			}
		</>
	)
};

export default PricingRequestStatusOverview;
