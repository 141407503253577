import React from "react";
import FormChoices from "../../../../Components/FormChoices";
import FormNumber from "../../../../Components/FormNumber";
import FormText from "../../../../Components/FormText";
import { Form } from "../../../../system/useForm";

export default ({ form } : { form : Form }) => {
	return (
		<>
			<FormChoices
				name="previousDamages"
				label="Sind Unfallvorschäden bekannt?"
				choices={{
					"Yes": "Ja",
					"YesWithoutDetails": "Ja, aber Details unbekannt",
					"No": "Nein"
				}}
				form={form}
				options={{
					required: true
				}}
			/>
			{form.getValue("previousDamages") === "Yes" && (
				<>
					<FormNumber
						name="previousDamagesDetails.repairCosts"
						label="Reparaturkosten (€)"
						form={form}
						options={{
							required: true
						}}
					/>
					<FormChoices
						name="previousDamagesDetails.insuranceType"
						label="Versicherungsart"
						form={form}
						choices={{
							"PartialCoverage": "Teilkasko",
							"Comprehensive": "Vollkasko",
							"Liability": "Haftpflicht"
						}}
						options={{
							required: true
						}}
					/>
					<FormText
						name="previousDamagesDetails.insuranceAgency"
						label="Versicherung"
						form={form}
						options={{
							required: true
						}}
					/>
				</>
			)}
		</>
	);
};
