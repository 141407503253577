import { Check, Clear, CloudDownload, DoneAll, GetApp, Launch, Mail } from "@mui/icons-material";
import { Box, Button, useTheme } from "@mui/material";
import axios from "axios";
import TextBlock from "Components/Layout/TextBlock";
import ListItem from "Components/ListItem";
import ListItemAction from "Components/ListItemAction";
import ListItemActions from "Components/ListItemActions";
import ListItemChip from "Components/ListItemChip";
import ListItemChips from "Components/ListItemChips";
import ListItemContent from "Components/ListItemContent";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { BcaAuction, BcaAuctionStatus, ThemeColor } from "system/Domain";
import formatDateTime from "system/formatDateTime";
import formatNumber from "system/formatNumber";
import formatRelativeDate from "system/formatRelativeDate";
import { setWorkSession } from "system/reducers/workSessions/actions";
import useAction from "system/useAction";
import { downloadFile } from "../SaleOfferListItem";
import BcaAutionVehiclesChart from "./BcaAutionVehiclesChart";

interface Props {
	auction: BcaAuction;
}

export const formatBcaAuctionStatus = (status: BcaAuctionStatus) => {
	switch (status) {
		case "Closed":
			return "Geschlossen";
		case "Awarded":
			return "Ausgewertet";
		default:
			return status;
	}
};

export const paintBcaAuctionStatus = (status: BcaAuctionStatus): ThemeColor => {
	switch (status) {
		case "Closed":
			return "primary";
		case "Awarded":
			return "success";
		default:
			return "info";
	}
};

type Mode = "view" | "award" | "mail";

export default ({ auction }: Props) => {
	const [mode, setMode] = useState<Mode>("view");
	const dispatch = useDispatch();

	const downloadOrigin = () => {
		if (!auction.origin?.hash) {
			return;
		}

		downloadFile(auction.origin.hash);
	};

	const downloadResults = () => {
		if (!auction.results?.hash) {
			return;
		}

		downloadFile(auction.results.hash);
	};

	const startSession = async () => {
		const { data: workSession } = await axios.post(`/api/sales/bca/auctions/${auction.id}/work-sessions`);

		if (workSession) {
			dispatch(setWorkSession(workSession));
		}
	};

	const [awardAuction, awardAuctionRunning] = useAction(async () => {
		await axios.post(`/api/sales/bca/auctions/${auction.id}/award`, {});
		setMode("view");
	});

	const [mailAuction, mailAuctionRunning] = useAction(async () => {
		await axios.post(`/api/sales/bca/auctions/${auction.id}/mails`, {});
		setMode("view");
	});

	let color: ThemeColor = "info";
	if (mode === "award" || mode === "mail") {
		color = "primary";
	}

	const theme = useTheme();

	return (
		<ListItem color={color}>
			<ListItemActions>
				{mode === "view" && (
					<>
						<ListItemAction
							icon={<Launch />}
							tooltip="Verkäufe auswerten"
							onClick={startSession}
							disabled={auction.sales?.length === 0}
						/>
						<ListItemAction
							icon={<CloudDownload />}
							tooltip="Quelldatei runterladen"
							onClick={downloadOrigin}
							color="inherit"
						/>
						{auction.status === "Closed" && (
							<ListItemAction
								icon={<DoneAll />}
								tooltip="Auswertung abschließen"
								onClick={() => setMode("award")}
								disabled={auction.sales?.length === 0}
							/>
						)}
						{auction.status === "Awarded" && (
							<ListItemAction
								icon={<Mail />}
								tooltip="Auswertung versenden"
								onClick={() => setMode("mail")}
								disabled={!auction.results}
							/>
						)}
					</>
				)}
				{mode !== "view" && (
					<ListItemAction icon={<Clear />} tooltip="Abbrechen" onClick={() => setMode("view")} />
				)}
			</ListItemActions>
			<ListItemContent>
				<TextBlock
					flat
					primary={auction.name}
					secondary={`Importiert ${formatRelativeDate(auction.dateCreated)}`}
				/>

				{mode === "view" && (
					<>
						{!!auction.results && (
							<Box mt={2} pb={1}>
								<Button color="inherit" variant="text" onClick={downloadResults} size="small">
									{auction.results.filename}
								</Button>
							</Box>
						)}
						{auction.vehicles.length > 0 && (
							<Box mt={2}>
								<BcaAutionVehiclesChart auction={auction} />
							</Box>
						)}
					</>
				)}

				{mode === "award" && (
					<Box mt={3} mb={1} color={theme.palette.primary.light}>
						<Button
							color="inherit"
							variant="text"
							onClick={awardAuction}
							startIcon={<Check />}
							disabled={awardAuctionRunning}
						>
							Auswertung abschließen
						</Button>
					</Box>
				)}

				{mode === "mail" && (
					<Box mt={3} mb={1} color={theme.palette.primary.light}>
						<Button
							color="inherit"
							variant="text"
							onClick={mailAuction}
							startIcon={<Mail />}
							disabled={mailAuctionRunning}
						>
							Auswertung versenden
						</Button>
					</Box>
				)}

				<ListItemChips>
					<ListItemChip
						label={formatBcaAuctionStatus(auction.status)}
						color={paintBcaAuctionStatus(auction.status)}
					/>

					{auction.mails?.length > 0 && (
						<ListItemChip
							label={`Versendet am ${formatDateTime(auction.mails[auction.mails.length - 1].referenceCreated)}`}
						/>
					)}

					{auction.vehicles.length > 0 && (
						<ListItemChip label={`${formatNumber(auction.vehicles.length)} Ergebnisse`} />
					)}
					{auction.sales?.length > 0 && (
						<ListItemChip label={`${formatNumber(auction.sales.length)} synchronisierte Verkäufe`} />
					)}
				</ListItemChips>
			</ListItemContent>
		</ListItem>
	);
};
