
import FormElement from "./FormElement";
import { useTheme } from "@mui/material"
import { Signature } from "../system/Domain";
import { Box, Button } from "@mui/material";
import { Form, FormOptions } from "../system/useForm";
import { ReactNode, useEffect, useRef, useState } from "react";

// @ts-ignore
import SignatureCanvas from "react-signature-canvas";

const size = {
	width: 400,
	height: 200
};

export type FormSignatureProps = {
	name: string
	label: string | ReactNode
	form: Form
	options?: FormOptions
	onSign: (signature: Signature) => void
	onReset: () => void
	allowSign: boolean
	allowRemoval?: boolean
};

export default ({ name, label, form, options, onSign, onReset, allowSign, allowRemoval = true }: FormSignatureProps) => {
	const theme = useTheme();
	const signatureRef = useRef<any>(null);

	const value = form.getValue(name);
	const [formValue, setFormValue] = useState(value);
	if (formValue !== value) {
		setFormValue(value);
	}

	const [empty, setEmpty] = useState(!value);

	useEffect(() => {
		form.register(name, options);

		return () => {
			form.unregister(name);
		};
	}, [name]);

	useEffect(() => {
		if (!formValue || (!formValue.path && !formValue.image)) {
			signatureRef.current.clear();
			return;
		}

		if (formValue.path) {
			signatureRef.current.fromData(formValue.path);
		} else if (formValue.image) {
			signatureRef.current.fromDataURL(formValue.image);
		}
	}, [formValue]);

	const handleReset = () => {
		setFormValue(null);
		form.setValue(name, null, false);

		signatureRef.current.clear();
		setEmpty(true);
	};

	const handleSign = () => {
		const newValue = {
			path: signatureRef.current.toData(),
			image: signatureRef.current.toDataURL()
		};

		setFormValue(newValue);
		form.setValue(name, newValue, true);

		if (onSign) {
			onSign(newValue);
		}
	};

	const handleRemoveSignature = () => {
		handleReset();

		if (onReset) {
			onReset();
		}
	};

	const handleStrokeEnd = () => {
		setEmpty(signatureRef.current.isEmpty());
	};

	useEffect(() => {
		if (formValue) {
			signatureRef.current.off();
		} else {
			signatureRef.current.on();
		}
	}, [formValue]);

	return (
		<FormElement label={label}>
			<Box
				sx={{
					backgroundColor: theme.palette.background.paper,
					border: "1px solid rgba(0, 0, 0, 0.12)",
					borderRadius: 4,
					maxWidth: size.width,
					width: "100%",
					overflow: "hidden",
					...(formValue && { backgroundColor: "transparent" })
				}}>
				<SignatureCanvas
					ref={signatureRef}
					penColor={theme.palette.primary.dark}
					maxWidth={1.5}
					onEnd={handleStrokeEnd}
					canvasProps={{
						width: size.width,
						height: size.height
					}}
				/>
				{(!value || allowRemoval) && (
					<Box sx={{
						padding: theme.spacing(1),
						borderTop: "1px solid rgba(0, 0, 0, 0.12)",
						backgroundColor: theme.palette.background.default,
						justifyContent: "flex-end"
					}}>
						{!value && (
							<>
								<Button
									disabled={empty}
									sx={{
										marginRight: theme.spacing(0.5)
									}}
									color="secondary"
									onClick={handleReset}
								>Zurücksetzen</Button>

								<Button
									disabled={empty || !allowSign}
									sx={{
										marginRight: theme.spacing(0.5)
									}}
									color="primary"
									onClick={handleSign}
								>Unterschreiben</Button>
							</>
						)}
						{value && allowRemoval && (
							<>
								<Button
									sx={{
										marginRight: theme.spacing(0.5)
									}}
									color="secondary"
									disabled={!allowRemoval}
									onClick={handleRemoveSignature}
								>Unterschrift entfernen</Button>
							</>
						)}
					</Box>
				)}
			</Box>
		</FormElement>
	);
};
