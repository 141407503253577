import React from 'react';
import { useParams } from "react-router";
import useVehicle from "../../../system/useVehicle";
import LotScanContextMenu from "../LotScanContextMenu";
import VehicleHeaderMenu from "../VehicleHeaderMenu";
import VehicleSubMenu from "../VehicleSubMenu";
import Layout from "../../../Components/Layout/Layout";
import { CircularProgress, Grid } from "@mui/material";
import VehicleHeader from "../VehicleHeader";

const VehicleRegistrationDocumentHandover = () => {
	const { id } = useParams<{ id : string }>();
	const [vehicle] = useVehicle(id);

	return (
		<Layout
			contextMenu={[
				<LotScanContextMenu />,
				<VehicleHeaderMenu vehicle={vehicle} />
			]}
			subMenu={vehicle && <VehicleSubMenu vehicle={vehicle} />}
		>
			{!vehicle && (
				<CircularProgress size={48} />
			)}
			{vehicle && (
				<>
					<Grid container spacing={2} alignItems="flex-end" justifyContent="space-between">
						<Grid item>
							<VehicleHeader vehicle={vehicle} />
						</Grid>
						Test
					</Grid>
				</>
			)}
		</Layout>
	);
};

export default VehicleRegistrationDocumentHandover;
