import React from 'react';
import { VehicleRegistrationDocumentStatus } from "../../../system/Domain";
import { blue, green, lightBlue, orange } from "@mui/material/colors";
import { Chip } from "@mui/material";

interface Props {
	status: VehicleRegistrationDocumentStatus
}

export const translateVehicleRegistrationDocumentStatus = (status: VehicleRegistrationDocumentStatus | null | undefined): string => {
	switch (status) {
		case "Unknown":
			return "Unbekannt";
		case "Storage":
			return "Eingelagert";
		case "Requested":
			return "Abgefragt";
		case "Arrived":
			return "Angekommen";
		case "NotArrived":
			return "Nicht Angekommen";
		case "PartiallyArrived":
			return "Teilweise Angekommen";
		case "ReadyForHandOver":
			return "Versandbereit";
		case "HandedOver":
			return "Ausgegeben";
	}

	return "Unbekannt";
};

const statusSettings = {
	"Unknown": {
		label: translateVehicleRegistrationDocumentStatus("Unknown"),
		backgroundColor: blue[500]
	},
	"Storage": {
		label: translateVehicleRegistrationDocumentStatus("Storage"),
		backgroundColor: blue[500]
	},
	"Requested": {
		label: translateVehicleRegistrationDocumentStatus("Requested"),
		backgroundColor: lightBlue[500]
	},
	"Arrived": {
		label: translateVehicleRegistrationDocumentStatus("Arrived"),
		backgroundColor: green[500]
	},
	"PartiallyArrived": {
		label: translateVehicleRegistrationDocumentStatus("PartiallyArrived"),
		backgroundColor: orange[500]
	},
	"NotArrived": {
		label: translateVehicleRegistrationDocumentStatus("NotArrived"),
		backgroundColor: orange[500]
	},
	"ReadyForHandOver": {
		label: translateVehicleRegistrationDocumentStatus("ReadyForHandOver"),
		backgroundColor: green[500]
	},
	"HandedOver": {
		label: translateVehicleRegistrationDocumentStatus("HandedOver"),
		backgroundColor: green[500]
	}
}

const VehicleRegistrationDocumentsStatusPill = (props: Props) => {
	const setting = statusSettings[props.status];

	return (
		<Chip
			label={setting.label}
			size="small"
			style={{ backgroundColor: setting.backgroundColor }}
		/>
	);
};

export default VehicleRegistrationDocumentsStatusPill;
