import React, { useEffect, useState } from "react";
import { CarType, CompoundReference, CustomerOrder, DeliveryType, EngineType } from "../../../system/Domain";
import CustomerStepper, { Step, StepProps } from "../CustomerStepper";
import useForm from "../../../system/useForm";
import StepDeliveryInstructions from "../Steps/StepDeliveryInstructions";
import StepDelivery from "../Steps/StepDelivery";
import StepCollectionFinalRemarks from "../Steps/StepCollectionFinalRemarks";
import StepCarState from "../Steps/StepCarState";
import axios from "axios";
import { useRecoilState } from "recoil";
import { customerTitle } from "../../../system/atoms/customerTitle";

interface Props {
	customerOrder: CustomerOrder;
	toStatusOverview: () => void;
	backAction: () => void;
	formData?: CustomerDeliveryFormType;
}

const steps: Step[] = [
	{
		id: 1,
		title: "Anleitung",
		ui: (props: StepProps) => <StepDeliveryInstructions {...props} />,
	},
	{
		id: 2,
		title: "Allgemeine Fahrzeuginformationen",
		ui: (props: StepProps) => <StepCarState {...props} />,
	},
	{
		id: 3,
		title: "Anlieferung Dorfmark",
		ui: (props: StepProps) => <StepDelivery {...props} />,
	},
	{
		id: 4,
		title: "Anlieferungshinweise",
		ui: (props: StepProps) => <StepCollectionFinalRemarks {...props} />,
	},
];

export interface CustomerDeliveryFormType {
	plateNumber: string;
	contractNumber: number;
	isElectricCar?: boolean;
	isDrivable?: boolean;
	drivableComment?: string;
	isRegistered?: boolean;
	hasValidMainInspection?: boolean;
	mainInspectionValidUntil?: string;
	tireType?: string;
	carType?: CarType;
	delivery?: {
		deliveryType?: DeliveryType;
		compound?: CompoundReference;
		comment?: string;
		appointment?: {
			notBefore?: string;
			from?: string;
			to?: string;
		};
	};
	engineType?: EngineType;
}

const CustomerDeliveryChoice = (props: Props) => {
	const [, setCustomerTitle] = useRecoilState(customerTitle);
	const [customerForm, setCustomerForm] = useState<CustomerDeliveryFormType>(
		props.formData ?? {
			plateNumber: props.customerOrder.vehicle.plateNumber,
			contractNumber: props.customerOrder.leasmanContract?.id ?? 0,
			engineType: props.customerOrder.engineType,
			isDrivable: props.customerOrder.engineType === "Electric" ? false : undefined,
			delivery: {
				deliveryType: DeliveryType.CustomerDelivery,
			},
		},
	);

	const editMode = !!props.customerOrder.returnOrderId;

	useEffect(() => setCustomerTitle(editMode ? "Anlieferung bearbeiten" : "Anlieferung vereinbaren"), []);

	const form = useForm({
		values: customerForm,
		setValues: setCustomerForm,
	});

	const updateCall = async () => {
		await axios.put(`/api/orders/customers/${props.customerOrder.id}/delivery`, customerForm);
	};

	const createCall = async () => {
		await axios.post(`/api/orders/customers/${props.customerOrder.id}/delivery`, customerForm);
	};

	return (
		<CustomerStepper
			customerOrder={props.customerOrder}
			steps={steps}
			backAction={props.backAction}
			editMode={editMode}
			toStatusOverview={props.toStatusOverview}
			update={updateCall}
			create={createCall}
			form={form}
			isCustomerDelivery
		/>
	);
};

export default CustomerDeliveryChoice;
