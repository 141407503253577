import React from "react";
import FormChoices from "./FormChoices";
import { Form } from "../system/useForm";
import { RimType, TireType } from "../system/Domain";

export type FormTiresAndRimsProps = {
	name : string
	form : Form
	canHaveNoRims? : boolean
	disabled? : boolean
}

export const formatTireType = (type? : TireType | null, emptyPlaceholder : string = "-") => {
	if (!type) {
		return emptyPlaceholder;
	}

	switch (type) {
		case "Summer":
			return "Sommer";
		case "Winter":
			return "Winter";
		case "AllSeason":
			return "All-Season";
		default:
			return type;
	}
};

export const formatRimType = (type? : RimType | null, emptyPlaceholder : string = "-") => {
	if (!type) {
		return emptyPlaceholder;
	}

	switch (type) {
		case "None":
			return "Keine";
		case "Unknown":
			return "Unbekannt";
		case "Steel":
			return "Stahl-Felgen";
		case "Aluminium":
			return "Alu-Felgen";
		default:
			return type;
	}
};

export default ({ name, form, canHaveNoRims = false, disabled = undefined } : FormTiresAndRimsProps) => {
	let choices : any = {
		"Steel": formatRimType("Steel"),
		"Aluminium": formatRimType("Aluminium"),
	};

	if (canHaveNoRims) {
		choices = {
			...choices,
			"Unknown": formatRimType("Unknown"),
			"None": formatRimType("None"),
		};
	}

	return (
		<>
			<FormChoices
				name={`${name}.tireType`}
				label="Reifenart"
				choices={{
					"Summer": formatTireType("Summer"),
					"Winter": formatTireType("Winter"),
					"AllSeason": formatTireType("AllSeason"),
				}}
				form={form}
				disabled={disabled}
				options={{
					required: true
				}}
			/>
			<FormChoices
				name={`${name}.rimType`}
				label="Felgenart"
				choices={choices}
				form={form}
				disabled={disabled}
				options={{
					required: true
				}}
			/>
		</>
	);
};
