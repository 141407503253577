import Sidebar from "../../../Components/Sidebar/Sidebar";
import SidebarGroup from "../../../Components/Sidebar/SidebarGroup";
import SidebarButton from "../../../Components/Sidebar/SidebarButton";
import showMessage from "../../../Dialogs/showMessage";
import { useHistory } from "react-router";
import { UserRole, Vehicle, VehiclePictureSet } from "../../../system/Domain";
import React, { Dispatch, SetStateAction, useState } from "react";
import useAsyncEffect from "../../../system/useAsyncEffect";
import axios from "axios";
import useUser from "../../../system/useUser";
import useCompanies from "../../../system/useCompanies";
import parseContentDispositionFilename from "../../../system/parseContentDispositionFilename";
import fileDownload from "js-file-download";
import prompt from "../../../Dialogs/prompt";
import ask from "../../../Dialogs/ask";
import { HighlightOff } from "@mui/icons-material";
import { FormControl, Grid, NativeSelect } from "@mui/material";
import moment from "moment/moment";
import TextBlock from "../../../Components/Layout/TextBlock";
import Chip from "../../../Components/Chip";

interface Props {
	vehicle: Vehicle;
	currentPictureSet: VehiclePictureSet | null;
	setVehicle: Dispatch<SetStateAction<Vehicle>>;
	setCurrentPictureSet: Dispatch<SetStateAction<VehiclePictureSet>>;
	replaceMode: boolean;
	setReplaceMode: Dispatch<SetStateAction<boolean>>;
	handleUploadUpdatedPictureSet: () => void;
	updatedSets: string[];
}

const VehiclePictureSetsSidebar = (props: Props) => {
	const history = useHistory();
	const [companies] = useCompanies();

	const [canAddPictures, setCanAddPictures] = useState(false);
	const [downloading, setDownloading] = useState(false);

	const [user, , hasRole] = useUser();

	const userCanGenerate = hasRole(UserRole.AldManager);
	const userCanReject = hasRole(UserRole.AldManager);

	const isUploaded = Boolean(props.currentPictureSet?.dateUploaded);
	const isValid = !props.currentPictureSet?.dateRejected;

	useAsyncEffect(async () => {
		if (!props.vehicle) {
			return;
		}

		const hasTestPermissions = companies.find((f) => f.name == user.company?.name)?.testPermissions;

		if (hasTestPermissions) {
			setCanAddPictures(true);
			return;
		}

		const { data: numberOfOpenPictureServices } = await axios.get<number>(`/api/vehicles/services`, {
			params: {
				vehicleId: props.vehicle.id,
				serviceTypeId: "Picture",
				isOpen: true,
				countOnly: true,
			},
		});

		setCanAddPictures(numberOfOpenPictureServices !== 0);
	}, [props.vehicle?.id]);

	const handleAddPictureSet = async () => {
		if (!canAddPictures) {
			await showMessage({
				message:
					"Für dieses Fahrzeug gibt es keine offene Fototätigkeit. Ohne angefragte Tätigkeit kann kein neuer Fotosatz hinzugefügt werden.",
				title: "Fehler",
			});

			return;
		}

		history.push(`/vehicles/${props.vehicle.id}/picture-sets/new`);
	};

	const handleGeneratedPictureSetDeletion = async () => {
		if (!props.currentPictureSet?.dateGenerated) return;

		const response = await axios.delete<Vehicle>(
			`/api/vehicles/${props.vehicle!.id}/picture-sets/${props.currentPictureSet.id}`,
		);

		props.setVehicle(response.data);
	};

	const handleGeneratePictureSet = async () => {
		if (props.currentPictureSet) return;

		const response = await axios.post<Vehicle>(`/api/vehicles/${props.vehicle!.id}/picture-sets/generate`);

		props.setVehicle(response.data);
	};

	const handleDownloadPictureSet = async () => {
		if (!props.currentPictureSet) return;

		setDownloading(true);
		try {
			const response = await axios.get(
				`/api/vehicles/${props.vehicle!.id}/picture-sets/${props.currentPictureSet.id}?format=zip`,
				{
					responseType: "blob",
				},
			);

			const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
			fileDownload(response.data, `${props.vehicle!.vin}.zip` || filename);
		} finally {
			setDownloading(false);
		}
	};

	const handleMovePictureSet = async () => {
		if (!props.currentPictureSet) return;

		const result = await prompt("Bitte die Fahrgestellnummer des Zielfahrzeug eingeben");
		if (result) {
			const { data: newVehicle } = await axios.post(
				`/api/vehicles/${props.vehicle!.id}/picture-sets/${props.currentPictureSet.id}/move`,
				{
					vin: result,
				},
			);
			history.push(`/vehicles/${newVehicle!.id}/picture-sets`);
		}
	};

	const handleRejectPictureSet = async () => {
		if (!props.currentPictureSet) return;

		const result = await ask("Soll das Fotoset wirklich reklamiert werden?");
		if (result.yes) {
			const { data: newVehicle } = await axios.post(
				`/api/vehicles/${props.vehicle!.id}/picture-sets/${props.currentPictureSet.id}/reject`,
			);
			props.setVehicle(newVehicle);
		}
	};

	const handleResubmit = async () => {
		const result = await ask("Soll der Fotosatz nochmal übertragen werden?");
		if (result.yes) {
			const response = await axios.put<VehiclePictureSet>(
				`/api/vehicles/${props.vehicle!.id}/picture-sets/${props.currentPictureSet!.id}`,
				{
					dateUploaded: null,
				},
			);
			props.setCurrentPictureSet(response.data);
		}
	};

	const handleWithdrawAssessmentRejection = async () => {
		if (!props.vehicle || !props.currentPictureSet) return;

		const { data: newVehicleAssessment } = await axios.post<VehiclePictureSet>(
			`/api/vehicles/${props.vehicle!.id}/picture-sets/${props.currentPictureSet!.id}/withdraw-rejection`,
		);

		props.setCurrentPictureSet(newVehicleAssessment);
	};

	const handlePictureSetChange = (event: React.ChangeEvent<{ name: string; value: string }>) => {
		const currentPictureSet = props.vehicle?.pictureSets.find((ps) => ps.date === event.target.value);
		props.setCurrentPictureSet(currentPictureSet || null);
	};

	const pictureSets = props.vehicle?.pictureSets || [];

	return (
		<Sidebar>
			<SidebarGroup>
				<SidebarButton color="primary" label="Neuer Fotosatz" onClick={handleAddPictureSet} />
			</SidebarGroup>
			{props.vehicle && props.currentPictureSet && (
				<SidebarGroup>
					<Grid container spacing={2}>
						{props.currentPictureSet && pictureSets.length > 1 && (
							<Grid item>
								<Grid container spacing={1} alignItems="center" justifyContent="space-between">
									<Grid item>
										<FormControl variant="outlined">
											<NativeSelect
												value={props.currentPictureSet.date}
												onChange={handlePictureSetChange}
											>
												{pictureSets.map((ps) => (
													<option key={ps.date} value={ps.date}>
														Fotosatz vom {moment(ps.date).format("DD.MM.YYYY")}
													</option>
												))}
											</NativeSelect>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
						)}
						<Grid item>
							<TextBlock
								primary={moment(props.currentPictureSet?.date).format("LLL")}
								secondary="Erstellt am"
							/>
						</Grid>
						<Grid item>
							<TextBlock
								primary={
									<>
										{props.currentPictureSet.dateUploaded &&
											moment(props.currentPictureSet?.dateUploaded).format("LLL")}
										{!props.currentPictureSet.dateUploaded && (
											<>Fotos wurden noch nicht hochgeladen</>
										)}
									</>
								}
								secondary="Übertragen am"
							/>
						</Grid>
						{props.currentPictureSet.dateRejected && (
							<Grid item>
								<TextBlock
									secondary={moment(props.currentPictureSet?.dateRejected).format("LLL")}
									primary={<Chip label="Reklamiert" color="secondary" />}
								/>
							</Grid>
						)}
					</Grid>
				</SidebarGroup>
			)}
			{props.vehicle && props.currentPictureSet && (
				<SidebarGroup>
					<SidebarButton
						color={props.replaceMode ? "error" : "primary"}
						startIcon={props.replaceMode ? <HighlightOff /> : undefined}
						label={props.replaceMode ? "Aktualisierung abbrechen" : "Aktualisierung starten"}
						onClick={() => props.setReplaceMode((r) => !r)}
						disabled={downloading}
						useBusyWheelWhen={downloading}
					/>
					<SidebarButton
						color="primary"
						label="Fotosatz aktualisieren"
						onClick={props.handleUploadUpdatedPictureSet}
						disabled={!props.updatedSets.includes(props.currentPictureSet.id) || downloading}
						useBusyWheelWhen={downloading}
					/>
				</SidebarGroup>
			)}
			{props.vehicle && userCanGenerate && (
				<SidebarGroup>
					<SidebarButton
						color="secondary"
						label="Fotosatz generieren"
						onClick={handleGeneratePictureSet}
						disabled={props.vehicle.pictureSets.length > 0}
					/>

					{props.currentPictureSet?.dateGenerated && (
						<SidebarButton
							color="secondary"
							onClick={handleGeneratedPictureSetDeletion}
							label="generierten Fotosatz löschen"
						/>
					)}
				</SidebarGroup>
			)}
			{props.currentPictureSet && (
				<SidebarGroup>
					{isUploaded && (
						<SidebarButton
							label="Erneut übertragen"
							color="secondary"
							onClick={handleResubmit}
							disabled={downloading}
							useBusyWheelWhen={downloading}
						/>
					)}
					{isValid && (
						<SidebarButton
							label="Verschieben"
							color="secondary"
							onClick={handleMovePictureSet}
							disabled={downloading}
							useBusyWheelWhen={downloading}
						/>
					)}
					{isValid && userCanReject && (
						<SidebarButton
							label="Reklamieren"
							color="secondary"
							onClick={handleRejectPictureSet}
							disabled={downloading}
							useBusyWheelWhen={downloading}
						/>
					)}
					{props.currentPictureSet.dateRejected && (
						<SidebarButton
							label="Reklamation rückgänging machen"
							color="secondary"
							onClick={handleWithdrawAssessmentRejection}
							disabled={downloading}
							useBusyWheelWhen={downloading}
						/>
					)}
				</SidebarGroup>
			)}
			{props.currentPictureSet && (
				<SidebarGroup>
					<SidebarButton
						label="Download"
						color="info"
						onClick={handleDownloadPictureSet}
						disabled={downloading}
						useBusyWheelWhen={downloading}
					/>
				</SidebarGroup>
			)}
		</Sidebar>
	);
};

export default VehiclePictureSetsSidebar;
