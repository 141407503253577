import React from "react";
import FormChoices from "../../../../Components/FormChoices";
import FormOkNotOk from "../../../../Components/FormOkNotOk";
import FormYesNo from "../../../../Components/FormYesNo";
import { Form } from "../../../../system/useForm";
import FormUpload from "../../../../Components/FormUpload";

export default ({ form } : { form : Form }) => {
	return (
		<>
			<FormOkNotOk
				name="readiness.oilLevel"
				label="Ölstand"
				form={form}
				options={{
					required: true
				}}
			/>
			<FormOkNotOk
				name="readiness.coolant"
				label="Kühlwasser"
				form={form}
				options={{
					required: true
				}}
			/>
			<FormYesNo
				name="readiness.warningIndicator"
				label="Warnanzeige aktiv"
				form={form}
				options={{
					required: true
				}}
			/>
			<FormYesNo
				name="readiness.startAttempt"
				label="Startversuch erfolgreich"
				form={form}
				options={{
					required: true
				}}
			/>
			<FormOkNotOk
				name="readiness.lighting"
				label="Beleuchtung"
				form={form}
				options={{
					required: true
				}}
			/>
			<FormChoices
				name="readiness.fuelLevel"
				label="Tankinhalt"
				choices={{
					"Empty": "Leer",
					"OneQuarter": "1/4",
					"Half": "1/2",
					"ThreeQuarters": "3/4",
					"Full": "Voll"
				}}
				form={form}
				options={{
					required: true
				}}
			/>
			<FormUpload
				name="readiness.fuelLevelPictureProof"
				label="Tankinhalt Foto"
				form={form}
				options={{
					required: true
				}}
			/>
			<FormYesNo
				name="readiness.validInspections"
				label="HU / AU gültig"
				form={form}
				options={{
					required: true
				}}
			/>
			<FormYesNo
				name="readiness.inspectionReports"
				label="Prüfberichte vorhanden"
				form={form}
				options={{
					required: true
				}}
			/>
			<FormYesNo
				name="readiness.correctVin"
				label="Korrekte FIN"
				form={form}
				options={{
					required: true
				}}
			/>
		</>
	);
};
