import { Box } from "@mui/material";
import TileActions from "Components/Tiles/TileActions";
import CostsTextGroup from "Components/Vehicles/CostsTextGroup";
import DriveTextGroup from "Components/Vehicles/DriveTextGroup";
import HighlightsTextGroup from "Components/Vehicles/HighlightsTextGroup";
import ModelTextGroup from "Components/Vehicles/ModelTextGroup";
import TechnicalDetailsTextGroup from "Components/Vehicles/TechnicalDetailsTextGroup";
import moment from "moment";
import { Dispatch, SetStateAction } from "react";
import useUser from "system/useUser";
import { formatRimType, formatTireType } from "../../Components/FormTiresAndRims";
import { formatYesNo } from "../../Components/LeasmanContract";
import Tile from "../../Components/Tiles/Tile";
import TileContent from "../../Components/Tiles/TileContent";
import {
	AssessmentType,
	LeasmanVehicle,
	ModelKind,
	Price,
	PriceWithRanges,
	Sale,
	ThemeColor,
	UserRole,
	Vehicle,
	VehicleAssessment,
	WheelDrive,
} from "../../system/Domain";
import formatCurrency from "../../system/formatCurrency";
import formatDate from "../../system/formatDate";
import formatDateTime from "../../system/formatDateTime";
import formatNumber from "../../system/formatNumber";
import formatPercentage from "../../system/formatPercentage";
import { formatRepairTask } from "../Vehicles/CarglassRepairOrderListItem";
import ColoredBox from "./ColoredBox";
import SaleCancelActions from "./SaleCancelActions";
import { formatSaleStatus, paintSaleStatus } from "./SaleListItem";
import TextGroup from "./TextGroup";
import TextLine from "./TextLine";

interface Props {
	sale: Sale;
	setSale: Dispatch<SetStateAction<Sale>>;
	vehicle: Vehicle;
	leasmanVehicle?: LeasmanVehicle;
	assessment?: VehicleAssessment;
}

const formatModelKind = (kind: ModelKind) => {
	switch (kind) {
		case "PassengerCar":
			return "PKW";
		case "Transporter":
			return "Transporter";
		case "OffRoadVehicle":
			return "Geländewagen";
		default:
			return "-";
	}
};

export const formatWheelDrive = (wheelDrive: WheelDrive | null) => {
	switch (wheelDrive) {
		case "All":
			return "Allrad";
		case "Front":
			return "Vorderradantrieb";
		case "Back":
			return "Hinterradantrieb";
		case "Switchable":
			return "Allrad zuschaltbar";
		default:
			return "-";
	}
};

const formatPrice = (price: Price) => formatCurrency(price?.grossAmount);

export const formatPriceWithRanges = (priceWithRanges?: PriceWithRanges) => {
	if (!priceWithRanges || !priceWithRanges.grossAmount || !priceWithRanges.grossAmountRange) {
		return "-";
	}

	const range = priceWithRanges.grossAmountRange.upper - priceWithRanges.grossAmount;
	const percentage = (range / priceWithRanges.grossAmount) * 100;

	let color: ThemeColor = "info";
	if (percentage > 0 && percentage <= 5) {
		color = "success";
	} else if (percentage > 5 && percentage <= 8.5) {
		color = "warning";
	} else if (percentage > 8.5) {
		color = "error";
	}

	return (
		<Box>
			{formatCurrency(priceWithRanges.grossAmount)}
			<ColoredBox component="span" color={color} pl={2}>
				±{formatCurrency(range)}
			</ColoredBox>
		</Box>
	);
};

const formatAssessmentType = (type: AssessmentType | undefined) => {
	if (!type) {
		return "-";
	}

	return type;
};

export default ({ sale, setSale, vehicle, leasmanVehicle, assessment }: Props) => {
	const [, , hasRole] = useUser();
	const type = sale.vehicle.type;
	const exitDate = moment(vehicle.history.currentStockDateRange?.to || undefined);
	const soldDate = moment(sale.dateSold || undefined);

	const historyEntry =
		vehicle.history.entries.length > 0 ? vehicle.history.entries[vehicle.history.entries.length - 1] : null;

	return (
		<Tile title="Informationen">
			<TileContent>
				<TextGroup title="Allgemein">
					<Box>
						<TextLine
							label="Standort"
							value={`${sale.salesLocation} - ${historyEntry?.processStep.status}`}
						/>
						<TextLine label="Erstelldatum" value={formatDateTime(sale.dateCreated)} />
						<TextLine label="Hersteller" value={type?.make?.name} />
						<TextLine label="Modell" value={type?.model?.name} />
						<TextLine
							bold
							color={sale.assessment?.type === "Restwertgutachten" ? "secondary" : undefined}
							label="Gutachtentyp"
							value={formatAssessmentType(sale.assessment?.type)}
						/>
					</Box>
					<Box mt={1}>
						<TextLine label="EKZGV" value={formatCurrency(sale.ekzgv)} />
						<TextLine label="Händlereinkaufspreis" value={formatCurrency(sale.market1Price)} />
						<TextLine label="Händlerverkaufspreis" value={formatCurrency(sale.market2Price)} />
						<TextLine label="Unfallschaden" value={formatYesNo(sale.hadAccident)} />
						<TextLine label="Schäden" value={formatCurrency(sale.assessment?.totalDamageAmount)} />
						<TextLine label="Minderwerte" value={formatCurrency(sale.totalDepreciationAmount)} />
						<TextLine label="Reparaturkosten" value={formatCurrency(sale.totalRefurbishmentCosts)} />
						<TextLine
							label="Praiceman v3 Carmarket"
							value={formatPriceWithRanges(sale.praicemanPrediction?.carmarket)}
						/>
						<TextLine
							label="Praiceman v3 Retail"
							value={formatPriceWithRanges(sale.praicemanPrediction?.retail)}
						/>
						<TextLine
							label="Standtage Dorfmark"
							value={formatNumber(exitDate.diff(moment(sale.returnDate), "d") + 1)}
						/>
						<TextLine
							label="Standtage Palbs"
							value={formatNumber(
								sale.contract?.dateStopped
									? soldDate.diff(moment(sale.contract.dateStopped), "d") + 1
									: undefined,
							)}
						/>
						<TextLine label="UPE" value={formatPrice(type?.costs?.totalPrice)} />
						<TextLine label="Anzahl Vorbesitzer" value={formatNumber(sale.previousOwners)} />
					</Box>
					<Box mt={1}>
						<TextLine
							label="Quelle"
							value={sale.source}
							color={sale.source === "Carlos" ? "success" : "info"}
						/>
						<TextLine
							label="Status"
							value={formatSaleStatus(sale)}
							color={paintSaleStatus(sale)}
						/>
						<TextLine
							label="Höchstgebot"
							value={
								!sale.highestBid?.price
									? "-"
									: `${formatCurrency(sale.highestBid.price)} (${formatPercentage(
											sale.highestBid.price / type?.costs?.totalPrice.grossAmount,
										)})`
							}
						/>
						<TextLine label="FIN" value={vehicle.vin} />
						<TextLine label="Schwacke Code" value={type?.id} />
						<TextLine
							label="Kilometerstand"
							value={formatNumber(sale?.returnMileage || vehicle.remarketing.mileage)}
						/>
						<TextLine label="Erstzulassung" value={formatDate(vehicle.dateFirstRegistered)} />
						<TextLine
							label="Eingang in Dorfmark"
							value={formatDate(vehicle.history.currentStockDateRange?.from)}
						/>
						<TextLine
							label="Frontscheibe"
							color={vehicle.remarketing.hasWindshieldDamage ? "warning" : "info"}
							value={vehicle.remarketing.hasWindshieldDamage ? "Beschädigt" : "Nicht beschädigt"}
						/>
						<TextLine
							label="Frontscheibe Reparatur"
							color={
								vehicle.remarketing.windshieldDamage?.repairWork.description === "None"
									? "info"
									: "warning"
							}
							value={formatRepairTask(vehicle.remarketing.windshieldDamage?.repairWork)}
						/>
						<TextLine
							label="Austauschmotor"
							color={sale.hadEngineReplacement ? "warning" : "info"}
							value={formatYesNo(sale.hadEngineReplacement)}
						/>
						{sale.hadEngineReplacement && (
							<TextLine label="KM-Stand bei Tausch" value={formatNumber(sale.engineReplacementMileage)} />
						)}
						<TextLine label="Weitere Reifen" value={formatYesNo(sale.hasAdditionalTires)} />
						{sale.hasAdditionalTires && !!sale.tiresAndRims?.tireType && (
							<TextLine label="Reifenart" value={formatTireType(sale.tiresAndRims.tireType)} />
						)}
						{sale.hasAdditionalTires && !!sale.tiresAndRims?.rimType && (
							<TextLine label="Felgenart" value={formatRimType(sale.tiresAndRims.rimType)} />
						)}
					</Box>
				</TextGroup>
				<ModelTextGroup type={type} assessment={assessment} leasmanVehicle={leasmanVehicle} />
				<TechnicalDetailsTextGroup type={type} />
				<DriveTextGroup type={type} />
				<CostsTextGroup type={type} leasmanVehicle={leasmanVehicle} />
				<HighlightsTextGroup highlights={vehicle.remarketing.highlights} previousOwners={sale.previousOwners} />
			</TileContent>
			{hasRole(UserRole.SalesAdmin) && (
				<TileActions>
					<SaleCancelActions sale={sale} setSale={setSale} />
				</TileActions>
			)}
		</Tile>
	);
};
