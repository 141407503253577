import React from "react";
import { Grid, GridProps } from "@mui/material";
import FormDateTimePicker from "../../../Components/FormDateTimePicker";
import FormYesNo from "../../../Components/FormYesNo";
import FormText from "../../../Components/FormText";
import FormNumber from "../../../Components/FormNumber";

interface Props extends GridProps {
	form : any
}

const SalesOrderCommercialGridItem = ({ form, ...rest } : Props) => (
	<Grid {...rest}>
		<Grid container spacing={4}>
			<FormYesNo
				label="Fahrzeug hat Origianlmotor"
				form={form}
				name="hasOriginalMotor"
			/>
			<FormNumber
				label="Kilometerstand des Austauschmotors"
				form={form}
				name="replacementMotorMileage"
			/>
			<FormText
				label="Schäden"
				fullWidth
				form={form}
				name="damages"
			/>
			<Grid item>
				<FormDateTimePicker
					label="Datum der Angebotsliste"
					form={form}
					name="dateOfferList"
					variant="date"
					format="DD.MM.YYYY"
				/>
			</Grid>
			<FormNumber
				label="Minderwerte"
				form={form}
				name="damageAmount"
			/>
			<FormYesNo
				label="War das Fahzeug als Taxi zugelassen"
				form={form}
				name="wasTaxi"
			/>
		</Grid>
	</Grid>
);

export default SalesOrderCommercialGridItem;
