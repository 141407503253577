import React, { useEffect, useRef, useState } from "react";
import { Card } from "@mui/material";
import { CardProps } from "@mui/material/Card/Card";
import useUser from "../../system/useUser";
import { UserRole } from "../../system/Domain";

interface Props extends CardProps {
	debounce?: boolean
	duration?: number
	roles? : UserRole[]
}

const debounce = (fn:()=>void, ms:number) => {
	let timer:any;
	return () => {
		clearTimeout(timer)
		timer = setTimeout(() => {
			timer = null;
			// @ts-ignore
			fn.apply(this, arguments);
		}, ms)
	};
};

export default ({roles, children, duration, debounce:useDebounce, ...rest}:Props) => {
	const [, setWindowWidth] = useState<number>();
	const [height, setHeight] = useState<string>("0");
	const ref = useRef(null);
	const [user] = useUser();

	const handleResize = useDebounce ? () => debounce(() => {
			setWindowWidth(window.innerWidth);
		}, duration ?? 25) : () => {setWindowWidth(window.innerWidth);};

	if (roles && !user) return null;
	if (roles && user && !user.roles.some(r => roles.includes(r))) return null;

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		return () => { window.removeEventListener('resize', handleResize)};
	});

	useEffect(() => {
		if (ref)
		{ // @ts-ignore
			setHeight(ref.current.clientWidth);
		}
	});

	return (
		<Card ref={ref} style={{"height": height}} {...rest}>
			{children}
		</Card>
	);
}
