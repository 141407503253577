import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import TextBlock from "../../../Components/Layout/TextBlock";
import PaperBox from "../../../Components/PaperBox";
import formatNumber from "../../../system/formatNumber";
import { JobProps } from "./JobLayout";
import JobStatus from "./JobStatus";

const maxEntries = 3;

export default ({ job }: JobProps) => {
	const [expanded, setExpanded] = useState(false);

	let invocations = [...job.invocations];

	if (["Created", "Scheduled", "Running"].some((s) => s === job.status)) {
		invocations.push({
			id: "current",
			status: job.status,
			dateCreated: job.dateScheduled || job.dateCreated,
			worker: job.worker,
		});
	}

	invocations = invocations.reverse();

	const useExpansion = invocations.length > maxEntries + 2;

	let entries = invocations;
	if (useExpansion && !expanded) {
		entries = entries.slice(0, maxEntries);
	}

	return (
		<PaperBox>
			<Grid container spacing={3}>
				{entries.map((invocation, i) => {
					const status = (
						<JobStatus job={job} status={invocation.status} statusDate={invocation.dateCreated} />
					);

					const info = (
						<>
							<Box mb={1}>
								<TextBlock primary={invocation.message || invocation.status} />
							</Box>
							{invocation.exception && (
								<Box mb={1}>
									<Typography variant="caption" component="pre">
										{invocation.exception}
									</Typography>
								</Box>
							)}
							{invocation.worker && (
								<Box mb={1}>
									<Typography variant="caption">Worker: {invocation.worker}</Typography>
								</Box>
							)}
						</>
					);

					return (
						<React.Fragment key={i}>
							{i > 0 && (
								<Grid item xs={12}>
									<Divider />
								</Grid>
							)}
							<Grid item xs={12} md={3}>
								{status}
							</Grid>
							<Grid item xs={12} md={9}>
								{info}
							</Grid>
						</React.Fragment>
					);
				})}
				{useExpansion && (
					<>
						<Grid item xs={12}>
							<Divider />
							<Box mt={2.25} display="flex" alignContent="center">
								{!expanded && (
									<Button
										variant="text"
										size="small"
										color="primary"
										onClick={() => setExpanded(true)}
									>
										{formatNumber(invocations.length - entries.length)} weitere Einträge anzeigen
									</Button>
								)}
								{expanded && (
									<Button
										variant="text"
										size="small"
										color="primary"
										onClick={() => setExpanded(false)}
									>
										Weniger Einträge anzeigen
									</Button>
								)}
							</Box>
						</Grid>
					</>
				)}
			</Grid>
		</PaperBox>
	);
};
