import { Box, useTheme } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import useGoogleMaps from "system/useGoogleMaps";
import { ControlButton, CompoundPlaceDesign, locations } from "./mapUtils";
import { CompoundDesignerSettings, CompoundPlaceMerger } from "./CompoundDesigner";
import { MapHandler } from "./MapHandler";

interface MapComponentProps {
	compoundPlaces?: CompoundPlaceDesign[];
	compoundPlace: CompoundPlaceDesign;
	CompoundDesignerSettings: CompoundDesignerSettings;
	compoundPlaceMerger: CompoundPlaceMerger;
	setCompoundPlaceMerger: React.Dispatch<React.SetStateAction<CompoundPlaceMerger>>;
	mapHandler: MapHandler;
}

export default ({
	compoundPlaces = [],
	CompoundDesignerSettings,
	compoundPlace,
	compoundPlaceMerger,
	mapHandler,
}: MapComponentProps) => {
	const [map, setMap] = useState<google.maps.Map>();

	const [isLoaded] = useGoogleMaps();
	const theme = useTheme();

	useEffect(() => {
		if (!compoundPlace) return;
		mapHandler.updateCompoundPlace(compoundPlace);
	}, [compoundPlace]);

	useEffect(() => {
		mapHandler.setMerger(compoundPlaceMerger.isMergeMode);
	}, [compoundPlaceMerger.isMergeMode]);

	useEffect(() => {
		if (!map) return;
		compoundPlaces = compoundPlaces?.map((lp) => ({
			...lp,
			isActive: lp.isActive === null ? true : lp.isActive,
		}));
		if (!CompoundDesignerSettings.showInactiveCompoundPlaces)
			compoundPlaces = compoundPlaces?.filter((lp) => lp.isActive);
		if (!CompoundDesignerSettings.showActiveCompoundPlaces)
			compoundPlaces = compoundPlaces?.filter((lp) => !lp.isActive);

		map.overlayMapTypes.clear();
		mapHandler.cleanMap();
		mapHandler.setCompoundPlaces(compoundPlaces);

		compoundPlaces.forEach((lp) => {
			const polygonPath: any = lp.geoArea.points.map((p) => {
				return { lng: p.lon, lat: p.lat };
			});
			mapHandler.createPolygon(polygonPath, lp.id, lp.name, lp.isActive);
		});
	}, [
		compoundPlaces,
		map,
		CompoundDesignerSettings.showInactiveCompoundPlaces,
		CompoundDesignerSettings.showActiveCompoundPlaces,
	]);

	const initMap = useCallback((node) => {
		if (!node) return;

		const mapOpts: google.maps.MapOptions = {
			center: locations[0].coordinates,
			zoom: 17,
			disableDefaultUI: true,
			fullscreenControl: true,
			mapTypeId: google.maps.MapTypeId.SATELLITE,
		};

		const map = new google.maps.Map(node, mapOpts);
		mapHandler.setMap(map);
		setMap(map);
		const centerControlDiv = document.createElement("div");
		ControlButton(centerControlDiv, map, "&gt;", "");
		mapHandler.map.controls[google.maps.ControlPosition.TOP_LEFT].push(centerControlDiv);
		mapHandler.map.addListener("click", (e) => {
			mapHandler.createMarker(e);
		});
	}, []);

	return (
		<Box sx={{ height: "100%" }}>
			{isLoaded && (
				<Box
					sx={{
						flexGrow: 1,
						textShadow: "rgba(0, 0, 0, 0.8) 1px 1px 1px",
						"-webkit-text-stroke-width": "1px",
						"-webkit-text-stroke-color": "rgba(50, 50, 50, 0.6)",
						"-webkit-text-fill-color": "#ffffff",
						borderRadius: theme.spacing(0.5),
						height: "100%",
					}}
					ref={initMap}
				/>
			)}
		</Box>
	);
};
