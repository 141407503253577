import { Box, Button, Grid, Typography } from "@mui/material";
import axios from "axios";
import FormDateTimePicker from "Components/FormDateTimePicker";
import FormTextField from "Components/FormTextField";
import Layout from "Components/Layout/Layout";
import VehicleNavigation from "Navigation/VehicleNavigation";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import useForm from "system/useForm";
import useVehicle from "system/useVehicle";
import LotScanContextMenu from "../LotScanContextMenu";
import VehicleHeaderMenu from "../VehicleHeaderMenu";
import VehicleSubMenu from "../VehicleSubMenu";

interface NewAssessmentRequest {
	registrationDate: string;
	mileage: number;
}

export default function NewAssessmentPage() {
	const { id } = useParams<{ id: string }>();
	const [vehicle] = useVehicle(id);
	const [request, setRequest] = useState<NewAssessmentRequest>({
		registrationDate: "",
		mileage: 0,
	});
	const [error, setError] = useState<string | null>(null);
	const [busy, setBusy] = useState(false);

	const history = useHistory();

	const form = useForm({
		values: request,
		setValues: setRequest,
	});

	useEffect(() => {
		if (!vehicle) {
			return;
		}
		setRequest({
			registrationDate: vehicle.dateFirstRegistered || "",
			mileage: vehicle.remarketing.mileage || 0,
		});
	}, [vehicle]);

	async function handleFormSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		if (!vehicle) {
			return;
		}

		if (!request.registrationDate) {
			setError("Erstzulassung ist ein Pflichtfeld");
			return;
		}

		if (!request.mileage) {
			setError("Kilometerstand ist ein Pflichtfeld");
			return;
		}

		setBusy(true);
		try {
			await axios.post(`/api/assessments`, {
				vin: vehicle!.vin,
				...request,
			});
			history.push(`/vehicles/${vehicle.id}/assessments`);
		} finally {
			setBusy(false);
		}
	}

	return (
		<Layout
			title="Bewertung"
			plateNumber={vehicle?.plateNumber}
			navigation={<VehicleNavigation vehicle={vehicle} />}
			contextMenu={[<LotScanContextMenu />, <VehicleHeaderMenu vehicle={vehicle} />]}
			subMenu={vehicle && <VehicleSubMenu vehicle={vehicle} />}
		>
			<Typography variant="h6">Neue Trockenbewertung erstellen</Typography>
			<Typography>
				Erstelle ein neue Trockenbewertung für das Fahrzeug mit der VIN <strong>{vehicle?.vin}</strong>. Achtung, für die Bewertung wird eine kostenpflichtige VIN-Abfrage durchgeführt.
			</Typography>
			{error && (
				<Box my={2}>
					<Typography color="error">{error}</Typography>
				</Box>
			)}
			<Grid container spacing={2} py={2}>
				<Grid item xs={12} md={6}>
					<form onSubmit={handleFormSubmit}>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<FormDateTimePicker fullWidth form={form} name="registrationDate" label="Erstzulassung" variant="date" required />
							</Grid>
							<Grid item xs={12}>
								<FormTextField fullWidth form={form} name="mileage" label="Kilometerstand" type="number" required />
							</Grid>
							<Grid item xs={12} mt={2}>
								<Button color="primary" variant="contained" type="submit" disabled={busy}>
									Bewertung erstellen
								</Button>
							</Grid>
						</Grid>
					</form>
				</Grid>
			</Grid>
		</Layout>
	);
}
