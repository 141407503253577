export enum UserRole {
	DamageAssessor = "DamageAssessor",
	AldUser = "AldUser",
	AldManager = "AldManager",
	AldAdmin = "AldAdmin",
	UserAdmin = "UserAdmin",
	Impersonate = "Impersonate",
	LotManager = "LotManager",
	LotAdmin = "LotAdmin",
	PoolFleetUser = "PoolFleetUser",
	LogisticCompany = "LogisticCompany",
	ReceivablesManagement = "ReceivablesManagement", // Forderungsmanagement
	Driver = "Driver",
	Customer = "Customer",
	ServiceProvider = "ServiceProvider",
	ServiceManager = "ServiceManager",
	ServiceUser = "ServiceUser",
	ServiceAccounting = "ServiceAccounting",
	SalesUser = "SalesUser",
	SalesManager = "SalesManager",
	SalesBackoffice = "SalesBackoffice",
	SalesAdmin = "SalesAdmin",
	TemporaryUser = "TemporaryUser",
}

export enum DateTimeRangeInterval {
	Morning = 1,
	Afternoon = 2,
}

export interface DateTimeRange {
	date: string;
	range: DateTimeRangeInterval;
}

export interface Period {
	from: string;
	to: string;
}

export interface Return {
	morning: number;
	afternoon: number;
}

export interface CompanyReference {
	name: string;
}

export interface Company {
	name: string;
	address: Address;
	transports?: CompanyTransports;
	services?: CompanyVehicleServices;
	testPermissions?: boolean;
}

export interface CompanyTransports {
	active: boolean;
	maxOrdersPerDay: number;
	daysToCompleteOrder: number;
}

export interface CompanyVehicleServices {
	allowAccounting: boolean;
	allowRequests: boolean;
	requestableTypes: VehicleServiceType[];
}

export interface JwtToken {
	token: string;
	refreshToken?: string;
	daysValid: number;
}

export interface User extends Entity {
	loginToken?: JwtToken;
	pictureUrl?: string;
	userId?: string | null;
	name: string;
	email: string;
	roles: Array<UserRole>;
	company: CompanyReference;
	isActive: boolean;
	isTestUser: boolean;
	settings: UserSettings;
}

export interface UserSettings {
	menuOpen: boolean;
	theme: "dark" | "light";
	navigationOrientation: "horizontal" | "vertical";
}

export interface LogisticsUser {
	id: string;
	email: string;
	name: string;
}

export interface NotificationAddress {
	email: string;
	name: string;
	companyName: string;
}

export enum NotificationCategory {
	Orders = "Orders",
	Notifications = "Notifications",
}

export interface Notification {
	id: string;
	category: NotificationCategory;
	threadId: string;
	entityId: string;
	to: NotificationAddress;
	from: NotificationAddress;
	dateCreated: string;
	subject: string;
	body: string;
	link: string;
	mailjetMessageId: string;
	dateRead: string;
	readBy: LogisticsUser;
	sendEmail: boolean;
}

export interface Task {
	id: string;
	dateSubmited: Date;
	dateStarted?: Date;
	dateFinished?: Date;
	lastError?: string;
	name: string;
}

export interface LogEvent {
	id: string;
	jobId: string;
	date: string;
	level: string;
	message: string;
	exception?: string;
}

export interface Address {
	name: string;
	name1?: string;
	street: string;
	zipCode: string;
	zipCodeArea?: string;
	city: string;
	country: string;
	additionalInformation?: string;
	compoundName?: string;
}

export interface ContactPerson {
	name: string;
	email: string;
	telephone: string;
}

export interface Customer {
	id: number;
	name: string;
	serviceAgent: ContactPerson;
}

export interface EntityReference {
	referenceCreated?: string;
}

export interface LeasingContractReference extends EntityReference {
	id: number;
	businessLine: string;
	customer: Customer;
}

export interface StatisticItem {
	orders: number;
	returns: number;
	returnForms: number;
	plannedReturns: number;
	expectedReturns: number;
	assigns: number;
	ingoingVehiclesHistoryEntries: number;
	outgoingVehiclesHistoryEntries: number;
}

export interface StatisticItems {
	[key: string]: StatisticItem;
}

export interface Statistic extends StatisticItem {
	id: string;
	date: string;
	isWorkingDay: boolean;
	items: StatisticItems;
}

export interface BaseOrder extends Entity {
	businessLine: string;
	isPoolfleet: boolean;

	plateNumber: string;
	vin: string;

	vehicle: VehicleReference;
	leasmanContract?: LeasmanContractReference;
}

export type TransportOrderPerspective =
	| "All"
	| "Active"
	| "Cancelled"
	| "Unconfirmed"
	| "Confirmed"
	| "ScheduledForReturn"
	| "OverdueForReturn"
	| "Completed"
	| "Outbound";

export type TransportOrderStatus = "Created" | "Assigned" | "Completed" | "Cancelled";

export type TransportOrderType = "Arrival" | "Delivery" | "Internal" | "CrossTransport";

export interface TransportOrder extends BaseOrder {
	type: TransportOrderType;
	source: VehicleSource;

	vehicleId?: string;

	status: TransportOrderStatus;
	assignedTo: CompanyReference;

	importId?: string;
	returnOrderId?: string;

	isRegistered: boolean;
	isVehicleReadyForDriving: boolean;
	isUrgent: boolean;
	requiresExternalAssessment?: boolean;

	orderRef?: string;

	dateRequested: string;
	dateAssigned?: string;

	dateConfirmed?: string;
	confirmationDate?: string;

	plannedPickupDate?: string;
	customerPickupInformation?: string;
	datePickupConfirmed?: string;
	pickupDate?: string;
	pickupAddress: Address;
	pickupLocation: Location;
	pickupContact: ContactPerson;
	pickupCompound: CompoundReference;

	requestedDeliveryDate?: string;

	destinationAddress: Address;
	destinationLocation: Location;
	destinationContact: ContactPerson;
	destinationCompound: CompoundReference;

	dateExpectedReturn?: string;
	expectedReturnDate?: string;

	plannedReturnPeriod?: Period;
	dateReturned?: string;
	returnDate?: string;

	cancellationReason?: string;
	cancellationComment?: string;

	slaViolation?: number;

	remarks?: string;
	internalComment?: string;
	notes: Note[];
	internalNotes: Note[];

	protocol?: FileReference;
	manualProtocol?: FileReference;
	protocolNotification?: MailReference;

	dateLeasman?: string;

	invoiceInfo?: {
		invoiceId: string;
		invoiceNumber: string;
		invoiceDate: string;
		netAmount: number;
	};
	emailCommunications?: MailReference[];

	vehicleArrivalId?: string;
}

export interface VehicleArrival extends VehicleArrivalFormRequest, Entity {
	transportOrderId?: string;
	vehicle: VehicleReference;
}

export interface VehicleArrivalFormRequest {
	vehicleId?: string;
	previousTransportOrderId?: string;
	isRegistered?: boolean;
	registrationDocument?: boolean;
	registrationDocumentFront?: FileReference;
	registrationDocumentBack?: FileReference;
	plateNumbers?: string;
	plateNumbersFront?: FileReference;
	plateNumbersBack?: FileReference;
	doAssessment?: boolean;
	isDrivable?: boolean;
	isCancellation?: boolean;
	isPoolfleet?: boolean;
	unstickService?: boolean;
	unstickServiceType?: string;
	changeTiresService?: boolean;
	installedTiresAndRims?: TiresAndRims;
	suppliedTiresAndRims?: SuppliedTiresAndRims;
}

export interface Note {
	user: LogisticsUser;
	dateCreated: string;
	text: string;
}

export interface OrderEvent {
	id: string;
	last?: TransportOrder;
	current: TransportOrder;
}

export enum ImportStatus {
	Created = "Created",
	Imported = "Imported",
	Committed = "Committed",
	Deleted = "Deleted",
}

export type ImportType =
	| "LogisticOrdersImport"
	| "VehicleServiceImport"
	| "ReturnOrdersImport"
	| "CompleteUnregistrationOrdersImport"
	| "VehicleRegistrationDocumentRequestsImport"
	| "VehicleRegistrationDocumentArrivalsImport"
	| "VehicleRegistrationDocumentHandoversImport"
	| "VehicleRegistrationDocumentUnregistrationsImport"
	| "LeaseplanVehicleImport";

export interface ImportHistoryEntry {
	status: ImportStatus;
	date: string;
	taskId: string;
}

export interface Import {
	id: string;
	status: ImportStatus;
	importType: ImportType;
	tasks: string[];
	currentTaskId: string | null;
	error: string | null;
	dateCreated: string;
	filename: string;
	history: ImportHistoryEntry[];
}

export interface FileReference {
	hash: string;
	dmsId?: string;
	filename: string;
	referenceCreated?: string;
}

export interface StorageFile {
	hash: string;
	filename: string;
	date: string;
}

export interface Damage {
	component: string | null;
	type: string | null;
	remarks?: string;
	picture?: FileReference | null;
}

export interface Signature {
	path: [];
	image: string;
}

export interface SignatureInfo {
	firstName: string;
	lastName: string;
}

export interface VehiclePicture extends FileReference {
	plate?: string;
	isInvalid?: boolean;
}

export type TireType = "Summer" | "Winter" | "AllSeason";
export type RimType = "None" | "Unknown" | "Steel" | "Aluminium";

export interface TiresAndRims {
	tireType?: TireType;
	rimType?: RimType;
}

export interface InstalledTiresAndRims extends TiresAndRims {
	spareTire?: boolean;
	wheelTrimCap?: boolean;
	rimFrontLeft?: FileReference;
	rimBackLeft?: FileReference;
	rimBackRight?: FileReference;
	rimFrontRight?: FileReference;
}

export interface SuppliedTiresAndRims {
	tireType?: "Summer" | "Winter" | "AllSeason";
	rimType?: "Steel" | "Aluminium" | "None";
	picture?: FileReference;
}

export type TemplateVersion = "InitialTemplateVersionTill062023" | "TemplateVersionAfter062023LiabilityClause";

export interface ReturnForm {
	id?: string;
	title?: string;
	dateCreated?: string;
	createdBy?: LogisticsUser;

	archive?: FileReference;

	orderId?: string;
	predecessorId?: string;

	readiness?: {
		oilLevel?: boolean;
		coolant?: boolean;
		warningIndicator?: boolean;
		startAttempt?: boolean;
		lighting?: boolean;
		fuelLevel?: "Empty" | "OneQuarter" | "Half" | "ThreeQuarters" | "Full";
		fuelLevelPictureProof?: FileReference;
		validInspections?: boolean;
		inspectionReports?: boolean;
		correctVin?: boolean;
	};
	documentsAndEquipment?: {
		registrationDocument?: boolean;
		registrationDocumentFront?: FileReference;
		registrationDocumentBack?: FileReference;
		keys?: number;
		remoteControl?: boolean;
		fuelCards?: number;
		seats?: number;
		boardBriefcase?: boolean;
		operationManual?: boolean;
		serviceBooklet?: boolean;
		serviceRecord?: boolean;
		radioCodeCard?: boolean;
		radio?: boolean;
		navigationDevice?: boolean;
		navigationDataMedium?: boolean;
		plateNumbers?: number;
		plateNumbersFront?: FileReference;
		plateNumbersBack?: FileReference;
	};
	impedingConditions?: {
		lighting?: boolean;
		rain?: boolean;
		dirt?: boolean;
		snowOrIce?: boolean;
		other?: string;
		pictureOfEnvironment?: VehiclePicture;
		pictureOfVehicle?: VehiclePicture;
	};
	vehiclePictures?: {
		frontLeft?: VehiclePicture;
		backLeft?: VehiclePicture;
		backCenter?: VehiclePicture;
		trunk?: VehiclePicture;
		backRight?: VehiclePicture;
		tiresAndRims?: VehiclePicture;
		frontRight?: VehiclePicture;
		frontCenter?: VehiclePicture;
		windshield?: VehiclePicture;
		interior?: VehiclePicture;
	};
	installedTiresAndRims?: InstalledTiresAndRims;
	additionalTiresAndRims?: boolean;
	suppliedTiresAndRims?: SuppliedTiresAndRims;
	accessories?: {
		antenna?: boolean;
		roofRack?: boolean;
		bootCover?: boolean;
		loadSecuringNet?: boolean;
		toolKit?: boolean;
		jack?: boolean;
		tireFit?: boolean;
		firstAidKit?: boolean;
		warningTriangle?: boolean;
		chargingCable?: boolean;
	};
	previousDamages?: "Yes" | "YesWithoutDetails" | "No";
	previousDamagesDetails?: {
		repairCosts?: number;
		insuranceType?: "PartialCoverage" | "Comprehensive" | "Liability";
		insuranceAgency?: string;
	};
	damagesInterior?: Damage[];
	damagesExterior?: Damage[];
	transfer?: {
		date?: string;
		mileage?: number;
		remarks?: string;
		driversLicenseChecked?: boolean;
	};
	signatures?: {
		deliverer?: Signature;
		delivererInfo?: SignatureInfo;
		recipient?: Signature;
		recipientInfo?: SignatureInfo;
	};
	templateVersion?: TemplateVersion;
	isLocked?: () => boolean;
	isSigned?: () => boolean;
	isValid?: () => boolean;
	print?: () => Promise<void>;
	delete?: () => Promise<boolean>;
}

export enum InvoiceStatus {
	New = "New",
	Approved = "Approved",
	Rejected = "Rejected",
}

export interface InvoiceItem {
	isMissedTransport: boolean;
	plateNumber: string;
	deliveryDate: string;
	netConditionAmount: number;
	deregistrationNoTax: number;
	deregistrationWithTax: number;
	shipmentCost: number;
	kzkNoTax: number;
	kzkWithTax: number;
	netOilCost: number;
	specialHandlingCost: number;
	otherCost: number;
	netAmount: number;
	customerNetAmount: number | null;
	isCancelled: boolean;
	orderId: string | null;
	pickupAddress: Address;
	destinationAddress: Address;
	returnForms: number;
	businessLine: string | null;
	dateAssigned: string | null;
	returnDate: string | null;
	numberOfDays: number | null;
	isOrderUserAssigned: boolean;
}

export interface Invoice {
	id: string;
	company: Company;
	status: InvoiceStatus;
	invoiceNumber: string;
	dateCreated: string;
	invoiceDate: string;
	document: FileReference;
	netAmount: Number;
	canGoToSAP: boolean;
	lines: Array<InvoiceItem>;
}

export interface Entity {
	id: string;
	version: number;
	dateCreated: string;
	createdBy: LogisticsUser;
	dateUpdated: string;
	updatedBy: LogisticsUser;
}

export interface VehiclePictureSet {
	id: string;
	date?: string;
	dateUploaded?: string;
	dateRejected?: string;
	dateGenerated?: string;
	frontRight?: VehiclePicture;
	backRight?: VehiclePicture;
	backLeft?: VehiclePicture;
	frontLeft?: VehiclePicture;
	headlights?: VehiclePicture;
	tiresAndRims?: VehiclePicture;
	interiorFront?: VehiclePicture;
	interiorBack?: VehiclePicture;
	trunk?: VehiclePicture;
	cockpit?: VehiclePicture;
	infotainment?: VehiclePicture;
	instruments?: VehiclePicture;
}

export interface GeoPoint {
	lat: number;
	lon: number;
}

export interface GeoArea {
	points: GeoPoint[];
}

export interface CompoundReference {
	id?: string;
	name: string;
}

export interface Compound {
	name: string;
	address?: Address;
	addresses: Address[];
	// saleLocation?:
	telephone?: string;
	// ...
	isDefault: boolean;
	pickup: PickupInformation;
}

export interface PickupInformation {
	times: string;
	email: string;
	telephone: string;
}

export interface CompoundPlace extends Entity {
	name: string;
	isActive?: boolean;
	geoArea: GeoArea;
	compound: CompoundReference;
	groups: string[];
}

export interface LotScan {
	date: string;
	location: GeoPoint;
	placeId: string;
}

export type RemarketingStatus =
	| "Registriert"
	| "Eingang"
	| "VorlaufWerkstatt"
	| "Werkstatt"
	| "Waschen"
	| "Fotografieren"
	| "Bewerten"
	| "Entkleben"
	| "Carglass"
	| "Lagern"
	| "Aufbereiten"
	| "FotografierenRetail"
	| "LagernRetail"
	| "Probefahren"
	| "Ausliefern"
	| "OutletNorderstedt"
	| "Poolfleet"
	| "Ausgang";

export type VehiclePerspective = "Stock" | "In" | "Out" | "All" | "InventoryOpen" | "InventoryDone" | "Bookmarked";

export interface VehicleHistoryEntry {
	date: string;
	status: RemarketingStatus;

	processStep: LotProcessStep;
	placeId: string;
	location?: GeoPoint;
	orderId: string;
	inventoryId: string;

	statusChanged: boolean;
	placeChanged: boolean;
	orderChanged: boolean;

	user: LogisticsUser;
	note?: Note;
}

export interface DateRange {
	from: string;
	to?: string;
}

export interface VehicleHistory {
	currentStockDateRange?: DateRange;
	stockDateRanges: DateRange[];
	entries: VehicleHistoryEntry[];
	lastKnownLocation?: GeoPoint;
	lastStatusAssigned?: string;
	lastPlaceAssigned?: string;
}

export interface Price {
	netAmount: number;
	grossAmount: number;
}

export interface PriceRange {
	lower: number;
	upper: number;
}

export interface PriceWithRanges extends Price {
	netAmountRange: PriceRange;
	grossAmountRange: PriceRange;
}

export type FuelType =
	| "Diesel"
	| "Gasoline"
	| "Super"
	| "SuperPlus"
	| "Hybrid"
	| "Electric"
	| "Hydrogen"
	| "NaturalGas";

export type Transmission = "Automatic" | "Manual" | "Direct" | "Unknown";

export type WheelDrive = "All" | "Front" | "Back" | "Switchable";

export interface VehicleType {
	id: number;
	description: string;

	make: VehicleTypeMake;
	model: VehicleTypeModel;

	body: VehicleTypeBody;
	engine: VehicleTypeEngine;
	transmission: VehicleTypeTransmission;

	production: VehicleTypeProduction;
	performance: VehicleTypePerformance;
	consumption: VehicleTypeConsumption;
	emissions: VehicleTypeEmissions;

	costs: VehicleTypeCosts;
}

export interface VehicleTypeMake {
	id: number;
	name: string;
}

export interface VehicleTypeModel {
	name: string;
	version: string;
	variant: string;
	kind: ModelKind;
}

export type ModelKind = "PassengerCar" | "Transporter" | "OffRoadVehicle";

export interface VehicleTypeBody {
	type: string;
	paint: string;
	tires: number;
	wheelBase: number;
	doors: number;
	seats: number;
	cushions: string;
	size: VehicleTypeBodySize;
	weight: VehicleTypeBodyWeight;
	trunkVolume: VehicleTypeBodyTrunkVolume;
}

export interface VehicleTypeBodySize {
	length: number;
	height: number;
	width: number;
}

export interface VehicleTypeBodyWeight {
	base: number;
	payload: number;
	total: number;
}

export interface VehicleTypeBodyTrunkVolume {
	min: number;
	max: number;
}

export interface VehicleTypeEngine {
	type: string;
	fuelType: FuelType;
	fuelCapacity: number;

	power: number;
	horsePower: number;

	cubicCapacity: number;
	cylinders: number;

	wheelDrive: WheelDrive | null;
}

export interface VehicleTypeTransmission {
	type: Transmission;
	gears: number;
}

export interface VehicleTypeProduction {
	begin: VehicleTypeProductionDate;
	end?: VehicleTypeProductionDate;
}

export interface VehicleTypeProductionDate {
	date: string;
	year: number;
	month: number;
}

export interface VehicleTypePerformance {
	acceleration: number;
	topSpeed: number;
}

export interface VehicleTypeConsumption {
	total: number;
	inTown: number;
	outOfTown: number;
}

export interface VehicleTypeEmissions {
	co2: number;
	group: {
		id: string;
		name: string;
	};
	norm: string;
}

export interface VehicleTypeCosts {
	listPrice: Price;
	equipmentPrice: Price;
	totalPrice: Price;
}

export interface LeasmanExpense {
	referenceId: string;
	barcode: string;

	deliveryDate: string;
	invoiceDate: string;
	mileage?: number;
	info: string;

	service: {
		code: string;
		group: string;
		kind: string;
	};

	price: Price;
}

export interface Vehicle extends Entity {
	vin: string;
	plateNumber: string;

	type: VehicleType;
	equipment: Equipment[];
	expenses?: LeasmanExpense[];

	remarketing: Remarketing;
	leasing: Leasing;

	leasmanContractId?: number;
	businessLine: string;
	orderId: string;
	isPoolfleet: boolean;
	requiresExternalAssessment: boolean;
	dateFirstRegistered?: string;
	dateUnregistered?: string;

	isInStock: boolean;

	history: VehicleHistory;
	markers: VehicleMarkers;

	placeId: string;
	location: GeoPoint;
	pictureSets: VehiclePictureSet[];

	note: Note;
	bookmarks: Bookmark[];

	defect?: VehicleGeneralRepairDefect;
}

export interface RemarketingTransport {
	orderId: string;
	dateStarted: string;
	dateCompleted: string;
}

export interface Remarketing {
	fleetnetVehicleId?: number;
	leasmanVehicleId?: string;

	leasmanContract?: LeasmanContractReference;
	fleetnetVehicle: FleetnetVehicleReference;

	dateStarted: string;
	dateCompleted: string;

	status: RemarketingStatus;
	processStep: LotProcessStep;

	hasArrived: boolean;
	arrival: RemarketingTransport;

	isDelivered: boolean;
	delivery: RemarketingTransport;

	isUnregistered: boolean;
	unregistration: RemarketingUnregistration;

	isAssessed: boolean;
	assessment: RemarketingAssessment;

	isStopped: boolean;
	stops?: RemarketingStop[];

	previousOwners?: number;
	numPreviousOwners?: number;
	mileage?: number;
	hasWindshieldDamage?: boolean;
	windshieldDamage: RemarketingWindshieldDamage | null;
	publicHeadline: string;
	publicHeadlineConfirmed: boolean;
	highlights: EquipmentHighlightReference[];

	hadAccident?: boolean;
	hadEngineReplacement?: boolean;
	engineReplacementMileage?: number;
	hasAdditionalTires?: boolean;
	tiresAndRims?: TiresAndRims;

	isPrepared: boolean;
	saleId: string;

	isReadyForSale: boolean;

	notes: Note[];

	registrationDocuments: RegistrationDocuments;
}

export type LeasingType = "Direct" | "Delegated";
export type DelegatedLeasingStatus = "Active" | "Idle" | "PreDeployment";

export interface Leasing {
	type: LeasingType;

	main?: LeasmanContractReference;
	supplementary?: LeasmanContractReference;

	delegated: {
		status: DelegatedLeasingStatus;
	};
}

export interface RegistrationDocuments {
	requestImportId?: string;
	arrivalImport?: ArrivalImportReference;
	arrivalType: DocumentArrivalType;
	status: VehicleRegistrationDocumentStatus;
	dateStatusChanged: string;
	handover: DocumentHandover;
	documentsPictureProof?: DocumentsPictureProof;
	statusHistory: HistoryEntry<VehicleRegistrationDocumentStatus>[];
	isAtLeastRequested: boolean;
	arrivalTransmissionNote: ArrivalTransmissionNote;
	arrivalTransmissionNoteHistory: ArrivalTransmissionNote[];
}

export interface DocumentHandover {
	handoverType?: DocumentHandoverType;
	dateVaultRemoval?: string;
	removedBy?: string;
	importId?: string;
	trackingReference?: string;
}

export interface ArrivalImportReference {
	importId: string;
	status: "Imported" | "Completed";
	requestedBy: string;
	arrivalType?: DocumentArrivalType;
	unregistrationDate?: string;
}

export interface DocumentsPictureProof {
	registrationDocumentFront?: FileReference;
	registrationDocumentBack?: FileReference;
	registrationTitle?: FileReference;
}

export type DocumentHandoverType = "Norderstedt" | "BDK" | "Versandt" | "Dienstwagen" | "Flex" | "Kurier" | "Abholung";

export type DocumentArrivalType = "None" | "All" | "RegistrationTitle" | "RegistrationDocument";

export const vehicleRegistrationDocumentStates = [
	"Unknown",
	"Storage",
	"Requested",
	"Arrived",
	"NotArrived",
	"PartiallyArrived",
	"ReadyForHandOver",
	"HandedOver",
] as const;
export type VehicleRegistrationDocumentStatus = (typeof vehicleRegistrationDocumentStates)[number];

export interface RemarketingWindshieldDamage {
	orderId: string;
	repairWork: RepairWork | null;

	status?: RepairOrderStatus;
	statusDate?: string;
}

export interface EquipmentHighlightBase {
	id: string;
	name: string;

	relevance: EquipmentHighlightRelevance;
}

export interface EquipmentHighlightRelevance {
	priority: number;
	carmarketB2B: boolean;
	carmarketB2C: boolean;
	onlineMarket: boolean;
	priceTag: boolean;
	pricing: boolean;
	salesConsultant: boolean;
}

export interface EquipmentHighlight extends EquipmentHighlightBase {
	equipmentCategoryIds: number[];
}

export interface EquipmentHighlightReference extends EquipmentHighlightBase { }

export interface RemarketingUnregistration {
	orderId: string;
	dateStarted: string;
	dateCompleted: string;
	history: UnregistrationTask[];
}

export interface UnregistrationTask {
	taskNumber: string;
	taskName: string;
	startDate: string;
	remarks: string;
}

export interface RemarketingAssessment {
	orderId: string;
	assessmentId: string;
	dateStarted: string;
	dateCompleted: string;
}

export interface RemarketingStop {
	id: string;
	dateStarted: string;
	dateResolved?: string;
	isActive: boolean;
	reason: RemarketingStopReason;
	description: string;
	resolutionMessage?: string;
	startedBy: LogisticsUser;
	resolvedBy?: LogisticsUser;
}

export const remarketingStopReasons = [
	"Other",
	"Carglass",
	"ReturnOrderSalesComment",
	"VehicleAssessmentRejection",
	"IncorrectLeasmanData",
	"PictureSetQuality",
	"ExistingLeasmanSale",
] as const;
export type RemarketingStopReason = (typeof remarketingStopReasons)[number];

export interface LeasmanContract_v1 {
	id: number;
	tenant: string;
	vin: string;
	plateNumber: string;
	customer: Customer;

	dateSynced?: string;
	dateStart?: string;
	dateEnd?: string;
	dateActivated?: string;
	dateTransferred?: string;
	dateReturned?: string;
	dateEndOfRent?: string;
	dateFirstRegistered?: string;
}

export interface Bookmark {
	remark?: string;
	user: LogisticsUser;
	dateCreated: string;
}

export interface DmsDocument {
	id: string;
	documentType: string;
	documentTypeName: string;
	date: string;
}

export interface VehicleTaskCategory {
	name: string;
	description: string;
	defaultCompany: Company;
}

export interface VehicleTask {
	lotVehicleId: string | null;
	category: VehicleTaskCategory | null;
	description: string | null;
}

export interface LotProcessStep {
	displayName: string;
	status: RemarketingStatus;
	previousStatus: RemarketingStatus[];
}

export interface CompoundRegistrationReference {
	id: string;
	compoundVehicleId: string;
	compoundVehicle: Vehicle;
	vin: string;
	allowCompoundRegistration: boolean;
}

export interface Inventory extends Entity {
	inventoryDate?: string;

	expectedStock: number;
	actualStock: number;

	missingVehicles: number;
	correctedVehicles: number;
	unexpectedVehicles: number;
}

export interface VehicleService extends Entity {
	// id: string
	importId: string | null;
	billingId: string | null;
	billingClient: string | null;
	plateNumber: string;
	vin: string;
	text: string;
	businessLine: string | null;
	amount: number;
	vehicleId: string | null;
	vehicle: VehicleReference | null;
	dateAssigned: string | null;
	dateStarted: string | null;
	dateCompleted: string | null;
	dateBilled: string | null;
	dateRejected: string | null;
	dateCancelled: string | null;
	assignedTo: CompanyReference;
	status: VehicleServiceStatus;
	serviceType: VehicleServiceType;
	additionalInformation: string;
	rejectionReason: string;
	proofPicture?: FileReference;
	cancellationReason: string;
	completionNote: string;
}

export interface VehicleServiceType {
	amount: number;
	id: string;
	text: string;
	canHaveRequestText: boolean;
	requiresPictureProof: boolean;
	canHaveCompletionNote: boolean;
	requiresConfirmation: boolean;
	canBeStarted: boolean;
	defaultCompany: CompanyReference;
}

export type RecursivePartial<T> = {
	[P in keyof T]?: RecursivePartial<T[P]>;
};

export type VehicleServiceOverviewPerspective =
	| "All"
	| "Unfinished"
	| "Unassigned"
	| "Rejected"
	| "Requested"
	| "Started"
	| "Cancelled"
	| "Completed"
	| "Own";

export type VehicleServiceStatus =
	| "Created"
	| "Assigned"
	| "Requested"
	| "Rejected"
	| "Started"
	| "Completed"
	| "Cancelled"
	| "Billed";

export interface CustomerSettings {
	id: number;
	name: string;
	email: string;
	sendReturnForms: boolean;
}

export type BillingStatus = "Created" | "Closed" | "Approved";

export interface BillingServicesBase {
	total: number;
	netAmount: number;
}

export interface BillingServiceTypes extends BillingServicesBase {
	id: string;
	text: string;
}

export interface BillingServices extends BillingServicesBase {
	types: BillingServiceTypes[];
}

export interface BillingClient {
	name: string;
	services: BillingServices;
}

export interface BillingInvoice {
	client: string;
	netAmount?: number;

	dateUploaded: string;
	file: FileReference;
	size: number;
}

export interface Billing extends Entity {
	company: CompanyReference;
	nr: number;
	dateFrom: string;
	dateTo: string;
	status: BillingStatus;
	dateClosed?: string;
	dateApproved?: string;
	services: BillingServices;
	clients: BillingClient[];
	invoices: BillingInvoice[];
}

export interface ReturnOrder extends BaseOrder {
	isElectricCar: boolean;
	isDrivable: boolean;
	drivableComment?: string;
	isRegistered: boolean;
	hasValidMainInspection: boolean;
	mainInspectionValidUntil: string;
	tireType: ReturnOrderTireType;
	carType?: CarType;
	salesType?: SalesType;
	salesTypeComment?: string;
	doAssessment: boolean;
	assessmentIsPreprocessed: boolean;
	doUnregistration: boolean;
	transportCostInstallments?: string;
	costTransferCustomer?: string;
	debitingAld?: string;
	dateCancelled?: string;
	cancellationReason?: string;
	dateProcessed?: string;
	dateImported?: string;
	dateAssigned?: string;
	dateApproved?: string;
	dateTerminated?: string;
	collectionReferenceDate: string;
	status: ReturnOrderStatus;
	terminationStatus?: ReturnOrderTerminationStatus;
	collection: CollectionInstructions;
	delivery: DeliveryInstructions;
	initialCollectionAppointment: Appointment;
	assessment?: AssessmentInstructions;
	transportOrderId?: string;
	assessmentOrderId?: string;
	unregistrationOrderId?: string;
	importId?: string;
	customerServiceAgent?: {
		name?: string;
		email?: string;
		telephone?: string;
	};
	engineType: EngineType;
	customerOrderId?: string;
	emailCommunications?: MailReference[];
}

export const returnOrderStatus = [
	"Created",
	"Processed",
	"Cancelled",
	"Imported",
	"CustomerCreated",
	"Approved",
	"Terminated",
];
export type ReturnOrderStatus = (typeof returnOrderStatus)[number];

export interface ReturnOrderTerminationStatus {
	dateCreated: string;
	reason: ReturnOrderTerminationReason;
	description: string;
	createdBy: LogisticsUser;
	dateUpdated?: string;
	updatedBy?: LogisticsUser;
}

export const returnOrderTerminationReasons = [
	"CustomerNotAvailable",
	"LesseeRefers",
	"VehicleStolen",
	"NotDelivered",
	"Other",
] as const;
export type ReturnOrderTerminationReason = (typeof returnOrderTerminationReasons)[number];

export enum CarType {
	SUV = "SUV",
	Van = "Kastenwagen",
	Unknown = "Unbekannt",
	None = "Kein SUV oder Kastenwagen",
}

export type SalesType = "Sale" | "NoSale" | "Flex" | "Rkv";

export type EngineType = "Combustion" | "Electric" | "Hybrid" | "Unknown";

export interface VehicleReference {
	id: string;

	vin: string;
	plateNumber: string;
	dateFirstRegistered: string;

	type?: VehicleType;
}

export interface LeasmanStatus {
	id: number;
	name: string;
	processStatus: LeasmanProcessStatus;
}

export type LeasmanProcessStatus = "Offer" | "Order" | "Active" | "Inactive";

export interface LeasmanContractReference {
	id: number;
	tenant: string;
	businessLine: string;
	status: LeasmanStatus;

	customer: LeasmanVehicleAddress;
	serviceAgent: ContactPerson;

	duration?: number;
	mileage?: number;

	dateBegin?: string;
	dateEnd?: string;
	dateReturned?: string;
	dateStopped?: string;
}

export interface LeasmanVehicleAddress {
	id: number;

	name1: string;
	name2: string;
	fullName: string;

	email: string;
	phone: string;
	fax: string;

	street: string;
	postal: string;
	city: string;
	country: string;
	countryIsoCode: string;
}

export interface LeasmanAddress {
	id: string;

	tenant: string;
	addressId: number;

	type: string;
	kind: number;

	fullName: string;
	isNaturalPerson: boolean;
	name1: string;
	name2: string;

	street: string;
	postal: string;
	city: string;
	country: {
		id: string;
		name: string;
		sapId: string;
		isoCode: string;
	};

	email: string;
	phone: string;

	salutation: string;

	birthday?: string;
}

export type VehicleSource = "Ald" | "Cpm" | "TradeIn" | "Stock";

export interface CollectionInstructions {
	collectionType: CollectionType;
	comment: string;
	internalComment: string;
	address: Address;
	contact: ContactInformation;
	appointment: Appointment;
	compound: CompoundReference;
}

export type CollectionType =
	| "CustomEntry"
	| "CustomersAddress"
	| "DriversAddress"
	| "TraderAddress"
	| "CustomerDelivery"
	| "UsedCars";

export enum DeliveryType {
	CustomEntry = "CustomEntry",
	RebuyerAddress = "RebuyerAddress",
	UsedCars = "UsedCars",
	CustomerDelivery = "CustomerDelivery",
	Neuss = "Neuss",
}

export interface ContactInformation {
	firstName: string;
	lastName: string;
	email: string;
	telephone: string;
}

export interface Appointment {
	notBefore: string;
	from: string;
	to: string;
}

export interface DeliveryInstructions {
	deliveryType: DeliveryType;
	comment: string;
	isCrossTransport?: boolean;
	address: Address;
	contact: ContactInformation;
	appointment: Appointment;
	compound: CompoundReference;
}

export interface AssessmentInstructions {
	assessmentType: string;
	assessmentLocation: AssessmentLocation;
	appointment?: Appointment;
	address?: Address;
	assessmentComment?: string;
}

export type AssessmentLocation = "DeliveryAddress" | "CollectionAddress" | "RkvAddress";
export type AssessmentLocationOrigin =
	| "CustomEntry"
	| "DriverAddress"
	| "CarParkAddress"
	| "RkvAddress"
	| "CustomerAddres";

export interface AssessmentOrder extends BaseOrder {
	doUnregistration?: boolean;
	transportCostInstallments?: string;
	costTransferCustomer?: string;
	debitingAld?: string;
	assessmentType: AssessmentType;
	address: Address;
	appointment: Appointment;
	assessmentLocation: AssessmentLocation;
	assessmentLocationOrigin: AssessmentLocation;
	status: AssessmentOrderStatus;
	assessmentId?: string;
	assessmentStatus: AssessmentStatus;
	externalReference?: string;
	dateProcessed?: string;
	dateCancelled?: string;
	dateTransferred?: string;
	dateAssigned?: string;
	assessmentDate?: string;
	dateDelayed?: string;
	reasonForDelay?: string;
	cancellationReason?: string;
	assignedTo: CompanyReference;
	contact: ContactInformation;
	comment?: string;
	dateCompleted?: string;
	defect?: AssessmentOrderDefect;
	dateInterrupted?: string;
	subjectInquiries: SubjectInquiryDefect[];
}

export interface AssessmentOrderStatusInformation {
	id: string;
	status: AssessmentOrderStatus;
	dateProcessed?: string;
	dateCancelled?: string;
	dateCompleted?: string;
	assessmentType: AssessmentType;
	assessmentDate?: string;
	defects: string;
}

export interface VehicleGeneralRepairDefect {
	mileage?: number;
	hasCarsChequebook: boolean;
	dateLastMaintenance?: string;
	isReadyToDrive: boolean;
	defectItems: AssessmentOrderDefectItem[];
	createdBy: LogisticsUser;
	dateCreated: string;
	recipients: string[];
	comment: string;
}

export interface GeneralOrderDefect {
	id?: string;
	mileage?: number;
	hasCarsChequebook: boolean;
	dateLastMaintenance?: string;
	isReadyToDrive: boolean;
	defectItems: AssessmentOrderDefectItem[];
	createdBy: LogisticsUser;
	dateCreated: string;
	comment: string;
}

export interface AssessmentOrderDefect extends GeneralOrderDefect {
	assessor: Assessor;
	documents: AttachedDocument[]
	creationMethod: AssessmentOrderCreationMethod
}

export interface SubjectInquiryDefect extends AssessmentOrderDefect {
	status: SubjectInquiryStatus
}

export enum SubjectInquiryStatus {
	Created = "Created",
	Answered = "Answered",
	Ordered = "Ordered",
	Forwarded = "Forwarded",
	Completed = "Completed"
}

export enum AssessmentOrderCreationMethod {
	AssessorEntry = "AssessorEntry",
	SubjectInquiryConversion = "SubjectInquiryConversion"
}

export interface AttachedDocument {
	fileReference: FileReference
}

export interface AssessmentOrderDefectItem {
	id?: string;
	description: string;
	dateCreated: string;
	createdBy: LogisticsUser;
	dateUpdated?: string;
	updatedBy?: LogisticsUser;
}

export interface Assessor {
	firstName: string;
	lastName: string;
	email: string;
}

export type CustomerOrderType = "Return" | "CustomerDelivery" | "PricingRequest";

export interface CustomerOrder extends BaseOrder {
	token: string;
	link: string;
	returnOrderId?: string;
	pricingRequestId?: string;
	customerEmail?: string;
	isRkv: boolean;
	isTraderOrder: boolean;
	allowEarlyReturn?: boolean;
	parentOrderId: string;
	status: CustomerOrderStatus;
	cancellationReason?: string;
	dateCancelled?: string;
	dateCompleted?: string;
	dateTokenGenerated?: string;
	dateContractEnd?: string;
	engineType: EngineType;
	addresses: CustomerOrderAddressInformation;
	type?: CustomerOrderType;
	choiceHistory?: History<CustomerChoice>;
	assessmentType: AssessmentType;
	pricingRequestDecision?: PricingRequestDecision;
	emailCommunications?: MailReference[];
	linkedOrder: LinkedCustomerOrder;
}

export interface PricingRequestDecision {
	accepted: boolean;
	decisionDate: string;
}

export interface CustomerChoice {
	returnOrderId: string;
	customerOrderType: CustomerOrderType;
}

export interface CustomerOrderAddressInformation {
	customerAddress?: Address;
	traderAddress?: Address;
}

export interface LinkedCustomerOrder {
	orderId: string;
	dateCreated: string;
}

export interface SalesChannel {
	id: string;
	name: string;
	isCarmarket: boolean;
	isRetail: boolean;
	isTrade: boolean;
	hasAutomatedMinimumPrice: boolean;
	supportsMinimumPrice: boolean;
	supportsLeasing: boolean;
}

export interface VehicleSaleChannelHistoryEntry {
	requestId: number;
	dateFrom: string;
	dateTo: string | null;
	targetPrice: number | null;
	minimumPrice: number | null;
	salesChannel: SalesChannel;
}

export type SaleStatus =
	| "Returned"
	| "ReadyToAllocate"
	| "ReadyToSell"
	| "Reserved"
	| "Sold"
	| "ReadyForShipping"
	| "Shipped"
	| "End"
	| "Cancelled";

export const saleStatus = {
	Returned: "Zurückgegeben",
	ReadyToAllocate: "Bereit",
	ReadyToSell: "Bereit zum Verkauf",
	Reserved: "Reserviert",
	Sold: "Verkauft",
	ReadyForShipping: "Bereit zur Auslieferung",
	Shipped: "Ausgeliefert",
	End: "Abgeschlossen",
	Cancelled: "Abgebrochen",
};

export interface VehicleSaleStatusHistoryEntry {
	status: SaleStatus;
	date: string;
}

export type SaleLocation = "Unbekannt" | "Dorfmark" | "Norderstedt";

export interface SaleOrderLine {
	text: string;
	invoiceId?: number | null;
	invoiceDate?: string | null;
	paymentDate?: string | null;
	netAmount: number;
	vatRate: number;
	vat: number;
	grossAmount: number;
}

export interface SalesCustomer {
	id: string;
	source: "Leasman" | "Other";
	name: string;
	name2: string;
	salutation: string;
	birthday: string;
	street: string;
	countryCode: string;
	zipCode: string;
	city: string;
	email: string | null;
	telefone: string;
	naturalPerson: string;
	vatId: string;
}

export interface LeasmanInvoiceLine {
	amount: number;
	deliveryDate: string;
	plateNumber: string;
	contractId: number;
	productCode: string;
	productGroup: string;
	quantity: number;
	text: string;
}

export interface LeasmanInvoice {
	id: number;
	date: string;
	invoiceType: string;
	dateBooked: string;
	dateCreated: string;
	addressId: number;
	grossAmount: number;
	netAmount: number;
	vatAmount: number;
	vatHandling: string;
	lines: LeasmanInvoiceLine[];
}

export type SaleOrderStatus = "Active" | "Invoiced" | "Paid" | "Cancelled";

export type SaleEmailsBundleType =
	| "SaleDomestic"
	| "SaleInternational"
	| "RenaultZoeBatteriesHandoverProtocolDomestic"
	| "RenaultZoeBatteriesHandoverProtocolInternational"
	| "PickupOrderDomestic"
	| "PickupOrderInternational";

export interface SaleEmail {
	mailTrackingId: string;
	dateSent: string;
	to: string;
	cc: string;
	bcc: string;
	subject: string;
	template: SaleEmailsBundleType;
	attachments: SaleDocument[];
	extraText: string;
}

export const leadOrigin = [
	"Mobile",
	"Autoscout24",
	"AldShop",
	"WalkIn",
	"Marketing",
	"Recommendations",
	"Others",
] as const;
export type LeadOrigin = (typeof leadOrigin)[number];

export interface SaleOrder {
	id: number;
	status: SaleOrderStatus;
	invoiceId: number | null;
	vin: string;
	plateNumber: string;
	tenant: string | null;
	contractId: number | null;
	channel: SalesChannel;
	dateCreated: string;
	buyer: SalesCustomer;
	lines: SaleOrderLine[];
	emails: SaleEmail[] | null;
	offerId: string;
	deliveryDate: Date;
	isReadyForDriving: boolean;
	damages: string;
	damages1: string;
	otherDamages: string;
	wasTaxi: boolean;
	hasTrailerHitch: boolean;
	otherAgreements: string;
	numberOfOwners: number;
	numberOfRealOwners: number;
	accessories: string;
	additionalAgreements: string;
	mileage: number | null;
	mileageOwner: number | null;
	hasOriginalMotor: boolean;
	replacementMotorMileage: string | null;
	dateOfferList?: string | null;
	damageAmount?: number | null;
	repairCostInsuranceType?: string | null;
	repairCostInsuranceDuration?: number | null;
	repairCostInsurancePrice?: number | null;
	leadOrigin?: LeadOrigin;
	leadOriginComment?: string;
}

export type SalesDocumentType = "PickupOrder" | "Other";

export interface SaleDocument {
	orderId?: number;
	dateUploaded: string;
	uploadedBy: User;
	file: StorageFile;
	documentType: SalesDocumentType;
	isCurrent: boolean;
}

export interface SalesShipping {
	documents?: SaleDocument[];
	shippedBy: LogisticsUser | null;
}

export interface Sale extends Entity {
	source: "Carlos" | "Arms";
	vin: string;
	plateNumber: string;
	leaseplanInvoiceNumber?: string;

	vehicle: {
		id: string;
		leasmanId: string;
		fleetnetId: string;
		type: VehicleType;
		businessLine: string;
		dateFirstRegistered: string;
		highlights: EquipmentHighlight[];
	};

	contract: {
		id: number;
		tenant: string;
		duration: number;
		mileage: number;

		dateReturned: string;
		dateStopped: string;
	};

	assessment?: {
		id: string;
		type: AssessmentType;
		date: string;
		repairCosts: number;
		totalDamageAmount: number;
		depreciationAmount: number;
		color: VehicleAssessmentColor;
	};

	highestBid: SaleBid | null;

	previousOwners: number;
	dateSold: string;
	salesAmount: number | null;
	orderNumber: string | null;
	customerName: string;
	salesRepName: string;
	requestId: string;
	status: SaleStatus;
	isBlocked: boolean;
	statusHistory: History<SaleStatus>;
	salesChannel: SalesChannel;
	returnDate: string;
	returnMileage: number | null;
	numberOfOwners: number;
	hadAccident: boolean;
	hadEngineReplacement: boolean;
	engineReplacementMileage?: number;
	hasAdditionalTires: boolean;
	tiresAndRims: TiresAndRims | null;

	totalRefurbishmentCosts: number | null;
	totalDepreciationAmount: number | null;
	revisedDamageAmount: number | null;
	ekzgv?: number;

	assessmentDate: string | null;
	market1Price: number | null;
	market2Price: number | null;
	pricingDate: string | null;

	praicemanPrediction: PraicemanPrediction | null;

	salesLocation: SaleLocation;
	dateSaleUpdated: string;

	countOfBidRounds?: number;
	maxAmountBidRounds?: number;

	offers: SaleOffer[];
	orders: SaleOrder[];
	documents: SaleDocument[];

	corrections: SaleCorrection[];

	shipping?: SalesShipping | null;
	dateShipped: string | null;
}

export interface PraicemanPrediction {
	dateCreated: string;
	id: string;

	dateSold: string;
	mileage: number;

	carmarket: PriceWithRanges;
	retail: PriceWithRanges;

	modelVersion: string;
}

export type SaleCorrectionStatus = "Created" | "Cancelled" | "Applied";

export interface SaleCorrection {
	id: string;
	status: SaleCorrectionStatus;
	statusHistory: History<SaleCorrectionStatus>;

	dateCreated: string;
	createdBy: LogisticsUser;

	dateCancelled?: string;
	cancelledBy?: LogisticsUser;

	dateApplied?: string;
	appliedBy?: LogisticsUser;

	fleetnetVehicleId?: string;
	leasmanVehicleId?: string;
	assessmentId?: string;

	categories: EquipmentHighlightReference[];
	previousOwners: number;
	mileage?: number;
}

export type SaleOfferStatus =
	| "Pricing"
	| "Publishing"
	| "PublishingFailed"
	| "Active"
	| "Reserved"
	| "Stopping"
	| "Stopped"
	| "Archived"
	| "Sold";

export interface SalePricing {
	datePriced: string;
	pricedBy: LogisticsUser;
	approvedBy: LogisticsUser;
	startPrice: number;
	minimumPrice: number;
	targetPrice: number;
	comment?: string;
}

export type SaleBidStatus = "Active" | "Withdrawn" | "Reserved" | "Sold";

export interface SaleBidWithdrawal {
	date: string;
	approvedBy: LogisticsUser;
	comment: string;
}

export interface SaleBid {
	id: string;
	status: SaleBidStatus;
	statusHistory: History<SaleBidStatus>;
	date: string;
	createdBy: LogisticsUser;
	price: number;
	name: string;

	externalOfferId: string;
	externalBidderId: string;
	bidder: SaleBidder | null;

	isBestBid: boolean;
	isManualBid: boolean;

	withdrawal: SaleBidWithdrawal | null;
}

export interface SaleBidder {
	accountId: string;
	contactId: string;

	company: string;
	firstName: string;
	lastName: string;
	email: string;

	country: string;
	zipCode: string;
	city: string;
	street: string;

	externalId: string;
	vatId: string;
}

export interface SaleOffer {
	id: string;
	status: SaleOfferStatus;
	statusHistory: History<SaleOfferStatus>;

	dateCreated: string;
	datePublished?: string;
	dateSold?: string;
	dateWithdrawn?: string;

	channel: SalesChannel;
	pricing?: SalePricing;
	pricingHistory: History<SalePricing>;
	pricingTag?: SalePricingTag;
	leasing?: SaleOfferLeasing | null;

	externalOffer: SaleExternalOffer;
	externalOfferHistory: History<SaleExternalOffer>;

	bids: SaleBid[];
	highestBid: SaleBid | null;
	reservedBid: SaleBid | null;

	canPublish: boolean;
	canSetPricing: boolean;
	canSell: boolean;
	canStop: boolean;
	canDelete: boolean;
	canArchive: boolean;
	canReactivate: boolean;
}

export interface SaleOfferLeasing {
	active: boolean;
	manualOverride: boolean;
	changedBy: LogisticsUser;
	dateChanged: string;
}

export interface SaleExternalOffer {
	id: string;
	name: string;

	type: "CarmarketSale";
	status: SaleExternalOfferStatus;
	statusHistory: History<SaleExternalOfferStatus>;

	startDate: string;
	plannedEndDate: string;
	effectiveEndDate?: string;

	firstConfirmation?: ExternalOfferConfirmation;
	secondConfirmation?: ExternalOfferConfirmation;

	bestOfferAmount?: number | null;
	bestOfferTrader?: SaleExternalTrader | null;
}

export interface ExternalOfferConfirmation {
	dateConfirmed: string;
	user: LogisticsUser;
}

export type SaleExternalOfferStatus = "Active" | "Stopped" | "Closed" | "Awarded";

export interface SaleExternalTrader {
	id: string;
	externalReference: string;
}

export interface SalePricingTag {
	dateCreated: string;
	batchId: string;
	file: FileReference;
}

interface DekraAuftragsdaten {
	besichtigungsdatum: string;
}

export interface DekraAssessment {
	id: string;
	dateCreated: string;
	auftragsnummer: string;
	vorgangsnummer: string;
	kundenreferenz: string | null;
	fahrzeugdaten: DekraFahrzeugdaten;
	auftrag: DekraAuftragsdaten;
	attachments: DekraAssessmentAttachment[];
}

export type VehicleAssessmentSource =
	| "Dfo" // Old Dekra assessments from Dekra Fleet Operator
	| "Lea" // New Dekra assessments from Dekra system called Lea
	| "Dat" // DAT assessments == "Trockenbewertungen"
	| "Sgs"; // SGS assessments for Leaseplan

export interface VehicleAssessment {
	id: string;
	vin: string;
	source: VehicleAssessmentSource;
	externalId: string;
	plateNumber: string;
	assessmentDate: string;
	dateDms: string | null;
	dateWebMobile: string | null;
	dateCompleted: string | null;
	depreciationAmount: number;
	repairCosts: number;
	totalDamageAmount: number;
	market1Price: number;
	market2Price: number;
	kbaEmissionNumber: string;
	kbaMakerNumber: string;
	kbaModelNumber: string;
	attachments: VehicleAssessmentAttachment[];
	color: VehicleAssessmentColor;
	mileage?: number;
	orderId?: string;
	dateRejected?: string;
	rejectedBy?: LogisticsUser;
	rejectionReason?: string;
	rejectionTopic?: VehicleAssessmentRejectionTopic;
}

export const vehicleAssessmentRejectionTopics = [
	"DamagePosition",
	"DamageAmount",
	"Equipment",
	"PictureProof",
	"CostEstimation",
	"DatValues",
	"Mileage",
	"Vin",
	"NotFinished",
	"Others",
] as const;
export type VehicleAssessmentRejectionTopic = (typeof vehicleAssessmentRejectionTopics)[number];

export interface VehicleAssessmentColor {
	code: string;
	paintwork: string;
	original: string;
	name: string;
}

export interface VehicleAssessmentAttachment {
	type: string;
	dmsId: string;
	storageFile: StorageFile;
}

export interface DekraFahrzeugdaten {
	akz: string;
	kbA1: string;
	kbA2: string;
	kbA3: string;
}

export interface DekraAssessmentAttachment {
	beschreibung: string;
	mimeType: string;
	file: StorageFile;
}

export interface UnregistrationOrder extends BaseOrder {
	assignedTo: CompanyReference;
	status: UnregistrationOrderStatus;
	importId?: string;
	cancellationReason?: string;
	dateCancelled?: string;
	dateProcessed?: string;
	dateCompleted?: string;
	transferInformation?: TransferInformation;
}

export interface TransferInformation {
	dateTransferred?: string;
	error?: string;
	isTransferred: boolean;
	mode: TransferMode;
}

export type TransferMode = "Manual" | "Api";

export type AssessmentOrderStatus = "Created" | "Cancelled" | "Processed" | "Completed" | "Interrupted";
export type AssessmentStatus = "Unknown" | "Transferred" | "Delayed" | "Confirmed" | "Assigned" | "Cancelled" | "Done";

export type UnregistrationOrderStatus = "Created" | "Cancelled" | "Processed" | "Completed";
export type CustomerOrderStatus = "Created" | "Cancelled" | "Completed";

export type ReturnOrdersOverviewPerspective =
	| "All"
	| "Processed"
	| "Unprocessed"
	| "Cancelled"
	| "Imported"
	| "Assigned"
	| "CustomerDelivery"
	| "CustomerCreated"
	| "UnprocessedExternalAssessments"
	| "DeliveriesToForeignAddresses";

export type AssessmentOrdersOverviewPerspective =
	| "All"
	| "Processed"
	| "Unprocessed"
	| "Cancelled"
	| "Own"
	| "Completed"
	| "Interrupted";

export type UnregistrationOrdersOverviewPerspective =
	| "All"
	| "Processed"
	| "Unprocessed"
	| "Cancelled"
	| "Own"
	| "Completed";

export type CustomerOrdersOverviewPerspective = "All" | "Created" | "Cancelled" | "Completed" | "Own";

export type ReturnOrderSessionsOverviewPerspective = "All" | "Cancelled" | "Completed";

export type NotificationPartyPerspective = "Logistik" | "Dekra" | "LogistikAndDekra";

export type ReturnOrderSessionsOverviewType = "Fileless" | "ImportBased";

export type AssessmentType = "Restwertgutachten" | "Minderwertsgutachten";

export enum ReturnOrderTireType {
	wr = "Winterreifen",
	sr = "Sommerreifen",
	gjr = "Ganzjahresreifen",
	unk = "Unbekannt",
}

export interface ReturnOrderSession extends Entity {
	assessmentCsvReference: FileReference;
	importId: string;
	unregistrationCsvReference: FileReference;
	vehicleHistoryCsvReference: FileReference;
	assessmentOverviewCsvReference: FileReference;
	status: "Created" | "Cancelled" | "Completed";
	shouldBeAccessible: boolean;
	containsAssessments: boolean;
	containsUnregistrations: boolean;
	dateDocumentsTransferred?: string;
}

export interface VehicleAddresses {
	vehicle?: VehicleReference;
	rkvAddress?: Address;
	customerAddress?: Address;
	traderAddress?: Address;
	carParkAddress?: Address;
	driverAddress?: Address;
	neussAddress?: Address;
	isRkv: boolean;
}

export interface AldVehicleDetailsDTO {
	engineType: EngineType;
	customerServiceAgent?: {
		name?: string;
		email?: string;
		telephone?: string;
	};
}

export type ImageReferences =
	| "suppliedTiresAndRims"
	| "frontLeft"
	| "backLeft"
	| "backCenter"
	| "trunk"
	| "backRight"
	| "tiresAndRims"
	| "frontRight"
	| "frontCenter"
	| "windshield"
	| "interior";

export interface HistoryEntry<T> {
	id: string;
	date: string;
	value: T;
	createdBy: LogisticsUser;
}

export type History<T> = HistoryEntry<T>[];

export type ArmadaTicketStatus = "OFFEN" | "GESCHLOSSEN" | "IN_BEARBEITUNG" | "NACHRICHT_NEU";

export interface ArmadaTicketContact {
	email: string;
	phone: string;
}

export interface ArmadaTicketUser {
	id: number;
	accountId: number;
	applicationId: string;
	username: string;
	fullName: string;
}

export interface ArmadaTicketMessage {
	id: number;
	dateCreated: string;
	dateUpdated: string;
	text: string;
	isIntern: boolean;
	isRead: boolean;
	creator: ArmadaTicketUser | null;
}

export interface ArmadaTicket {
	id: number;
	partner: string;
	dateCreated: string;
	dateUpdated: string;
	contractId: number;
	plateNumber: string;
	category: string;
	status: ArmadaTicketStatus;
	text: string;
	clientId: number;
	dealerId: number;
	assigneeId: number;
	contact: ArmadaTicketContact;
	messages: ArmadaTicketMessage[];
	sync?: SyncReference;
}

export interface SyncReference {
	id: string;
	date: string;
	status: "Planned" | "Started" | "Failed" | "Succeeded" | "Cancelled";
}

export interface VehiclePricing {
	dateProposed: string;
	dateConfirmed: string;

	price?: Price;

	user1: LogisticsUser;
	user1Name: string;

	user2: LogisticsUser;
	user2Name: string;

	isProposed: boolean;
	isConfirmed: boolean;
}

export interface PricingRequest extends Entity {
	vin: string;
	plateNumber: string;

	leasmanVehicleId: string;

	tenant: string;
	brand: string;
	contractId: number;
	dateContractEnd: string;
	dateFirstRegistered: string;
	hasBuybackAgreement: boolean;
	residualValue: number;

	type: VehicleType;
	highlights: EquipmentHighlightReference[];

	armadaTicket?: ArmadaTicket;
	praicemanPrediction?: PraicemanPrediction;

	status: PricingRequestStatus;
	statusHistory: HistoryEntry<PricingRequestStatus>[];

	datePurchase?: string;
	purchaser?: PricingRequestPurchaser;
	mileage?: number;
	mileagePictureProof?: FileReference;

	price?: Price;
	pricing?: VehiclePricing;

	customerOrderId?: string;

	pricingExportFile?: FileReference;
	pricingImportFile?: FileReference;

	completedNotification?: MailReference;
	sync?: SyncReference;
}

export interface MailReference extends EntityReference {
	from: string;
	to: string[];
	subject: string;
	attachments: string[];
	mailjetResponses: MailJetResponse[];
}

export interface MailJetResponse {
	email: string;
	messageUid: string;
	messageId: string;
}

export type PricingRequestStatus = "Created" | "Requested" | "Pricing" | "Priced" | "Completed" | "Withdrawn";

export type PricingRequestPurchaser = "Driver" | "Lessee" | "Dealer" | "BusinessClient";

export interface StorageFile {
	hash: string;
	date: string;
	filename: string;
}

export type WorkItemType =
	| "Vehicle"
	| "Sale"
	| "Presale"
	| "InventoryVehicle"
	| "PricingRequest"
	| "DirectSale"
	| "DocumentHandover"
	| "FailedCarmarketPublishAttempts"
	| "VehicleRepairOrder"
	| "AssessmentOrder";

export interface WorkItem {
	nr: number;
	type: WorkItemType;

	id: string;
	parameters: {
		[key: string]: string;
	};
}

export type WorkSessionPerspective =
	| "Manual"
	| "Presales"
	| "BlockedPresales"
	| "ActiveSales"
	| "SalesWithoutPricing"
	| "PotentialRetailSales"
	| "OverdueRetailSales"
	| "SalesWithoutPriceChanges"
	| "ReservedCarmarketSales"
	| "ReservedCarmarketSalesToday"
	| "CarmarketSalesEvaluation"
	| "OpenPricingRequests"
	| "DocumentHandovers"
	| "FailedCarmarketPublishAttempts"
	| "StoppedSales";

export interface WorkSession extends Entity {
	perspective: WorkSessionPerspective;
	title: string;
	referenceId?: string;

	items: WorkItem[];
	current: number;

	isPublic: boolean;
	participants?: LogisticsUser[];
}

export interface WorkSessionUserNavigation {
	sessionId: string;
	user: LogisticsUser;
	date: string;
	current: number;
	url: string;
}

export interface HistoryEntry<T> {
	id: string;
	date: string;
	value: T;
}

export const jobStatusList = [
	"Created",
	"Scheduled",
	"Running",
	"Completed",
	"Cancelled",
	"Failed",
	"Deleted",
] as const;
export type JobStatus = (typeof jobStatusList)[number];

export interface Job {
	id: string;
	application: string;
	worker: string;

	dateCreated: string;
	dateScheduled?: string;
	dateLastActive: string;

	status: JobStatus;

	type: string;
	method: string;
	args: string;
	cancel: boolean;

	invocations: JobInvocation[];
	cronJob: CronJob;
}

export interface JobInvocation {
	id: string;
	worker: string;
	dateCreated: string;
	dateCompleted?: string;
	status: JobStatus;
	message?: string;
	exception?: string;
}

export interface CronJob {
	expression: string;
	statistics: {
		[status in JobStatus]?: {
			status: JobStatus;
			firstRun: string;
			lastRun: string;
			count: number;
		};
	};
}

export interface Equipment {
	id: number;
	typeId?: number;

	text: string;

	kind: string;
	isExtra: boolean;

	categories: EquipmentCategory[];
}

export interface EquipmentCategoryIdNamePair {
	id: number;
	name: string;
}

export interface EquipmentCategory extends EquipmentCategoryIdNamePair {
	group: EquipmentCategoryIdNamePair;
	subGroup: EquipmentCategoryIdNamePair;
}

export interface LeasmanOrderInvoiceLine {
	id: number;
	netAmount: number;
	grossAmount: number;
	taxAmount: number;
	taxRate: number;
}

export interface LeasmanOrderInvoice {
	id: number;
	nr: string;
	netAmount: number;
	grossAmount: number;
	lines: LeasmanOrderInvoiceLine[];
}

export interface LeasmanOrder {
	nr: string;
	traderId: number;
	dateExpected: string | null;
	dateDelivered: string | null;
	taxRate: number;
	discount: number;
	invoices: LeasmanOrderInvoice[];
	calculatedNetAmount: number;
	calculatedGrossAmount: number;
}

export interface LeasmanVehicleQuery {
	tenants?: string[];

	fullText?: string;

	vin?: string; // not in API
	vins?: string[];

	plateNumber?: string; // not in API
	plateNumbers?: string[];

	contractId?: number; // not in API
	contractIds?: number[];

	customerIds?: number[];

	includeDetails?: boolean;
}

export interface LeasmanVehicle {
	id: string;

	tenant: string;
	brand: string;
	objectId: number;

	contract: LeasmanContract;
	order?: LeasmanOrder;

	vin: string;
	plateNumber: string;
	dateFirstRegistered: string;

	type: VehicleType;
	equipment: Equipment[];
}

export interface LeasmanContract {
	id: number;
	type: LeasmanContractType;
	status: LeasmanStatus;

	customer: LeasmanVehicleAddress;
	serviceAgent: ContactPerson;

	duration: number;
	mileage: number;

	begin: {
		date: string;
		mileage: number;
	};

	end: {
		date?: string;
	};

	stop: {
		date?: string;
	};

	return: {
		date?: string;
		mileage?: number;
	};

	finance: LeasmanContractFinance | null;
	services: LeasmanContractService[];
	buyback: LeasmanContractBuyback | null;
}

export type LeasmanContractType =
	| "Mileage"
	| "ResidualValue"
	| "External"
	| "Service"
	| "Supplementary"
	| "Finance"
	| "Installment";

export interface LeasmanContractBuyback {
	hasAgreement: boolean;
}

export interface LeasmanContractFinance {
	duration: number;
	yearlyMileage: number;
	residualValue: number;
	fee: number;
	adminFee: number;

	mileage: {
		over: {
			limit: number;
			tolerance: number;
			rate1: number;
			rate2: number;

			services: {
				limit: number;
				tolerance: number;
			};
		};
		under: {
			limitMin: number;
			limitMax: number;
			tolerance: number;
			rate1: number;
			rate2: number;

			services: {
				limitMin: number;
				limitMax: number;
				tolerance: number;
			};
		};
	};
}

export interface LeasmanVehicleDetail {
	tenant: string;
	contractId: number;
	objectId: number;

	equipment: Equipment[];
	orderInvoices: LeasmanOrderInvoice[];
	accidents: LeasmanAccident[];
	contractServices: LeasmanContractService[];
}

export type LeasmanAccidentStatus = "Created" | "Active" | "Closed" | "Lawyer";

export interface LeasmanAccidentDamage {
	id: number;
	type: string;
}

export interface LeasmanAccident {
	id: number;
	date: string;
	dateClosed: string | null;
	status: LeasmanAccidentStatus;
	expectedDamageAmount: number | null;
	residualValue: number | null;
	fullKasko: boolean;
	partialKasko: boolean;
	liability: boolean;
	damages: LeasmanAccidentDamage[];
}

export interface LeasmanContractService {
	id: number;
	type: {
		id: string;
		name: string;
	};
	validFrom: string | null;
	validTo: string | null;
	fee: number;
	adminFee: number;

	mileage: {
		over: {
			rate1: number;
			rate2: number;
		};
		under: {
			rate1: number;
			rate2: number;
		};
	};
}

export interface LeasmanContractCustomer {
	id: number;

	name1: string;
	name2: string;

	email: string;
	phone: string;
	fax: string;

	street: string;
	postal: string;
	city: string;
	country: string;
	countryIsoCode: string;
}

export interface CatalogEquipment extends Equipment {
	dateCreated: string;
	current: CatalogEquipmentVersion;
	versions: CatalogEquipmentVersion[];
}

// export type CatalogEquipmentKind = "Regular" | "Optional" | "Unavailable";

export interface CatalogEquipmentVersion {
	id: number;
	typeId: number;

	from: string;
	to?: string;

	price: Price | null;
	flag: number;
}

export interface CatalogVehicle extends CatalogPreview {
	equipment: CatalogEquipment[];
}

export type ThemeColor = "info" | "success" | "warning" | "error" | "primary" | "secondary" | "grey";

export interface CatalogPreviewQuery {
	make?: string;
	model?: string;
	fullText?: string;
}

export interface CatalogPreview {
	id: number;
	type: VehicleType;
}

export interface FleetnetVehicleQuery {
	plateNumber?: string;
	vin?: string;
	clientNr?: number;
	nr?: number;

	skip?: number;
	take?: number;
}

export interface FleetnetClient {
	nr: number;
	name: string;
}

export interface FleetnetDriver {
	id: number;
	nr: number;
	title: string;
	firstName: string;
	lastName: string;
	personalNr: string;
	group: string;
	street: string;
	city: string;
	phone: string;
	mobile: string;
	email: string;
	isVip: boolean;
}

export interface FleetnetVehicle {
	id: number;
	nr: number;

	client: FleetnetClient;
	driver: FleetnetDriver;

	vin: string;
	plateNumber: string;
	dateFirstRegistered: string;
	dateUnregistered?: string;
	type: VehicleType;
	equipment: Equipment[];
}

export interface FleetnetVehicleReference {
	id: number;
	nr: number;

	client: FleetnetClient;
	driver: FleetnetDriver;
}

export type BatchFileType =
	| "Unknown"
	| "PricingTags"
	| "WebMobilSales"
	| "RepairOrders"
	| "DadArrivalTransmission"
	| "BcaSales";

export type BatchFileStatus = "Planned" | "Created" | "Failed";

export interface BatchFile extends Entity {
	status: BatchFileStatus;
	type: BatchFileType;
	file: FileReference;
	entries: number;
	mailReferences: MailReference[];

	pricingTags?: BatchFilePricingTags;
	repairOrders?: BatchFileRepairOrders;
	arrivalTransmission?: BatchFileArrivalTransmission;
}

export interface BatchFileQuery {
	types?: BatchFileType[];
	pricingTags?: BatchFilePricingTags;
}

export interface MailReference {
	referenceCreated: string;
	from: string;
	subject: string;
	attachments: string[];
}

export interface BatchFilePricingTags {
	location: SaleLocation;
	firstTimeBatching?: boolean;
}

export interface BatchFileRepairOrders {
	company: CompanyReference;
	compound: CompoundReference;
}

export interface BatchFileArrivalTransmission {
	compound: CompoundReference;
	businessLines: string[];
}

export interface RepairOrder extends BaseOrder {
	company?: CompanyReference;
	compound?: CompoundReference;

	status: RepairOrderStatus;
	statusHistoryTemp: History<RepairOrderHistoryEntry>;
	repairWorks: RepairWork[];
	notes: Note[];
	dateAssigned?: string;
	dateAssignable?: string;
	dateCompleted?: string;
	repairOrderType: RepairOrderType;
	vehicleAssessmentDefectInfo: VehicleAssessmentDefectInfo;
	costEstimate: RepairWorkCostEstimate;
	comment: string;
	creationMethod: AssessmentOrderCreationMethod
}

export interface RepairOrderHistoryEntry {
	status: RepairOrderStatus;
	workshopName?: string;
}

export interface VehicleAssessmentDefectInfo {
	isExternalAssessment: boolean;
	mileage?: number;
	hasCarsChequebook?: boolean;
	isReadyToDrive?: boolean;
	dateLastMaintenance?: string;
	recipients?: string[];
}

export interface Workshop {
	name: string;
}

export type RepairOrderType = "Carglass" | "AssessmentReport" | "GeneralRepair";

export type RepairOrderStatus =
	| "Created"
	| "Assigned"
	| "Ordered"
	| "Cancelled"
	| "Completed"
	| "Assignable"
	| "CostEstimated";

export type RepairWorkCostEstimateStatus = "Accepted" | "Rejected";

export interface RepairWorkCostEstimate {
	document: FileReference;
	status: RepairWorkCostEstimateStatus;
}

export interface RepairWork {
	description: string;
	repairTask: RepairTask;
}

export type RepairTask = "None" | "WindshieldReplacement" | "WindshieldSmartRepair" | "General";

export type RepairOrderPerspective = "All" | "Actionable";

export type CarmarketSaleType = "auction" | "fixedPrice" | "tender";

export interface CarmarketSale {
	id: string;
	name: string;
	code: string;
	description: string;
	type: CarmarketSaleType;
	status: CarmarketSaleStatus;
	saleChannelCode: string;
	isPrivate: boolean;
	startDate: string;
	plannedEndDate: string;
	effectiveEndDate?: string;
	publishedDate?: string;
	awardedDate?: string;
	businessUnitCode: string;
	brandCode: string;
	vehicles?: CarmarketSaleVehicleReference[];
	offers?: CarmarketSaleOffer[];
	outcomes?: CarmarketSaleOutcome[];
}

export type CarmarketSaleStatus = "Created" | "Published" | "Opened" | "Closed" | "Awarded" | "Withdrawn";
export type CarmarketSaleVehicleStatus = "NotReady" | "Ready" | "Allocated" | "Sold";
export type CarmarketSaleOutcomeStatus = "Pending" | "Awarded" | "Withdrawn";

export interface CarmarketSaleVehicleReference {
	vin: string;
	externalId: string;
	status: CarmarketSaleVehicleStatus;
	plateNumber: string;
}

export interface CarmarketVehicleReference {
	vin: string;
	externalReference: string;
	startingPrice?: number;
	targetPrice?: number;
	reservePrice?: number;
	buyNowPrice?: number;
}

export interface CarmarketSaleOffer {
	date: string;
	amount: number;
	vehicle: CarmarketVehicleReference;
	trader?: CarmarketTraderReference;
}

export interface CarmarketTraderReference {
	id: string;
	externalReference?: string;
}

export interface CarmarketSaleOutcome {
	awardedDateUtc?: string;
	status: CarmarketSaleOutcomeStatus;
	vehicle: CarmarketVehicleReference;
	amount?: number;
	awardedTrader?: CarmarketTraderReference;
	bestOfferAmount?: number;
	bestOfferTrader?: CarmarketTraderReference;
}

export interface SaleMarkers {
	initialPricing?: boolean;
	retailCandidate?: boolean;
	carmarketPublishFailure?: boolean;
	reservedWithoutValidKyc?: boolean;
	pickupOrderSent?: boolean;
}

export interface NumberQuery {
	equals?: number;
	greaterThan?: number;
	greaterThanOrEquals?: number;
	lessThan?: number;
	lessThanOrEquals?: number;
}

export interface KpisQuery {
	age?: NumberQuery;
}

export interface SalesKpisQuery extends KpisQuery {
	status?: SaleStatus[];
	channels?: string[];
	locations?: SaleLocation[];
	markers?: SaleMarkers;
	ageReserved?: NumberQuery;
	agePriced?: NumberQuery;
}

export interface VehicleMarkers {
	preSaleCandidate?: boolean;
	saleStop?: boolean;
	readyForSale?: boolean;
	openAssessment?: boolean;
	openUnregistration?: boolean;
}

export interface VehiclesKpisQuery extends KpisQuery {
	markers?: VehicleMarkers;
}

export interface PricingRequestsKpisQuery extends KpisQuery {
	status?: PricingRequestStatus[];
}

export interface KpisGroupSection<TQuery> {
	groups: KpisGroup<TQuery>[];
}

export interface KpisGroup<TQuery> {
	name: string;
	tags: string[];
	query: TQuery;

	total: number;
	ages: number[];

	sections: KpisGroupSection<TQuery>[];
}

export interface SalesKpis {
	active: KpisGroup<SalesKpisQuery>;
	reserved: KpisGroup<SalesKpisQuery>;
	carmarket: KpisGroup<SalesKpisQuery>;
	manualResalePlattforms: KpisGroup<SalesKpisQuery>;
	sold: KpisGroup<SalesKpisQuery>;
	paid: KpisGroup<SalesKpisQuery>;
	withoutPickupOrderSent: KpisGroup<SalesKpisQuery>;
}

export interface VehiclesKpis {
	stock: {
		today: number
		todayByCompound: CompoundsKpis[]
		lastWeek: number
		in: number
		expectedReturns: number
		out: number
	}
	presales: {
		unassessed: number
		missingPhotos: number
		missingAssessmentOrders: number
	}
}
export interface CompoundsKpis {
	compoundName: string
	amount: number
}

export type WorkSessionSalePreparationsPerspective = "OpenUnregistrations" | "OpenAssessments" | "MissingPictures" | "NotRequestedDocuments" | "MissingAssessmentOrders" | "MissingUnregistrationOrders";

export interface VehicleForSaleKpis {
	preparation: KpisGroup<VehiclesKpisQuery>;
}

export interface PricingRequestsKpis {
	requests: KpisGroup<PricingRequestsKpisQuery>;
}

export interface VehicleRegistrationDocumentsProjection {
	id: string;
	vin: string;
	plateNumber: string;
	makeModel: string;
	isUnregistered: boolean;
	businessLine: string;
	registrationDocuments: RegistrationDocuments;
}

export type PoolFleetVehicleStatus =
	| "InPreparation"
	| "ReadyForRent"
	| "Reserved"
	| "InDelivery"
	| "Rented"
	| "InReturn"
	| "Unavailable";

export type PoolFleetLotStatus = "Refurbishing" | "Registration" | "Ready" | "Unknown";

export interface PoolFleetCategory {
	name: string;
}

export interface PoolFleetProduct {
	name: string;
	categories: PoolFleetCategory[];
}

export interface PoolFleetVehicleHistoryItem {
	date: string;
	status: PoolFleetVehicleStatus;
	lotStatus: PoolFleetLotStatus;
	text: string | null;
	user: LogisticsUser;
}

export interface PoolFleetCustomer {
	id: number;
	name1: string;
	name2: string;
	street: string;
	zipCode: string;
	city: string;
	email: string | null;
	telefone: string | null;
	mobile: string | null;
}

export type PoolFleetContractStatus = "OFFER" | "ORDER" | "ACTIVE" | "INACTIVE";

export interface PoolFleetContract {
	contractId: number;
	startDate?: string;
	endDate?: string;
	duration: number;
	mileage: number;
	rate: number;
	status: PoolFleetContractStatus;
	customer: PoolFleetCustomer;
	includeDelivery: boolean;
	deliveryOrderId: string | null;
	pickupOrderId: string | null;
	desiredDeliveryDate: string | null;
	deliveryAddress: Address | null;
	deliveryContact: ContactPerson | null;
}

export interface PoolFleetVehicle {
	contractId: number;
	contractStatusId: number;
	plateNumber: string;
	makeModel: string;
	vehicleId: number;
	vin: string;
	status: PoolFleetVehicleStatus;
	lotStatus: PoolFleetLotStatus;
	currentContract: PoolFleetContract | null;
	contracts: PoolFleetContract[];
	transitions: PoolFleetVehicleStatus[];
	categories: { [name: string]: string };
	history: {
		entries: PoolFleetVehicleHistoryItem[];
	};
}

export interface ArrivalTransmissionNote {
	text: string;
	arrivalTransmissionId: string;
	dateCreated: string;
	createdBy: LogisticsUser;
}

export type DateFormat = "Relative" | "Absolute" | "RelativeOnlyToday";

export const dorfmarkAddress: Address = {
	name: "ALD AUTOLEASING D GMBH",
	street: "Becklinger Str. 23",
	zipCode: "29683",
	city: "Dorfmark",
	country: "Deutschland",
};

export const badFallingBostelAddress: Address = {
	name: "DEKRA Automobil GmbH",
	street: "Bockhorner Weg 9",
	zipCode: "29683",
	city: "Bad Fallingbostel",
	country: "Deutschland",
};

export const neussContactInfo: ContactInformation = {
	firstName: "Massimo",
	lastName: "Mariotti",
	email: "massimo.mariotti@atneuss.com",
	telephone: "02131-977-562",
};

export interface TransportPrice {
	numberOfVehicle: number;
	pricePerVehicle: number;
}

export interface AreaPricing {
	areaCode: string;
	prices: TransportPrice[];
}

export interface TransportPricing extends Entity {
	company: CompanyReference;
	missedTransportPrice: number | null;
	areasPricing: AreaPricing[];
}

export interface KpisAge {
	fromAge: number;
	count: number;
}

export interface CapacityKpisGroupSection<TQuery> {
	groups: CapacityKpisGroup<TQuery>[];
}

export interface CapacityKpisGroup<TQuery> {
	id: string;
	name: string;
	tags: string[];
	query: TQuery;
	capacity?: number;
	total: number;
	ages: KpisAge[];
	sections: CapacityKpisGroupSection<TQuery>[];
}

export interface RepairOdersKpisQuery extends KpisQuery {
	status?: string;
	company?: string;
}

export interface RepairOrdersKpis {
	all: CapacityKpisGroup<RepairOdersKpisQuery>;
	ordered: CapacityKpisGroup<RepairOdersKpisQuery>;
	costEstimated: CapacityKpisGroup<RepairOdersKpisQuery>;
}

export interface AssessmentFilters {
	hasTransportOrder?: boolean;
	isTransportOrderCompleted?: boolean;
	isOrderedOutsideDorfmark?: boolean;
	hasRepairOrder?: boolean;
	hasSubjectInquiries?: boolean;
	isAssessmentRejected?: boolean;
}

export interface AssessmentsKpisQuery extends KpisQuery {
	filters?: AssessmentFilters;
	statusOptions?: string;
	status?: string;
}

export interface AssessmentsKpis {
	all: CapacityKpisGroup<AssessmentsKpisQuery>;
}

export interface InvoiceReference {
	invoiceId: string;
	invoiceDate: string;
	invoiceNumber: string;
	netAmount: number;
}

export interface InvoiceLineOccuranceDTO {
	plateNumber: string;
	invoices: InvoiceReference[];
}

export interface SaleQuery {
	fullText?: string;

	vehicleId?: string;
	vin?: string;

	dateFrom?: string;
	makes?: string[];
	models?: string[];
	modelVariants?: string[];
	modelVersions?: string[];

	highlights?: string[];
	highlightsShouldMatch?: number;

	duration?: {
		from?: number;
		to?: number;
	};

	mileage?: {
		from?: number;
		to?: number;
	};

	retail?: boolean;
	sold?: boolean;
	status?: SaleStatus[] | null;
	previousOwners?: number | null;

	isStopped?: boolean | null;
	channel?: string;
}

export interface BcaAuction extends Entity {
	name: string;
	origin: FileReference;
	results: FileReference;
	status: BcaAuctionStatus;
	vehicles: BcaAuctionVehicle[];
	sales: BcaAuctionSaleReference[];
	mails: MailReference[];
}

export interface BcaAuctionSaleReference extends EntityReference {
	id: string;
	vin: string;
	plateNumber: string;
	nr: number;
}

export type BcaAuctionStatus = "Closed" | "Awarded";

export type BcaAuctionResult = "NotSold" | "Sold";

export interface BcaAuctionVehicle {
	auctionName: string;
	vin: string;
	inventoryId: number | null;
	catalogId: number | null;
	result: BcaAuctionResult;
	make: string;
	model: string;
	buyerName: string;
	buyerNr: number | null;
	referenceId: string;
	plateNumber: string;
	dateFirstRegistered: string;
	mileage: number | null;
	minimumPrice: Price;
	highestBid: Price;
	priceDifference: Price;
	highestBidLastAuction: Price;
	numAuctions: number;
	numBidders: number;
	numBids: number;
}

export interface BcaAuctionQuery { }
