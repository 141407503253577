import React from "react";
import { Link, TableCell, TableRow, TableRowProps, Typography } from "@mui/material";
import { AversiSessionProduct } from "./AversiSessionsPage";
import formatCurrency from "../../system/formatCurrency";
import _ from "lodash";

interface Props extends TableRowProps {
	type: string
	refAmount?: number | null
	products: AversiSessionProduct[]
	onClick: () => void
}

const AversiSessionDetailRow = ({ type, refAmount, products, onClick, ...rest }: Props) => {
	const total = _.sum(products.map(i => i.amount));
	const color = refAmount !== undefined && refAmount !== null && Math.abs(refAmount - total) > 0.001 ? "secondary" : "textPrimary";

	const hf = products.find(i => i.productCode === "HF")?.amount || 0;
	const vk = products.find(i => i.productCode === "VK")?.amount || 0;
	const misc = total - hf - vk;

	return (
		<TableRow {...rest}>
			<TableCell>
				<Link href="#" onClick={() => onClick()}>
					{type}
				</Link>
			</TableCell>
			<TableCell align="right">
				{formatCurrency(total)}
			</TableCell>
			<TableCell align="right">
				{refAmount !== null && refAmount !== undefined ? (
					<Typography variant="body2" color={color}>{formatCurrency(refAmount || 0)}</Typography>
				) : (
					<span>-</span>
				)}
			</TableCell>
			<TableCell align="right">{formatCurrency(hf)}</TableCell>
			<TableCell align="right">{formatCurrency(vk)}</TableCell>
			<TableCell align="right">{formatCurrency(misc)}</TableCell>
		</TableRow>
	)
};

export default AversiSessionDetailRow;
