import React from 'react';
import { Form } from "../../../system/useForm";
import { Box, Grid, Typography } from "@mui/material";
import { CustomerOrder, CustomerOrderAddressInformation } from "../../../system/Domain";
import FormTextField from "../../../Components/FormTextField";
import FormDateTimePicker from "../../../Components/FormDateTimePicker";
import TextBlock from "../../../Components/Layout/TextBlock";
import CustomerQuickFAQ from "../CustomerQuickFAQ";
import CollectionRadioChoice from "../Collection/CollectionRadioChoice";
import moment from "moment";

interface Props {
	form: Form
	aldAddressData: CustomerOrderAddressInformation
	customerOrder: CustomerOrder
}

const StepCollection = (props: Props) => {
	const entryType = props.form.getValue("collection.collectionType") ?? "";

	const canEdit = entryType === "CustomEntry";

	const collectionMinDate =
		props.customerOrder.allowEarlyReturn
			? moment().add(1, 'd').toString()
			: props.customerOrder.dateContractEnd;

	return (
		<Box mt={2} mb={2}>
			<Box mt={2}>
				<Typography variant="h6" gutterBottom>
					Abholung von
				</Typography>
			</Box>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<CollectionRadioChoice
						name="collection.collectionType"
						form={props.form}
						aldAddressData={props.aldAddressData}
						required
						isTraderOrder={props.customerOrder.isTraderOrder}
					/>
				</Grid>
			</Grid>
			{entryType && (
				<>
					<Box mt={4}>
						<Typography variant="h6" gutterBottom>
							Ort
						</Typography>
					</Box>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<FormTextField
								form={props.form}
								fullWidth
								name="collection.address.name"
								label="Name"
								disabled={!canEdit}
								required
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormTextField
								form={props.form}
								fullWidth
								name="collection.address.street"
								label="Straße und Hausnummer"
								disabled={!canEdit}
								required
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormTextField
								form={props.form}
								fullWidth
								name="collection.address.additionalInformation"
								label="Adresszusatz"
								disabled={!canEdit}
								maxLength={30}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<FormTextField
								form={props.form}
								disabled={!canEdit}
								name="collection.address.zipCode"
								label="PLZ"
								required
								maxLength={5}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<FormTextField
								form={props.form}
								required
								name="collection.address.city"
								label="Stadt"
								fullWidth
								disabled={!canEdit}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<FormTextField
								form={props.form}
								required
								name="collection.address.country"
								defaultValue="Deutschland"
								label="Land"
								fullWidth
								disabled={!canEdit}
							/>
						</Grid>
					</Grid>
					<Box mt={4}>
						<Typography variant="h6" gutterBottom>
							Ansprechpartner
						</Typography>
					</Box>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6}>
							<FormTextField
								form={props.form}
								required
								name="collection.contact.firstName"
								label="Vorname"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormTextField
								form={props.form}
								required
								name="collection.contact.lastName"
								label="Nachname"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormTextField
								form={props.form}
								required
								name="collection.contact.email"
								label="E-Mail"
								fullWidth
								email
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormTextField
								required
								form={props.form}
								name="collection.contact.telephone"
								label="Telefon"
								fullWidth
							/>
						</Grid>
					</Grid>

					<Box mt={4}>
						<Typography variant="h6">
							Frühestens abholbereit <CustomerQuickFAQ faqKey="collection-capacity-warning" open boxProps={{ pb: 0 }} />
						</Typography>
					</Box>
					<Box>
						<FormDateTimePicker
							name="collection.appointment.notBefore"
							form={props.form}
							label="ab dem"
							margin="dense"
							variant="date"
							format="DD.MM.YYYY"
							minDate={collectionMinDate}
							disablePast
							disableWeekends
							required
						/>
						<TextBlock
							secondary="Unser Logistikdienstleister wird sich ab dem angegebenen Datum bei Ihnen melden."
							tertiary={<em>Der Abholtermin kann je nach Verfügbarkeit variieren.</em>}
						/>
					</Box>
					<Box mt={4}>
						<Typography variant="h6" gutterBottom>
							Zusatzinformationen
						</Typography>
					</Box>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<FormTextField
								form={props.form}
								name="collection.comment"
								label="Bemerkung"
								fullWidth
							/>
						</Grid>
					</Grid>
				</>
			)}
		</Box>
	);
};

export default StepCollection;
