import React from "react";
import { ReactNode } from "react";
import { Box } from "@mui/material";

interface Props {
	primary: ReactNode
	secondary: string | ReactNode
	onClick: () => void
}

export default ({primary, secondary, onClick}:Props)=>{
	return (
		<Box display="flex" height="100%" flexDirection="column" onClick={onClick}>
			<Box display="flex" height="66%" alignItems="center" justifyContent="center">
				{primary}
			</Box>
			<Box display="flex" height="34%" alignItems="center" justifyContent="center" pb="10%" pl="5%" pr="5%" textAlign="center">
				{secondary}
			</Box>
		</Box>
	);
}
