import React, { useState } from "react";
import { Box, Chip, TableBody, TableHead, TableRow, useTheme } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import {
	Import,
	Remarketing,
	RemarketingAssessment,
	RemarketingUnregistration,
	ReturnOrder,
	TransportOrder,
	UserRole
} from "../../../system/Domain";
import TextBlock from "../../../Components/Layout/TextBlock";
import TransportDirection from "./TransportDirection";
import PaperTable from "../../../Components/PaperTable";
import { useAsync } from "react-use";
import { Block, Check } from "@mui/icons-material";
import useLogisticCompanies from "../../../system/useLogisticCompanies";
import ListItemChip from "../../../Components/ListItemChip";
import ListItemChips from "../../../Components/ListItemChips";
import alterReturnOrderAssessmentInformation from "./alterReturnOrderAssessmentInformation";
import { calculateAssessmentStatus } from "./AssessmentStatusActionTile";
import { calculateUnregistrationStatus } from "./UnregistrationStatusActionTile";
import AlterReturnOrderUnregistrationInformation from "./AlterReturnOrderUnregistrationInformation";
import useUser from "../../../system/useUser";
import { Col, TableCell } from "../../../Components/BreakpointStyledComponents";

interface ImportReferencesLine {
	transportOrder: TransportOrder
	returnOrder?: ReturnOrder
	remarketing?: Remarketing
}

const ReturnOrdersEditView = ({ imp }: { imp: Import }) => {
	const theme = useTheme();
	const [, , hasRole] = useUser();

	const [companies] = useLogisticCompanies();

	const [orderLines, setOrderLines] = useState<ImportReferencesLine[]>([]);
	const [orderToUpdate, setOrderToUpdate] = useState<TransportOrder | null>(null);

	useAsync(async () => {
		const { data: orders } = await axios.get<ImportReferencesLine[]>(`/api/imports/${imp.id}/return-order-import`);

		setOrderLines(orders)
	}, [imp]);

	if (orderLines.length === 0) {
		return null;
	}

	const updateOrderLine = (line: ImportReferencesLine) => {
		setOrderLines(orderLines => {

			const index = orderLines.findIndex(order => order.transportOrder.id === line.transportOrder.id);

			if (index >= 0) {
				const orderLineAtIndex = orderLines[index];

				orderLineAtIndex.transportOrder = line.transportOrder;
				if (line.remarketing) {
					orderLineAtIndex.remarketing = line.remarketing;
				}
				if (line.returnOrder) {
					orderLineAtIndex.returnOrder = line.returnOrder;
				}

				return [
					...orderLines.slice(0, index),
					orderLineAtIndex,
					...orderLines.slice(index + 1)
				];
			}

			return orderLines;
		});

	}

	const getIndicator = (value: boolean | undefined, color: "primary" | "secondary") => {
		return (
			<>
				{value ? <Check color={color} /> : <Block color={color} />}
			</>
		);
	};

	const getAssessmentIndicator = (line: ImportReferencesLine) => {
		const stats = calculateAssessmentStatus(line);

		const detailsHandler = async () => {
			const result = await alterReturnOrderAssessmentInformation(line);
			if (result && line.transportOrder) {
				if (line.returnOrder?.doAssessment && !result.doAssessment) {

					await axios.put(`/api/orders/returns/${line.returnOrder.id}/do-assessment`, { value: false });
					line.returnOrder.doAssessment = false;
					updateOrderLine(line);
				}

				if (line.returnOrder?.doAssessment && line.returnOrder?.assessmentIsPreprocessed &&
					line.remarketing?.assessment && line.returnOrder.assessmentOrderId && result.relinkAssessment) {
					await axios.put(`/api/orders/returns/${line.returnOrder.id}/relink-assessment`);
					line.remarketing.assessment.orderId = line.returnOrder.assessmentOrderId;
					updateOrderLine(line);
				}

				if (line.returnOrder?.doAssessment && line.returnOrder?.assessmentIsPreprocessed &&
					line.remarketing?.assessment && line.returnOrder.assessmentOrderId && result.syncReturnOrder) {
					await axios.put(`/api/orders/returns/${line.returnOrder.id}/sync-assessment`);
					line.returnOrder.assessmentOrderId = line.remarketing.assessment.orderId;
					updateOrderLine(line);
				}

				const assessmentOrderId = line?.remarketing?.assessment?.orderId;
				if (line.returnOrder?.doAssessment && assessmentOrderId && line.remarketing && result.unlinkAssessment) {
					await axios.put(`/api/orders/assessments/${assessmentOrderId}/unlink/`);
					line.remarketing.assessment = {} as RemarketingAssessment;
					updateOrderLine(line);
				}

			}
		}

		if (!stats.doAssessment) {
			if (!stats.linkingMissmatch) {
				return <ListItemChip
					label="Nicht Beauftragt"
					title="Es soll kein Gutachten erstellt werden"
					color="grey"
					onClick={(hasRole(UserRole.AldManager) ? detailsHandler : undefined)}
				/>
			} else {
				return <ListItemChip
					label="Nicht beauftragt"
					title="Es soll kein Gutachten erstellt werden und ein anderer Gutachtenauftrag ist bereits beauftragt"
					color="secondary"
					onClick={(hasRole(UserRole.AldManager) ? detailsHandler : undefined)}
				/>
			}
		} else { // doAssessment
			if (stats.linkingMissmatch) {
				return <ListItemChip
					label="Fehler"
					title="Es soll ein Gutachten erstellt werden aber ein anderer Gutachtenauftrag wurde bereits beauftragt"
					color="error"
					onClick={(hasRole(UserRole.AldManager) ? detailsHandler : undefined)}
				/>
			}

			if (stats.isPreprocessed) {
				return <ListItemChip
					label="Verarbeitet"
					title="Gutachten wurde bereits zu einem früheren Zeitpunkt verarbeitet"
					color={!stats.linkedAssessment ? "warning" : "primary"}
					onClick={(hasRole(UserRole.AldManager) ? detailsHandler : undefined)}
				/>
			} else {
				return <ListItemChip
					label="Beauftragt"
					title="Gutachten soll erstellt werden"
					color="primary"
					onClick={(hasRole(UserRole.AldManager) ? detailsHandler : undefined)}
				/>
			}
		}
	};

	const getUnregistrationIndicator = (line: ImportReferencesLine) => {
		const stats = calculateUnregistrationStatus(line);

		const detailsHandler = async () => {
			const result = await AlterReturnOrderUnregistrationInformation(line);
			if (result && line.transportOrder) {
				if (line.returnOrder && line.returnOrder.doUnregistration !== result.doUnregistration) {

					await axios.put(`/api/orders/returns/${line.returnOrder.id}/do-unregistration`, { value: result.doUnregistration });
					line.returnOrder.doUnregistration = result.doUnregistration;
					updateOrderLine(line);
				}
				const unregistrationOrderId = line?.remarketing?.unregistration?.orderId;
				if (line.returnOrder?.doUnregistration &&
					unregistrationOrderId && line.remarketing && result.unlinkUnregistration) {
					await axios.put(`/api/orders/unregistrations/${unregistrationOrderId}/unlink/`);
					line.remarketing.unregistration = {} as RemarketingUnregistration;
					updateOrderLine(line);
				}
			}
		}

		if (!stats.doUnregistration) {
			if (!stats.linkingMissmatch) {
				return <ListItemChip
					label="Nicht beauftragt"
					title="Es ist keine Abmeldung beauftragt"
					color="grey"
					onClick={(hasRole(UserRole.AldManager) ? detailsHandler : undefined)} />
			} else {
				return <ListItemChip
					label="Nicht beauftragt"
					title="Es ist keine Abmeldung beauftragt und ein anderer Abmeldeauftrag ist bereits beauftragt"
					color="secondary"
					onClick={(hasRole(UserRole.AldManager) ? detailsHandler : undefined)} />
			}
		} else { // doUnregistration
			if (stats.linkingMissmatch) {
				return <ListItemChip
					label="Fehler"
					title="Es soll eine Abmeldung beauftragt werden, aber eine anderer Abmeldeauftrag ist bereits beauftragt"
					color="error"
					onClick={(hasRole(UserRole.AldManager) ? detailsHandler : undefined)} />
			}

			return <ListItemChip
				label="Beauftragt"
				title="Abmeldung soll beauftragt werden"
				color="primary"
				onClick={(hasRole(UserRole.AldManager) ? detailsHandler : undefined)} />
		}
	};

	return (
		<PaperTable>
			<colgroup>
				<col width="auto" />
				<col width="auto" />
				<col width="40%" />
				<Col md lg xl width="40%" />
				<col width="auto" />
				<col width="auto" />
				<col width="auto" />
				<col width="20%" />
			</colgroup>
			<TableHead>
				<TableRow>
					<TableCell>Status</TableCell>
					<TableCell>Fahrbereit/<br />Zugelassen</TableCell>
					<TableCell>Fahrzeug</TableCell>
					<TableCell md lg xl>Kunde</TableCell>
					<TableCell>Gutachten</TableCell>
					<TableCell>Abmeldung</TableCell>
					<TableCell>Transport</TableCell>
					<TableCell>Logistiker</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{orderLines && orderLines[0] && orderLines.map(o => {

					const handleCompanyChanged = async (event: React.ChangeEvent<any>) => {
						const companyName = event.target.value;
						const company = companies?.find(c => c.name === companyName);

						if (orderToUpdate !== null) return;

						setOrderToUpdate(o.transportOrder);
						const { data: order } = await axios.put<TransportOrder>(`/api/orders/transports/${o.transportOrder.id}`, {
							...o.transportOrder,
							assignedTo: !company ? null : {
								name: company.name
							}
						});

						updateOrderLine({ transportOrder: order });
						setOrderToUpdate(null);
					};

					return (
						<TableRow key={o.transportOrder.id}>
							<TableCell>
								<Chip
									label={o.transportOrder.orderRef ? "Änderung" : "Neu"}
								/>
							</TableCell>
							<TableCell>
								{getIndicator(o.transportOrder.isVehicleReadyForDriving, "primary")}
								{getIndicator(o.transportOrder.isRegistered, "secondary")}
							</TableCell>
							<TableCell>
								<TextBlock
									primary={o.transportOrder.plateNumber}
									secondary={
										<span style={{ whiteSpace: "nowrap" }}>
											{o.transportOrder.businessLine} | {o.transportOrder.leasmanContract?.id}
										</span>
									}
									tertiary={o.transportOrder.vehicle.type?.description}
								/>
							</TableCell>
							<TableCell md lg xl>
								<TextBlock
									primary={o.transportOrder.leasmanContract?.customer.name1}
									secondary={o.transportOrder.pickupAddress.name}
									tertiary={`${o.transportOrder.pickupAddress.street} - ${o.transportOrder.pickupAddress.city}`}
								/>
								{o.transportOrder.internalComment && (
									<TextBlock
										primary=""
										secondary={<Box fontStyle="italic"
														color={theme.palette.secondary.light}>{o.transportOrder.internalComment}</Box>}
									/>
								)}
							</TableCell>
							<TableCell>
								<ListItemChips>
									{getAssessmentIndicator(o)}
								</ListItemChips>
							</TableCell>
							<TableCell>
								<ListItemChips>
									{getUnregistrationIndicator(o)}
								</ListItemChips>
							</TableCell>
							<TableCell>
								<TransportDirection
									from={o.transportOrder.pickupAddress.zipCode}
									to={o.transportOrder.destinationAddress.zipCode}
								/>
							</TableCell>
							<TableCell>
								<Select
									fullWidth
									readOnly={orderToUpdate === o.transportOrder}
									value={o.transportOrder.assignedTo?.name || ""}
									onChange={handleCompanyChanged}
									variant="standard"
								>
									{companies && companies.filter(c => c.transports?.active === true).map(c => (
										<MenuItem key={c.name} value={c.name}>{c.name}</MenuItem>
									))}
								</Select>
							</TableCell>
						</TableRow>
					);
				})}
			</TableBody>
		</PaperTable>
	);
};

export default ReturnOrdersEditView;
