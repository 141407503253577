import React from "react";
import LogisticOrdersView from "./LogisticOrdersView";
import { Import, ImportStatus } from "../../../system/Domain";
import LogisticOrdersEditView from "./LogisticOrdersEditView";

export default ({ imp }: { imp: Import }) => (
	<>
		{imp.status === ImportStatus.Committed && (
			<LogisticOrdersView imp={imp} />
		)}

		{imp.status !== ImportStatus.Committed && (
			<LogisticOrdersEditView imp={imp} />
		)}
	</>
);
