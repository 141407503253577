import React, { useState } from "react";
import { FilterList } from "@mui/icons-material";
import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";

interface Props {
	values: any
	selected: string
	onChange?: (value: string) => void
}

const Filter = ({ values, selected, onChange }: Props) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleChange = (value: string) => () => {
		onChange && onChange(value);
		setAnchorEl(null);
	};

	return (
		<>
			<Box display="flex" flexDirection="row" alignItems="center">
				<Typography>{values[selected]}</Typography>
				<IconButton onClick={handleClick}>
					<FilterList />
				</IconButton>
			</Box>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				{Object.keys(values).map(key => {
					return (
						<MenuItem
							key={key}
							selected={selected === key}
							value={key}
							onClick={handleChange(key)}
						>
							{values[key]}
						</MenuItem>
					)
				})}
			</Menu>
		</>
	)
};

export default Filter;
