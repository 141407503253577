import { Box, Chip, CircularProgress, Grid, Typography } from "@mui/material";
import axios, { AxiosResponse } from "axios";
import moment from "moment";
import OrdersNavigation from 'Navigation/OrdersNavigation';
import { useState } from 'react';
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { useAsync } from "react-use";
import AldVehicleHeader from "../../../Components/AldVehicleHeader";
import AppointmentInformation from "../../../Components/AppointmentInformation";
import Layout from "../../../Components/Layout/Layout";
import TextBlock from "../../../Components/Layout/TextBlock";
import Tile from "../../../Components/Tiles/Tile";
import TileContent from "../../../Components/Tiles/TileContent";
import VinDisplay from "../../../Components/VinDisplay";
import askForTextAnswer from "../../../system/askForTextAnswer";
import dateFormats from "../../../system/dateFormats";
import { AssessmentOrder, UserRole, VehicleAssessment } from "../../../system/Domain";
import useAsyncEffect from "../../../system/useAsyncEffect";
import AssessmentOverviewTile from "../../Vehicles/Assessments/AssessmentOverviewTile";
import TextGroup from "../../VehicleSales/TextGroup";
import TextLine from "../../VehicleSales/TextLine";
import OrdersSubMenu from "../OrdersSubMenu";
import AssessmentOrderStatusPill from "./AssessmentOrderStatusPill";
import DekraAssessmentOrderTile from "./DekraAssessmentOrderTile";
import ExpertAssesmentTile, { AssesmentInterruptionFormType } from "./ExpertAssesmentTile";
import useUser from "../../../system/useUser";
import RepairOrderDisplay from "./RepairOrderDisplay";
import AssessmentOrderDetailsSidebar from "./AssessmentOrderDetailsSidebar";
import useQuery from "system/useQuery";
import { AssessmentOrdersOverviewQuery } from "./AssessmentOrdersOverview";
import InquiryAssesmentTile from "./InquiryAssessmentTile";

const AssessmentOrderDetails = () => {
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const [assessmentOrder, setAssessmentOrder] = useState<AssessmentOrder>();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [currentAssessment, setCurrentAssessment] = useState<VehicleAssessment | null>(null);
	const [isEditingDefect, setIsEditingDefect] = useState<boolean>(false);
	const [isEditingInquiryRequest, setIsEditingInquiryRequest] = useState<boolean>(false);
	const [, , hasRole] = useUser();

	const isAldUser = hasRole(UserRole.AldUser);
	const isAldManager = hasRole(UserRole.AldManager);

	useAsync(async () => {
		if (!assessmentOrder?.assessmentId) {
			return;
		}

		const result = await axios.get<VehicleAssessment[]>(`/api/assessments?id=${assessmentOrder.assessmentId}`);
		setCurrentAssessment(result.data[0]);
	}, [assessmentOrder]);

	useAsyncEffect(async () => {
		if (!id) {
			return;
		}

		const { data: ao } = await axios.get<AssessmentOrder[]>(`/api/orders/assessments`, {
			params: {
				id: id
			}
		});

		if (ao.length !== 1) {
			history.push('/orders/assessments');
			return;
		}
		setAssessmentOrder(ao[0]);
	}, [id]);

	const handleCancellation = async () => {
		if (!assessmentOrder) {
			return;
		}

		const cancellationReason = await askForTextAnswer({ text: '', required: true, label: 'Stornierungsgrund', title: "Stornierung" });

		if (!cancellationReason) {
			return;
		}

		setIsSubmitting(true);

		try {
			const response = await axios.post<AssessmentOrder>(`/api/orders/assessments/${assessmentOrder.id}/cancel`, {
				reason: cancellationReason
			});

			setAssessmentOrder(response.data);
		} finally {
			setIsSubmitting(false);
		}
	};

	const handleReactivation = async () => {
		if (!assessmentOrder) {
			return;
		}

		setIsSubmitting(true);

		try {
			const response = await axios.post<AssessmentOrder>(`/api/orders/assessments/${assessmentOrder.id}/reactivate`);

			setAssessmentOrder(response.data);
		} finally {
			setIsSubmitting(false);
		}
	};

	const saveAssessmentInterruption = async (formData: AssesmentInterruptionFormType): Promise<AssessmentOrder> => {
		let responseData: AssessmentOrder;
		let response: AxiosResponse<AssessmentOrder>;
		if (!formData) {
			return;
		}

		setIsSubmitting(true);

		try {
			if (assessmentOrder.defect) {
				response = await axios.put<AssessmentOrder>(`/api/orders/assessments/${assessmentOrder.id}/disruption`, formData);
			} else {
				response = await axios.post<AssessmentOrder>(`/api/orders/assessments/${assessmentOrder.id}/disruption`, formData);
			}
			responseData = response.data;
			setAssessmentOrder(response.data);
		} finally {
			setIsSubmitting(false);
		}

		return responseData;
	}

	const updateInterruptionFormState = (defectForm: boolean, inquiryForm: boolean) => {
		setIsEditingDefect(defectForm);
		setIsEditingInquiryRequest(inquiryForm);
	}

	const [query, setQuery, fetchNext, resetQuery] = useQuery<AssessmentOrdersOverviewQuery>('assessmentOrdersOverviewQuery', {
		perspective: "All",
		skip: 0,
		take: 20,
		fullText: "",
		sort: "dateCreated:desc",
		dateFrom: moment().add(-3, 'months').startOf('d').toISOString(true),
		isTransferred: undefined,
	});

	const hasAnySubjectInqueries = assessmentOrder?.subjectInquiries !== null && assessmentOrder?.subjectInquiries.length > 0;

	return (
		<Layout
			title="Gutachtenauftrag"
			plateNumber={assessmentOrder?.plateNumber}
			navigation={<OrdersNavigation />}
			subMenu={<OrdersSubMenu />}
			sidebar={<AssessmentOrderDetailsSidebar
				assessmentOrder={assessmentOrder}
				setAssessmentOrder={setAssessmentOrder}
				isEditingDefect={isEditingDefect}
				isEditingInquiryRequest={isEditingInquiryRequest}
				startDefect={() => updateInterruptionFormState(true, false)}
				startInquiryRequest={() => updateInterruptionFormState(false, !isEditingInquiryRequest)}
				canNavigateToAssessments={currentAssessment !== null}
			/>}
		>
			{!assessmentOrder && <CircularProgress size={48} />}
			{assessmentOrder && (
				<>
					<Grid
						container
						justifyContent="space-between"
						alignItems="flex-end"
						spacing={2}
					>
						<Grid item>
							<AldVehicleHeader
								plateNumber={assessmentOrder.plateNumber}
								entries={[assessmentOrder.vehicle.type?.description]}
								additionalEntries={[<VinDisplay vin={assessmentOrder.vin} />]}
							/>
						</Grid>
					</Grid>
					<Box mt={4}>
						<Grid container spacing={6}>
							<Grid item xs={12} md={6}>
								<Tile title={assessmentOrder.assessmentType}>
									<TileContent>
										<Box>
											<TextBlock
												primary={assessmentOrder.address.name}
												secondary={`${assessmentOrder.address.street} - ${assessmentOrder.address.zipCode}`}
												tertiary={`${assessmentOrder.address.city} - ${assessmentOrder.address.country}`}
											/>
											<TextBlock
												secondary={assessmentOrder.address.additionalInformation}
											/>
										</Box>
										<Box mt={2}>
											<TextBlock
												primary="Kontaktperson"
												secondary={`${assessmentOrder.contact?.firstName} ${assessmentOrder.contact?.lastName}`}
												tertiary={`${assessmentOrder.contact?.email} - ${assessmentOrder.contact?.telephone}`}
											/>
										</Box>
										{assessmentOrder.appointment && (
											<Box mt={2}>
												<AppointmentInformation appointment={assessmentOrder.appointment} />
											</Box>
										)}
										{assessmentOrder.comment && (
											<Box mt={2}>
												{assessmentOrder.comment}
											</Box>
										)}
									</TileContent>
								</Tile>
							</Grid>

							<Grid item xs={12} md={6}>
								<Tile title="Auftragsdetails">
									<TileContent>
										<TextGroup>
											<TextLine
												label="Status"
												value={
													<AssessmentOrderStatusPill
														assessmentOrder={assessmentOrder}
														additionalInformation={
															assessmentOrder.cancellationReason ? (
																<>{assessmentOrder.cancellationReason}</>
															) : undefined
														}
													/>
												}
											/>
										</TextGroup>
										<TextGroup>
											<TextLine
												label="Dienstleister"
												value={
													<Chip
														size="small"
														label={assessmentOrder.assignedTo.name}
													/>
												}
											/>
										</TextGroup>
										{(assessmentOrder.isPoolfleet || assessmentOrder.doUnregistration) && (
											<TextGroup>
												<TextLine
													label="Zusatzinformationen"
													value={
														<>
															{assessmentOrder.isPoolfleet && (
																<Box>ALD Flex</Box>
															)}
															{assessmentOrder.doUnregistration && (
																<Box mt={2}>Abmeldung beauftragt</Box>
															)}
														</>
													}
												/>
											</TextGroup>
										)}
										<TextGroup>
											<TextLine
												label="Besichtigungstermin"
												value={
													<Box>
														{assessmentOrder.assessmentDate ? moment(assessmentOrder.assessmentDate).format(dateFormats.date) : "Unbekannt"}
													</Box>
												}
											/>
										</TextGroup>
										<TextGroup>
											<TextLine
												label="Kostenverteilung"
												value={
													<>
														{assessmentOrder.transportCostInstallments && (
															<TextBlock
																primary={assessmentOrder.transportCostInstallments}
																secondary="Transportkosten in Rate kalkuliert"
															/>
														)}
														{assessmentOrder.costTransferCustomer && (
															<TextBlock
																primary={assessmentOrder.costTransferCustomer}
																secondary="Weiterbelastung an Kunden"
															/>
														)}
														{assessmentOrder.debitingAld && (
															<TextBlock
																primary={assessmentOrder.debitingAld}
																secondary="Kosten zu Lasten ALD"
															/>
														)}
														{!assessmentOrder.transportCostInstallments && !assessmentOrder.costTransferCustomer && !assessmentOrder.debitingAld && (
															<Typography component="div">
																<Box fontStyle="italic">
																	nicht angegeben
																</Box>
															</Typography>
														)}
													</>
												}
											/>
										</TextGroup>
									</TileContent>
								</Tile>
							</Grid>

							{assessmentOrder.assignedTo.name === "Dekra" && (
								<Grid item xs={12} md={6}>
									<DekraAssessmentOrderTile order={assessmentOrder} />
								</Grid>
							)}
							{currentAssessment && (
								<Grid item xs={12} md={6}>
									<AssessmentOverviewTile
										assessment={currentAssessment}
									/>
								</Grid>
							)}

							{(assessmentOrder.defect || isEditingDefect) && (
								<Grid item xs={12} md={6}>
									<ExpertAssesmentTile
										title="Mängelmeldung"
										assesment={assessmentOrder}
										isLoading={isSubmitting}
										isEditingInterruption={isEditingDefect}
										setIsEditingInterruption={setIsEditingDefect}
										save={saveAssessmentInterruption}
									/>
								</Grid>
							)}

							{assessmentOrder.defect && isAldManager && (
								<Grid item xs={12} md={6}>
									<RepairOrderDisplay assessmentOrder={assessmentOrder} />
								</Grid>
							)}

							{(isEditingInquiryRequest || hasAnySubjectInqueries) &&
								<Grid item xs={12} md={6}>
									<InquiryAssesmentTile
										title="Sachverhaltsanfragen"
										assesment={assessmentOrder}
										setAssessmentOrder={setAssessmentOrder}
										isLoading={isSubmitting}
										setIsSubmitting={setIsSubmitting}
										hideSubjectInquiryForm={() => setIsEditingInquiryRequest(false)}
									/>
								</Grid>
							}
							<Grid item xs={12} md={6}>
								<Tile title="Historie">
									<TileContent>
										<TextBlock
											primary={`Erstellt: ${assessmentOrder.createdBy.name}`}
											secondary={moment(assessmentOrder.dateCreated).format(dateFormats.dateTime)}
										/>
										{assessmentOrder.updatedBy && (
											<Box mt={1}>
												<TextBlock
													primary={`Zuletzt angepasst: ${assessmentOrder.updatedBy.name}`}
													secondary={moment(assessmentOrder.dateUpdated).format(dateFormats.dateTime)}
												/>
											</Box>
										)}
									</TileContent>
								</Tile>
							</Grid>
						</Grid>
					</Box>
				</>
			)}
		</Layout>
	);
};

export default AssessmentOrderDetails;
