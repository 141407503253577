import { Box, Chip, CircularProgress, Grid, MenuItem } from "@mui/material";
import axios from "axios";
import moment from "moment";
import OrdersNavigation from 'Navigation/OrdersNavigation';
import { useState } from 'react';
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import ActionButton from "../../../Components/ActionButton";
import Actions from "../../../Components/Actions";
import AldVehicleHeader from "../../../Components/AldVehicleHeader";
import HeaderMenu from "../../../Components/Layout/HeaderMenu";
import Layout from "../../../Components/Layout/Layout";
import TextBlock from "../../../Components/Layout/TextBlock";
import Tile from "../../../Components/Tiles/Tile";
import TileContent from "../../../Components/Tiles/TileContent";
import VinDisplay from "../../../Components/VinDisplay";
import askForTextAnswer from "../../../system/askForTextAnswer";
import dateFormats from "../../../system/dateFormats";
import { UnregistrationOrder, UserRole } from "../../../system/Domain";
import useAsyncEffect from "../../../system/useAsyncEffect";
import useUser from "../../../system/useUser";
import useVehicle from "../../../system/useVehicle";
import VehicleRegistrationDocumentsStatusPill from "../../Vehicles/DocumentManagement/VehicleRegistrationDocumentsStatusPill";
import OrdersSubMenu from "../OrdersSubMenu";
import UnregistrationOrderStatusPill from "./UnregistrationOrderStatusPill";
import TextGroup from "../../VehicleSales/TextGroup";
import TextLine from "../../VehicleSales/TextLine";

const UnregistrationOrderDetails = () => {
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const [unregistrationOrder, setUnregistrationOrder] = useState<UnregistrationOrder>();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [, , hasRole] = useUser();
	const [vehicle] = useVehicle(unregistrationOrder?.vehicle.id);

	const isAldAdmin = hasRole(UserRole.AldAdmin);

	useAsyncEffect(async () => {
		if (!id || unregistrationOrder) {
			return;
		}

		const { data: uo } = await axios.get<UnregistrationOrder[]>(`/api/orders/unregistrations`, {
			params: {
				id: id
			}
		});

		if (uo.length !== 1) {
			history.push('/orders/unregistrations');
			return;
		}

		setUnregistrationOrder(uo[0]);
	}, [id]);

	const handleCancellation = async () => {
		if (!unregistrationOrder) {
			return;
		}

		const cancellationReason = await askForTextAnswer({ text: '', required: true, label: 'Stornierungsgrund', title: "Stornierung" });

		if (!cancellationReason) {
			return;
		}

		setIsSubmitting(true);

		try {
			const response = await axios.post<UnregistrationOrder>(`/api/orders/unregistrations/${unregistrationOrder.id}/cancel`, {
				reason: cancellationReason
			});

			setUnregistrationOrder(response.data);
		} finally {
			setIsSubmitting(false);
		}
	};

	const handleReactivation = async () => {
		if (!unregistrationOrder) {
			return;
		}

		setIsSubmitting(true);

		try {
			const response = await axios.post<UnregistrationOrder>(`/api/orders/unregistrations/${unregistrationOrder.id}/reactivate`);

			setUnregistrationOrder(response.data);
		} finally {
			setIsSubmitting(false);
		}
	};

	const handleCompletion = async () => {
		if (!unregistrationOrder) {
			return;
		}

		setIsSubmitting(true);

		try {
			const response = await axios.post<UnregistrationOrder>(`/api/orders/unregistrations/${unregistrationOrder.id}/complete`);

			setUnregistrationOrder(response.data);
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<Layout
			title="Abmeldeauftrag"
			plateNumber={unregistrationOrder?.plateNumber}
			navigation={<OrdersNavigation />}
			subMenu={<OrdersSubMenu />}>
			{!unregistrationOrder && (
				<CircularProgress size={48} />
			)}
			{unregistrationOrder && (
				<>
					<Grid container justifyContent="space-between" alignItems="flex-end" spacing={2}>
						<Grid item>
							<AldVehicleHeader plateNumber={unregistrationOrder.plateNumber}
											  entries={[unregistrationOrder.vehicle.type?.description]}
											  additionalEntries={[<VinDisplay vin={unregistrationOrder.vin} />]}
							/>
						</Grid>
						<Grid item>
							<Grid container spacing={2} alignItems="center" justifyContent="space-between">
								<Grid item>
									<Actions>
										{unregistrationOrder.vehicle.id && (
											<HeaderMenu>
												<MenuItem onClick={() => history.push(`/vehicles/${unregistrationOrder.vehicle.id}/orders`)}>Zum
													Fahrzeug</MenuItem>
											</HeaderMenu>
										)}
										{(unregistrationOrder.status === "Created" || unregistrationOrder.status === "Processed") && (
											<ActionButton color="secondary" disabled={isSubmitting} onClick={handleCancellation}>Stornieren</ActionButton>
										)}
										{unregistrationOrder.status === "Cancelled" && !unregistrationOrder.dateProcessed && (
											<ActionButton color="secondary" disabled={isSubmitting} onClick={handleReactivation}>Reaktivieren</ActionButton>
										)}
										{unregistrationOrder.status === "Processed" && isAldAdmin && (
											<ActionButton color="secondary" disabled={isSubmitting} onClick={handleCompletion}>Abschließen</ActionButton>
										)}
										{unregistrationOrder.importId && (
											<ActionButton color="primary" onClick={() => history.push(`/imports/${unregistrationOrder?.importId}`)}>Zum
												Import</ActionButton>
										)}
									</Actions>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Box mt={4}>
						<Grid container spacing={6}>
							<Grid item xs={12} md={6}>
								<Tile title="Auftragsdetails">
									<TileContent>
										<TextGroup>
											<TextLine
												label="Status"
												value={
													<>
														<UnregistrationOrderStatusPill unregistrationOrder={unregistrationOrder} />
														{unregistrationOrder.cancellationReason && (
															<Box mt={2}>
																<em>{unregistrationOrder.cancellationReason}</em>
															</Box>
														)}
													</>
												}
											/>
										</TextGroup>
										<TextGroup>
											<TextLine
												label="Dienstleister"
												value={
													<Chip
														size="small"
														label={unregistrationOrder.assignedTo.name}
													/>
												}
											/>
										</TextGroup>
										<TextGroup>
											<TextLine
												label="Übertragung"
												value={
													<>
														{unregistrationOrder.transferInformation?.dateTransferred && (
															moment(unregistrationOrder.transferInformation.dateTransferred).format(dateFormats.dateTime)
														)}
														{!unregistrationOrder.transferInformation?.dateTransferred && unregistrationOrder.transferInformation?.mode === "Api" && (
															"-"
														)}
														{!unregistrationOrder.transferInformation?.dateTransferred && unregistrationOrder.transferInformation?.mode === "Manual" && (
															"manuell übertragen"
														)}
													</>
												}
											/>
										</TextGroup>
									</TileContent>
								</Tile>
							</Grid>
							{vehicle?.remarketing?.registrationDocuments?.isAtLeastRequested && (
								<Grid item xs={12} md={6}>
									<Tile title="Papiere">
										<TileContent>
											<Box>
												<TextBlock
													primary={
														<VehicleRegistrationDocumentsStatusPill
															status={vehicle?.remarketing?.registrationDocuments?.status ?? "Unknown"}
														/>
													}
													secondary={vehicle?.remarketing?.registrationDocuments?.statusHistory ? moment(vehicle?.remarketing?.registrationDocuments?.statusHistory[vehicle?.remarketing?.registrationDocuments?.statusHistory.length - 1].date).format(dateFormats.dateTime) : ""}
												/>
											</Box>
										</TileContent>
									</Tile>
								</Grid>
							)}
							<Grid item xs={12} md={6}>
								<Tile title="Historie">
									<TileContent>
										<TextBlock
											primary={`Erstellt: ${unregistrationOrder.createdBy.name}`}
											secondary={moment(unregistrationOrder.dateCreated).format(dateFormats.dateTime)}
										/>
										{unregistrationOrder.updatedBy && (
											<Box mt={1}>
												<TextBlock
													primary={`Zuletzt angepasst: ${unregistrationOrder.updatedBy.name}`}
													secondary={moment(unregistrationOrder.dateUpdated).format(dateFormats.dateTime)}
												/>
											</Box>
										)}
									</TileContent>
								</Tile>
							</Grid>
						</Grid>
					</Box>
				</>
			)}
		</Layout>
	);
};

export default UnregistrationOrderDetails;
