import React, { useEffect} from "react";
import { Form } from "../../../system/useForm";
import { VehicleAddresses } from "../../../system/Domain";
import { translateCollectionType } from "../../../system/translate";
import { FormControl, FormControlLabel, Radio, RadioGroup, useTheme } from "@mui/material";

interface Props {
	name: string;
	form: Form;
	aldAddressData: VehicleAddresses;
	required?: boolean;
}

const CollectionRadioChoice = (props: Props) => {
	const theme = useTheme();
	useEffect(() => {
		props.form.register(props.name, { required: props.required ?? false });

		return () => {
			props.form.unregister(props.name);
		};
	}, [props.name]);

	const handleEntryTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const currentCollectionType = (event.target as HTMLInputElement).value;
		
		props.form.setValue(props.name, currentCollectionType);
		props.form.validate(props.name, currentCollectionType);
	};

	return (
		<FormControl component="fieldset" sx={!!props.form.errors[props.name] && { color: theme.palette.error.main }}>
			<RadioGroup
				row
				aria-label="position"
				name="position"
				defaultValue="top"
				value={props.form.getValue(props.name) ?? ""}
				onChange={handleEntryTypeChange}
			>
				<FormControlLabel
					value="CustomersAddress"
					disabled={!props.aldAddressData.customerAddress}
					control={
						<Radio
							sx={!!props.form.errors[props.name] && { color: theme.palette.error.main }}
							color="primary"
						/>
					}
					label={translateCollectionType("CustomersAddress")}
					labelPlacement="end"
				/>
				<FormControlLabel
					value="TraderAddress"
					disabled={!props.aldAddressData.traderAddress}
					control={
						<Radio
							sx={!!props.form.errors[props.name] && { color: theme.palette.error.main }}
							color="primary"
						/>
					}
					label={translateCollectionType("TraderAddress")}
					labelPlacement="end"
				/>
				<FormControlLabel
					value="DriversAddress"
					disabled={!props.aldAddressData.driverAddress}
					control={
						<Radio
							sx={!!props.form.errors[props.name] && { color: theme.palette.error.main }}
							color="primary"
						/>
					}
					label={translateCollectionType("DriversAddress")}
					labelPlacement="end"
				/>
				<FormControlLabel
					value="CustomEntry"
					control={
						<Radio
							sx={!!props.form.errors[props.name] && { color: theme.palette.error.main }}
							color="primary"
						/>
					}
					label={translateCollectionType("CustomEntry")}
					labelPlacement="end"
				/>
				<FormControlLabel
					value="UsedCars"
					control={
						<Radio
							sx={!!props.form.errors[props.name] && { color: theme.palette.error.main }}
							color="primary"
						/>
					}
					label="Gebrauchtwagenplatz"
					labelPlacement="end"
				/>
			</RadioGroup>
		</FormControl>
	);
};

export default CollectionRadioChoice;
