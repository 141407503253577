import {
	AssessmentOrderDefectItem,
	AssessmentOrder,
	ThemeColor,
	UserRole,
	SubjectInquiryDefect,
	SubjectInquiryStatus
} from "../../../system/Domain";
import { useState } from "react";
import TextBlock from "../../../Components/Layout/TextBlock";
import ListItem from "../../../Components/ListItem";
import ListItemAction from "../../../Components/ListItemAction";
import ListItemActions from "../../../Components/ListItemActions";
import ListItemContent from "../../../Components/ListItemContent";
import { AltRoute, Assessment, CarCrash, ChangeCircleOutlined, Check, Clear, Delete, Done, Edit, Email, ExpandMore, ListAlt, QuestionMark, UploadFile } from "@mui/icons-material";
import { Box, Button, useTheme, List } from "@mui/material";
import formatDateTime from "../../../system/formatDateTime";
import useUser from "system/useUser";
import RepairOrderInquiryForm from "./RepairOrderInquiryForm";
import useForm from "system/useForm";
import { RepairOrderViewModeBase, ViewModeAssessment } from "../../../Pages/Vehicles/DekraRepairOrderListItem";
import FluentGrid from "../../../Components/FluentGrid";
import ListItemChips from "../../../Components/ListItemChips";
import ListItemChip from "../../../Components/ListItemChip";
import formatNumber from "system/formatNumber";
import getFiles from "../../../Dialogs/getFiles";
import InquiryDocument, { InquiryDocumentViewMode } from "./InquiryDocument";
import { AssessmentOrderPropsBase, SubjectInquiryFormType } from "./InquiryAssessmentTile";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import axios, { AxiosResponse } from "axios";
import executeWithProgressDialog from "../../../Dialogs/executeWithProgressDialog";

export enum InquirySpecificViewMode {
	DecisionView = 10,
	Delete = 11,
	DecisionViewCompleteInquiry = 12,
	DecisionViewConvertToAssessment = 13,
	DecisionViewAskDekra = 14
}

export type InquiryViewMode = RepairOrderViewModeBase | ViewModeAssessment | InquirySpecificViewMode;

enum AttachDocumentAction {
	AttachDocument = "AttachDocument",
	AttachAndComplete = "AttachAndComplete"
}

type EditProps = {
	defectItem: Partial<AssessmentOrderDefectItem>
	new?: false
};

type NewProps = {
	defectItem?: Partial<AssessmentOrderDefectItem>
	new: true
};

interface CommonProps extends AssessmentOrderPropsBase {
	assessmentOrder: AssessmentOrder
	subjectInquiryDefect?: SubjectInquiryDefect
	onSaving: (formData: SubjectInquiryFormType, viewMode: InquiryViewMode) => void
	viewMode: InquiryViewMode
	cancel: () => void
	canSave?: (assesmentInterruptionFormType: SubjectInquiryFormType) => boolean
	setAssessmentOrder: (assessmentOrder: AssessmentOrder) => void
	setIsSubmitting: (isSubmitting: React.SetStateAction<boolean>) => void
}

type SpecificProps = EditProps | NewProps;

const InquiryDefectListItem = (props: (CommonProps & SpecificProps)) => {
	const [, , hasRole] = useUser();
	const [mode, setMode] = useState<InquiryViewMode>(props.viewMode ?? RepairOrderViewModeBase.View);

	const theme = useTheme();
	let color: ThemeColor;
	switch (mode) {
		case RepairOrderViewModeBase.Cancel:
			color = "warning";
			break;
		case RepairOrderViewModeBase.Complete:
		case RepairOrderViewModeBase.Edit:
		case InquirySpecificViewMode.DecisionView:
			color = "primary";
			break;
		case InquirySpecificViewMode.Delete:
			color = "error"
			break;
		case RepairOrderViewModeBase.View:
		default:
			color = "info";
			break;
	}

	const isAldEntitledUser = !hasRole(UserRole.DamageAssessor) && (hasRole(UserRole.LotManager) || hasRole(UserRole.AldManager));
	const isDamageAssessor = hasRole(UserRole.DamageAssessor);

	const copyAssessmentOrderFromPropsToInterrupionDetails = (): SubjectInquiryFormType => {
		if (props.viewMode === RepairOrderViewModeBase.NewItem) {
			return { assessor: undefined, defectItems: [], hasCarsChequebook: undefined, isReadyToDrive: undefined, mileage: undefined, dateLastMaintenance: undefined, id: undefined };
		}

		return {
			id: props.subjectInquiryDefect?.id,
			mileage: props.subjectInquiryDefect?.mileage,
			hasCarsChequebook: props.subjectInquiryDefect?.hasCarsChequebook,
			assessor: props.subjectInquiryDefect?.assessor,
			dateLastMaintenance: props.subjectInquiryDefect?.dateLastMaintenance || null,
			isReadyToDrive: props.subjectInquiryDefect?.isReadyToDrive,
			defectItems: props.subjectInquiryDefect?.defectItems
				.filter((item) => item.id !== undefined)
				.map((item) => ({
					id: item.id,
					description: item.description,
					createdBy: item.createdBy,
					dateCreated: item.dateCreated,
					updatedBy: item.updatedBy,
					dateUpdated: item.dateUpdated
				}))
		}
	};

	const [assesmentInterruptionDetails, setAssesmentInterruptionDetails] = useState<SubjectInquiryFormType>(copyAssessmentOrderFromPropsToInterrupionDetails);
	const form = useForm({
		values: assesmentInterruptionDetails,
		setValues: setAssesmentInterruptionDetails
	});

	const stopEditing = () => {
		if (props as NewProps) {
			props.cancel();
		}
		setMode(RepairOrderViewModeBase.View);
	};

	const onSaving = (formData: SubjectInquiryFormType, viewMode: InquiryViewMode) => {
		formData.defectItems.forEach(element => {
			if (element?.id?.startsWith('local')) {
				element.id = undefined
			}
		});

		props.onSaving(formData, viewMode)
		setMode(ViewModeAssessment.Details);
	}

	const performInquiryFileUpload = async (attachDocumentAction: AttachDocumentAction) => {
		const title = attachDocumentAction === AttachDocumentAction.AttachAndComplete ? `Dokumente zum abschließen der Sachverhaltsanfrage hinzufügen - ${props.assessmentOrder?.vehicle?.plateNumber} - ${props.assessmentOrder?.vehicle?.vin}` : `Document für die Sachverhaltsanfrage hinzufügen - ${props.assessmentOrder?.vehicle?.plateNumber} - ${props.assessmentOrder?.vehicle?.vin}`;

		const result = await getFiles(title, { multiple: true });
		if (result.ok) {
			let formData = new FormData();
			result.files.forEach(file => {
				formData.append("files", file);
			});

			let response: AxiosResponse<AssessmentOrder>;

			await executeWithProgressDialog(async progress => {
				response = await axios.post<AssessmentOrder>(`/api/orders/assessments/${props.assessmentOrder.id}/subject-inquiry/${props.defectItem.id}/documents`,
					formData, {
						headers: {
							'Content-Type': 'multipart/form-data'
						},
						onUploadProgress: evt => {
							const completed = Math.ceil((evt.loaded / evt.total) * 100);
							progress(completed);
						}
					});
			});

			props.setAssessmentOrder(response.data);
		}
	};

	const statusToLabelText = (status: SubjectInquiryStatus) => {
		switch (status) {
			case SubjectInquiryStatus.Created: {
				return "Erstellt";
			}
			case SubjectInquiryStatus.Answered: {
				return "Beantwortet";
			}
			case SubjectInquiryStatus.Ordered: {
				return "Beauftragt";
			}
			case SubjectInquiryStatus.Forwarded: {
				return "Weitergleitet";
			}
			case SubjectInquiryStatus.Completed: {
				return "Abgeschlossen";
			}
		}
	};

	const onSubjectInquiryComplete = async () => {
		await callWithSetingIsSubmittingFlag(axios.put<AssessmentOrder>(`/api/orders/assessments/${props.assessmentOrder.id}/subject-inquiry/${props.subjectInquiryDefect.id}/complete`)).then(() => setMode(ViewModeAssessment.Details));
	};

	const onSubjectInquiryConvertToDefect = async () => {
		await callWithSetingIsSubmittingFlag(axios.put<AssessmentOrder>(`/api/orders/assessments/${props.assessmentOrder.id}/subject-inquiry/${props.subjectInquiryDefect.id}/convert-to-defect`)).then(() => setMode(ViewModeAssessment.Details));
	};

	const onSubjectInquiryAskDekra = async () => {
		await callWithSetingIsSubmittingFlag(axios.post<AssessmentOrder>(`/api/orders/assessments/${props.assessmentOrder.id}/subject-inquiry/${props.subjectInquiryDefect.id}/ask-dekra`)).then(() => setMode(ViewModeAssessment.Details));
	}

	const onSubjectInquiryDelete = async () => {
		await callWithSetingIsSubmittingFlag(axios.delete<AssessmentOrder>(`/api/orders/assessments/${props.assessmentOrder.id}/subject-inquiry/${props.subjectInquiryDefect.id}`)).then(() => ViewModeAssessment.Details);
	}

	const onSubjectInquiryDeleteDocument = async (assessmentOrderId: string, subjectInquiryDefectId: string, documentId: string) => {
		await callWithSetingIsSubmittingFlag(axios.delete<AssessmentOrder>(`/api/orders/assessments/${assessmentOrderId}/subject-inquiry/${subjectInquiryDefectId}/documents/${documentId}`));
	}

	const callWithSetingIsSubmittingFlag = async (axiosMethodCall: Promise<AxiosResponse<AssessmentOrder>>) => {
		props.setIsSubmitting(true);
		try {
			const response = await axiosMethodCall;
			props.setAssessmentOrder(response.data);
		} finally {
			props.setIsSubmitting(false);
		}
	}

	const canBeEdited = (status: SubjectInquiryStatus) => (status === SubjectInquiryStatus.Created);

	if (props.isLoading) {
		return (<LoadingIndicator isLoading={props.isLoading} />);
	}

	return (
		<ListItem color={color} boxShadow={0}>
			{mode === RepairOrderViewModeBase.View && (
				<>
					<ListItemActions boxShadow={0}>
						<ListItemAction
							icon={<ExpandMore />}
							onClick={() => setMode(ViewModeAssessment.Details)}
						/>
					</ListItemActions>
					<ListItemContent boxShadow={0}>
						{<RepairOrderInquiryForm
							form={form}
							viewMode={RepairOrderViewModeBase.View}
							subjectInquiryDefect={props.subjectInquiryDefect}
						/>}
						<ListItemChips>
							<ListItemChip
								label={statusToLabelText(props.subjectInquiryDefect?.status)}
								color="info"
							/>
							<ListItemChip
								label="Scheckheft gepflegt"
								color={props.subjectInquiryDefect?.hasCarsChequebook ? "success" : "error"}
							/>
							<ListItemChip
								label="Fahrbereit"
								color={props.subjectInquiryDefect?.isReadyToDrive ? "success" : "error"}
							/>
							<ListItemChip
								label={formatNumber(
									props.subjectInquiryDefect?.defectItems?.length,
									"-",
									"Einträge",
									"Eintrag"
								)}
								color="info"
							/>
							<ListItemChip
								label={formatNumber(
									props.subjectInquiryDefect?.documents?.length,
									"-",
									"Dokumente",
									"Dokument"
								)}
								color="info"
							/>

						</ListItemChips>
					</ListItemContent>
				</>
			)}

			{(mode === RepairOrderViewModeBase.NewItem || mode === RepairOrderViewModeBase.Edit) && (
				<>
					<ListItemActions color={color}>
						<ListItemAction
							icon={<Check />}
							disabled={!props.canSave(assesmentInterruptionDetails)}
							onClick={() => onSaving(assesmentInterruptionDetails, mode)}
						/>

						{isAldEntitledUser && mode === RepairOrderViewModeBase.Edit &&
							<ListItemAction
								tooltip="Dokumente hochladen"
								icon={<UploadFile />}
								onClick={() => performInquiryFileUpload(AttachDocumentAction.AttachDocument)}
							/>
						}

						<ListItemAction
							icon={<Clear />}
							onClick={stopEditing}
						/>
					</ListItemActions>
					<ListItemContent boxShadow={0}>
						<RepairOrderInquiryForm
							form={form}
							viewMode={mode}
							subjectInquiryDetails={assesmentInterruptionDetails}
						/>

						{mode === RepairOrderViewModeBase.Edit &&
							<List>
								{props.subjectInquiryDefect?.documents?.map(document => (
									<InquiryDocument
										key={document.fileReference.filename}
										defectId={props.assessmentOrder.id}
										subjectInquiryDefect={props.subjectInquiryDefect}
										inquiryDocument={document}
										viewMode={InquiryDocumentViewMode.Edit}
									/>
								))}
							</List>
						}
					</ListItemContent>
				</>
			)}

			{(mode === ViewModeAssessment.Details) && (
				<>
					<ListItemActions color={color}>
						{canBeEdited(props.subjectInquiryDefect.status) && (
							<>
								{(isAldEntitledUser || isDamageAssessor) && (
									<ListItemAction
										tooltip="Bearbeiten"
										icon={<Edit />}
										onClick={() => setMode(RepairOrderViewModeBase.Edit)}
									/>
								)}

								{isAldEntitledUser && (
									<ListItemAction
										tooltip="Dokumente hochladen"
										icon={<UploadFile />}
										onClick={() => performInquiryFileUpload(AttachDocumentAction.AttachDocument)}
									/>
								)}

								{isAldEntitledUser && (
									<ListItemAction
										tooltip="Verwalten"
										icon={<AltRoute />}
										onClick={() => setMode(InquirySpecificViewMode.DecisionView)}
									/>
								)}

								{props.subjectInquiryDefect.status === SubjectInquiryStatus.Created && (
									<ListItemAction
										tooltip="Löschen"
										icon={<Delete />}
										onClick={() => setMode(InquirySpecificViewMode.Delete)}
									/>
								)}
							</>
						)}

						<ListItemAction
							icon={<Clear />}
							onClick={stopEditing}
						/>
					</ListItemActions>
					<ListItemContent color={color} boxShadow={0}>
						<RepairOrderInquiryForm
							form={form}
							viewMode={mode}
							subjectInquiryDefect={props.subjectInquiryDefect}
						/>

						<List sx={{ padding: 0 }}>
							{props.subjectInquiryDefect?.documents?.map(document => (
								<InquiryDocument
									key={document.fileReference.filename}
									defectId={props.assessmentOrder.id}
									subjectInquiryDefect={props.subjectInquiryDefect}
									inquiryDocument={document}
									viewMode={InquiryDocumentViewMode.View}
									onSubjectInquiryDeleteDocument={onSubjectInquiryDeleteDocument}
								/>
							))}
						</List>

						<ListItemChips>
							<ListItemChip
								label={statusToLabelText(props.subjectInquiryDefect?.status)}
								color="info"
							/>
							<ListItemChip
								label="Scheckheft gepflegt"
								color={props.subjectInquiryDefect?.hasCarsChequebook ? "success" : "error"}
							/>
							<ListItemChip
								label="Fahrbereit"
								color={props.subjectInquiryDefect?.isReadyToDrive ? "success" : "error"}
							/>
							<ListItemChip
								label={formatNumber(
									props.subjectInquiryDefect?.documents?.length,
									"-",
									"Dokumente",
									"Dokument"
								)}
								color="info"
							/>
						</ListItemChips>
					</ListItemContent>
				</>
			)
			}

			{(mode === InquirySpecificViewMode.DecisionView
				|| mode === InquirySpecificViewMode.DecisionViewCompleteInquiry
				|| mode === InquirySpecificViewMode.DecisionViewConvertToAssessment
				|| mode === InquirySpecificViewMode.DecisionViewAskDekra
			) && (
				<>
					<ListItemActions color={color}>
						{mode === InquirySpecificViewMode.DecisionView &&
							(
								<>
									<ListItemAction
										tooltip="Abschließen"
										disabled={props.subjectInquiryDefect?.documents.length === 0}
										icon={<Done />}
										onClick={() => setMode(InquirySpecificViewMode.DecisionViewCompleteInquiry)}
									/>

									<ListItemAction
										tooltip="Zu einer Mängelmeldung umwandeln"
										disabled={props.assessmentOrder.defect !== null}
										icon={<ChangeCircleOutlined />}
										onClick={() => setMode(InquirySpecificViewMode.DecisionViewConvertToAssessment)}
									/>

									<ListItemAction
										tooltip="Schadengutachten erstellen"
										icon={<CarCrash />}
										onClick={() => setMode(InquirySpecificViewMode.DecisionViewAskDekra)}
									/>

									<ListItemAction
										icon={<Clear />}
										onClick={stopEditing}
									/>
								</>
							)}

						{(mode === InquirySpecificViewMode.DecisionViewAskDekra
							|| mode === InquirySpecificViewMode.DecisionViewCompleteInquiry
							|| mode === InquirySpecificViewMode.DecisionViewConvertToAssessment) && (

							< ListItemAction
								icon={<Clear />}
								onClick={() => setMode(InquirySpecificViewMode.DecisionView)}
							/>
						)
						}
					</ListItemActions>
					<ListItemContent color={color} boxShadow={0}>
						<RepairOrderInquiryForm
							// assessmentOrder={props.assessmentOrder}
							form={form}
							viewMode={mode}
							subjectInquiryDefect={props.subjectInquiryDefect}
						/>

						<List sx={{ padding: 0, marginTop: 2 }}>
							{props.subjectInquiryDefect?.documents?.map(document => (
								<InquiryDocument
									key={document.fileReference.filename}
									defectId={props.assessmentOrder.id}
									subjectInquiryDefect={props.subjectInquiryDefect}
									inquiryDocument={document}
									viewMode={InquiryDocumentViewMode.View}
									onSubjectInquiryDeleteDocument={onSubjectInquiryDeleteDocument}
								/>
							))}
						</List>

						{mode === InquirySpecificViewMode.DecisionViewCompleteInquiry && (
							<Button
								startIcon={<Check />}
								disabled={props.assessmentOrder.defect !== null}
								onClick={() => onSubjectInquiryComplete()}
								style={{ color: theme.palette.primary.light }}
							>
								Abschließen
							</Button>
						)}

						{mode === InquirySpecificViewMode.DecisionViewConvertToAssessment && (
							<Button
								startIcon={<ChangeCircleOutlined />}
								disabled={props.assessmentOrder.defect !== null}
								onClick={() => onSubjectInquiryConvertToDefect()}
								style={{ color: theme.palette.primary.light }}
							>
								Zu einer Mängelmeldung umwandeln
							</Button>
						)}

						{mode === InquirySpecificViewMode.DecisionViewAskDekra && (
							<Button
								startIcon={<CarCrash />}
								onClick={onSubjectInquiryAskDekra}
								style={{ color: theme.palette.primary.light }}
							>
								Schadengutachten erstellen
							</Button>
						)}

						<ListItemChips>
							<ListItemChip
								label={statusToLabelText(props.subjectInquiryDefect?.status)}
								color="info"
							/>
							<ListItemChip
								label="Scheckheft gepflegt"
								color={props.subjectInquiryDefect?.hasCarsChequebook ? "success" : "error"}
							/>
							<ListItemChip
								label="Fahrbereit"
								color={props.subjectInquiryDefect?.isReadyToDrive ? "success" : "error"}
							/>
							<ListItemChip
								label={formatNumber(
									props.subjectInquiryDefect?.documents?.length,
									"-",
									"Dokumente",
									"Dokument"
								)}
								color="info"
							/>
						</ListItemChips>
					</ListItemContent>
				</>
			)
			}

			{
				mode === ViewModeAssessment.List && (
					<ListItemContent>
						<Box mt={2} mb={1}>
							<FluentGrid>
								<>
									<TextBlock
										primary={props.subjectInquiryDefect.assessor.firstName + " " + props.subjectInquiryDefect.assessor.lastName}
										secondary="Gutachter"
									/>
									<TextBlock
										primary={props.subjectInquiryDefect.defectItems.map(si => si.description).join(', ')}
										secondary="Sachverhaltsanfragen"
										reduced
									/>
									<TextBlock
										tertiary={<span>{formatDateTime(props.subjectInquiryDefect.dateCreated)} ({props.subjectInquiryDefect.createdBy?.name})</span>}
									/>
								</>
							</FluentGrid>
						</Box>
					</ListItemContent>
				)
			}

			{
				mode === InquirySpecificViewMode.Delete && (
					<>
						<ListItemActions color={color}>
							<ListItemAction
								icon={<Clear />}
								onClick={stopEditing}
							/>
						</ListItemActions>
						<ListItemContent color={color}>
							<RepairOrderInquiryForm
								form={form}
								viewMode={mode}
								subjectInquiryDefect={props.subjectInquiryDefect}
							/>
							<List>
								{props.subjectInquiryDefect?.documents?.map(document => (
									<InquiryDocument
										key={document.fileReference.filename}
										defectId={props.assessmentOrder.id}
										subjectInquiryDefect={props.subjectInquiryDefect}
										inquiryDocument={document}
										viewMode={InquiryDocumentViewMode.View}
										onSubjectInquiryDeleteDocument={onSubjectInquiryDeleteDocument}
									/>
								))}
							</List>

							<Button startIcon={<Delete />} color="error" onClick={() => onSubjectInquiryDelete()}>Löschen </Button>

							<ListItemChips>
								<ListItemChip
									label={statusToLabelText(props.subjectInquiryDefect?.status)}
									color="info"
								/>
								<ListItemChip
									label="Scheckheft gepflegt"
									color={props.subjectInquiryDefect?.hasCarsChequebook ? "success" : "error"}
								/>
								<ListItemChip
									label="Fahrbereit"
									color={props.subjectInquiryDefect?.isReadyToDrive ? "success" : "error"}
								/>
								<ListItemChip
									label={formatNumber(
										props.subjectInquiryDefect?.documents?.length,
										"-",
										"Dokumente",
										"Dokument"
									)}
									color="info"
								/>
							</ListItemChips>
						</ListItemContent>
					</>
				)
			}
		</ListItem>
	);
};

export default InquiryDefectListItem;
