import TileContent from 'Components/Tiles/TileContent';
import { useState } from 'react';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { Box, Checkbox, FormControlLabel, IconButton, MenuItem, Select } from '@mui/material';
import Tile from '../../Components/Tiles/Tile';
import VehiclePicture from '../../Components/VehiclePicture';
import { enumeratePictureSet, NamedVehiclePicture } from '../../Components/VehiclePictureList';
import { VehiclePictureSet } from '../../system/Domain';

interface Props {
	pictureSet : VehiclePictureSet
	limit? : number
}

export default ({ pictureSet } : Props) => {
	const [useThumbnails, setUseThumbnails] = useState(false);

	const [pictures] = useState(
		Array.from(enumeratePictureSet(pictureSet))
	);

	const [selectedPicture, setSelectedPicture] = useState<NamedVehiclePicture>(pictures[0]);

	const previousPicture = () => {
		const index = pictures.indexOf(selectedPicture);
		if (index === 0) {
			return;
		}

		setSelectedPicture(pictures[index - 1]);
	}

	const nextPicture = () => {
		const index = pictures.indexOf(selectedPicture);
		if (index === pictures.length - 1) {
			return;
		}

		setSelectedPicture(pictures[index + 1]);
	}

	return (
		<Tile title="Fotos">
			<TileContent dense>
				<Box sx={{
					display: "flex",
					justifyContent: "space-between",
					margin: 0.5
				}}>
					<IconButton
						disabled={pictures[0] === selectedPicture}
						onClick={() => previousPicture()}
					>
						<ArrowLeft/>
					</IconButton>
					<Select
						variant="standard"
						value={selectedPicture?.title}
						onChange={e => {
							const picture = pictures.find(p => p.title === e.target.value);
							if (!!picture) {
								setSelectedPicture(picture);
							}
						}}
						disableUnderline
					>
						{pictures.map(p => (
							<MenuItem key={p.title} value={p.title}>{p.title}</MenuItem>
						))}
					</Select>
					<IconButton
						disabled={pictures[pictures.length - 1] === selectedPicture}
						onClick={() => nextPicture()}
					>
						<ArrowRight/>
					</IconButton>
				</Box>
				<Box sx={{
					display: "flex",
					gap: 2,
					flexWrap: "wrap"
				}}>
					{selectedPicture && (
						<VehiclePicture
							key={selectedPicture.hash}
							picture={selectedPicture}
							thumbnail={useThumbnails}
						/>
					)}
				</Box>
			</TileContent>
			<Box display="flex" justifyContent="flex-end" mt={1}>
				<FormControlLabel
					control={(
						<Checkbox
							checked={useThumbnails}
							onChange={e => setUseThumbnails(e.target.checked)}
						/>
					)}
					label="Geringere Auflösung benutzen"
				/>
			</Box>
		</Tile>
	);
}
