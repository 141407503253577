import React, { useState } from 'react';
import { Import, VehicleRegistrationDocumentsProjection } from "../../../system/Domain";
import { useAsync } from "react-use";
import axios from "axios";
import { Box, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import PaperTable from "../../../Components/PaperTable";
import Plate from "../../../Components/Plate/Plate";
import TextBlock from "../../../Components/Layout/TextBlock";
import ArrivalToggleButtonGroup from "./ArrivalToggleButtonGroup";
import VehicleRegistrationDocumentsStatusPill from "./VehicleRegistrationDocumentsStatusPill";
import UnregistrationStatusPill from "./UnregistrationStatusPill";

const VehicleRegistrationDocumentArrivalsImportEditView = ({ imp } : { imp : Import }) => {
	const [vehicles, setVehicles] = useState<VehicleRegistrationDocumentsProjection[]>([]);
	const [vehicleToUpdate, setVehicleToUpdate] = useState<VehicleRegistrationDocumentsProjection | null>(null);

	useAsync(async () => {
		const { data } = await axios.get<VehicleRegistrationDocumentsProjection[]>(`/api/vehicles/remarketing/registration-documents`, {
			params: {
				importId: imp.id,
				specialSort: "PlateNumberNumbers"
			}
		});

		setVehicles(data);
	}, [imp]);

	return (
		<Box mt={2}>
			<PaperTable>
				<colgroup>
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />
				</colgroup>
				<TableHead>
					<TableRow>
						<TableCell>
							Fahrzeug
						</TableCell>
						<TableCell>
							Status
						</TableCell>
						<TableCell>
							Abmeldung
						</TableCell>
						<TableCell>
							Angekommenes Dokument
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{vehicles && vehicles.map((v, i) => {
						const handleChangeEvent = async (event : React.ChangeEvent<any>, value : any) => {
							if (vehicleToUpdate !== null) return;

							setVehicleToUpdate(v);

							try {
								const { data: vehicle } = await axios.put<VehicleRegistrationDocumentsProjection>(`/api/vehicles/${v.id}/remarketing/documents/import/arrival`, {
									type: value
								});

								setVehicles(vehicles => {
									const index = vehicles.findIndex(ve => ve.id === v.id);

									if (index >= 0) {
										return [
											...vehicles.slice(0, index),
											vehicle,
											...vehicles.slice(index + 1)
										]
									}
									return vehicles;
								});
							} finally {
								setVehicleToUpdate(null);
							}
						};

						return (
							<TableRow
								key={i}
								hover
							>
								<TableCell>
									<Plate plateNumber={v.plateNumber} style={{ zoom: 0.6 }} />
									<TextBlock
										primary={v.makeModel}
										secondary={v.vin}
										compact
									/>
								</TableCell>
								<TableCell>
									<VehicleRegistrationDocumentsStatusPill status={v.registrationDocuments.status} />
								</TableCell>
								<TableCell>
									<UnregistrationStatusPill isUnregistered={v.isUnregistered} registrationDocuments={v.registrationDocuments} />
								</TableCell>
								<TableCell>
									<ArrivalToggleButtonGroup
										onChange={handleChangeEvent}
										value={v.registrationDocuments.arrivalImport?.arrivalType}
										disable={vehicleToUpdate !== null}
										previousValue={v.registrationDocuments.arrivalType}
										status={v.registrationDocuments.status}
									/>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</PaperTable>
		</Box>
	);
};

export default VehicleRegistrationDocumentArrivalsImportEditView;
