import axios from "axios";
import { merge } from "lodash";
import { GeoPoint, CompoundPlace } from "system/Domain";
import { CompoundPlaceMerger } from "./CompoundDesigner";
import {
	CompoundPlacePolygon,
	CompoundPlaceMarker,
	CompoundPlaceDesign,
	generateTempId,
	polygonDefaultOptions,
	polygonInactiveOptions,
} from "./mapUtils";

export class MapHandler {
	private markers: CompoundPlaceMarker[] = [];
	private currentPolygon: CompoundPlacePolygon | null = null;
	public map: google.maps.Map;
	private compoundPlaces: CompoundPlaceDesign[];
	private setCompoundPlace: React.Dispatch<React.SetStateAction<CompoundPlaceDesign>>;
	private labels: CompoundPlaceMarker[] = [];
	private polygons: CompoundPlacePolygon[] = [];

	constructor(
		compoundPlaces: CompoundPlaceDesign[],
		setCompoundPlace: React.Dispatch<React.SetStateAction<CompoundPlaceDesign>>,
		setCompoundPlacesState: React.Dispatch<React.SetStateAction<CompoundPlaceDesign[]>>,
		private compoundPlaceMerger: CompoundPlaceMerger,
		private setUploading: React.Dispatch<React.SetStateAction<boolean>>,
	) {
		this.compoundPlaces = compoundPlaces ?? [];
		this.setCompoundPlace = setCompoundPlace;
	}

	setMap(map: google.maps.Map) {
		this.map = map;
	}

	setCompoundPlaces(compoundPlaces: CompoundPlaceDesign[]) {
		this.compoundPlaces = compoundPlaces;
	}

	setMerger(isMergeMode: boolean) {
		this.compoundPlaceMerger.isMergeMode = isMergeMode;
	}

	createMarker = (e: google.maps.MapMouseEvent | google.maps.IconMouseEvent) => {
		const position = { lat: e.latLng.lat(), lng: e.latLng.lng() };

		const marker = new google.maps.Marker({
			position,
			map: this.map,
			label: this.markers.length === 0 ? "First Marker" : "",
		}) as CompoundPlaceMarker;
		marker.id = generateTempId();
		this.markers.push(marker);

		google.maps.event.addListener(marker, "contextmenu", () => this.removeMarkerEvent(marker));
		google.maps.event.addListener(marker, "click", () => {
			this.markerClickEvent(marker);
		});

		return marker;
	};

	updateCompoundPlace = (compoundPlace: CompoundPlaceDesign) => {
		const polygon = this.polygons.find((p) => p.id === compoundPlace.id);
		if (!polygon) return;
		const coordinates = compoundPlace.geoArea.points.map((p) => {
			return { lng: p.lon, lat: p.lat };
		});
		const labelMarker = this.labels.find((l) => l.id === compoundPlace.id);
		const centroid = this.getPolygonCenterFromPolygon(polygon);

		this.compoundPlaces = this.compoundPlaces.map((lp) => (lp.id === compoundPlace.id ? compoundPlace : lp));

		if (labelMarker) {
			labelMarker.setPosition(centroid);
			labelMarker.setLabel(compoundPlace.name);
		} else if (compoundPlace.name) this.addLabelToPolygon(polygon, compoundPlace.name);

		polygon.setPaths(coordinates);
	};

	private removeMarkerEvent = (marker: CompoundPlaceMarker) => {
		google.maps.event.clearInstanceListeners(marker);
		this.markers = this.markers.filter((m) => m.id !== marker.id);
		marker.setMap(null);
	};

	public updatePolygonsId = (compoundPlace: CompoundPlaceDesign, tempId: string) => {
		const polygonIndex = this.polygons.findIndex((p) => p.id === tempId);
		console.log(polygonIndex);
		console.log(compoundPlace.id);
		this.polygons[polygonIndex].id = compoundPlace.id;
	};

	private markerClickEvent = (marker: google.maps.Marker) => {
		if (this.markers.length <= 2) return;

		if (marker.getPosition() === this.markers[0].getPosition()) {
			const coordinates = this.markers.map((m) => m.getPosition());
			this.markers.forEach((m) => m.setMap(null));
			this.markers = [];
			this.map.overlayMapTypes.clear();
			this.createPolygon(coordinates);
		}
	};

	private polygonClickEvent = (polygon: CompoundPlacePolygon) => {
		const compoundPlace = this.compoundPlaces.find((lp) => lp.id === polygon.id);
		if (this.compoundPlaceMerger.isMergeMode) {
			const activeCompoundPlace = this.compoundPlaces.find((lp) => lp.id === this.currentPolygon.id);
			const newCompoundPlace = merge(activeCompoundPlace, compoundPlace);
			const polygonPath: any = newCompoundPlace.geoArea.points.map((p) => {
				return { lng: p.lon, lat: p.lat };
			});
			this.createPolygon(polygonPath, newCompoundPlace.id, newCompoundPlace.name);

			this.compoundPlaceMerger.isMergeMode = false;

			return;
		}
		polygon.setDraggable(true);
		polygon.setEditable(true);

		if (this.currentPolygon) {
			this.currentPolygon.setEditable(false);
			this.currentPolygon.setDraggable(false);
		}

		this.currentPolygon = polygon;
		if (compoundPlace) this.setCompoundPlace(compoundPlace);
	};

	createPolygon = (
		coordinates: google.maps.LatLng[],
		id: string = null,
		name: string = null,
		isActive: boolean = true,
	) => {
		const polygon = new google.maps.Polygon({
			map: this.map,
			paths: coordinates,
			...(isActive ? polygonDefaultOptions : polygonInactiveOptions),
		}) as CompoundPlacePolygon;

		polygon.id = id ? id : generateTempId();
		if (!id) {
			this.compoundPlaces.push({
				id: polygon.id,
				isNew: true,
				name: "",
				isActive: true,
				geoArea: {
					points: coordinates.map((c) => {
						return { lat: c.lat(), lon: c.lng() };
					}),
				},
			} as CompoundPlaceDesign);
		}
		if (name) this.addLabelToPolygon(polygon, name);
		google.maps.event.addListener(polygon, "click", () => {
			this.polygonClickEvent(polygon);
		});
		google.maps.event.addListener(polygon, "dragend", () => {
			this.polygonHandleEvent(polygon);
		});

		this.polygons.push(polygon);

		return polygon;
	};

	createPolygonFromCompoundPlace = (compoundPlace: CompoundPlaceDesign) => {
		const polygon = new google.maps.Polygon({
			map: this.map,
			paths: compoundPlace.geoArea.points.map((p) => {
				return { lng: p.lon, lat: p.lat };
			}),
			...(compoundPlace.isActive ? polygonDefaultOptions : polygonInactiveOptions),
		}) as CompoundPlacePolygon;

		compoundPlace.id = !compoundPlace.id ? generateTempId() : compoundPlace.id;
		polygon.id = compoundPlace.id;

		if (compoundPlace.isNew) this.compoundPlaces.push(compoundPlace);
		if (compoundPlace.name) this.addLabelToPolygon(polygon, compoundPlace.name);
		google.maps.event.addListener(polygon, "click", () => {
			this.polygonClickEvent(polygon);
		});
		google.maps.event.addListener(polygon, "dragend", () => {
			this.polygonHandleEvent(polygon);
		});

		this.polygons.push(polygon);
		this.polygonClickEvent(polygon);
		return polygon;
	};

	getCurrentVertexPosition = (compoundPlaceId: string): GeoPoint[] => {
		const polygon = this.polygons.find((p) => compoundPlaceId === p.id);
		if (!polygon) return;
		const path = polygon.getPath();
		const geoPoints: GeoPoint[] = [];

		// Iterate over the polygon's path and create GeoPoint objects
		path.forEach((latLng) => {
			const geoPoint: GeoPoint = {
				lat: latLng.lat(),
				lon: latLng.lng(),
			};
			geoPoints.push(geoPoint);
		});

		return geoPoints;
	};

	private polygonHandleEvent = (polygon: CompoundPlacePolygon) => {
		const labelMarker = this.labels.find((l) => l.id === polygon.id);
		const centroid = this.getPolygonCenterFromPolygon(polygon);
		console.log("Polygon size or shape changed");
		if (labelMarker) {
			labelMarker.setPosition(centroid);
		}

		const compoundPlace = this.compoundPlaces.find((lp) => lp.id === polygon.id);
		if (compoundPlace) {
			const newPoints = polygon
				.getPath()
				.getArray()
				.map((p) => ({ lat: p.lat(), lon: p.lng() }));
			compoundPlace.geoArea.points = newPoints;
			this.setCompoundPlace(compoundPlace);
		}
	};

	removeCompoundPlace = async (compoundPlace: CompoundPlaceDesign) => {
		this.setUploading(true);
		try {
			/**
			 * If lot place is already saved, check if any vehicle is currently there.
			 */

			if (!compoundPlace.isNew) {
				const response = await axios.delete(`api/compounds/places/${compoundPlace.id}`);
				this.setUploading(false);
				if (response.status !== 200) return;
			}
			const polygon = this.polygons.find((p) => p.id === compoundPlace.id);
			this.removePolygon(polygon);
		} catch (e) {
			return e;
		} finally {
			this.setUploading(false);
		}
	};

	public removePolygon = (polygon?: CompoundPlacePolygon, id?: string) => {
		if (!polygon && !id) return;
		if (!polygon && id) polygon = this.polygons.find((p) => p.id === id);
		this.polygons = this.polygons.filter((p) => p.id !== polygon.id);
		google.maps.event.clearInstanceListeners(polygon);
		polygon.setMap(null);

		const label = this.labels.find((l) => l.id === polygon.id);
		if (label) {
			this.labels = this.labels.filter((l) => l.id !== polygon.id);
			google.maps.event.clearInstanceListeners(label);
			label.setMap(null);
		}
	};
	cleanMap = () => {
		this.markers.forEach((m) => {
			google.maps.event.clearInstanceListeners(m);
			m.setMap(null);
		});

		this.polygons.forEach((p) => {
			google.maps.event.clearInstanceListeners(p);
			p.setMap(null);
		});

		this.labels.forEach((l) => {
			google.maps.event.clearInstanceListeners(l);
			l.setMap(null);
		});

		this.markers = [];
		this.polygons = [];
		this.labels = [];
	};

	private getPolygonCenterFromPolygon = (polygon: google.maps.Polygon): google.maps.LatLng => {
		const path = polygon.getPath();
		let lat = 0;
		let lng = 0;
		path.forEach((point) => {
			lat += point.lat();
			lng += point.lng();
		});
		return new google.maps.LatLng(lat / path.getLength(), lng / path.getLength());
	};

	private addLabelToPolygon(polygon: CompoundPlacePolygon, label: string) {
		const centroid = this.getPolygonCenterFromPolygon(polygon);
		const transparentIcon = {
			url: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/h8AAwAB/DEWUQkAAAAASUVORK5CYII=",
			size: new google.maps.Size(1, 1),
			anchor: new google.maps.Point(0, 0),
		};
		const labelMarker = new google.maps.Marker({
			position: centroid,
			map: this.map,
			icon: transparentIcon,
			label: {
				text: label,
				color: "#fff",
				fontSize: "14px",
			},
		}) as CompoundPlaceMarker;

		labelMarker.id = polygon.id;
		this.labels.push(labelMarker);

		return labelMarker;
	}
}
