import React from "react";
import Layout from "../../Components/Layout/Layout";
import LotScanContextMenu from "./LotScanContextMenu";
import LotMenu from "./LotSubMenu";

export default () => {
	return (
		<Layout
			contextMenu={[<LotScanContextMenu />]}
		>
			<LotMenu />
		</Layout>
	);
};
