import React, { useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { UserRole, Vehicle } from "../../system/Domain";
import useAsyncEffect from "../../system/useAsyncEffect";
import axios from "axios";
import { useHistory } from "react-router";
import VehicleList from "./VehicleTable";
import Layout from "../../Components/Layout/Layout";
import VehiclesSidebar from "./VehiclesSidebar";
import Search from "../../Components/Search";
import useVehicleQuery from "../../system/useVehicleQuery";
import LotScanContextMenu from "./LotScanContextMenu";
import VehiclesNavigation from "Navigation/VehiclesNavigation";
import useUser from "../../system/useUser";

export default () => {
	const history = useHistory();
	const [vehicles, setVehicles] = useState<Vehicle[]>();
	const [, , hasRole] = useUser();

	const [query, setQuery, place, setPlace, resetQuery] = useVehicleQuery('vehicleQuery', {
		perspective: hasRole(UserRole.AldAdmin) ? "All" : "Stock",
		sort: "statusAssigned:desc",
		fullText: ""
	});

	useAsyncEffect(async (cancelToken) => {
		const { data } = await axios.post<Vehicle[]>(`/api/vehicles/search`, query, {
			cancelToken: cancelToken.getAxiosToken()
		});

		setVehicles(data);
	}, [query]);

	const onUpdateVehicle = (vehicle: Vehicle) => {
		if (!vehicles) return;

		setVehicles(vehicles => vehicles?.map(s => s.id === vehicle.id ? vehicle : s));
	};

	return (
		<Layout
			title="Fahrzeuge"
			navigation={<VehiclesNavigation />}
			contextMenu={[<LotScanContextMenu />]}
			sidebar={(
				<VehiclesSidebar
					query={query}
					setQuery={setQuery}
					place={place}
					setPlace={setPlace}
					resetQuery={resetQuery}
				/>
			)}
		>
			<Box mb={2}>
				<Search
					value={query.fullText}
					realtime
					onSearch={(value) => value !== query.fullText && setQuery(q => ({
						...q,
						fullText: value
					}))}
				/>
			</Box>
			{!vehicles && (
				<Box m={2}>
					<CircularProgress size={48} />
				</Box>
			)}
			{!!vehicles && (
				<>
					{vehicles.length === 0 && (
						<Box>Keine Fahrzeuge vorhanden</Box>
					)}
					{vehicles.length > 0 && (
						<VehicleList
							vehicles={vehicles}
							onClick={v => history.push({
								pathname: `/vehicles/${v.id}`
							})}
							onUpdate={onUpdateVehicle}
							perspective={query.perspective}
						/>
					)}
				</>
			)}
		</Layout>
	);
};
