import { Dialog, DialogProps, StyledEngineProvider, ThemeProvider, useTheme } from "@mui/material";
import createTheme from "createTheme";
import React from "react";
import { RecoilRoot, useRecoilValue } from "recoil";
import { settingsAtom } from "system/atoms/settings";

export interface ThemedDialogProps extends DialogProps {
	children: React.ReactNode;
}

const ThemedDialog = ({ children, onClose, ...props }: ThemedDialogProps) => {
	const handleClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
		if (reason === "backdropClick") {
			return false;
		}

		if (typeof onClose === "function") {
			onClose(event, reason);
		}
	};

	const settings = useRecoilValue(settingsAtom);
	const theme = createTheme(settings.theme);

	return (
		<ThemeProvider theme={theme}>
			<StyledEngineProvider injectFirst>
				<Dialog {...props} onClose={handleClose}>
					{children}
				</Dialog>
			</StyledEngineProvider>
		</ThemeProvider>
	);
};

export default (props: ThemedDialogProps) => {
	return (
		<RecoilRoot>
			<ThemedDialog {...props} />
		</RecoilRoot>
	);
};
