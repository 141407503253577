import React from "react";
import { alpha, Box, BoxProps, useTheme } from "@mui/material";
import { ThemeColor } from "../system/Domain";

interface Props extends BoxProps {
	color?: ThemeColor
}

export default ({ color = "info", children, onClick, ...rest }: Props) => {
	const theme = useTheme();

	const getColor = (color: string): string => {
		switch (color) {
			case "primary": return alpha(theme.palette.primary.main,0.2);
			case "success": return alpha(theme.palette.success.main, 0.2);
			case "warning": return alpha(theme.palette.warning.main, 0.2);
			case "error": return alpha(theme.palette.error.main, 0.2);
			default: return "inherit";
		}
	};

	return (
		<Box
			p={2}
			flexGrow={1}
			sx={{
				transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
				backgroundColor: getColor(color),
				cursor: !!onClick ? "pointer" : "default"
			}}
			onClick={onClick}
			{...rest}>
			{children}
		</Box>
	);
}
