import { useState } from 'react';
import Layout from "../../Components/Layout/Layout";
import SalesTableSidebar from "./SalesTableSidebar";
import { Box } from "@mui/material";
import { UserRole, SaleQuery } from "../../system/Domain";
import useUser from "../../system/useUser";
import SalesNavigation from 'Navigation/SalesNavigation';
import SalesTable from './SalesTable';
import Search from 'Components/Search';
import List from "Components/List";
import useSaleQuery from 'system/useSaleQuery';

const SalesLayout = () => {
	const [user, , hasRole] = useUser();

	const [query, setQuery,] = useSaleQuery('salesLayoutQuery', {
		fullText: ""
	});

	return (
		<Layout
			title="Verkäufe"
			navigation={<SalesNavigation />}
			sidebar={<SalesTableSidebar query={query} setQuery={setQuery} />}
		>
			{user && hasRole(UserRole.SalesUser) && (
				<Box mb={2}>
					<List>
						<Search
							onSearch={s => setQuery(q => ({
								...q,
								fullText: s
							}))}
							realtime
							autoFocus
						/>
						<SalesTable
							query={query}
						/>
					</List>
				</Box>
			)}
		</Layout>
	);
};

export default SalesLayout;
