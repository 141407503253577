import { Box, CircularProgress, Grid } from "@mui/material";
import axios from "axios";
import VehicleNavigation from "Navigation/VehicleNavigation";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import ActionButton from "../../Components/ActionButton";
import Actions from "../../Components/Actions";
import Layout from "../../Components/Layout/Layout";
import { VehicleService } from "../../system/Domain";
import useAsyncEffect from "../../system/useAsyncEffect";
import useVehicle from "../../system/useVehicle";
import LotScanContextMenu from "../Vehicles/LotScanContextMenu";
import VehicleHeader from "../Vehicles/VehicleHeader";
import VehicleHeaderMenu from "../Vehicles/VehicleHeaderMenu";
import VehicleSubMenu from "../Vehicles/VehicleSubMenu";
import useVehicleServicePermissions from "./useVehicleServicePermissions";
import VehicleServiceTable from "./VehicleServiceTable";

export default () => {
	const history = useHistory();

	const { id } = useParams<{ id: string }>();
	const [vehicle] = useVehicle(id);

	const [vehicleServices, setVehicleServices] = useState<VehicleService[]>([]);
	const vehicleServicePermissions = useVehicleServicePermissions();

	useAsyncEffect(async () => {
		if (!vehicle) {
			return;
		}

		const { data } = await axios.get<VehicleService[]>(`/api/vehicles/services`, {
			params: {
				vehicleId: vehicle.id,
				take: 1000,
				sort: "dateCreated:desc"
			}
		});

		setVehicleServices(data);
	}, [vehicle]);

	return (
		<Layout
			title="Tätigkeiten"
			plateNumber={vehicle?.plateNumber}
			navigation={<VehicleNavigation vehicle={vehicle} />}
			contextMenu={[
				<LotScanContextMenu />,
				<VehicleHeaderMenu vehicle={vehicle} />
			]}
			subMenu={vehicle && <VehicleSubMenu vehicle={vehicle} />}
		>
			{!vehicle && (
				<CircularProgress size={48} />
			)}
			{vehicle && (
				<>
					<Grid container spacing={2} alignItems="flex-end" justifyContent="space-between">
						<Grid item>
							<VehicleHeader vehicle={vehicle} />
						</Grid>
						<Grid item>
							{
								vehicleServicePermissions.canCreate && (
									<Actions>
										<ActionButton onClick={() => history.push(`/vehicles/${vehicle.id}/services/new`)}>Tätigkeit anlegen</ActionButton>
									</Actions>
								)
							}
						</Grid>
					</Grid>
					<Box mt={2}>
						<VehicleServiceTable setVehicleServices={setVehicleServices} vehicleServices={vehicleServices} />
					</Box>
				</>
			)}
		</Layout>
	)
}
