import React, { useState } from 'react';
import { confirmable, createConfirmation, ReactConfirmProps } from "react-confirm";
import ThemedDialog from "../../Components/ThemedDialog";
import { Box, Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import PictureUpload from "../../Components/PictureUpload";
import { FileReference } from "../../system/Domain";
import { ImageUploadProps } from "../Vehicles/PictureSets/VehiclePictureSets";

interface Props extends UploadPictureProofOptions, ReactConfirmProps<FileReference> {
	show : boolean
}

const UploadPictureProofDialog = (props : Props) => {
	const [imageUpload, setImageUpload] = useState<ImageUploadProps | undefined>({
		fileReference: props.fileReference
	});

	const handleClose = () => {
		props.cancel();
	};

	return (
		<ThemedDialog open={props.show} maxWidth="sm" onClose={handleClose}>
			<DialogTitle>
				{props.headline}
			</DialogTitle>
			<DialogContent>
				<Box mb={2}>
					<PictureUpload
						disabled={false}
						fullWidth={false}
						readOnly={false}
						previewUrl=""
						setter={setImageUpload}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					color="secondary"
					href=""
					onClick={handleClose}
				>
					Abbrechen
				</Button>
				<Button
					variant="outlined"
					color="primary"
					href=""
					onClick={() => {
						if (imageUpload)
							props.proceed(imageUpload.fileReference)
					}}
				>
					Ok
				</Button>
			</DialogActions>
		</ThemedDialog>
	);
};

interface UploadPictureProofOptions {
	fileReference? : FileReference
	headline : string
}

export default (options : UploadPictureProofOptions) => {
	const dialog = confirmable(props => <UploadPictureProofDialog {...props} {...options} />);
	return createConfirmation<FileReference>(dialog)();
}
