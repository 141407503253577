import { CircularProgress } from "@mui/material";
import axios from "axios";
import VehicleSearch from "Components/VehicleSearch";
import OrdersNavigation from "Navigation/OrdersNavigation";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import useAsyncEffect from "system/useAsyncEffect";
import Layout from "../../../Components/Layout/Layout";
import NotificationSnackbar from "../../../Components/Layout/NotificationSnackbar";
import confirm from "../../../Dialogs/confirm";
import {
	CarType,
	CustomerOrder,
	DeliveryType,
	ReturnOrder,
	ReturnOrderTireType,
	TransportOrder,
	Vehicle,
} from "../../../system/Domain";
import ReturnOrdersForm, { ReturnOrderFormType } from "./ReturnOrdersForm";

const ReturnOrdersNew = (props: any) => {
	const { vehicleId } = useParams<{ vehicleId: string }>();
	const defaultTestState: ReturnOrderFormType = {
		vehicleId,
		isRegistered: true,
		hasValidMainInspection: true,
		isDrivable: true,
		tireType: "sr",
		carType: CarType.SUV,
		isPoolfleet: false,
		collection: {
			collectionType: "CustomEntry",
			address: {
				name: "Nino Seyyedi-Kuhlmann",
				street: "Sonninstraße 13",
				zipCode: "20097",
				city: "Hamburg",
				country: "Deutschland",
			},
			contact: {
				firstName: "Nino",
				lastName: "Seyyedi-Kuhlmann",
				email: "nino.seyyedikuhlmann@gmail.com",
				telephone: "+49 176 23597389",
			},
		},
		delivery: {
			deliveryType: DeliveryType.UsedCars,
			compound: {
				name: "Neuss",
			},
		},
		salesType: "Sale",
		doAssessment: false,
		doUnregistration: false,
	};

	const inputState: ReturnOrderFormType =
		(props.location && {
			...props.location.state,
			vehiclePlateNumber: undefined,
			delivery: { ...props.location.state?.delivery, isCrossTransport: true}
			// ...defaultTestState,
		}) ||
		undefined;

	const [error, setError] = useState<string>("");
	const [vehicle, setVehicle] = useState<Vehicle>();
	const history = useHistory();
	const [vehicleSearchFullText] = useState<string>(props.location?.state?.vehiclePlateNumber || "");

	const isCustomerDelivery = inputState?.delivery?.deliveryType === DeliveryType.CustomerDelivery;

	useAsyncEffect(async () => {
		if (!vehicleId) {
			return;
		}

		const { data } = await axios.get(`/api/vehicles/${vehicleId}`);
		onVehicleSelect(data);
	}, [vehicleId]);

	const onVehicleSelect = async (vehicle: Vehicle) => {
		const { data: customerOrders } = await axios.get<CustomerOrder[]>("/api/orders/customers", {
			params: {
				vehicleId: vehicle.id,
				status: "Created", // we ignore "completed", because they have spawned a return order and the relevant test is then finding active return orders
			},
		});

		if (customerOrders?.length >= 1) {
			setError("Es gibt bereits einen angefragten Kundenauftrag zu diesem Fahrzeug");
			return;
		}

		const { data: returnOrders } = await axios.get<ReturnOrder[]>("/api/orders/returns", {
			params: {
				vehicleId: vehicle.id,
			},
		});

		if (returnOrders?.length >= 1) {
			if (
				returnOrders.some(
					(s) =>
						s.status === "Created" ||
						s.status === "Imported" ||
						s.status === "CustomerCreated" ||
						s.status === "Approved",
				)
			) {
				setError("Es gibt für dieses Fahrzeug bereits einen offenen Rückholauftrag");
				return;
			} else if (returnOrders.some((s) => s.status === "Processed")) {
				const processedReturnOrders = returnOrders.filter((f) => f.status === "Processed");

				for (let processedReturnOrder of processedReturnOrders) {
					const { data: transportOrders } = await axios.get<TransportOrder[]>("/api/orders/transports", {
						params: {
							returnOrderId: processedReturnOrder.id,
							status: "Assigned",
						},
					});

					if (transportOrders.length > 0) {
						setError(
							"Es gibt zum dem Fahrzeug bereits einen offenen Transportauftrag. Bitte Transportauftrag prüfen.",
						);
						return;
					}
				}

				// const result = await confirm(
				// 	"Achtung - es liegt bereits ein verarbeiteter Rückholauftrag für das Fahrzeug vor. Wollen Sie wirklich fortfahren?",
				// 	"Warnung",
				// );
				//
				// if (!result.ok) {
				// 	setVehicle(null);
				// }
			}
		}

		if (!vehicleId) {
			history.push(`/vehicles/${vehicle.id}/orders/returns/new`, inputState);
			return;
		}

		setVehicle(vehicle);
	};

	const handleClearError = () => {
		setError("");
	};

	return (
		<Layout
			plateNumber={vehicle?.plateNumber}
			title={isCustomerDelivery ? "Neue Eigenanlieferung" : "Neue Rückholung"}
			navigation={<OrdersNavigation />}
		>
			<NotificationSnackbar message={error} onClose={handleClearError} color="error" />
			{!vehicleId && !vehicle && <VehicleSearch onClick={onVehicleSelect} fullText={vehicleSearchFullText} />}
			{!!vehicleId && !vehicle && <CircularProgress size={48} />}
			{vehicle && <ReturnOrdersForm vehicle={vehicle} formData={inputState} />}
		</Layout>
	);
};

export default ReturnOrdersNew;
