import React, { useState } from 'react';
import { CustomerOrder, UserRole } from "../../../system/Domain";
import TileContent from "../../../Components/Tiles/TileContent";
import { Box } from "@mui/material";
import FormChoices from "../../../Components/FormChoices";
import FormYesNo from "../../../Components/FormYesNo";
import TextBlock from "../../../Components/Layout/TextBlock";
import TileActions from "../../../Components/Tiles/TileActions";
import ActionButton from "../../../Components/ActionButton";
import Tile from "../../../Components/Tiles/Tile";
import useForm from "../../../system/useForm";
import { CustomerOrderDetailsFormType } from "./CustomerOrdersNew";
import moment from "moment";
import dateFormats from "../../../system/dateFormats";
import { useTheme } from "@mui/styles";
import useUser from "../../../system/useUser";
import CustomerQuickFAQ from "../../Customer/CustomerQuickFAQ";

interface Props {
	customerOrder: CustomerOrder
	save: (formData: CustomerOrderDetailsFormType) => void
}

const CustomerOrderConfigurationTile = (props: Props) => {
	const theme = useTheme();

	const [, , hasRole] = useUser();
	const [editMode, setEditMode] = useState(false);
	const [saving, setSaving] = useState(false);
	const [customerOrderDetails, setCustomerOrderDetails] = useState<CustomerOrderDetailsFormType>({
		isPoolfleet: props.customerOrder.isPoolfleet,
		assessmentType: props.customerOrder.assessmentType,
		allowEarlyReturn: props.customerOrder.allowEarlyReturn
	});

	const isReceivablesManagement = hasRole(UserRole.ReceivablesManagement);

	const form = useForm({
		values: customerOrderDetails,
		setValues: setCustomerOrderDetails
	});

	const save = () => {
		setSaving(true);

		try {
			props.save(customerOrderDetails);
		} finally {
			setSaving(false);
			setEditMode(false);
		}
	};

	const isRkv = props.customerOrder.isRkv;

	return (
		<Tile title="Details">
			<TileContent>
				{editMode && (
					<Box>
						<FormChoices
							name="assessmentType"
							label="Gutachtentyp"
							form={form}
							choices={{
								"Restwertgutachten": "R1 - Restwertgutachten",
								"Minderwertsgutachten": "R2 - Minderwertsgutachten"
							}}
							options={{ required: true }}
						/>
						<Box mt={2}>
							<FormYesNo
								name="isPoolfleet"
								label="Handelt es sich um ein ALD Flex Fahrzeug?"
								form={form}
								options={{ required: true }}
							/>
						</Box>
					</Box>
				)}
				{!editMode && (
					<Box>
						<TextBlock
							primary={props.customerOrder.assessmentType}
							secondary={<em>{props.customerOrder.isPoolfleet ? "ALD Flex Fahrzeug" : "kein ALD Flex Fahrzeug"}</em>}
							tertiary={<Box fontStyle="italic" color={isRkv ? theme.palette.secondary.light : undefined}>{isRkv ? "Rkv" : "kein Rkv"}</Box>}
						/>
					</Box>
				)}
				<Box mt={3}>
					<TextBlock
						primary={moment(props.customerOrder.dateContractEnd).format(dateFormats.date)}
						secondary="geplantes Vertragsende"
					/>
				</Box>
				<Box mt={3}>
					<TextBlock
						primary={`${props.customerOrder.leasmanContract.serviceAgent.name} - ${props.customerOrder.leasmanContract.serviceAgent.email}`}
						secondary="hinterlegter Kundenbetreuer"
					/>
				</Box>
				{!editMode && isReceivablesManagement && (
					<Box mt={3} sx={{
						...(props.customerOrder.allowEarlyReturn === true && { color: theme.palette.secondary.light })
					}}>
						<TextBlock
							primary={props.customerOrder.allowEarlyReturn === true ? "Vertragslaufzeitprüfung ist ausgesetzt" : "Vertragslaufzeitprüfung wird durchgeführt"}
							secondary="Prüfung des Vertragsendes"
						/>
					</Box>
				)}
				{editMode && isReceivablesManagement && (
					<Box mt={3}>
						<FormYesNo
							name="allowEarlyReturn"
							label={
								<>
									Handelt es sich um eine frühzeitige Rückführung?
									<CustomerQuickFAQ faqKey="creation-early-return-hint" />
								</>
							}
							form={form}
							options={{ required: true }}
						/>
					</Box>
				)}
			</TileContent>
			<TileActions>
				<ActionButton
					variant="text"
					color="primary"
					disabled={props.customerOrder.status !== "Created"}
					onClick={() => setEditMode(e => !e)}
				>
					{editMode ? "Abbrechen" : "Anpassen"}
				</ActionButton>
				{editMode && (
					<ActionButton
						variant="text"
						disabled={saving || (customerOrderDetails.isPoolfleet === null || customerOrderDetails.assessmentType === null)}
						color="secondary"
						onClick={save}
					>
						Speichern
					</ActionButton>
				)}
			</TileActions>
		</Tile>
	);
};

export default CustomerOrderConfigurationTile;
