import React from 'react';
import { ReturnOrder } from "../../../system/Domain";
import { blue, green, orange, red, yellow } from "@mui/material/colors";
import { Chip } from "@mui/material";
import TextBlock from "../../../Components/Layout/TextBlock";
import dateFormats from "../../../system/dateFormats";
import moment from "moment";
import { translateReturnOrderStatus } from "../../../system/translate";

interface Props {
	returnOrder: ReturnOrder
}

const statusSettings = [
	{
		key: "Created",
		label: translateReturnOrderStatus("Created"),
		backgroundColor: yellow[500],
		color: "black",
		date: (returnOrder: ReturnOrder): string => moment(returnOrder.dateCreated).format(dateFormats.dateTime)
	},
	{
		key: "Cancelled",
		label: translateReturnOrderStatus("Cancelled"),
		backgroundColor: red[500],
		color: "white",
		date: (returnOrder: ReturnOrder): string => moment(returnOrder.dateCancelled).format(dateFormats.dateTime)
	},
	{
		key: "Imported",
		label: translateReturnOrderStatus("Imported"),
		backgroundColor: orange[500],
		color: "white",
		date: (returnOrder: ReturnOrder): string => moment(returnOrder.dateImported).format(dateFormats.dateTime)
	},
	{
		key: "Assigned",
		label: translateReturnOrderStatus("Assigned"),
		backgroundColor: yellow[800],
		color: "white",
		date: (returnOrder: ReturnOrder): string => moment(returnOrder.dateAssigned).format(dateFormats.dateTime)
	},
	{
		key: "Processed",
		label: translateReturnOrderStatus("Processed"),
		backgroundColor: green[500],
		color: "white",
		date: (returnOrder: ReturnOrder): string => moment(returnOrder.dateProcessed).format(dateFormats.dateTime)
	},
	{
		key: "CustomerCreated",
		label: translateReturnOrderStatus("CustomerCreated"),
		backgroundColor: blue[500],
		color: "white",
		date: (returnOrder: ReturnOrder): string => moment(returnOrder.dateCreated).format(dateFormats.dateTime)
	},
	{
		key: "Approved",
		label: translateReturnOrderStatus("Approved"),
		backgroundColor: blue[800],
		color: "white",
		date: (returnOrder: ReturnOrder): string => moment(returnOrder.dateApproved).format(dateFormats.dateTime)
	},
	{
		key: "Terminated",
		label: translateReturnOrderStatus("Terminated"),
		backgroundColor: red[800],
		color: "white",
		date: (returnOrder: ReturnOrder): string => moment(returnOrder.dateTerminated).format(dateFormats.dateTime)
	}
];

const ReturnOrderStatusPill = (props: Props) => {
	const setting = statusSettings.find(f => f.key === props.returnOrder.status)!;

	return (
		<TextBlock
			primary={
				<Chip
					label={setting.label}
					size="small"
					style={{ backgroundColor: setting.backgroundColor, color: setting.color }}
				/>
			}
			secondary={setting.date(props.returnOrder)}
		/>
	);
};

export default ReturnOrderStatusPill;
