import React from "react";
import Tile from "../../../Components/Tiles/Tile";
import TileContent from "../../../Components/Tiles/TileContent";
import { ArmsTableValue } from "./ArmsVehicle";
import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import armsValueConverter from "./armsValueConverter";

interface ArmsAttributeTableGroupProps {
	name : string
	value : ArmsTableValue
}

const ArmsAttributeTableGroup = ({ name, value } : ArmsAttributeTableGroupProps) => (
	<Tile title={name}>
		<TileContent>
			{value.rows.length === 0 && (
				<Typography>Keine Daten</Typography>
			)}
			<Table size="small">
				<TableHead>
					<TableRow>
						{value.columns.map(column => (
							<TableCell key={column}>{column}</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{value.rows.map(row => (
						<TableRow key={row.rowNumber}>
							{row.columns.map(column => (
								<Tooltip key={`${column.name}_${row.rowNumber}`} title={column.name}>
									<TableCell>{armsValueConverter(column)}</TableCell>
								</Tooltip>
							))}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TileContent>
	</Tile>
);

export default ArmsAttributeTableGroup;
