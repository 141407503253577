import React from 'react';
import Layout from "../../../Components/Layout/Layout";
import OrdersSubMenu from "../OrdersSubMenu";
import Imports from "../../Imports/Imports";
import OrdersNavigation from "../../../Navigation/OrdersNavigation";

const UnregistrationOrderImports = () => {
	return (
		<Layout
			navigation={<OrdersNavigation />}
			subMenu={<OrdersSubMenu />}
		>
			<Imports importType="CompleteUnregistrationOrdersImport" />
		</Layout>
	);
};

export default UnregistrationOrderImports;
