import React, { useState } from 'react';
import SidebarGroup from 'Components/Sidebar/SidebarGroup';
import SidebarButton from 'Components/Sidebar/SidebarButton';
import Sidebar from 'Components/Sidebar/Sidebar';
import uploadPostman from './uploadPostman';
import { useDispatch } from 'react-redux';
import { downloadApiAsJson } from './postmanUtils';
import { CallMerge, GetApp } from "@mui/icons-material";

const PostmanSidebar = () => {
	const [isWorking, setIsWorking] = useState<boolean>(false);
	const dispatch = useDispatch();

	const apiToPostmanFile = () => {
		return async () => {
			setIsWorking(true);

			try {
				await downloadApiAsJson();
			}
			finally {
				setIsWorking(false);
			}
		}
	};

	const apiMergeWithCollection = async () => {
		// suspend usual error hanlding - we do it on our own
		// (uploadPostman is a dialog, so we can't use Redux calls inside it
		//  therefore - we do it here)
		dispatch({ type: "SUSPEND_ERROR_HANDLING" });

		setIsWorking(true);
		await uploadPostman();
		setIsWorking(false);

		dispatch({ type: "RESUME_ERROR_HANDLING" });
	}

	return (
		<Sidebar>
			<SidebarGroup>
				<SidebarButton
					startIcon={<GetApp />}
					disabled={isWorking}
					onClick={apiToPostmanFile()}
					label="Export"
				/>
			</SidebarGroup>
			<SidebarGroup>
				<SidebarButton
					startIcon={<CallMerge />}
					disabled={isWorking}
					onClick={async () => await apiMergeWithCollection()}
					label="Zusammenführen"
				/>
			</SidebarGroup>
		</Sidebar>
	)
}

export default PostmanSidebar;

